import { baseUrl } from './general-api';
import { Ventilator } from './ventilators-api';

/**
 * Base URL for the technologies API.
 */
export const baseTechnologiesPath = baseUrl + '/.rest/delivery/v1/technologies';

export const getTechnologies = (device?: string): Promise<Ventilator[]> => {
  let url = baseTechnologiesPath;
  const params: Record<string, string> = {};
  params.limit = '10000';
  if (device) {
    params.ventilator = device;
  }
  const parameterString = new URLSearchParams(params).toString();
  url = `${url}?${parameterString}`;

  return fetch(`${url}`)
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        return data;
      }

      return [];
    })
    .catch(() => {
      return [];
    });
};
