import { ComponentObservable, componentObserver } from '../../component-observer';
import { currentBreakpoint, equalBreakpoints } from '../../utils/resize.utils';
import { Icon } from '../../general/icons/Icon';
import { Param, readUrlParameter, writeToUrl } from '../../utils/url.utils';

/**
 * Initializes the main navigation
 */
class MainNavigationModule implements ComponentObservable {
  /**
   * Selector of all main navigation elements that must be initialized in javascript.
   */
  componentSelector = '.main-navigation';

  /**
   * Current breakpoint
   */
  breakpoint = null;

  /**
   * Initialize all main navigation elements on the current page.
   * @param observe Boolean if the MainNavigationModule should listen for changes in the DOM and initialize dynamically
   *   added main navigation elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allElements = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allElements.length; i++) {
        // Set an internal id to each main navigation element for mapping the intervals
        allElements[i].setAttribute('data-id', i.toString());
        this.initialize(allElements[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize main navigation elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Closes the menu
   * @param mainNavigation The main navigation root element
   */
  closeMenu(mainNavigation: HTMLElement): void {
    const metaNavigation = mainNavigation.querySelector('.main-navigation__meta');
    const button = metaNavigation.querySelector('.menu-button');
    if (button) {
      const menu = mainNavigation.parentElement.querySelector('.main-navigation__menu');
      button.setAttribute('aria-expanded', 'false');
      menu.setAttribute('aria-hidden', 'false');
      menu.querySelectorAll('a').forEach((link) => {
        link.setAttribute('tabindex', '0');
      });
      // Toggle background scrolling if menu is opened or closed
      if (document.documentElement.dataset.preventScroll !== 'true') {
        document.documentElement.classList.remove('no-scroll');
      }
      // Hide e-catalog if menu is open to avoid overlay problems
      const eCatalogMenu = document.querySelector<HTMLElement>('.e-catalog');
      const resourcesCenterMenu = document.querySelector<HTMLElement>('.resources-center');
      const newsArchiveMenu = document.querySelector<HTMLElement>('.news-archive');
      eCatalogMenu?.removeAttribute('style');
      resourcesCenterMenu?.removeAttribute('style');
      newsArchiveMenu?.removeAttribute('style');

      setTimeout(() => {
        menu.setAttribute('aria-hidden', 'true');
        menu.querySelectorAll('a').forEach((link) => {
          link.setAttribute('tabindex', '-1');
        });
      }, 50);
    }
  }

  /**
   * Opens the menu
   * @param mainNavigation The main navigation root element
   */
  openMenu(mainNavigation: HTMLElement): void {
    const metaNavigation = mainNavigation.querySelector('.main-navigation__meta');
    const menu = mainNavigation.parentElement.querySelector('.main-navigation__menu');
    const button = metaNavigation.querySelector('.menu-button');
    button.setAttribute('aria-expanded', 'true');
    menu.setAttribute('aria-hidden', 'true');
    // Close global search if opened
    this.closeGlobalSearch(mainNavigation);
    // Toggle background scrolling if menu is opened or closed
    document.documentElement.classList.add('no-scroll');
    // Hide e-catalog if menu is open to avoid overlay problems
    const eCatalogMenu = document.querySelector<HTMLElement>('.e-catalog');
    const resourcesCenterMenu = document.querySelector<HTMLElement>('.resources-center');
    const newsArchiveMenu = document.querySelector<HTMLElement>('.news-archive');
    if (eCatalogMenu) {
      eCatalogMenu.style.display = 'none';
    }
    if (resourcesCenterMenu) {
      resourcesCenterMenu.style.display = 'none';
    }
    if (newsArchiveMenu) {
      newsArchiveMenu.style.display = 'none';
    }
    setTimeout(() => {
      menu.removeAttribute('aria-hidden');
    }, 50);
  }

  /**
   * Adds or removes the menu button depending on the screen size.
   * @param mainNavigation The main navigation element
   */
  toggleMenuButton(mainNavigation: HTMLElement): void {
    const metaNavigation = mainNavigation.querySelector('.main-navigation__meta');
    const menu = mainNavigation.parentElement.querySelector('.main-navigation__menu');
    let menuButton = metaNavigation.querySelector('.menu-button');
    // Menu button click listener
    const clickListener = (): void => {
      if (menuButton.getAttribute('aria-expanded') === 'true') {
        this.closeMenu(mainNavigation);
      } else {
        this.openMenu(mainNavigation);
      }
    };

    // Remove menu button on desktop or larger
    if (this.breakpoint.isDesktop && menuButton) {
      menuButton.removeEventListener('click', clickListener);
      metaNavigation.removeChild(menuButton.parentElement);
      menu.removeAttribute('aria-hidden'); // Remove hidden menu
    } else if (!this.breakpoint.isDesktop && !menuButton) {
      // Create menu button on tablet or smaller
      const li = document.createElement('li');
      li.innerHTML = `<button class="menu-button" aria-label="menu" aria-haspopup="menu" aria-expanded="false">
      <div class="hamburger-icon">
        <div class="hamburger-icon__line"></div>
        <div class="hamburger-icon__line"></div>
        <div class="hamburger-icon__line"></div>
      </div>
    </button>`;
      metaNavigation.appendChild(li);
      menu.setAttribute('aria-hidden', 'true'); // Hide menu on mobile
      menu.querySelectorAll('a').forEach((link) => {
        link.setAttribute('tabindex', '-1');
      });
      // Get the menu button and add click listener
      menuButton = metaNavigation.querySelector('.menu-button');
      menuButton.addEventListener('click', clickListener);
    }
  }

  /**
   * Moves the menu to the main navigation wrapper on mobile and moves it back to the main navigation on desktop.
   * This is necessary for the animation to keep the menu below the main navigation on mobile.
   * @param mainNavigation The main navigation element
   */
  moveMenu(mainNavigation: HTMLElement): void {
    const menu = mainNavigation.parentElement.querySelector('.main-navigation__menu');
    const menuList = menu.firstElementChild; // ul element with class "menu"
    const isInMainNavigation = mainNavigation.querySelector('.main-navigation__menu');
    const meta = mainNavigation.parentElement.querySelector('.main-navigation__meta');
    const loginButton = mainNavigation.parentElement.querySelector('#meta-link-button-user');
    const globalButton = mainNavigation.parentElement.querySelector('#meta-link-button-global');

    if (!loginButton || !globalButton) {
      // eslint-disable-next-line no-console
      console.error(
        'Missing ids: Login button must have the id "meta-link-button-user", language button must have the id "meta-link-button-global"'
      );
    }

    // Check if menu is a child of the main navigation element on desktop or move it otherwise
    if (this.breakpoint.isDesktop && !isInMainNavigation) {
      const eCatalogMenu = document.querySelector<HTMLElement>('.e-catalog');
      const resourcesCenterMenu = document.querySelector<HTMLElement>('.resources-center');
      const newsArchiveMenu = document.querySelector<HTMLElement>('.news-archive');
      eCatalogMenu?.removeAttribute('style');
      resourcesCenterMenu?.removeAttribute('style');
      newsArchiveMenu?.removeAttribute('style');
      mainNavigation.insertBefore(menu, meta);
      // Put the login and global button inside an li element again and
      // insert them to the meta navigation (desktop view)
      const liLogin = document.createElement('li');
      liLogin.appendChild(loginButton);
      const liGlobal = document.createElement('li');
      liGlobal.appendChild(globalButton);
      meta.insertBefore(liGlobal, meta.firstElementChild);
      meta.insertBefore(liLogin, liGlobal);
      // Remove empty burger menu list item
      const metaMenuItem = mainNavigation.querySelector('.menu__item--meta');
      metaMenuItem.remove();
    } else if (!this.breakpoint.isDesktop && isInMainNavigation) {
      mainNavigation.parentElement.appendChild(menu);
      // Create a new list item and insert the login and global button (mobile view in burger menu)
      const metaMenuItem = document.createElement('li');
      metaMenuItem.classList.add('menu__item', 'menu__item--meta');
      loginButton.parentElement.remove();
      globalButton.parentElement.remove();
      metaMenuItem.appendChild(loginButton);
      metaMenuItem.appendChild(globalButton);
      menuList.insertBefore(metaMenuItem, menuList.firstElementChild);
    }
  }

  /**
   * Initializes and updates the main navigation on breakpoint changes
   * @param mainNavigation The main navigation element
   */
  updateMainNavigation(mainNavigation: HTMLElement): void {
    this.moveMenu(mainNavigation);
    this.toggleMenuButton(mainNavigation);
  }

  /**
   * Closes the global search
   * @param mainNavigation The main navigation root element
   */
  closeGlobalSearch(mainNavigation: HTMLElement): void {
    const searchButton = mainNavigation.querySelector('#meta-link-button-search');
    if (!searchButton) {
        return;
    }
    const icon = searchButton.querySelector('.meta-link__icon');
    const globalSearch = document.querySelector<HTMLElement>('.global-search-wrapper');
    if (globalSearch) {
      icon.innerHTML = Icon.search;
      searchButton.parentElement.setAttribute('aria-expanded', 'false');
      setTimeout(() => {
        globalSearch.setAttribute('aria-hidden', 'true');
        window.dispatchEvent(new Event('globalsearchclosed'));
      }, 50);
      // Toggle background scrolling if menu is opened or closed
      if (document.documentElement.dataset.preventScroll !== 'true') {
        document.documentElement.classList.remove('no-scroll');
      }
    }

    // remove the search from the URL
    writeToUrl(null, [Param.GLOBAL_SEARCH_PHRASE, Param.GLOBAL_SEARCH_TYPE], false, true);
  }

  /**
   * Opens the global search
   * @param mainNavigation The main navigation root element
   */
  openGlobalSearch(mainNavigation: HTMLElement): void {
    const searchButton = mainNavigation.querySelector('#meta-link-button-search');
    const icon = searchButton.querySelector('.meta-link__icon');
    const globalSearch = document.querySelector<HTMLElement>('.global-search-wrapper');
    icon.innerHTML = Icon.closeThick;
    searchButton.parentElement.setAttribute('aria-expanded', 'true');
    setTimeout(() => {
      globalSearch.removeAttribute('aria-hidden');
    }, 50);

    // Prevent body from scrolling
    document.documentElement.classList.add('no-scroll');

    // Close menu if opened
    this.closeMenu(mainNavigation);
  }

  /**
   * Initializes the buttons in the main navigation
   * @param mainNavigation The main navigation root element
   */
  initializeButtons(mainNavigation: HTMLElement): void {
    const searchButton = mainNavigation.querySelector('#meta-link-button-search');
    const globalSearch = document.querySelector<HTMLElement>('.global-search-wrapper');
    if (globalSearch && searchButton) {
      searchButton.addEventListener('click', (event) => {
        event.stopImmediatePropagation();
        if (globalSearch.getAttribute('aria-hidden') !== 'true') {
          this.closeGlobalSearch(mainNavigation);
        } else {
          this.openGlobalSearch(mainNavigation);
        }
      });
    }
  }

  /**
   * Initializes the global search.
   * @param mainNavigation The main navigation root element
   */
  initializeGlobalSearch(mainNavigation: HTMLElement): void {
    const globalSearchPhrase = readUrlParameter(Param.GLOBAL_SEARCH_PHRASE);

    if (globalSearchPhrase) {
      // if there was a search phrase, open the global search
      this.openGlobalSearch(mainNavigation);
    }
  }

  /**
   * Initialize the given main navigation.
   * @param mainNavigation The main navigation element that should be initialized
   */
  initialize(mainNavigation: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, mainNavigation);
    this.breakpoint = currentBreakpoint();
    this.updateMainNavigation(mainNavigation);
    this.initializeButtons(mainNavigation);
    this.initializeGlobalSearch(mainNavigation);

    // timeoutId for debounce mechanism
    let timeoutId = null;
    // Set window resize listener
    const resizeListener = (): void => {
      // Prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // Change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        const newBreakPoint = currentBreakpoint();
        if (!equalBreakpoints(newBreakPoint, this.breakpoint)) {
          this.breakpoint = newBreakPoint;
          this.updateMainNavigation(mainNavigation);
        }
      }, 500);
    };

    // Set resize listener
    window.addEventListener('resize', resizeListener);

    const importantMessage = mainNavigation.parentElement.querySelector<HTMLElement>('.important-message');
    // Scroll listener to fade out/in navigation on scroll
    let lastScrollTop = 0;
    const scrollListener = (): void => {
      const doc = document.documentElement;
      const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      // Get sub navigation if available
      const subNavigation = document.querySelector<HTMLElement>('.sub-navigation');
      // Scrolls down and hide main navigation
      if (scrollTop > lastScrollTop && scrollTop > 0) {
        mainNavigation.parentElement.style.top = '-120px';
        // Hide sub navigation if main navigation is above
        // otherwise it is shown in Safari if you scroll higher than 0
        if (subNavigation) {
          subNavigation.style.opacity = '1';
        }
      } else if (!subNavigation?.getAttribute('data-interaction')) {
        // Scrolls up and show main navigation
        mainNavigation.parentElement.style.top = '0';
        // Hide sub navigation if main navigation is shown
        if (subNavigation) {
          subNavigation.style.opacity = '0';
        }
      }
      // Fade out/in the important message only on top
      if (importantMessage && scrollTop > 70) {
        importantMessage.style.transform = 'translate(0, -120px)';
      } else if (importantMessage) {
        importantMessage.style.transform = 'translate(0, 0)';
      }
      lastScrollTop = scrollTop;
    };

    // Set scroll listener
    window.addEventListener('scroll', scrollListener);
  }
}

const mainNavigationModule = new MainNavigationModule();
export { mainNavigationModule as mainNavigation };
