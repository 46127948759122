/**
 * The base url can be set using the STORYBOOK_BASE_URL environment variable. For local development this should be the
 * public instance (dev-public.hamilton-medical.com). For production this variable should not be set so the base
 * url is empty. Which means that all API calls are relative to the current host. This is necessary because authors
 * may access the page through the authors instance (dev-author.hamilton-medical.com) and then the API requests need
 * to use that url also.
 */
export const baseUrl = process.env.STORYBOOK_BASE_URL || '';

/**
 * Shared header for all json requests.
 */
export const requestHeadersJson = {
  'Content-Type': 'application/json'
};

/**
 * Shared header for all json requests that require basic auth for testing.
 */
export const requestHeadersJsonBasicAuth =
  process.env.STORYBOOK_INCLUDE_AUTH === 'yes'
    ? {
        'Content-Type': 'application/json',
        Authorization: `Basic ${process.env.STORYBOOK_BASIC_AUTH_PASSWORD}`
      }
    : requestHeadersJson;

/**
 * Shared header for requests that require basic auth for testing.
 */
export const requestHeadersBasicAuth =
  process.env.STORYBOOK_INCLUDE_AUTH === 'yes'
    ? {
        Authorization: `Basic ${process.env.STORYBOOK_BASIC_AUTH_PASSWORD}`
      }
    : {};

export enum ResourceDetailPageType {
  Resource = 'resource',
  Article = 'article'
}

/**
 * Returns the html from the given url
 */
export const getHtmlFromUrl = (customPath: string): Promise<string> => {
  const urlPath = customPath.split('.html');
  const newUrl = urlPath[0] + '~mgnlArea=content~.html';
  const url = baseUrl + newUrl;

  return fetch(url)
    .then((response) => response.text())
    .then((data) => {
      return data;
    })
    .catch(() => {
      return '';
    });
};

/**
 * Returns the html from the given path. This is the detail page content for the resource center.
 */
export const getCatalogDetailPageFromUrl = (
  url: string,
  resourceId: string,
  resourceType: string,
  resourceDetailPageType: ResourceDetailPageType
): Promise<string> => {
  const appendix =
    resourceDetailPageType === ResourceDetailPageType.Article
      ? 'mgnlArea=articleContent~.html'
      : 'mgnlArea=downloadContent~.html';

  const urlPath = `${url}~${resourceType}~${resourceId}~${appendix}`;
  return fetch(urlPath)
    .then((response) => response.text())
    .then((data) => {
      return data;
    })
    .catch(() => {
      return '';
    });
};

/**
 * Returns the URL for the detail page of the given resource.
 */
export const getCatalogDetailPageUrl = (url: string, resourceId: string, resourceType: string, title: string): string => {
  return `${url}~${resourceType}~${resourceId}~${title}~.html`;
};
