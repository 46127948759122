import { baseUrl } from '../api/general-api';

/**
 * Converts the given fileSize from bytes to kb or mb, depending on the file size.
 * Returns an empty string if the given size is NaN or 0.
 * @param size The size to convert
 */
export const convertFileSize = (size: string): string => {
  const fileSize = Number(size);
  let result = 0;
  if (!isNaN(fileSize) && fileSize !== 0) {
    const kb = fileSize < 10 ** 5;
    result = kb ? fileSize / 10 ** 3 : fileSize / 10 ** 6;
    return result.toFixed(1) + (kb ? ' KB' : ' MB');
  }
  return '';
};

/**
 * Scrolls to the top.
 */
export const scrollToTop = (): void => {
  window.scrollTo({ top: 0 });
};

/**
 * Checks if the given url exists and if it is a valid image.
 * @param url The image url to check
 */
export const checkImage = async (url: string): Promise<boolean> => {
  const res = await fetch(url);
  const buff = await res.blob();

  return buff.type.startsWith('image/');
};

/**
 * Scrolls smooth to the given element
 * @param element The element to scroll to
 */
export const smoothScrollToElement = (element: HTMLElement, additionalOffset = 0): void => {
  // Take into account the sub navigation height so that the element is not overlapped by the sub navigation
  const subNavigation = document.querySelector('.sub-navigation');
  const subNavHeight = subNavigation ? subNavigation.clientHeight : 0;

  const y = element.getBoundingClientRect().top + window.pageYOffset - subNavHeight;
  setTimeout(() => {
    window.scroll({ top: y - additionalOffset, behavior: 'smooth' });
  }, 50);
};

/**
 * Returns the full URL to a file with the given path.
 * @param filePath: Path to the file as provided by the API.
 */
export const getUrlForFileDownload = (filePath: string): string => {
  // File paths sometimes contain non-breaking spaces which can not
  // be removed by the backend but break the image URL, therefore
  // we need to replace all those non-breaking spaces with regular ones.
  const nonBreakingSpace = '%C2%A0';
  const regularSpace = '%20';

  // Check if filePath already contains the base url, in this case do nothing and return the path
  if (filePath && filePath.indexOf(baseUrl) === -1 && !filePath.includes('https://')) {
    return baseUrl + filePath.replaceAll(nonBreakingSpace, regularSpace);
  } else if (filePath) {
    return filePath;
  }
  return '';
};

/**
 * Linear interpolation between two values v0 and v1. E.g. gives a number between v0 and v1 at a specified position t.
 * @param v0: Start value
 * @param v1: End value
 * @param t: Interpolation value between the two values v0 and v1. Possible values between 0 and 1.
 */
export const lerp = (v0: number, v1: number, t: number): number => {
  return v0 * (1 - t) + v1 * t;
};

/**
 * Clamps the given number in a range between min and max value.
 * @param v: Value that should be clamped
 * @param min: v should not be smaller than this value. If it is, the min value will be returned.
 * @param max: v should not be larger than this value. If it is, the max value will be returned.
 */
export const clamp = (v: number, min: number, max: number): number => {
  return Math.max(min, Math.min(max, v));
};

export const adaptOverviewPageForPartnernet = (url: string): string => {
  if (window.location.pathname.indexOf('/Partner-net/') > -1 && url) {
    const currentUrl = new URL(window.location.href);
    let locale = '/';
    if (!currentUrl.pathname.startsWith('/Partner-net')) {
      locale = currentUrl.pathname.slice(0, currentUrl.pathname.indexOf('/Partner-net'));
      url = url.replace(locale, '');
      locale += '/';
    }
    if (url && url.startsWith('http')) {
      const urlInst = new URL(url);
      return urlInst.origin + '/' + locale + 'Partner-net' + urlInst.pathname;
    }
    
    return locale + 'Partner-net' + url;
  }
  return url;
};
