import { ComponentObservable, componentObserver } from '../../../component-observer';

/**
 * Initializes the footnote tooltip and set listener on resize
 */
class FootnoteModule implements ComponentObservable {
  /**
   * Selector of all buttons that must be initialized in javascript.
   */
  componentSelector = '.footnote';

  /**
   * Initialize all footnote tooltips on the current page.
   * @param observe Boolean if the FootnoteModule should listen for changes in the DOM and initialize dynamically added
   *   buttons
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allElements = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allElements.length; i++) {
        this.initialize(allElements[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize footnote tooltips when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given footnote tooltip.
   * @param root The footnote root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);

    // Delay needed to properly position the tooltip
    setTimeout(()=> {
      this.setTooltipPosition(root);
    }, 1000);

    // timeoutId for debounce mechanism
    let timeoutId = null;
    // Set window resize listener
    const resizeListener = (): void => {
      // Prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // Change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        this.setTooltipPosition(root);
      }, 500);
    };
    // Set resize listener
    window.addEventListener('resize', resizeListener);
  }

  /**
   * Sets the tooltip position depending on the available space next to the anchor element (footnote).
   * @param root The footnote root element
   */
  setTooltipPosition(root: HTMLElement): void {
    const tooltip = root.querySelector<HTMLElement>('.footnote-text');
    const boundAnchor = root.getBoundingClientRect();
    const boundTooltip = tooltip.getBoundingClientRect();
    const left = boundAnchor.left + window.scrollX; // Available space left
    const right = window.outerWidth - left - boundAnchor.width; // Available space right
    const tooltipWidth = boundTooltip.width - 48;

    if (right >= tooltipWidth && left < tooltipWidth) {
      root.classList.remove('footnote--open-left');
      root.classList.remove('footnote--open-center');
      root.classList.add('footnote--open-right');
    } else if (right < tooltipWidth && left < tooltipWidth) {
      root.classList.remove('footnote--open-left');
      root.classList.add('footnote--open-center');
      root.classList.remove('footnote--open-right');
    } else {
      root.classList.add('footnote--open-left');
      root.classList.remove('footnote--open-center');
      root.classList.remove('footnote--open-right');
    }
  }
}

const footnoteModule = new FootnoteModule();
export { footnoteModule as footnote };
