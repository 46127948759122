import { baseUrl } from './general-api';

export interface ReferenceResponse {
  id: string;
  type: string;
  refId: string;
  listItem: string;
  overlay: string;
}

/**
 * Base URL for the Contacts API.
 */
export const referencesEndpoint = baseUrl + '/.rest/utils/v1/footnote-reference';

export const getReferenceInfo = async (text: string): Promise<ReferenceResponse[]> => {
  const res = await fetch(referencesEndpoint, {
    method: 'POST',
    headers: {
      'Cotent-Type': 'plain/text; charset=utf-8'
    },
    body: text
  });
  return await res.json();
};
