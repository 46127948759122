import { currentBreakpoint, equalBreakpoints } from './resize.utils';

/**
 * Module that sends custom events when breakpoints change.
 */
export class BreakpointListenerModule {
  /**
   * Current breakpoint
   */
  breakpoint = null;

  constructor() {
    this.breakpoint = currentBreakpoint();

    // timeoutId for debounce mechanism
    let timeoutId = null;
    // Set window resize listener
    const resizeListener = (): void => {
      // Prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // Change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        const newBreakpoint = currentBreakpoint();
        // Render view depending on screen size
        if (
          (this.breakpoint.isMobileAny && !newBreakpoint.isMobileAny) ||
          (!this.breakpoint.isMobileAny && newBreakpoint.isMobileAny)
        ) {
          // Send custom event when breakpoint changed from mobile -> desktop or the other way round
          window.dispatchEvent(new Event('breakpoint-changed-mobile-desktop'));
        }

        if (
          (this.breakpoint.isProductAnimation && !newBreakpoint.isProductAnimation) ||
          (!this.breakpoint.isProductAnimation && newBreakpoint.isProductAnimation)
        ) {
          // Send custom event when breakpoint changed from mobile -> desktop or the other way round
          window.dispatchEvent(new Event('breakpoint-changed-product-animation'));
        }

        if (!equalBreakpoints(this.breakpoint, newBreakpoint)) {
          // Send custom event when breakpoint changed
          window.dispatchEvent(new Event('breakpoint-changed'));
        }

        this.breakpoint = newBreakpoint;
      }, 500);
    };
    // Set resize listener
    window.addEventListener('resize', resizeListener);
  }

  /**
   * Calls the given function as soon as the breakpoint changes between any of the mobile breakpoints
   * (landscape and portrait) and the desktop/tablet breakpoints
   */
  listenForBreakpointChangeBetweenMobileAndDesktop(listener: () => void): void {
    window.addEventListener('breakpoint-changed-mobile-desktop', listener);
  }

  /**
   * Calls the given function as soon as the breakpoint changes between the product animation breakpoints
   */
  listenForBreakpointChangeBetweenProductAnimationBreakpoints(listener: () => void): void {
    window.addEventListener('breakpoint-changed-product-animation', listener);
  }

  /**
   * Calls the given function when the breakpoint changes.
   */
  listenForBreakpointChange(listener: () => void): void {
    window.addEventListener('breakpoint-changed', listener);
  }
}

const breakpointListenerModule = new BreakpointListenerModule();
export { breakpointListenerModule as breakpointListener };
