import { ComponentObservable, componentObserver } from '../../component-observer';

/**
 * Initializes help sticky buttons.
 */
class HelpStickyButtonModule implements ComponentObservable {
  /**
   * Selector of all buttons that must be initialized in javascript.
   */
  componentSelector = '.help-sticky-button';

  /**
   * Threshold when the button should appear
   */
  SCROLL_Y_THRESHOLD = 500;

  /**
   * When the speech bubble should disappear. This is the duration between the fading in of the
   * whole help sticky button (including the speech bubble) and when the speech bubble should
   * automatically fade out.
   */
  SPEECH_BUBBLE_DISAPPEAR_AFTER = 8000;

  /**
   * Initialize all help sticky buttons on the current page.
   * @param observe Boolean if the helpStickyButtonModule should listen for changes in the DOM and initialize dynamically added
   *   buttons
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise<void>((resolve) => {
      const allElements = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allElements.length; i++) {
        this.initialize(allElements[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize help sticky buttons when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given help sticky button element
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);

    // Check if `data-help-sticky-button-container` is set, use root in this case, use window otherwise
    const scrollInContainer = root.dataset.attachToContainer !== undefined;
    const main = scrollInContainer ? root.parentElement : window;

    // Scroll listener to show or hide the button
    const scrollListener = (): void => {

      // Wait for a potential animation to finish before showing the button
      let scrollTarget = this.SCROLL_Y_THRESHOLD;
      const animation = document.querySelector('.product-animation');

      if (animation) {
        const animationEnds = (<HTMLElement>animation).offsetHeight - (<HTMLElement>animation).offsetTop;
        scrollTarget = animationEnds - 200;
      }

      if (!document.querySelector(this.componentSelector)) {
        main.removeEventListener('scroll', scrollListener);
      }

      const currentScrollPosition = scrollInContainer ? (<HTMLElement>main).scrollTop : window.scrollY;
      const scrollContainerScrollHeight = scrollInContainer
        ? (<HTMLElement>main).scrollHeight
        : document.documentElement.scrollHeight;
      const scrollContainerHeight = scrollInContainer
        ? (<HTMLElement>main).clientHeight
        : document.documentElement.clientHeight;
      const scrollContainerCantScroll = scrollContainerScrollHeight <= scrollContainerHeight;

      if (currentScrollPosition > scrollTarget) {
        this.fadeInHelpButton(root);
      } else if(animation) {
        // Hide the button back whenever over an animation
        this.fadeOutHelpButton(root);
      }

      if (scrollContainerCantScroll) {
        // fade in the contact button after a small period of time
        setTimeout(() => {
          this.fadeInHelpButton(root);
        }, 3000);
      }
    };

    // Show/Hide button on scroll
    main.addEventListener('scroll', scrollListener);

    // trigger the scroll listener once in case the user can't scroll on the page
    scrollListener();

    // Make the speech bubble appear/disappear on mouse enter/leave
    const speechBubble = root.querySelector('.help-sticky-button__speech-bubble');

    root.addEventListener('mouseenter', ()=>{
      speechBubble.classList.remove('help-sticky-button__speech-bubble--hidden');

      root.addEventListener('mouseleave', ()=>{
        speechBubble.classList.add('help-sticky-button__speech-bubble--hidden');
      });
    }); 
  }

  fadeInHelpButton(root: HTMLElement): void {
    // Only add the visibility class if the button was hidden
    if(root.classList.contains('help-sticky-button--visible')) {
      return;
    }

    root.classList.add('help-sticky-button--visible');

    // remove the speech bubble after a given amount of time
    setTimeout(() => {
      const speechBubble = root.querySelector('.help-sticky-button__speech-bubble');
      speechBubble.classList.add('help-sticky-button__speech-bubble--hidden');
    }, this.SPEECH_BUBBLE_DISAPPEAR_AFTER);
  }

  fadeOutHelpButton(root: HTMLElement): void {
    // Only remove the visibility class if the button was visible
    if(!root.classList.contains('help-sticky-button--visible')) {
      return;
    }

    root.classList.remove('help-sticky-button--visible');
  }
}

const helpStickyButtonModule = new HelpStickyButtonModule();
export { helpStickyButtonModule as helpStickyButton };
