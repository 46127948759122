import { smoothScrollToElement } from '../../../utils/helper.utils'

export const cdInputInitializeAll = (mutations, observer): void => {
  const allInputs = document.querySelectorAll('.responsiveCell input, .responsiveCell select, .responsiveCell textarea');

  if(allInputs.length)
    observer.disconnect();
  else{
    return;
  }

  // Remove unuseful style
  removeInlineStyle(document.querySelector('.clickdform'));

  document.querySelectorAll('.emptyCell').forEach(el => el.remove());
  document.querySelectorAll('.requiredStar').forEach(el => el.remove());

  const allCells = document.querySelectorAll('.responsiveCell');

  if(allCells.length) 
    allCells.forEach(el => el.classList.remove('responsiveCellSize1'));

  const allminSize1 = document.querySelectorAll('.minSize1');

  if(allminSize1.length) 
    allminSize1.forEach(el => el.classList.remove('minSize1'));

  const allminSize2 = document.querySelectorAll('.minSize2');

  if(allminSize2.length) 
  allminSize2.forEach(el => el.classList.remove('minSize2'));

  const allmaxSize1 = document.querySelectorAll('.maxSize1');

  if(allmaxSize1.length) 
    allmaxSize1.forEach(el => el.classList.remove('maxSize1'));

  const allmaxSize2 = document.querySelectorAll('.maxSize2');

  if(allmaxSize2.length) 
    allmaxSize2.forEach(el => el.classList.remove('maxSize2'));

  const buttons = document.querySelectorAll('.buttonContainer input');

  buttons.forEach(button => {
    button.classList.add('button','button--primary');
    button.setAttribute('style', '');
  });

  for (let i = 0; i < allInputs.length; i++) {
    if (allInputs[i].getAttribute('type') !== 'hidden' && allInputs[i].getAttribute('type') !== 'button' && allInputs[i].getAttribute('type') !== 'checkbox' && allInputs[i].getAttribute('multiple') !== 'multiple') {
      initialize(allInputs[i] as HTMLInputElement);
    } else {
      addRequiredLabel(allInputs[i]);
    }
  }
};

const initialize = (input: HTMLInputElement): void => {
  let label;
  input.removeAttribute('style');
  input.classList.add('input');
  const formControl = input.parentElement;
  if (formControl && !formControl.classList.contains('dropdown-search')) {
    formControl.classList.add('form-control', 'form-control--stretch');
  }

  // Label formatting for legacy forms
  document.querySelectorAll(".QuestionTitleContainer").forEach(labelContainer => {
    const inputId = labelContainer.id.replace('cont_title_id_', '');
    const labelElement = labelContainer.firstElementChild  as HTMLElement | null;
    label = labelElement.innerText;

    const inputElement = document.querySelector('#' + inputId);

    if(inputElement)
      inputElement.ariaLabel = label;

  });

  // Radio buttons styling
  document.querySelectorAll('input[type=radio]').forEach((input, index) => {

    input.id = input.getAttribute('name') + '_' + index;
    input.classList.add('radio-button');
    input.classList.remove('input');

    input.parentElement.classList.remove('form-control', 'form-control--stretch');
    input.parentElement.setAttribute('style', 'margin-top:10px');

    if(input.nextElementSibling){
      const labelText = input.nextElementSibling.textContent;
      input.setAttribute('aria-label', labelText);
      input.nextElementSibling.remove();
    }

    // Add label
    label = document.createElement('label');
    label.setAttribute('for', input.getAttribute('id'));
    label.innerText = input.getAttribute('aria-label');
    label.classList.add('label');
    if (!formControl.contains(label)) {
      input.insertAdjacentElement('afterend', label);
    }

    // Add required label
    const helperText = document.querySelector('#required_info_' + input.getAttribute('name'));

    if (helperText === null)
      return;

    helperText.classList.add('helper-text');

    if(input.getAttribute('aria-required') === 'true') {
      helperText.innerHTML = '<div>Required</div>';
      helperText.setAttribute('aria-required', 'true');
    }

    const helperTextObserver = new MutationObserver(formatRequiredField);

    helperTextObserver.observe(helperText, { childList: true });
  })

  const globalContainer = formControl.parentElement.parentElement;
  if (globalContainer) {
    const clear = globalContainer.querySelector('.clear');
    if (clear) {
      if (clear.previousElementSibling.tagName === 'DIV')
        globalContainer.removeChild(clear.previousElementSibling);
      globalContainer.removeChild(clear);
    }
  }
  
  addRequiredLabel(input);
  
  if(input.getAttribute('type') !== 'radio') {
    label = document.createElement('label');
    label.setAttribute('for', input.getAttribute('id'));
    label.innerText = input.getAttribute('aria-label');
    if (!formControl.contains(label)) {
      formControl.insertBefore(label, input);
    }
    const focusListener = (event: FocusEvent): void => {
      event.stopImmediatePropagation();
      label.classList.add('label--sup');
      label.classList.add('label--active');
    };
    const blurListener = (event: FocusEvent): void => {
      const inputElement = event.currentTarget as HTMLInputElement;
      setTimeout(()=>{
        label.classList.remove('label--active');
        if (inputElement.value.length === 0 || input.value === '-1') {
          label.classList.remove('label--sup');
        }
      }, 100);
      
    };
    if (input.value.length > 0 && input.value !== '-1') {
      label.classList.add('label--sup');
    }
    if (!input.disabled) {
      input.addEventListener('focus', focusListener, false);
      input.addEventListener('blur', blurListener, false);
    } else {
      label.classList.remove('label--active');
      label.classList.add('label--disabled');
    }
  }

  // Move up the labels if they're exceeding the input's height (e.g. they're in two lines)
  if(label){
    const inputBottom = input.getBoundingClientRect().bottom;
    const labelBottom = label.getBoundingClientRect().bottom;
  
    if(labelBottom > inputBottom) {
      label.style.transform = 'translateY(0%)';
    }
  }
};

function removeInlineStyle (node) {
  if (!node.classList.contains('QapTcha')) {
    const style = node.getAttribute('style');

    if (style) {
      let display = '';

      if (style.includes('display')) {
        display = node.style.display;
      }
  
      node.setAttribute('style', display ? 'display:' + node.style.display : '');
    }

    const children = [...node.children];
    children.forEach(child => removeInlineStyle(child));
  }
}

function addRequiredLabel (input) {
  const helperText = document.querySelector('#required_info_' + input.getAttribute('id'));

  if (helperText === null)
    return;

  helperText.classList.add('helper-text');

  if(input.getAttribute('aria-required') === 'true') {
    helperText.innerHTML = '<div>Required</div>';
    helperText.setAttribute('aria-required', 'true');
  }

  const helperTextObserver = new MutationObserver(formatRequiredField);

  helperTextObserver.observe(helperText, { childList: true });
}

function formatRequiredField(mutationsList, observer) {

  const formControl = mutationsList[0].target.parentElement;

  const helperText = formControl.querySelector('.requiredInfo');

  const errorMessageField = formControl.querySelector('[name="reqField"]');

  if(errorMessageField) {
    const requiredMessage = errorMessageField.getAttribute('requrederrormsg'); // The typo is on the CD side
    const errorMessage = errorMessageField.getAttribute('errormsg');

    if(helperText.firstChild === null && helperText.getAttribute('aria-required') === 'true') {
      observer.disconnect();
      helperText.innerHTML = '<div>Required</div>';
      helperText.setAttribute('style', '');
      observer.observe(mutationsList[0].target,  { childList: true });
    }

    if (helperText.firstChild && (helperText.firstChild.innerText === requiredMessage || helperText.firstChild.innerText === errorMessage)) {
      formControl.classList.add('form-control--invalid');
      smoothScrollToElement(formControl, 100);
    } else {
      formControl.classList.remove('form-control--invalid');
    }
  }
}
