import { baseUrl } from './general-api';

/**
 * Base URL for the taxonomies API.
 */
export const baseTaxonomiesPath = baseUrl + '/.rest/delivery/v1/catalog/taxonomies';

/**
 * A taxonomy category is a tag that can be attached to any product. It can
 * contain multiple other taxonomies.
 */
export interface TaxonomyCategory {
  /**
   * Translated name, that is for example displayed
   * as the filter groups name in the filter sidebar.
   */
  '@name': string;
  /**
   * If the @name property above contains special characters, this property may be set.
   */
  name: string;
  /**
   * Human-readable label that should be displayed to the user preferred
   * over the @name property. If this is not present, the name should be used.
   */
  label: string;
  /**
   * Taxonomy category ID.
   */
  '@id': string;
  /**
   * List of taxonomies that belong to this taxonomy category.
   * This list contains taxonomy IDs, that map to properties
   * of this (taxonomy category) object.
   */
  '@nodes': string[];
  /**
   * This property does not come from the API but is created
   * for convenience purposes. Using the @nodes property, the
   * taxonomy objects are collected (which are initially stored
   * as properties of the category object) and stored in this list.
   */
  taxonomies: Taxonomy[];
}

/**
 * A taxonomy is a tag that can be attached to any product. It can
 * belong to a taxonomy category.
 */
export interface Taxonomy {
  /**
   * Translated name, that is for example displayed
   * as the filter tag name in the filter sidebar.
   */
  '@name': string;
  /**
   * If the @name property above contains special characters, this property may be set.
   */
  name: string;
  /**
   * Human-readable label that should be displayed to the user preferred
   * over the @name property. If this is not present, the name should be used.
   */
  label: string;
  /**
   * Taxonomy ID.
   */
  '@id': string;
}

/**
 * Returns a list of all taxonomy categories and their corresponding taxonomies.
 */
export const getAllTaxonomyCategories = (filter?: string, uuid?: string): Promise<TaxonomyCategory[]> => {
  let url = baseTaxonomiesPath;
  const params: Record<string, string> = {};

  // We need all taxonomies
  params.limit = '10000';
  params.orderBy = 'order asc';

  if (filter) {
    params.q = filter;
  }

  if (uuid) {
    params['jcr:uuid'] = uuid;
  }
  if (Object.keys(params).length > 0) {
    const parameterString = new URLSearchParams(params).toString();
    url = `${url}?${parameterString}`;
  }

  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data && data.results) {
        const taxonomyCategories = data.results;

        // for convenience collect the taxonomies for each category
        // and store them in the 'taxonomies' property.
        for (const taxonomyCategory of taxonomyCategories) {
          const taxonomies = [];
          for (const taxonomyId of taxonomyCategory['@nodes']) {
            taxonomies.push(taxonomyCategory[taxonomyId]);
          }
          taxonomyCategory.taxonomies = taxonomies;
        }

        return taxonomyCategories;
      }

      return [];
    })
    .catch(() => {
      return [];
    });
};
