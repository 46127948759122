import { baseUrl } from './general-api';
/**
 * API to sing in and check user data
 */

export interface Userinfo {
  properties?: UserProperties;
  groupList?: GroupsList;
}

export interface UserProperties {
  uid?: string;
}

export interface GroupsList {
  list?: string[];
}

export const checkLogin = (): Promise<Userinfo> => {
  const url = baseUrl + '/.oauth/userinfo';

  return fetch(url.toString())
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(() => {
      return '';
    });
};

export const login = async (action: string, csrf?: string, email?: string, password?: string): Promise<boolean> => {
  const params = {
    csrf,
    mgnlRealm: 'external',
    mgnlUserId: email,
    mgnlUserPSWD: password
  };
  const searchParams = new URLSearchParams(params).toString();
  const res = await fetch(action, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: searchParams
  });
  await res.text();
  return true;
};
