import { clamp, lerp } from '../../utils/helper.utils';
import { CacheController } from './CacheController';
import { currentBreakpoint } from '../../utils/resize.utils';
import { breakpointListener } from '../../utils/breakpoint-listener.module';
import { productAnimation } from './product-animation.module';

/**
 * Information about one animated segment on the product page.
 */
class AnimationSegment {
  /**
   * The root HTMLElement of this segment. Can be either a video or div.
   */
  element: HTMLElement;
  /**
   * The time in seconds (relative to the total duration of the whole animation) when this
   * section should start animating.
   */
  startSec: number;
  /**
   * The time in seconds (relative to the total duration of the whole animation) that this segment
   * should start before the previous segment has ended. This makes it possible to let segments overlap. Negative
   * numbers let the segments overlap. Positive numbers move them further apart (should not be used).
   */
  startOffset: number;
  /**
   * The time in seconds (relative to the total duration of the whole animation) that this segment
   * should end after the next segment has already started. This makes it possible to let segments overlap. Positive
   * numbers let the segments overlap. Negative numbers move them further apart (should not be used).
   */
  endOffset: number;
  /**
   * Either the duration for the info box or the actual duration of the input video file in seconds. For videos this should be a number with one decimal place.
   */
  duration: number;
  /**
   * Used for videos to set a duration (in seconds) that is different from the actual video duration. This can be
   * used to slow down or speed up the animation of a single video animation segment. E.g. if a video is
   * 4 seconds long, set the 'data-duration' to 4 seconds and the 'data-scroll-length' to 8 second and the video
   * will play with half speed (e.g. twice as long). The proportion between the actual and playback duration is stored in the playbackFactor.
   */
  playbackDuration: number;
  /**
   * How long the last frame of a video segment should stay visible after the segment finished. If an info box
   * follows a video segment, this variable should have the same duration as the info box so that the last frame is
   * visible as long as the info box is visible.
   */
  keepLastFrameFor: number;
  /**
   * If this segment is currently visible.
   */
  visible: boolean;
  /**
   * If the segment is a video or info box.
   */
  isVideo: boolean;

  /**
   * The pixel position relative to the animatable area (validRange) where the animation
   * of this segment should start.
   */
  startPx: number;
  /**
   * The pixel position relative to the animatable area (validRange) where the animation
   * of this segment should end.
   */
  endPx: number;
  /**
   * The pixel position relative to the animatable area (validRange) where the last frame
   * of the segment should be faded out (keep-last-frame).
   */
  extendedPx: number;
}

/**
 * Animation segment for a video.
 */
class VideoAnimationSegment extends AnimationSegment {
  /**
   * Type of the video
   */
  videoType: VideoType;
  /**
   * Path to the video file without file extension
   */
  filepathStub: string;
  /**
   * Path to the video file including file extension
   */
  filepath: string;
  /**
   * If this video has been cached yet.
   */
  cached: boolean;
  /**
   * If this video has a poster frame.
   */
  hasPosterFrame: boolean;
  /**
   * Ratio between the actual duration of a video and the requested scroll length (e.g. playbackDuration).
   * 0.5 means half speed, 2 means twice as fast.
   */
  playbackFactor: number;
  /**
   * The root element of the video (video tag).
   */
  element: HTMLVideoElement;
}

class VideoType {
  suffix: string;
  type: string;
}

// If adding new formats here, make sure to also add them
// to the service worker (event listener for fetch requests is filtered by these file types)
export const WEB_M_VP9_SUFFIX = '-vp9.webm';
export const MP4_H264_SUFFIX = '-h264.mp4';
export const WEB_M_AV1_SUFFIX = '-av1.webm';

/**
 * Class that is responsible for animating the videos and info boxes on the animated product page.
 */
export class AnimationController {
  /**
   * Factor for damping the animation during scroll. The video animation is not bound directly to the scroll
   * position but rather lags a bit behind and keeps moving (slowing down) when the user stops scrolling.
   * This factor is used to interpolate between the actual scroll position and the target scroll position for
   * the animation. Range [0 - 1] higher is less dampening.
   */
  dampenFactor = 0.15;

  /**
   * Factor for how fast the whole page should scroll. This affects how long a video segment is shown as well as
   * how long a text box is shown. Increasing this value makes the whole scrolling slower, decreasing it makes it
   * faster. This always affects all animation segments. If you want to make only a single segment faster, you
   * need to adjust the 'data-scroll-length' property of a segment. The value specified here is the default value.
   * A different value can be specified using the `data-scroll-speed` attribute on the `.product-animation` element.
   */
  scrollSpeedFactor = 150;

  /**
   * Timeout in seconds for caching the animation videos until the fallback page is displayed.
   */
  cacheTimeout = 15;

  /**
   * Class used to toggle the visibility of animation segments.
   */
  animationSegmentVisibleClass = 'animation-segment--visible';

  /**
   * Class used to toggle the visibility of poster frames.
   */
  videoPosterVisibleClass = 'video-poster--visible';

  /**
   * Class used to toggle the visibility of info boxes.
   */
  productInfoBoxVisibleClass = 'product-info-box--visible';

  /**
   * HTMLElement that the whole animation.
   */
  rootElement: HTMLElement;

  /**
   * HTMLElement that contains the animation segments.
   */
  animationContainer: HTMLElement;

  /**
   * HTMLElement that reserves the space on a page that is necessary for the whole animation.
   */
  animationWrapper: HTMLElement;

  /**
   * List of animation segments (either video or info box).
   */
  animations: AnimationSegment[] = [];

  /**
   * The pixel range on the page where the animation happens. When the user scrolled to 'start',
   * the animation of the first segment starts. When the user reached 'end', the last animation
   * stops. The length is the height of the page in px where the animation happens.
   */
  validRange = {
    start: 0,
    end: 0,
    length: 0
  };

  /**
   * The current position where the animation is relative to the whole page (window) in px.
   */
  currentScrollPosition = 0;

  /**
   * The current position of the animation relative to the animatable area. This position is somewhere between
   * the validRange.start and validRange.end. This position stores where the current animation is and not where it
   * should animate to. It therefore may differ from the targetAnimationPosition until the animation stopped.
   */
  currentAnimationPosition = 0;

  /**
   * The actual current scroll position of the page (window) in px. Is always updated to be window.scrollY.
   * This is where the animation ends (relative to the window) when the user stops scrolling.
   */
  targetScrollPosition: number;

  /**
   * The actual scroll position relative to the valid scroll range. This is where the animation should end
   * when the user stops scrolling.
   */
  targetAnimationPosition: number;

  /**
   * Helper to store the previous position of the animation.
   */
  previousAnimationPosition: number;

  /**
   * Current video type.
   */
  videoType: VideoType;

  /**
   * Total duration of the animation in seconds. This is the sum of the playback duration of all segments.
   * It is not only a sum of every duration of each video, but rather takes the individual scrollLength
   * into account.
   */
  totalDuration: number;

  /**
   * Responsible for caching the videos.
   */
  cacheController: CacheController;
  /**
   * Property to keep track if all videos have been cached yet.
   */
  allVideosCached = false;

  /**
   * Property to keep track if the animation loop is running.
   */
  isAnimating = false;

  /**
   * Responsible for caching videos.
   */
  cacheControllerModule;

  constructor(productAnimationElement: HTMLElement, animationContainerId: string, animationWrapperId: string) {
    // read out the scroll speed factor, otherwise use fallback
    const scrollSpeed = parseInt(productAnimationElement.dataset.scrollSpeed);
    if (scrollSpeed) this.scrollSpeedFactor = scrollSpeed;

    this.animationContainer = productAnimationElement.querySelector(`.${animationContainerId}`);
    this.animationWrapper = productAnimationElement.querySelector(`.${animationWrapperId}`);
    this.rootElement = this.animationWrapper.parentElement;
    this.videoType = checkCanPlay();
    this.animate = this.animate.bind(this);

    // only animate on desktop and no fallback is shown
    this.startOrStopAnimation(this.rootElement);

    // listen for changes that would require the animation to start or stop afterwards
    this.listenForSettingChangesToStartOrStopAnimation(productAnimationElement);
  }

  /**
   * Initialize the animation controller. Only called when the device actually supports the
   * animation and it will be played/shown to the user.
   */
  initializeAnimationController(): void {
    if (!this.cacheController) {
      this.cacheController = new CacheController(this.cacheTimeout);
      this.initAnimations();
      this.initIndicators();
      this.calculateYs();
      window.addEventListener('resize', this.calculateYs.bind(this));

      this.targetScrollPosition = window.scrollY;
      this.targetAnimationPosition = window.scrollY - this.validRange.start;
      this.currentAnimationPosition = this.targetAnimationPosition;

      window.addEventListener('scroll', () => {
        this.targetScrollPosition = window.scrollY;
        this.targetAnimationPosition = clamp(window.scrollY - this.validRange.start, 0, this.validRange.length);
      });
    }
  }

  /**
   * Checks if the animation should be played on the current device. This depends on for example
   * the support of service workers, the network type & speed, the video type support etc.
   * @returns: Boolean if the animation should be played.
   */
  shouldAnimationBePlayed(rootElement: HTMLElement): boolean {
    // no animation on mobile
    const breakpoint = currentBreakpoint();
    // no animation if the fallback class is set (e.g. when no service worker is available)
    const serviceWorkerSupported = 'serviceWorker' in navigator;
    // no animation if the user prefers reduced motion
    const userPrefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches === true;
    const userPrefersSaveData =
      'connection' in navigator
        ? (navigator.connection || navigator.mozConnection || navigator.webkitConnection)?.saveData || false
        : false;
    const videoTypeIsSupported = this.videoType !== null;

    const is2GNetwork =
      'connection' in navigator
        ? navigator.connection.effectiveType === 'slow-2g' || navigator.connection.effectiveType === '2g'
        : false;

    // Don't show the animation on android devices. This excludes only tablets (as phones have a too narrow width). On Android tablets the animation performance is too bad, so we won't show it there.
    // Performance on iPads on the other hand is good, so we show the animation there.
    const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;

    // If the caching ran into a timeout, this attribute is set so that we don't try to cache again
    const fallbackIsForced = !!rootElement.getAttribute('force-fallback');

    return (
      breakpoint.isProductAnimation &&
      !isAndroid &&
      serviceWorkerSupported &&
      !userPrefersReducedMotion &&
      !userPrefersSaveData &&
      videoTypeIsSupported &&
      !is2GNetwork &&
      !fallbackIsForced
    );
  }

  /**
   * Setup listeners that observe the settings that caused the animation to be played or not be played.
   * For example listen for changes in the reduced motion preferences or breakpoint of the page.
   */
  listenForSettingChangesToStartOrStopAnimation(rootElement: HTMLElement): void {
    // resize changes
    breakpointListener.listenForBreakpointChangeBetweenProductAnimationBreakpoints(() => {
      this.startOrStopAnimation(rootElement);
    });

    // reduced motion changes
    window.matchMedia('(prefers-reduced-motion: reduce)').addEventListener('change', () => {
      this.startOrStopAnimation(rootElement);
    });
  }

  /**
   * Start or stop the product animation. This function checks if the animation should/can be played
   * on the current device/settings and kicks off or stops the animation accordingly.
   */
  startOrStopAnimation(rootElement: HTMLElement): void {
    const shouldAnimate = this.shouldAnimationBePlayed(rootElement);
    productAnimation.showFallbackPage(!shouldAnimate);

    if (!this.isAnimating && shouldAnimate) {
      this.isAnimating = true;
      this.initializeAnimationController();
      this.calculateYs();
      window.requestAnimationFrame(this.animate);
    } else if (this.isAnimating && !shouldAnimate) {
      this.isAnimating = false;
    }
  }

  /**
   * Initialize the indicators that show where the animation is currently at and which can be used
   * to jump to a specific info box.
   */
  initIndicators(): void {
    const infoBoxSegments = this.animations.filter((animation) => !animation.isVideo);
    const indicatorsContainer = this.rootElement.querySelector<HTMLElement>('.indicators.indicators--vertical');

    // create an indicator for each info box
    for (let i = 0; i < infoBoxSegments.length; i++) {
      const indicatorElement = document.createElement('span');

      // listen for clicks on that indicator
      indicatorElement.addEventListener('click', () => {
        const animation = infoBoxSegments[i];
        const animationPositionInPage = this.validRange.start + animation.startPx + 10; // add a few pixels so that we are inside the info box segment and not exactly at the start
        window.scrollTo({ top: animationPositionInPage });
      });

      indicatorElement.classList.add('indicator');
      indicatorsContainer.appendChild(indicatorElement);
    }

    // initially update the active indicator
    this.updateActiveIndicator();
  }

  /**
   * Highlight the currently active indicator.
   */
  updateActiveIndicator(): void {
    const infoBoxSegments = this.animations.filter((animation) => !animation.isVideo);
    let activeInfoBoxIndex = 0;

    for (let i = 0; i < infoBoxSegments.length; i++) {
      const infoBox = infoBoxSegments[i];
      if (this.currentAnimationPosition > infoBox.startPx) {
        activeInfoBoxIndex = i;
      }
    }

    const indicators = document.querySelectorAll<HTMLElement>('.indicators--vertical .indicator');
    for (let i = 0; i < indicators.length; i++) {
      const indicator = indicators[i];
      indicator.classList.toggle('indicator--active', activeInfoBoxIndex === i);
    }
  }

  /**
   * Calculate the position and size of the animation. This function needs to be called every time the
   * browser size changes.
   */
  calculateYs(): void {
    const animationContainerRect = this.animationContainer.getBoundingClientRect();

    // update the scroll spacer height (reserves space for the whole animated section)
    const scrollSpacer = this.animationWrapper.querySelector<HTMLElement>('.product-animation__scroll-spacer');
    scrollSpacer.style.height = `${this.totalDuration * this.scrollSpeedFactor - animationContainerRect.height}px`;

    const animationWrapperRect = this.animationWrapper.getBoundingClientRect();
    const videoHeight = animationContainerRect.height;

    this.animationContainer.style.setProperty('--video-height', videoHeight + 'px');
    this.validRange.start =
      animationWrapperRect.y + window.scrollY - (window.innerHeight - animationContainerRect.height) / 2;
    this.validRange.end = this.validRange.start + animationWrapperRect.height - animationContainerRect.height;

    this.validRange.length = this.validRange.end - this.validRange.start;

    // calculate the pixel positions where animation segments start/end
    for (let i = 0; i < this.animations.length; i++) {
      const animation = this.animations[i];
      const secondsToPixelFactor = this.validRange.length / this.totalDuration;

      this.calculateHowLongLastFrameMustBeKept(animation, i);
      const startPx = animation.startSec * secondsToPixelFactor;
      const endPx = (animation.startSec + animation.playbackDuration) * secondsToPixelFactor;
      const extendedPx =
        (animation.startSec + animation.playbackDuration + animation.keepLastFrameFor) * secondsToPixelFactor;

      animation.startPx = startPx;
      animation.endPx = endPx;
      animation.extendedPx = extendedPx;
    }
  }

  /**
   * Checks how long the last frame of a video must be kept. This is necessary because if the following segment
   * is an info box, the last frame of the video should be visible next to it until the next video appears.
   */
  calculateHowLongLastFrameMustBeKept(animation: AnimationSegment, i: number): void {
    if (animation.isVideo) {
      const nextAnimationSegment = this.animations.length - 1 >= i + 1 ? this.animations[i + 1] : null;
      if (nextAnimationSegment && !nextAnimationSegment.isVideo) {
        // the duration that the next segment is actually visible without the previous or next segment
        const diffDuration =
          nextAnimationSegment.playbackDuration + nextAnimationSegment.startOffset - nextAnimationSegment.endOffset;

        // keep the last frame of the video for as long as no other video is visible
        if (animation.keepLastFrameFor === null) {
          animation.keepLastFrameFor = diffDuration + 1; // add one second to every keepLastFrame to prevent any rendering issues between animation segments
        }
      }
    }
  }

  /**
   * Initialize the animation segments defined in the HTML. Creates a list of AnimationSegment (or VideoAnimationSegment)
   * objects that represent each animated section (video or info box).
   */
  initAnimations(): void {
    // The animation segments that are defined in html
    const animSegments = this.animationContainer.querySelectorAll<HTMLElement>('.animation-segment');

    let totalDuration = 0;
    for (const s of animSegments) {
      const startOffset = Number.parseFloat(s.dataset.offsetStart) || 0;
      const endOffset = Number.parseFloat(s.dataset.offsetEnd) || 0;
      const duration = Number.parseFloat(s.dataset.duration);
      const playbackDuration = Number.parseFloat(s.dataset.scrollLength) || Number.parseFloat(s.dataset.duration);
      const diffDuration = playbackDuration + startOffset - endOffset;

      // Create a segment object for each segment that should get animated
      let seg: AnimationSegment = {
        element: s,
        startSec: Math.max(0, totalDuration + startOffset),
        duration,
        playbackDuration,
        keepLastFrameFor: Number.parseFloat(s.dataset.keepLastFrame) + 1 || null,
        visible: false,
        isVideo: false,
        startPx: NaN,
        endPx: NaN,
        extendedPx: NaN,
        startOffset,
        endOffset
      };

      // For the video segments, create an object with additional info about the video file
      if (s.tagName === 'VIDEO') {
        const videoType = this.videoType;
        const hasPosterFrame = s.previousElementSibling?.classList?.contains('video-poster');
        const videoAnimationSegment = {
          ...seg,
          isVideo: true,
          videoType,
          filepathStub: s.dataset.filepathStub,
          filepath: s.dataset.filepathStub + videoType.suffix,
          cached: false,
          playbackFactor: seg.duration / seg.playbackDuration,
          hasPosterFrame
        };

        // Cache the required video file
        this.cacheController.cacheFile(videoAnimationSegment.filepath, () => {
          // when the video was cached -> append it to the video tag
          const videoSource = document.createElement('source');
          videoSource.setAttribute('src', videoAnimationSegment.filepath);
          videoSource.setAttribute('type', videoAnimationSegment.videoType.type);
          s.appendChild(videoSource);
          videoAnimationSegment.cached = true;

          // update the loading indicator and store if all videos have been cached
          this.showLoadingStateIfNecessary();
          this.allVideosCached = !this.animations.find((seg) => seg.isVideo && !(seg as VideoAnimationSegment).cached);
        });

        s.addEventListener('loadedmetadata', () => {
          this.animationContainer.style.setProperty(
            '--video-height',
            this.animationContainer.getBoundingClientRect().height + 'px'
          );
        });

        seg = videoAnimationSegment;
      }

      if (s.tagName === 'DIV') {
        seg.isVideo = false;
      }

      this.animations.push(seg);

      // add the segment duration to the total duration, so that we know
      // when the next segment needs to start
      totalDuration += diffDuration;

      if (seg.startSec === 0) {
        s.classList.toggle(this.animationSegmentVisibleClass, true);
        seg.visible = true;
        if (s.previousElementSibling?.classList?.contains('video-poster')) {
          s.previousElementSibling.classList.add(this.videoPosterVisibleClass);
        }
      } else if (seg.isVideo) {
        s.classList.toggle(this.animationSegmentVisibleClass, false);
        seg.visible = false;
        if (s.previousElementSibling?.classList?.contains('video-poster')) {
          s.previousElementSibling.classList.remove(this.videoPosterVisibleClass);
        }
      }
    }
    this.totalDuration = totalDuration;
  }

  /**
   * Update the visibility of the given video animation segment. This function fades in/out the given video
   * segment depending on the current position. It also toggles the visibility of poster frames.
   */
  controlVideoVisibility(animation: VideoAnimationSegment): void {
    if (this.currentAnimationPosition >= animation.startPx && this.currentAnimationPosition <= animation.extendedPx) {
      animation.element.classList.toggle(this.animationSegmentVisibleClass, true);
      animation.visible = true;

      if (!animation.cached && animation.hasPosterFrame) {
        animation.element.previousElementSibling.classList.add(this.videoPosterVisibleClass);
      }
    } else {
      animation.element.classList.toggle(this.animationSegmentVisibleClass, false);
      animation.visible = false;

      if (animation.hasPosterFrame) {
        animation.element.previousElementSibling.classList.remove(this.videoPosterVisibleClass);
      }
    }
  }

  /**
   * Update the visibility of the given animation segment (info box). This function fades in/out the given info box
   * depending on the current position.
   */
  controlInfoBoxVisibility(animation: AnimationSegment): void {
    const segmentIsVisible =
      this.currentAnimationPosition >= animation.startPx && this.currentAnimationPosition <= animation.extendedPx;
    animation.element
      .querySelector('.product-info-box')
      .classList.toggle(this.productInfoBoxVisibleClass, segmentIsVisible);
  }

  /**
   * Show or hide the loading state (transparent black overlay with loading indicators) if necessary.
   */
  showLoadingStateIfNecessary(): void {
    const visibleVideoSegment = this.animations.find(
      (segment) =>
        segment.isVideo &&
        this.currentAnimationPosition >= segment.startPx &&
        this.currentAnimationPosition <= segment.extendedPx
    ) as VideoAnimationSegment;

    if (visibleVideoSegment && !visibleVideoSegment.cached) {
      this.rootElement.classList.add('product-animation--loading');
    } else {
      this.rootElement.classList.remove('product-animation--loading');
    }
  }

  animate(): void {
    // only do something if we have not arrived at actual scroll position yet
    const animationIsNotAtTargetPosition =
      Math.abs(this.targetAnimationPosition - this.currentAnimationPosition) > 0.05;

    if (!this.allVideosCached && this.currentAnimationPosition > 0) {
      this.showLoadingStateIfNecessary();
    }

    if (animationIsNotAtTargetPosition) {
      this.updateActiveIndicator();
      this.previousAnimationPosition = this.currentAnimationPosition;

      this.currentAnimationPosition = lerp(
        clamp(this.currentAnimationPosition, 0, this.validRange.length),
        clamp(this.targetAnimationPosition, 0, this.validRange.length),
        this.dampenFactor
      );

      for (let i = 0; i < this.animations.length; i++) {
        const animation = this.animations[i];

        if (
          this.previousAnimationPosition >= animation.startPx &&
          this.previousAnimationPosition <= animation.extendedPx
        ) {
          if (this.previousAnimationPosition <= animation.endPx) {
            if (animation.isVideo) {
              const videoAnimation = <VideoAnimationSegment>animation;

              const segmentLength = animation.endPx - animation.startPx;
              const absolutePositionInSegment = this.currentAnimationPosition - animation.startPx;
              const relativePositionInSegment = clamp(absolutePositionInSegment / segmentLength, 0, 1); // e.g. 0.5 in the middle

              const nextTime = relativePositionInSegment * animation.duration;

              if (!videoAnimation.cached) {
                continue;
              }
              videoAnimation.element.currentTime = nextTime;
            }
          }
        }
        if (animation.isVideo) {
          this.controlVideoVisibility(<VideoAnimationSegment>animation);
        } else {
          this.controlInfoBoxVisibility(animation);
        }
      }
    }

    if (this.isAnimating) {
      window.requestAnimationFrame(this.animate);
    } 
  }
}

/**
 * Checks if the current browser can play the required video type for animations (webm vp9)
 * and returns the type. If not supported, it returns null.
 */
function checkCanPlay(): VideoType {
  const videoElement = document.querySelector<HTMLVideoElement>('video');
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  if (videoElement) {
    // return of canPlayType can be 'probably', 'maybe' or ''. We only know for sure that the video type is
    // supported if the answer is 'probably'.
    const supportsVP9 = videoElement.canPlayType('video/webm; codecs="vp9"') === 'probably';
    const supportsH264 = videoElement.canPlayType('video/mp4; codecs="avc1.42E01E, mp4a.40.2"') === 'probably';

    if (supportsVP9) {
      const supportsAV1 = videoElement.canPlayType('video/webm; codecs="vp9"') === 'probably';

      if (supportsAV1 && isFirefox) {
        // At the time of writing this, the animation is very slow/buggy in Firefox browsers on modern M1 MacBook Pros.
        // So we are going to use av1 videos instead. They are only used for Firefox because in chrome they perform
        // worse than webm/vp9.
        return {
          suffix: WEB_M_AV1_SUFFIX,
          type: 'video/webm; codecs="av01.0.05M.08"'
        };
      }
      return {
        suffix: WEB_M_VP9_SUFFIX,
        type: 'video/webm; codecs="vp9"'
      };
    } else if (supportsH264) {
      return {
        suffix: MP4_H264_SUFFIX,
        type: 'video/mp4'
      };
    }
  }

  return null;
}
