import { ComponentObservable, componentObserver } from '../../component-observer';
import { AnimationController } from './AnimationController';

class ProductAnimationModule implements ComponentObservable {
  /**
   * Selector of all product info boxes that must be initialized in javascript.
   */
  componentSelector = '.product-animation';

  /**
   * This element wraps the whole animation. It contains the fixed positioned animation-container that is always
   * visible and positioned in the middle of the viewport during animation as well as the spacer element, that
   * reserves the necessary space on the page for scrolling.
   */
  animationWrapperClass = 'product-animation__wrapper';

  /**
   * This element is positioned 'fixed' in the middle of the viewport as long as the animation runs. It contains
   * the video and info box segments.
   */
  animationContainerClass = 'product-animation__container';

  /**
   * Controller responsible for the product animation.
   */
  animationController: AnimationController;

  /**
   * Display the product animation fallback page.
   */
  showFallbackPage(show: boolean, force = false): void {
    const animationElement = document.querySelector<HTMLElement>(this.componentSelector);
    animationElement.classList.toggle('product-animation--fallback', show);

    // hide the loading state
    animationElement.classList.remove('product-animation--loading');

    if (show && force) {
      animationElement.setAttribute('force-fallback', 'true');
    }
  }

  /**
   * Initialize all product animations on the current page.
   * @param observe Boolean if the productAnimationModule should listen for changes in the DOM and initialize dynamically
   *   added product animations
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise<void>((resolve) => {
      const allProductAnimationComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allProductAnimationComponents.length; i++) {
        this.initialize(allProductAnimationComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize product animations when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given product animation element.
   * @param productAnimation The product animation root element that should be initialized
   */
  initialize(productAnimation: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, productAnimation);

    this.animationController = new AnimationController(
      productAnimation,
      this.animationContainerClass,
      this.animationWrapperClass
    );

    // This button might be helpful for debugging the animation in future (clears service worker cache)
    // document.querySelector<HTMLElement>('.clear-cache').addEventListener('click', async () => {
    //   await caches.delete('videos');
    // });
  }
}

const productAnimationModule = new ProductAnimationModule();
export { productAnimationModule as productAnimation };
