import TouchSweep from 'touchsweep';

/**
 * Initializes the static compare table and renders swiper on mobile.
 */
export abstract class SliderModule {
  /**
   * Listener for swipe left
   */
  swipeLeftListener = null;

  /**
   * Listener for swipe right
   */
  swipeRightListener = null;

  /**
   * Instance of the touch sweep module
   */
  swiper = null;

  /**
   * Whether the swipe options are visible or not (depending on screen size)
   */
  sliderEnabled = false;

  /**
   * Count of the indicators that should be visible
   */
  indicatorCount = null;

  /**
   * Removes styling and data attributes from the given table
   * @param table The table to reset
   */
  abstract resetColumns(table: HTMLElement): void;

  /**
   * Set the column visibility with opacity animation for table cells and table headers
   * @param table The table with the columns
   * @param visibleColumnId The column id that should be visible
   */
  abstract setColumnVisibility(table: HTMLElement, visibleColumnId: string): void;

  /**
   * Set slider options like the indicator count variable
   */
  abstract updateSlideOptions(root: HTMLElement): void;

  /**
   * Update mobile specific changes
   */
  abstract updateMobileChanges(root: HTMLElement): void;

  /**
   * Sets the current breakpoint and the slider enabled property
   */
  abstract updateBreakpoint(): void;

  /**
   * Function to insert or append the indicators to the root element
   * @param root The html root element
   * @param indicators The indicators to append or insert
   */
  abstract insertIndicators(root: HTMLElement, indicators: HTMLElement): void;

  /**
   * Sets the indicators active and invokes the function to set the column visibility
   * @param staticCompareTable The static table element (parent of table)
   * @param indicator The indicator that becomes active
   * @param activeColumn The column that is shown
   */
  setIndicatorActive(staticCompareTable: HTMLElement, indicator: HTMLElement, activeColumn: string): void {
    // Remove active styling from all indicators
    const elements = staticCompareTable.querySelectorAll('.indicator--active');
    elements.forEach((element) => element.classList.remove('indicator--active'));
    // Add active styling to current indicator
    indicator.classList.add('indicator--active');
    this.setColumnVisibility(staticCompareTable, activeColumn);
  }

  /**
   * Initializes the root element with indicators and updates the column visibility depending on screen size
   * @param root The root element to initialize and update
   */
  updateColumnVisibility(root: HTMLElement): void {
    // Show indicators
    const oldIndicators = root.parentElement.querySelector('.indicators');
    oldIndicators && root.parentElement.removeChild(oldIndicators);
    const indicators = document.createElement('div');
    indicators.classList.add('indicators');
    this.insertIndicators(root, indicators);

    // Indicator click listener
    const indicatorClickListener = (event: MouseEvent): void => {
      const indicator = event.currentTarget as HTMLElement;
      const activeColumn = indicator.getAttribute('data-indicator');
      this.setIndicatorActive(indicator.parentElement.parentElement, indicator, activeColumn);
    };

    // Set listener for swipe to the left if it is not set yet
    if (this.swipeLeftListener === null) {
      this.swipeLeftListener = (event: MouseEvent): void => {
        const table = event.currentTarget as HTMLElement;
        const indicator = table.parentElement.querySelector('.indicator--active');
        const hasNext = !!indicator.nextElementSibling;
        const next = indicator.nextElementSibling || indicator.parentElement.firstElementChild;
        const nextIndex = hasNext ? Number(indicator.getAttribute('data-indicator')) + 1 : 1;
        this.setIndicatorActive(table.parentElement, next as HTMLElement, nextIndex.toString());
      };
    }

    // Set listener for swipe to the right if it is not set yet
    if (this.swipeRightListener === null) {
      this.swipeRightListener = (event: MouseEvent): void => {
        const table = event.currentTarget as HTMLElement;
        const indicator = table.parentElement.querySelector('.indicator--active');
        const hasPrevious = !!indicator.previousElementSibling;
        const previous = indicator.previousElementSibling || indicator.parentElement.lastElementChild;
        const previousIndex = hasPrevious
          ? Number(indicator.getAttribute('data-indicator')) - 1
          : indicator.parentElement.childElementCount;
        this.setIndicatorActive(table.parentElement, previous as HTMLElement, previousIndex.toString());
      };
    }

    // Reset listeners and table columns
    root.removeEventListener('swipeleft', this.swipeLeftListener, false);
    root.removeEventListener('swiperight', this.swipeRightListener, false);
    this.resetColumns(root.parentElement);

    // Get current breakpoint from window size
    if (this.sliderEnabled) {
      this.updateSlideOptions(root);

      // Set first column visible
      this.setColumnVisibility(root.parentElement, '1');

      // Add swipe
      if (this.swiper === null) {
        this.swiper = new TouchSweep(root, 200);
      }

      root.addEventListener('swipeleft', this.swipeLeftListener);
      root.addEventListener('swiperight', this.swipeRightListener);

      // Create indicators depending on screen size
      for (let i = 1; i <= this.indicatorCount; i++) {
        const indicator = document.createElement('span');
        indicator.classList.add('indicator');
        indicator.setAttribute('data-indicator', i.toString());
        // Add click listener
        indicator.addEventListener('click', indicatorClickListener, false);
        // Set first indicator active by default
        if (i === 1) {
          indicator.classList.add('indicator--active');
        }
        indicators.appendChild(indicator);
      }

      this.updateMobileChanges(root);
    }
  }
}
