import { ComponentObservable, componentObserver } from '../../component-observer';
import { createHtmlElementFromString } from './../../utils/html.utils';

/**
 * Initializes the clickdimensions adblock notification.
 */
class CDForm implements ComponentObservable {
  /**
   * Selector of all clickdimension forms
   */
  componentSelector = '.cd-form';
  adblockTitle = '';

  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  initialize(cdFormElement: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, cdFormElement);
    const formElementChildren = cdFormElement.querySelectorAll('div');
    this.adblockTitle = cdFormElement.dataset.adblockTitle;

    for (let i = 0; i < formElementChildren.length; i++) {
        const currentDiv = formElementChildren[i] as HTMLElement;
        if (currentDiv.hasAttribute('pageid') && currentDiv.innerHTML.trim().length == 0) {
            // CD Form was blocked. Show notification.
            this.showAdBlockerNotification(currentDiv);
        }
    }
  }

  showAdBlockerNotification(container: HTMLElement) {
        const notificationElement = `<div id="blockNotification" class="text-and-image-component text-and-image-component--text-full" style="display: none;">
                                        <div class="text-and-image-component__right-content">
                                            <h2>
                                                ${this.adblockTitle}
                                            </h2>
                                            <div class="text-and-image-component__full-image-right-content">
                                                <div class="text-and-image-component__text text-large rte-style text--margin-bottom"> 
                                                    <strong>We do not display advertisements from third parties on this page or elsewhere on our website.</strong>
                                                    Adblocking extensions (such as “adblock” or “ublock”) sometimes block legitimate forms from being displayed. Please disable your adblocking extension and reload the page in order to see the form on this page. Thank you.
                                                </div>
                                            </div>
                                        </div>
                                    </div>`;
        container.append(createHtmlElementFromString(notificationElement));
        setTimeout(() => {
            if (container.querySelector<HTMLElement>('#blockNotification')) {
                container.querySelector<HTMLElement>('#blockNotification').style.display = 'block';
            }
        }, 1000);
  }

  initializeAll(observe: boolean): Promise<void> {
      return new Promise<void>((resolve, reject) => {
          const allClickdimensionsForms = document.querySelectorAll(this.componentSelector);
          for (let i = 0; i < allClickdimensionsForms.length; i++) {
              this.initialize(allClickdimensionsForms[i] as HTMLElement);
          }

          if (observe) {
              this.startListening();
          }
          resolve();
      });
  }
}

const cdFormModule = new CDForm();
export { cdFormModule as cdForm };
