export const initializeCookieSettings = () => {
    prepareCountrySelectorLinks();
    const observer = new MutationObserver(prepareCountrySelectorLinks);
    const countrySelector = document.querySelector<HTMLElement>('.country-selector');
    if (countrySelector !== null) {
        observer.observe(countrySelector, {childList: true});
    }

    // Event Listeners

    // Country Detected Overlay close button
    let closeCountryDetected = document.querySelector(".close-country-detected");

    if(closeCountryDetected){
        closeCountryDetected.addEventListener("click", ()=>{
            setCookie('closed_hint', 'true', 90);
        });
    }
}

const setCookie = (name, value, exdays) => {
    let d, expires;
    exdays = exdays || 1;
    d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + "; " + expires + ";path=/";
}
  
const getCookie = (name) => {
    let cookie, c;
    const cookies = document.cookie.split(';');
    for (let i=0; i < cookies.length; i++) {
      c = cookies[i].split('=');
      if (c[0].trim() == name.trim()) {
        return c[1];
      }
    }
    return "";
}
  
const removeCookie = (name) => {
    setCookie(name, '', -1);
}
  
const cookieSettings = (cookies, activate) => {
    cookies.forEach((cookie)=>{
        if(activate){
            setCookie(cookie, "true", 365)
        }
        else{
            removeCookie(cookie)
        }
    });
}
  
  // Convert a locale like en_US -> en-us
const convertLocale = (locale) => {
    return locale.replace('_', '-').toLowerCase();
}
  
  // Add the data-locale and class attributes to the Country Selector links in order to enable setting the saved_locale cookie
const prepareCountrySelectorLinks = () => {
    // Get the current locale from the data-locale attribute on the Country Selector
    const countrySelector = document.querySelector<HTMLElement>(".country-selector");
    if (!countrySelector) return;
    const locale = countrySelector.dataset.selectedItem;
    const baseUrl = countrySelector.dataset.baseUrl || '';
    // Current path: current pathname without the locale specification
    const currentPath = window.location.pathname.replace('/'+locale, '');
  
    document.querySelectorAll('.country-selector .selector-item > a[href]').forEach(function (element){
            const href = element.getAttribute("href");
    
            // Extract the linked locale from the href and set the data-locale attribute
            if(href){
                // Get the href link
                let selectedLocale = href; // e.g. https://www.hamilton-medical.com/en_US/News.html
    
            // Remove the base Url
            selectedLocale = selectedLocale.replace(baseUrl, ""); // -> /en_US/News.html
    
            // Remove the page path
            selectedLocale = selectedLocale.replace(currentPath, ""); // -> /en_US
    
            // Remove the leading slash
            selectedLocale = selectedLocale.replace("/", ""); // -> en_US
    
            element.setAttribute("data-locale", selectedLocale || "en"); // set data-locale and default to "en" if empty
            element.classList.add("save-locale");
        }
    });
  
    // Country Detected Overlay "Switch to" button and Country Selector links
    document.querySelectorAll(".save-locale").forEach((element) => element.addEventListener("click", saveLocale));
}
  
const saveLocale = (evt) => {
    let a_tag = evt.target.classList.contains("save-locale") ? evt.target : evt.target.closest(".save-locale");
    let locale = a_tag.dataset.locale;
    locale = convertLocale(locale);
    setCookie("saved_locale", locale, 90);
}