import { ComponentObservable, componentObserver } from '../../component-observer';
import { checkLogin, login, Userinfo } from '../../api/login-api';
import { getLocaleFromUrl } from './../../utils/url.utils';

/**
 * Initializes the registration form.
 */
class LoginModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.login-page__form';

  /**
   * Initialize all login forms on the current page.
   * @param observe Boolean if the LoginModule should listen for changes in the DOM and initialize dynamically
   *   added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allComponents.length; i++) {
        this.initialize(allComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given login form element.
   * Sets the listener for resize.
   * @param root The login form root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);
    root.addEventListener('submit', (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.disableSubmit(root);
      this.hideErrors(root);
      if (this.validateForm(e)) {
        const csrf = root.querySelector<HTMLInputElement>('#csrf').value;
        const email = root.querySelector<HTMLInputElement>('#login-username-input').value;
        const password = root.querySelector<HTMLInputElement>('#login-password-input').value;
        const helperTextContainer = root
          .querySelector<HTMLElement>('#login-password-input')
          .parentElement.querySelector<HTMLElement>('.helper-text');

        login(root.getAttribute('action'), csrf, email, password)
          .then(() => {
            checkLogin().then((result: Userinfo) => {
              if (!result || !result.properties || !result.properties.uid || !result.properties.uid.length) {
                this.enableSubmit(root);
                helperTextContainer.querySelector<HTMLElement>('.invalid-username-password').style.display = 'block';
                this.checkInvalidInput(root.querySelector<HTMLInputElement>('#login-username-input').parentElement);
              } else {
                const url = new URL(window.location.href);
                let group = 'partner-net';
                if (window.location.hostname.indexOf('medin') > -1) {
                  group = 'medin-partner-net';
                }
                if (result.groupList.list.indexOf(group) > -1) {
                  url.pathname = (getLocaleFromUrl(url) !== '' ? '/' : '') + getLocaleFromUrl(url) + '/Partner-net';
                }
                url.searchParams.delete('login');
                window.location.href = url.toString();
              }
            });
            this.enableSubmit(root);
          })
          .catch(() => {
            this.enableSubmit(root);
            helperTextContainer.querySelector<HTMLElement>('.invalid-username-password').style.display = 'block';
            this.checkInvalidInput(root.querySelector<HTMLInputElement>('#login-username-input').parentElement);
          });
      }
      this.enableSubmit(root);
      return false;
    });
  }

  disableSubmit(root: HTMLElement): void {
    root.querySelector<HTMLElement>('.button').setAttribute('disabled', 'disabled');
  }

  enableSubmit(root: HTMLElement): void {
    root.querySelector<HTMLElement>('.button').removeAttribute('disabled');
  }

  validateForm(e): boolean {
    e.preventDefault();
    e.stopPropagation();
    const formElement = document.querySelector<HTMLElement>(this.componentSelector);
    const requiredFields = ['login-username-input', 'login-password-input'];
    let flag = true;

    for (let i = 0; i < requiredFields.length; i++) {
      const inputField = formElement.querySelector<HTMLInputElement>('#' + requiredFields[i]);
      const helperTextContainer = inputField.parentElement.querySelector<HTMLElement>('.helper-text');

      if (inputField.value === '') {
        flag = false;
        helperTextContainer.querySelector<HTMLElement>('.required-error').style.display = 'block';
        this.checkInvalidInput(inputField.parentElement);
      } else {
        inputField.parentElement.classList.remove('form-control--invalid');
        helperTextContainer.querySelector<HTMLElement>('.required-error').style.display = 'none';
        this.checkInvalidInput(inputField.parentElement);
      }
    }

    const emailHelperTextContainer = formElement
      .querySelector<HTMLInputElement>('#login-username-input')
      .parentElement.querySelector<HTMLElement>('.helper-text');
    if (!this.validateEmail(formElement.querySelector<HTMLInputElement>('#login-username-input').value)) {
      emailHelperTextContainer.querySelector<HTMLElement>('.invalid-email-error').style.display = 'block';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#login-username-input').parentElement);
    } else {
      emailHelperTextContainer.querySelector<HTMLElement>('.invalid-email-error').style.display = 'none';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#login-username-input').parentElement);
    }

    return flag;
  }

  checkInvalidInput(element: HTMLElement): void {
    let flag = true;
    element
      .querySelector('.helper-text')
      .querySelectorAll('div')
      .forEach((message) => {
        if (message.clientHeight > 0) {
          element.classList.add('form-control--invalid');
          flag = false;
        }
      });
    if (flag) {
      element.classList.remove('form-control--invalid');
    }
  }

  hideErrors(root: HTMLElement): void {
    root.querySelectorAll('.helper-text').forEach((errorContainer: HTMLElement) => {
      errorContainer.querySelectorAll('div').forEach((errorMessage: HTMLElement) => {
        errorMessage.style.display = 'none';
      });
    });
  }

  validateEmail(email: string): boolean {
    const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
    return re.test(String(email).toLowerCase());
  }
}

const loginModule = new LoginModule();
export { loginModule };
