import { productAnimation } from './product-animation.module';

class CacheFile {
  /**
   * Path to the file that needs to be cached.
   */
  filepath: string;
  /**
   * Callback function that is invoked when the is cached completely.
   */
  callback: () => void;

  /**
   * ID for the timer that tracks if the file was cached within the timeout.
   * Used to cancel the timeout.
   */
  timeoutId: number;
}

export class CacheController {
  /**
   * List of video files that need to be cached for the animation.
   */
  filesToCache: CacheFile[] = [];

  /**
   * Property that indicates if the CacheController is ready to cache files. This is false
   * as long as the service worker is not ready yet.
   */
  isReadyToCache = false;

  /**
   * Boolean to keep track if the caching is still ongoing or finished.
   */
  isCurrentlyCaching = false;

  /**
   * Timeout in seconds for caching the animation videos until the fallback page is displayed.
   */
  cacheTimeout: number;

  /**
   * Factor to convert seconds into milliseconds.
   */
  readonly SECONDS_TO_MILLIS_FACTOR = 1000;

  constructor(timeout: number) {
    this.cacheTimeout = timeout;

    if (document.readyState === 'complete') {
      this.load();
    } else {
      // Use the window load event to keep the page load performant
      window.addEventListener('load', this.load.bind(this));
    }
  }

  /**
   * Check if the service worker is already activated, otherwise listen for
   * the controllerchange event that notifies when it is ready. Then start caching.
   */
  async load(): Promise<void> {
    // check if the service worker controller is already active
    if (navigator.serviceWorker.controller) {
      this.initCaching();
    } else {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        this.initCaching();
      });
    }
  }

  /**
   * Initialize the caching process.
   */
  initCaching(): void {
    this.isReadyToCache = true;
    this.cacheNow();
  }

  /**
   * Cache the given video file.
   */
  cacheFile(filepath: string, callback: () => void): void {
    this.filesToCache.push({
      filepath,
      callback,
      timeoutId: null
    });
    this.cacheNow();
  }

  /**
   * Start caching the video animation files.
   */
  cacheNow(): void {
    if (!this.isReadyToCache) {
      return;
    }
    if (this.isCurrentlyCaching) {
      return;
    }

    if (this.filesToCache.length > 0) {
      this.isCurrentlyCaching = true;
      const f = this.filesToCache.shift();
      f.timeoutId = window.setTimeout(() => {
        // show the fallback
        productAnimation.showFallbackPage(true, true);

        // stop the animation
        productAnimation.animationController.isAnimating = false;

        // cancel caching
        caches.delete('videos');
        this.isCurrentlyCaching = false;
        this.filesToCache = [];
      }, this.cacheTimeout * this.SECONDS_TO_MILLIS_FACTOR);

      // Tell the service worker to cache the file
      navigator.serviceWorker.controller.postMessage({
        msg: 'cache-video',
        filepath: f.filepath
      });

      // Listen for messages from the service worker that the file has been cached
      navigator.serviceWorker.addEventListener(
        'message',
        (event) => {
          if (event.data.msg && event.data.msg === 'video-cached' && event.data.filepath === f.filepath) {
            f.callback();
            window.clearTimeout(f.timeoutId);

            // cache next file
            this.isCurrentlyCaching = false;
            this.cacheNow();
          }
        },
        { once: true }
      );
    }
  }
}
