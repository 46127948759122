import { ComponentObservable, componentObserver } from '../component-observer';
import { inViewportObserver } from '../in-viewport-observer';

/**
 * Initializes all elements that need to be animated when they enter the viewport. This module is also a
 * ComponentObservable because dynamically added elements should also be animated.
 */
class AnimationModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '[data-animate-when-visible]';

  /**
   * Initialize all animatable elements on the current page.
   * @param observe Boolean if the AnimationModule should listen for changes in the DOM and initialize dynamically added
   *   animatable elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const elementsToAnimate = document.querySelectorAll('[data-animate-when-visible]');
      elementsToAnimate.forEach((el: HTMLElement) => {
        this.initialize(el);
      });

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize animatable elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the animatable element.
   * @param element The animatable elements that should be initialized
   */
  initialize(element: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, element);
    setTimeout(() => {
      // timeout necessary because otherwise intersection observer fires for
      // every element although they might not even be in the viewport.
      inViewportObserver.observeElement(element);
    }, 50);
  }
}

const animationModule = new AnimationModule();
export { animationModule as animation };
