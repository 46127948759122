import { ComponentObservable, componentObserver } from '../../component-observer';
import { Icon } from '../../general/icons/Icon';

/**
 * Initializes the promo element with slider function
 */
class PromoElementModule implements ComponentObservable {
  /**
   * Selector of all promo elements with slider that must be initialized in javascript.
   */
  componentSelector = '.promo--slider';

  /**
   * Initialize all promo elements with slider on the current page.
   * @param observe Boolean if the PromoElementModule should listen for changes in the DOM and initialize dynamically
   *   added promo elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allPromoElements = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allPromoElements.length; i++) {
        this.initialize(allPromoElements[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize promo elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Hides the indicators, images and contents that are not shown and sets the elements with the given index active
   * @param index The index of the current visible/active element
   * @param indicators The indicator elements
   * @param images The image elements
   * @param contents The content elements
   */
  setElementsActive(
    index: number,
    indicators: HTMLElement[],
    images: NodeListOf<Element>,
    contents: NodeListOf<Element>
  ): void {
    for (let i = 0; i < images.length; i++) {
      i === index
        ? indicators[i].classList.add('indicator--active')
        : indicators[i].classList.remove('indicator--active');
      images[i].setAttribute('aria-hidden', i === index ? 'false' : 'true');
      contents[i].setAttribute('aria-hidden', i === index ? 'false' : 'true');
    }
  }

  /**
   * Creates an icon only button with the given icon (for slider)
   * @param icon The svg icon
   */
  createButton(icon: string): HTMLElement {
    const button = document.createElement('button');
    button.classList.add('button', 'button--icon-only', 'button--white');
    const buttonSpan = document.createElement('span');
    buttonSpan.classList.add('button__icon-single');
    buttonSpan.innerHTML = icon;
    button.appendChild(buttonSpan);
    return button;
  }

  /**
   * Initialize the given promo element with slider element.
   * @param promoElement The promo element with slider that should be initialized
   */
  initialize(promoElement: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, promoElement);
    const images = promoElement.querySelectorAll('.promo-element__image img');
    const contents = promoElement.querySelectorAll('.promo-element__content');
    const indicatorElements = []; // created automatically below
    // Slider buttons are created automatically
    const nextButton = this.createButton(Icon.rightArrow);
    const previousButton = this.createButton(Icon.leftArrow);

    if (images.length !== contents.length) {
      /* eslint-disable no-console */
      console.error('There must be an image for each content element and vice versa');
      return;
    }

    // Remove slider controls if available
    const oldSliderControls = promoElement.querySelector('.slider-controls');
    oldSliderControls && promoElement.removeChild(oldSliderControls);
    // Create new slider controls
    const sliderControls = document.createElement('div');
    sliderControls.classList.add('slider-controls');
    const sliderContent = document.createElement('div');
    sliderContent.classList.add('slider-controls__content');
    sliderControls.appendChild(sliderContent);
    sliderContent.appendChild(previousButton);
    sliderContent.appendChild(nextButton);
    promoElement.insertBefore(sliderControls, contents[0]);

    // Create indicators
    const oldIndicators = promoElement.querySelector('.indicators');
    oldIndicators && promoElement.removeChild(oldIndicators);
    const indicators = document.createElement('div');
    indicators.classList.add('indicators', 'indicators--white');
    promoElement.insertBefore(indicators, sliderControls[0]);

    // Indicator click listener
    const indicatorClickListener = (event: MouseEvent): void => {
      const indicator = event.currentTarget as HTMLElement;
      const activeColumn = indicator.getAttribute('data-indicator');
      this.setElementsActive(Number(activeColumn), indicatorElements, images, contents);
    };

    // Create indicators depending on image count
    for (let i = 0; i < images.length; i++) {
      const indicator = document.createElement('span');
      indicator.classList.add('indicator');
      indicator.setAttribute('data-indicator', i.toString());
      // Add click listener
      indicator.addEventListener('click', indicatorClickListener, false);
      indicators.appendChild(indicator);
      indicatorElements.push(indicator);
    }

    // Click on the next button checks if there is a next element or starts with the first one again
    nextButton.addEventListener(
      'click',
      () => {
        const activeElement = indicators.querySelector('.indicator--active');
        const next = activeElement.nextElementSibling || indicatorElements[0];
        const activeColumn = next.getAttribute('data-indicator');
        this.setElementsActive(Number(activeColumn), indicatorElements, images, contents);
      },
      false
    );

    // Click on the previous button checks if there is a previous element or starts with the last one again
    previousButton.addEventListener(
      'click',
      () => {
        const activeElement = indicators.querySelector('.indicator--active');
        const previous = activeElement.previousElementSibling || indicatorElements[indicatorElements.length - 1];
        const activeColumn = previous.getAttribute('data-indicator');
        this.setElementsActive(Number(activeColumn), indicatorElements, images, contents);
      },
      false
    );

    // Set initially the first element to visible
    this.setElementsActive(0, indicatorElements, images, contents);
  }
}

const promoElementModule = new PromoElementModule();
export { promoElementModule as promoElement };
