import { ComponentObservable, componentObserver } from '../../component-observer';

class SurveyModule implements ComponentObservable {
    componentSelector = '.cv-survey';

    initializeAll(observe: boolean): Promise<void> {
        return new Promise((resolve) => {
            const allSurveys = document.querySelectorAll(this.componentSelector);
            for (let i = 0; i < allSurveys.length; i++) {
                this.initialize(allSurveys[i] as HTMLFormElement);
            }

            if (observe) {
                this.startListening();
            }
            resolve();
        });
    }

    startListening(): void {
        componentObserver.subscribeListener(this);
    }

    initialize(survey: HTMLFormElement): void {
        componentObserver.markElementAsInitialized(this.componentSelector, survey);
        var se = new window.SurveyEmbed(survey.id.split('-')[1],
            "https://customervoice.microsoft.com/",
            "https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/",
            "true");

        var context = {
            "First Name": survey.dataset.firstName,
            "Last Name": survey.dataset.lastName,
            "locale": survey.dataset.locale,
        };
        se.renderInline(survey.id, context);
    }
}

const surveyModule = new SurveyModule();
export { surveyModule as survey };
