import { ComponentObservable, componentObserver } from '../../component-observer';
import { currentBreakpoint } from '../../utils/resize.utils';
import { SliderModule } from '../../utils/slider.module';

/**
 * Initializes the static compare table and renders swiper on mobile.
 */
class StaticCompareTableModule extends SliderModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.static-compare-table__table';

  /**
   * Current breakpoint
   */
  breakpoint = null;

  /**
   * Initialize all static compare elements on the current page.
   * @param observe Boolean if the StaticCompareTableModule should listen for changes in the DOM and initialize dynamically added
   *   elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allTableComponents = document.querySelectorAll<HTMLElement>(this.componentSelector);
      for (let i = 0; i < allTableComponents.length; i++) {
        if (!allTableComponents[i].getAttribute('data-initialized')) {
          this.initialize(allTableComponents[i]);
        }
        componentObserver.markElementAsInitialized(this.componentSelector, allTableComponents[i]);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Removes styling and data attributes from the given table
   * @param table The table to reset
   */
  resetColumns(table: HTMLElement): void {
    const th = table.querySelectorAll('th');
    const tr = table.querySelectorAll('tr');
    // Remove data-attribute and style from the given columns
    const resetColumn = (td: NodeListOf<HTMLElement>, dataAttribute: string): void => {
      for (let i = 1; i < td.length; i++) {
        td[i].removeAttribute(dataAttribute);
        td[i].removeAttribute('style');
      }
    };
    // Reset table cells and headers
    for (let i = 0; i < tr.length; i++) {
      const td = tr[i].querySelectorAll('td') as NodeListOf<HTMLElement>;
      resetColumn(td, 'data-slide-column');
      resetColumn(th, 'data-slide-header');
    }
  }

  /**
   * Set the column visibility with opacity animation for table cells and table headers
   * @param table The table with the columns
   * @param visibleColumnId The column id that should be visible
   */
  setColumnVisibility(table: HTMLElement, visibleColumnId: string): void {
    // Change table on mobile
    const columnCount = this.breakpoint.isMobilePortrait ? 2 : 3;

    const th = table.querySelectorAll('th');
    const tr = table.querySelectorAll('tr');
    // Set column visibility and animation
    const configureColumns = (td: NodeListOf<HTMLElement>, dataAttribute: string, visibleColumnId: string): void => {
      for (let i = 1; i < td.length; i++) {
        td[i].setAttribute(dataAttribute, (i - columnCount + 2).toString());
        // Hide columns if it does not have the given visible column id
        if (td[i].getAttribute(dataAttribute) !== visibleColumnId) {
          td[i].removeAttribute('style'); // Removes 'opacity: 1'
          td[i].style.display = 'none';
        } else {
          // Otherwise set the column visible
          td[i].removeAttribute('style'); // Removes 'display: none'
          td[i].style.opacity = '0';
          // Set rounded border for the last column of the table header
          if (dataAttribute.indexOf('header') !== -1) {
            td[i].style.borderRadius = '0 10px 10px 0';
          }
          // If screen width is mobile landscape there are 3 columns visible
          if (columnCount === 3) {
            td[i - 1].removeAttribute('style'); // Removes 'display: none'
            td[i - 1].style.opacity = '0';
          }
          // Fade in by removing the 'opacity: 0'
          setTimeout(() => {
            td[i].removeAttribute('style');
            if (columnCount === 3) {
              td[i - 1].removeAttribute('style');
            }
          }, 200);
        }
      }
    };

    // Iterate over all rows and set the visibility for cells and headers
    for (let i = 0; i < tr.length; i++) {
      const td = tr[i].querySelectorAll('td') as NodeListOf<HTMLElement>;
      configureColumns(td, 'data-slide-column', visibleColumnId);
      configureColumns(th, 'data-slide-header', visibleColumnId);
    }
  }

  /**
   * Initialize the given static compare element.
   * Sets the listeners for resize event.
   * @param table The table root element that should be initialized
   */
  initialize(table: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, table);

    this.updateBreakpoint();
    this.updateColumnVisibility(table);

    // timeoutId for debounce mechanism
    let timeoutId = null;
    // Set window resize listener
    const resizeListener = (): void => {
      // Prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // Change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        this.updateBreakpoint();
        this.updateColumnVisibility(table);
      }, 500);
    };

    // Set resize listener
    window.addEventListener('resize', resizeListener);
  }

  /**
   * Inserts the indicators to the root element
   * @param root The table root element
   * @param indicators The indicators to insert/append
   */
  insertIndicators(root: HTMLElement, indicators: HTMLElement): void {
    // Add indicators to the static compare table
    if (!root.parentElement.contains(indicators)) {
      root.parentElement.insertBefore(indicators, root);
    }
  }

  updateBreakpoint(): void {
    this.breakpoint = currentBreakpoint();
    this.sliderEnabled = this.breakpoint.isMobilePortrait || this.breakpoint.isMobileLandscape;
  }

  /**
   * There are no additional changes necessary on mobile view
   */
  updateMobileChanges(): void {
    return;
  }

  /**
   * Updates the indicator count and sets the first table column visible
   * @param root The table root element
   */
  updateSlideOptions(root: HTMLElement): void {
    const columnCount = this.breakpoint.isMobilePortrait ? 2 : 3;
    const th = root.querySelectorAll('th');
    this.indicatorCount = th.length - columnCount + 1;

    // Set first column visible
    this.setColumnVisibility(root.parentElement, '1');
  }
}
const staticCompareTableModule = new StaticCompareTableModule();
export { staticCompareTableModule as staticCompareTable };
