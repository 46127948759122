import { ComponentObservable, componentObserver } from '../../component-observer';
import { getContactsForCountry } from '../../api/contacts-api';
import { createHtmlElementFromString } from '../../utils/html.utils';

/**
 * Initializes and renders the contact component.
 */
class Contact implements ComponentObservable {
  /**
   * Selector of all contact components that must be initialized in javascript.
   */
  componentSelector = '.contact-component';
  translations = {
    phone: '',
    contactButtonLabel: '',
    website: ''
  };

  /**
   * Listen for changes in DOM and initialize contact components when new ones appears
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given contact component.
   * @param table The table root element that should be initialized
   */
  initialize(contact: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, contact);
    this.translations = JSON.parse(contact.dataset.translations);
    this.addDropdownListener(contact);
    this.loadContacts(contact);
  }

  addDropdownListener(contactElement: HTMLElement): void {
    const countryDropdown = document.querySelector<HTMLElement>('ul[data-reference-id="countryDropdown"]');
    countryDropdown.addEventListener('click', () => {
      this.loadContacts(contactElement);
    });
  }

  async loadContacts(contactElement: HTMLElement): void {
    const countryInput = document.querySelector<HTMLInputElement>('input[name="form-countryDropdown"]');
    const selectedCountry = countryInput.value;
    const subsidiaryCountries = ['United States', 'United Kingdom', 'India', 'China', 'France'];

    if (subsidiaryCountries.indexOf(selectedCountry) >= 0) {
      // Show subsidiary contact
      const contacts = await getContactsForCountry(selectedCountry);
      if (contacts.length > 0) {
        const contact = contacts[0];
        const subsidiaryElement = `<div class="text-and-image-component text-and-image-component--image-small">
                <div class="text-and-image-component__left-content">
                    <div class="text-and-image-component__image-wrapper">
                        <img src="${contact.image ? contact.image.renditions.large.link : ''}" alt="${
          contact.companyName
        }" />
                    </div>
                </div>
                <div class="text-and-image-component__right-content">
                    <h3 class="text-and-image-component__heading">${
                      contact.companyName
                    } <span class="text-color-light">${contact.city || ''}</span></h3>
                    <p class="text-and-image-component__text text-large">
                      ${contact.address || ''}<br/>
                      ${contact.city || ''}, ${contact.postalCode}<br/>
                      ${contact.region || ''}<br/>
                      ${
                        contact.phone
                          ? `${this.translations.phone}: <a href="tel:${contact.phone}">${contact.phone || ''}</a>`
                          : ''
                      }
                    </p>
                    <div class="button-section button-section--small-margin">
                        <a href="${contactElement.dataset.contactFormLink}?id=${contact.guid}" class="button">
                            <span class="button__text">${this.translations.contactButtonLabel}</span>
                        </a>
                    </div>
                </div>
            </div>`;
        contactElement.querySelector('.subsidiary-container').innerHTML = subsidiaryElement;
        contactElement.querySelector<HTMLElement>('.hq-divider').style.display = 'block';
        contactElement.querySelector('.contact-tiles-container').innerHTML = '';
      }
    } else if (selectedCountry !== 'Switzerland') {
      // Show contacts list tiles
      const contacts = await getContactsForCountry(selectedCountry);
      contactElement.querySelector('.contact-tiles-container').innerHTML = '';

      for (let i = 0; i < contacts.length; i++) {
        const contact = contacts[i];
        const tile = `<div class="tile tile--teaser-card tile--contact" data-animate-when-visible="fade-in-up">
                        ${
                          contact.image
                            ? `<div class="tile__image">
                            <img src="${contact.image.renditions.small.link}" alt="${contact.companyName}" 
                            srcset="${contact.image.renditions.small.link} 500w, 
                            ${contact.image.renditions.large.link} 636w" 
                            sizes="(max-width: 479px) 91vw, (max-width: 767px) 81vw, (max-width: 991px) 39vw, (max-width: 1439px) 29vw, 388px" />
                        </div>`
                            : ''
                        }
                        <div class="tile__content">
                            <h4 class="tile__headline">${contact.companyName}</h4>
                            <div class="tile__rich-text text-large">
                                <p>${contact.address || ''}</p>
                                <p>${contact.postalCode || ''} ${contact.city || ''}</p>
                                <p>${contact.region || ''}</p><br/>
                                ${
                                  contact.phone
                                    ? `${this.translations.phone}: <a href="tel:${contact.phone}">${
                                        contact.phone || ''
                                      }</a>`
                                    : ''
                                }
                                ${
                                  contact.website
                                    ? `<p><a target="_blank" href="${contact.website}">${this.translations.website}</a></p>`
                                    : ''
                                }
                            </div>
                            <div class="button-section">
                              <a href="${contactElement.dataset.contactFormLink}?id=${contact.guid}">
                                <button class="button" aria-label="${this.translations.contactButtonLabel}">
                                  <span class="button__text">${this.translations.contactButtonLabel}</span>
                                </button>
                              </a>
                            </div>
                        </div>
                      </div>`;

        contactElement.querySelector('.contact-tiles-container').appendChild(createHtmlElementFromString(tile));
      }
      contactElement.querySelector('.subsidiary-container').innerHTML = '';
      contactElement.querySelector<HTMLElement>('.hq-divider').style.display = 'block';
    } else {
      contactElement.querySelector('.contact-tiles-container').innerHTML = '';
      contactElement.querySelector('.subsidiary-container').innerHTML = '';
      contactElement.querySelector<HTMLElement>('.hq-divider').style.display = 'none';
    }
  }

  /**
   * Initialize all contact components on the current page.
   * @param observe Boolean if the contact components should listen for changes in the DOM and initialize dynamically added contact components
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise(resolve => {
      const allContactComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allContactComponents.length; i++) {
        this.initialize(allContactComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }
}

const contactModule = new Contact();
export { contactModule as contact };
