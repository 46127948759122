/**
 * Module that is responsible for fetching and caching the user data.
 * The user-data-api is only responsible for fetching the data from the api, this module in contrast
 * adds some caching functionality, so that the user data must not be fetched each time they are used.
 */
import { getUserData, HamiltonUserData } from './api/user-data-api';
import { getLastSelectedCustomerId } from './api/shopping-cart-api';

class UserDataModule {
  /**
   * Last fetched user data information.
   */
  userData: HamiltonUserData = null;

  /**
   * The last customer ID that the user selected in the customer dropdown in the e-catalog filter.
   */
  lastSelectedCustomerId: string = null;

  /**
   * Get the user data. If it has not been cached yet, this
   * function makes a request to the user data API. After that it will return the cached user data.
   * @param forceUpdate Boolean If the user data should be loaded from the API even if there exists a cached version.
   */
  async getCachedUserData(forceUpdate = false): Promise<HamiltonUserData> {
    if (this.userData && !forceUpdate) {
      // If the user data has been fetched already, return the cached one
      return Promise.resolve(this.userData);
    }

    return new Promise((resolve) => {
      // Otherwise call the user data API
      getUserData().then((userData) => {
        this.userData = userData;
        resolve(this.userData);
      });
    });
  }

  /**
   * Returns all user data
   */
  async getCachedCustomerIdsForCurrentUser(forceUpdate = false): Promise<string[]> {
    if (this.userData && !forceUpdate) {
      // If the user data has been fetched already, return the cached customer numbers
      if (this.userData.customerNumbers) {
        return Promise.resolve(this.userData.customerNumbers.split(','));
      }
      return Promise.resolve([]);
    }

    return new Promise((resolve) => {
      // Otherwise call the user data API
      getUserData().then((userData) => {
        this.userData = userData;
        if (this.userData.customerNumbers) {
          resolve(this.userData.customerNumbers.split(','));
        } else {
          resolve([]);
        }
      });
    });
  }

  /**
   * Returns all user data
   */
  async getCachedLastSelectedCustomerId(forceUpdate = false): Promise<string> {
    if (this.lastSelectedCustomerId && !forceUpdate) {
      // If the user data has been fetched already, return the cached customer id
      return Promise.resolve(this.lastSelectedCustomerId);
    }

    return new Promise((resolve) => {
      // Otherwise call the user data API
      const lastSelectedCustomerIdPromise = getLastSelectedCustomerId();
      if (lastSelectedCustomerIdPromise !== null) {
        lastSelectedCustomerIdPromise.then((lastSelectedCustomerId) => {
          this.lastSelectedCustomerId = lastSelectedCustomerId;
          resolve(this.lastSelectedCustomerId);
        });
      } else {
        resolve('');
      }
    });
  }
}

const userDataModule = new UserDataModule();
export { userDataModule as userData };
