import { ComponentObservable, componentObserver } from '../../component-observer';
import { toastNotification } from '../../building-blocks/toast-notification/toast-notification.module';
import { registerMatomoEvent } from '../../utils/matomo.utils';
import {currentBreakpoint} from "../../utils/resize.utils";

/**
 * Initializes all social share buttons to open/close the social share flyout
 */
class SocialShareModule implements ComponentObservable {
  /**
   * Selector of all share buttons that must be initialized in javascript.
   */
  componentSelector = '.social-share-button';

  /**
   * Base URL for sharing a page on facebook. Simply append the URL
   * to share to the end of the base URL.
   */
  facebookShareBaseURL = 'https://www.facebook.com/share.php?u=';
  /**
   * Base URL for sharing a page on twitter. Simply append the URL
   * to share to the end of the base URL. Hashtags and text would also
   * be possible by append the parameters 'hashtags' and 'text'.
   */
  twitterShareBaseURL = 'http://twitter.com/share?url=';
  /**
   * Base URL for sharing a page on linkedIn. Simply append the URL
   * to share to the end of the base URL.
   */
  linkedInShareBaseURL = 'https://www.linkedin.com/sharing/share-offsite/?url=';
  /**
   * Base URL for sharing a page on via email. Simply append the URL
   * to share to the end of the base URL. Subject and receiver can also be set.
   * Put the receiver after the 'mailto:' and the subject using the 'subject' parameter.
   */
  mailShareBaseURL = 'mailto:?body=';

  /**
   * Initialize all social share buttons on the current page.
   * @param observe Boolean if the socialShareModule should listen for changes in the DOM and initialize dynamically
   *   added buttons
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allSocialShareButtons = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allSocialShareButtons.length; i++) {
        this.initialize(allSocialShareButtons[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize social share buttons when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Set the correct URLs for the share buttons. This means setting the URL to share
   * to the current page (window.location).
   */
  setupShareLinksForCurrentPage(root: HTMLElement): void {
    const currentPageURL = encodeURIComponent(window.location.href);
    const facebookButton = root.querySelector<HTMLAnchorElement>('.social-share__facebook');
    const twitterButton = root.querySelector<HTMLAnchorElement>('.social-share__twitter');
    const linkedInButton = root.querySelector<HTMLAnchorElement>('.social-share__linkedIn');
    const mailButton = root.querySelector<HTMLAnchorElement>('.social-share__mail');
    const copyButton = root.querySelector<HTMLElement>('.social-share__copy');

    if (facebookButton) {
      facebookButton.href = `${this.facebookShareBaseURL}${currentPageURL}`;
      facebookButton.addEventListener('click', () => {
        registerMatomoEvent('Share on', 'Share button click', facebookButton.href);
      });
    }
    if (twitterButton) {
      twitterButton.href = `${this.twitterShareBaseURL}${currentPageURL}`;
      twitterButton.addEventListener('click', () => {
        registerMatomoEvent('Share on', 'Share button click', twitterButton.href);
      });
    }
    if (linkedInButton) {
      linkedInButton.href = `${this.linkedInShareBaseURL}${currentPageURL}`;
      linkedInButton.addEventListener('click', () => {
        registerMatomoEvent('Share on', 'Share button click', linkedInButton.href);
      });
    }
    if (mailButton) {
      mailButton.href = `${this.mailShareBaseURL}${currentPageURL}`;
      mailButton.addEventListener('click', () => {
        registerMatomoEvent('Share on', 'Share button click', mailButton.href);
      });
    }
    if (copyButton) {
      copyButton.addEventListener('click', () => {
        const url = copyButton.dataset.detailLink ? copyButton.dataset.detailLink : window.location.href;
        navigator.clipboard.writeText(url).then(() => {
          // confirm with a notification
          toastNotification.createSuccessToastMessage(copyButton.dataset.labelCopied);

          // close the share flyout
          this.showFlyout(false, root);
          registerMatomoEvent('Share on', 'Share button click', window.location.href);
        });
      });
    }
  }

  /**
   * Show or hide the social share flyout.
   * @param open If the flyout should be opened or closed.
   * @param socialShareButton The social share button root element that should be initialized
   */
  showFlyout(open: boolean, socialShareButton: HTMLElement): void {
    const button = socialShareButton.querySelector('button');
    const socialShare = socialShareButton.querySelector('.social-share');

    document.querySelectorAll('.social-share').forEach(element => {
      element.classList.add('social-share--hidden');
    });

    document.querySelectorAll(this.componentSelector).forEach(element => {
      element.querySelector('button').classList.remove('button--active');
    });

    if (open) {
      socialShare.classList.remove('social-share--hidden');
      button.classList.add('button--active');
    } else {
      socialShare.classList.add('social-share--hidden');
      button.classList.remove('button--active');
    }
  }

  /**
   * Initialize the given social share button element.
   * @param socialShareButton The social share button root element that should be initialized
   */
  initialize(socialShareButton: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, socialShareButton);
    const button = socialShareButton.querySelector('button');
    const socialShare = socialShareButton.querySelector('.social-share');
    const breakpoint = currentBreakpoint();

    button.addEventListener('click', (event) => {
      event.stopImmediatePropagation();
      if (socialShare.classList.contains('social-share--hidden')) {
        this.showFlyout(true, socialShareButton);
      } else {
        this.showFlyout(false, socialShareButton);
      }
    });

    this.setupShareLinksForCurrentPage(socialShareButton);
    console.log(socialShare.classList);
    if (socialShare.classList.contains('social-share-detail')) {
      if (breakpoint.isMobilePortrait || breakpoint.isMobileLandscape) {
        socialShare.classList.add('social-share--top');
      } else {
        socialShare.classList.remove('social-share--top');
      }
    }
  }
}

const socialShareModule = new SocialShareModule();
export { socialShareModule as socialShare };
