import { ComponentObservable, componentObserver } from '../../component-observer';

/**
 * Initializes the animation of the news tables.
 */
class NewsTableModule implements ComponentObservable {
  /**
   * Selector of all news tables that must be initialized in javascript.
   */
  componentSelector = '.news-table';

  /**
   * Class for the highlighted row
   */
  highlightClass = 'news-table--highlight';
  /**
   * Initialize all news table on the current page.
   * @param observe Boolean if the cookieBannerModule should listen for changes in the DOM and initialize dynamically
   *   added news tables
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allNewsTableComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allNewsTableComponents.length; i++) {
        this.initialize(allNewsTableComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize news tables when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given news table element.
   * @param table The news table root element that should be initialized
   */
  initialize(table: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, table);
    const anchors = table.querySelectorAll('a');
    let highlightedRow = table.querySelector(`.${this.highlightClass}`);

    // Add on click listener for row
    anchors.forEach((anchor) => {
      const tr = anchor.parentElement.parentElement;
      tr.addEventListener(
        'click',
        (event: MouseEvent) => {
          event.stopImmediatePropagation();
          document.location.href = anchor.getAttribute('href');
        },
        false
      );
    });

    // Highlight row one after another
    setInterval(() => {
      const nextElement = highlightedRow.nextElementSibling || table.querySelector('tr:first-child');
      nextElement.classList.add(this.highlightClass);
      highlightedRow.classList.remove(this.highlightClass);
      highlightedRow = nextElement;
    }, 4000);
  }
}

const newsTableModule = new NewsTableModule();
export { newsTableModule as newsTable };
