import { ComponentObservable, componentObserver } from '../../../component-observer';
import { currentBreakpoint } from '../../../utils/resize.utils';
import { createHtmlElementFromString } from '../../../utils/html.utils';
import { breakpointListener } from '../../../utils/breakpoint-listener.module';

/**
 * Initializes the media overlay.
 */
class MediaOverlayModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.overlay--media';

  /**
   * Initialize all media overlays on the current page.
   * @param observe Boolean if the MediaOverlayModule should listen for changes in the DOM and initialize dynamically
   *   added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allComponents.length; i++) {
        this.initialize(allComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given media overlay element.
   * Sets the listener for resize.
   * @param root The media overlay root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);

    const urlParams = new URLSearchParams(window.location.search);
    const media = urlParams.get('media');

    if(media !== undefined){
      document.querySelector('html').classList.add('no-scroll');
      const footer = document.querySelector('footer');
      root.appendChild(footer.cloneNode(true));
      root.querySelectorAll('.footer__info-wrapper').forEach(element => element.classList.add('is-visible'));

      // If the video exceeds the viewport, apply a different styling
      const rect = root.querySelector('iframe').getBoundingClientRect();
    
      // Check if the element's rectangle is within the viewport
      const isOverflowing = (
          rect.top < 0 ||
          rect.left < 0 ||
          rect.bottom > window.innerHeight ||
          rect.right > window.innerWidth
      );

      if (isOverflowing) {
        root.classList.add('overlay--media__full-height');
      }
    }
  }
}

const mediaOverlayModule = new MediaOverlayModule();
export { mediaOverlayModule as mediaOverlay };
