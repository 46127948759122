import { baseUrl, requestHeadersBasicAuth, requestHeadersJsonBasicAuth } from './general-api';
import { HamiltonShoppingCartItem } from '../building-blocks/shopping-cart/shopping-cart.module';
import { v4 as generateUUID } from 'uuid';
import { HamiltonProductPrice } from './products-api';
import { userData } from '../user-data.module';

/**
 * Base URL for operations on the shopping cart.
 */
export const baseShoppingCartPath = baseUrl + '/.rest/nodes/v1/userdata/shopping-cart';

/**
 * Base URL to retrieve the shopping cart.
 */
export const baseShoppingCartGetAllPath = baseUrl + '/.rest/delivery/v1/catalog/userdata';

export interface CheckoutResponse {
  errors: string[];
  success: boolean;
}

/**
 * Return a string that contains the price and the currency. E.g. '135 EUR'
 */
export const getPriceWithCurrencyForOnePiece = (prices: HamiltonProductPrice[]): string => {
  if (prices && prices.length > 0) {
    for (let i = 0; i < prices.length; i++) {
      if (prices[i].quantity === 1) {
        return getPriceWithCurrency(prices[i]);
      }
    }
  }
  return '';
};

/**
 * Return a string that contains the price and the currency. E.g. '135 EUR'
 */
export const getPriceWithCurrency = (price: HamiltonProductPrice): string => {
  if (price) {
    return `${price.price} ${price.currency}`;
  }
  return '';
};

/**
 * Return the price for a single item.
 */
export const getPrice = (prices: HamiltonProductPrice[]): number => {
  if (prices && prices.length > 0) {
    for (let i = 0; i < prices.length; i++) {
      if (prices[i].quantity === 1) {
        return prices[i].price;
      }
    }
  }
  return 0;
};

/**
 * Return the currency for a single item.
 */
export const getCurrency = (prices: HamiltonProductPrice[]): string => {
  if (prices && prices.length > 0) {
    for (let i = 0; i < prices.length; i++) {
      if (prices[i].quantity === 1) {
        return prices[i].currency;
      }
    }
  }
  return '';
};

/**
 * Returns all wish lists
 */
export const getShoppingCart = (): Promise<HamiltonShoppingCartItem[]> => {
  if (document.querySelector('html').classList.contains('user-logged-out')) {
    return null;
  }
  let url = baseShoppingCartGetAllPath;
  const params: Record<string, string> = {};

  if (userData.lastSelectedCustomerId) {
    params.customerNo = userData.lastSelectedCustomerId;
  }

  if (Object.keys(params).length > 0) {
    const parameterString = new URLSearchParams(params).toString();
    url = `${url}?${parameterString}`;
  }

  return fetch(url, {
    headers: requestHeadersBasicAuth
  })
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        const shoppingCart = data['shopping-cart'];
        const availableNodes = shoppingCart['@nodes'];
        const shoppingCartItems = [];

        for (const node of availableNodes) {
          shoppingCartItems.push(shoppingCart[node]);
        }
        return shoppingCartItems;
      }

      return [];
    })
    .catch(() => {
      return null;
    });
};

/**
 * Removes the shopping cart item with the given name from the shopping cart.
 * @param productId: ID of the product that should be deleted from the shopping cart.
 */
export const deleteItemFromShoppingCart = (productId: string): Promise<boolean> => {
  return fetch(`${baseShoppingCartPath}/${productId}`, {
    method: 'DELETE',
    headers: requestHeadersJsonBasicAuth
  })
    .then((response) => {
      return response.ok;
    })
    .catch(() => {
      return false;
    });
};

/**
 * Store the selected customer ID in the backend, so the next time the user visits the page, the customer is still selected.
 * @param customerId: The id of the customer selected from the customer dropdown.
 */
export const updateSelectedCustomerId = (customerId: string): Promise<boolean> => {
  const customerIdUpdateBody = {
    properties: [
      {
        name: 'currentCustomerNumber',
        type: 'String',
        multiple: false,
        values: [customerId]
      }
    ]
  };

  return fetch(`${baseShoppingCartPath}`, {
    method: 'POST',
    headers: requestHeadersJsonBasicAuth,
    body: JSON.stringify(customerIdUpdateBody)
  })
    .then((response) => {
      return response.ok;
    })
    .catch(() => {
      return false;
    });
};

/**
 * Retrieve the last selected customer of the user from the API. Returns the ID or null if no customer set yet.
 */
export const getLastSelectedCustomerId = (): Promise<string | null> => {
  if (document.querySelector('html').classList.contains('user-logged-out')) {
    return null;
  }
  return fetch(`${baseShoppingCartPath}`, {
    method: 'GET',
    headers: requestHeadersJsonBasicAuth
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.properties) {
        const customerNumberProps = data.properties.find((item) => item.name === 'currentCustomerNumber');
        if (customerNumberProps?.values?.length > 0) {
          return customerNumberProps.values[0];
        }
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

/**
 * Adds the shopping cart item with the given name and ID to the shopping cart.
 * @param productId: ID of the product that should be added to the shopping cart.
 */
export const addItemToShoppingCart = (productId: string): Promise<boolean> => {
  const uuid = generateUUID();

  const cartItem = {
    name: uuid, // Any value. it will be the name of the item in the shopping cart. Could be any unique ID or number.
    type: 'mgnl:contentNode',
    properties: [
      {
        name: 'article',
        type: 'String',
        multiple: false,
        values: [productId] // id of the article
      },
      {
        name: 'quantity',
        multiple: false,
        type: 'Long',
        values: [1] // quantity
      }
    ]
  };

  return fetch(`${baseShoppingCartPath}`, {
    method: 'PUT',
    body: JSON.stringify(cartItem),
    headers: requestHeadersJsonBasicAuth
  })
    .then((response) => {
      return response.ok;
    })
    .catch(() => {
      return false;
    });
};

/**
 * Submits an order
 * @param serialNumbers: A comma separated list of serial numbers
 * @param licenseType: The type of the license (0,1,2,3)
 * @param note: Note for the order
 * @param customerNumber: customer purchase number
 */
export const submitOrder = (
  serialNumbers: string,
  licenseType: string,
  note: string,
  customerNumber: string
): Promise<CheckoutResponse> => {
  let url = baseUrl + '/.rest/utils/v1/checkout-order';
  const params: Record<string, string> = {};
  const urlParams = new URLSearchParams(window.location.search);
  params.serialNumbers = serialNumbers;
  params.licenseType = licenseType;
  params.shipmentInstructions = note;
  params.customerPurchaseNumber = customerNumber;
  params.customerNumber = urlParams.get('customerNo');

  url = `${url}?${new URLSearchParams(params).toString()}`;
  return fetch(`${url}`, {
    method: 'POST',
    headers: requestHeadersJsonBasicAuth
  })
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        return data;
      }

      return '';
    })
    .catch(() => {
      return '';
    });
};
