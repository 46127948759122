/**
 * Check if the user is visiting the page on mobile safari. This function is not bullet proof, as there might be
 * some minor browsers (on ios), that this function misses. But its sufficient to detect that a user is visiting
 * at least on iOS+mobile and probably on safari.
 */
export const isMobileSafari = (): boolean => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
};
