import { ComponentObservable, componentObserver } from '../../component-observer';
import { currentBreakpoint } from '../../utils/resize.utils';
//import { selectWishListOverlay } from './e-catalog-overlays/select-wish-list-overlay.module';
import { isMobileSafari } from '../../utils/user-agent.utils';

/**
 * Initializes all buttons that should open an overlay. Those buttons have the data-overlay-id attribute that specifies
 * which overlay should be opened on click.
 */
class OverlayTriggerButtonModule implements ComponentObservable {
  /**
   * Attribute that all buttons have, that should open an overlay.
   */
  componentSelector = '[data-overlay-id]';

  /**
   * Move here from select-wish-list-overlay.module.ts for separation
   */
  selectWishlistOverlayId = 'select-wish-list-overlay';

  /**
   * Initialize all buttons that trigger overlays on the current page.
   * @param observe Boolean if the OverlayTriggerButtonModule should listen for changes in the DOM and initialize dynamically added
   *   buttons
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const buttons = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < buttons.length; i++) {
        this.initialize(buttons[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize buttons when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Open the overlay with the specified ID on click.
   * @param event The mouse event
   */
  openOverlay(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (
      (event.currentTarget as HTMLElement).tagName !== 'FOOTNOTE-REFERENCE' &&
      (event.currentTarget as HTMLElement).parentElement.tagName !== 'FOOTNOTE-REFERENCE' &&
      !(event.currentTarget as HTMLElement).querySelector('footnote-reference') &&
      !(event.currentTarget as HTMLElement).parentElement.classList.contains('reference-item') &&
      !(event.currentTarget as HTMLElement).parentElement.classList.contains('link-list__item') &&
      !((event.currentTarget as HTMLElement).dataset.overlayId.includes('video-'))
    ) {
      if (window.localStorage) {
        window.localStorage.setItem('scrollPosition', window.scrollY);
      }
      setTimeout(()=> {
        document.querySelector('html').classList.add('no-scroll');
      }, 10);
    }

    const triggerButton = event.currentTarget as HTMLElement;

    const overlayId = triggerButton.getAttribute('data-overlay-id');
    const optionalImageSource = triggerButton.getAttribute('data-overlay-image');
    const optionalTitle = triggerButton.getAttribute('data-overlay-title');
    const optionalDescription = triggerButton.getAttribute('data-overlay-description');
    const optionalImageCaption = triggerButton.getAttribute('data-overlay-image-caption');
    const optionalInputValue = triggerButton.getAttribute('data-overlay-input-value');
    const optionalId = triggerButton.getAttribute('data-overlay-associated-id');
    const shouldFocusInput = triggerButton.dataset.focusInput;

    if (overlayId) {
      // open the overlay with the given ID
      const overlay = document.querySelector<HTMLElement>(`#${overlayId}`);

      if (optionalImageSource) {
        // if specified, load a custom image
        const overlayImage = overlay.querySelector('img');

        // first hide the current image (so that the previous image does not confuse the user until the new one is loaded)
        overlayImage.src = '';

        // now set the new image
        overlayImage.src = optionalImageSource;

        // Set alt attribute to the title
        if (optionalTitle) {
          overlayImage.alt = optionalTitle;
        }
      }

      if (optionalTitle) {
        // if specified, load a custom title
        const overlayTitle = overlay.querySelector<HTMLElement>('.overlay__title');
        overlayTitle.innerText = optionalTitle;
      }

      if (optionalDescription) {
        // if specified, load a custom description
        const overlayDescription = overlay.querySelector<HTMLElement>('.overlay__text');
        overlayDescription.innerText = optionalDescription;
      }

      if (optionalImageCaption) {
        // if specified, load a custom image caption
        const overlayImageLegend = overlay.querySelector('figcaption');
        overlayImageLegend.innerText = optionalImageCaption;
      }

      if (optionalInputValue) {
        // if specified, set the input value
        const inputElement = overlay.querySelector('input');
        inputElement.value = optionalInputValue;

        // make sure the label is collapsed when setting a new value.
        if (inputElement.previousElementSibling && inputElement.previousElementSibling.classList.contains('label')) {
          inputElement.previousElementSibling.classList.add('label--sup');
        }
      }

      if (optionalId) {
        // if specified, append an additional ID (used for example to store which wish list is being edited)
        overlay.setAttribute('data-overlay-associated-id', optionalId);
      }

      // Check if the overlay is for desktop only and open it if the current breakpoint is desktop or larger
      const breakpoint = currentBreakpoint();
      const openOverlay =
        overlay && overlay.classList.contains('overlay-wrapper--desktop-only') ? breakpoint.isDesktop : true;
      if (overlay && openOverlay) {
        overlay.classList.add('overlay--visible');
      }

      // TODO: Optimization: find a workaround for this
      /*if (overlayId === selectWishListOverlay.overlayId) {
        // if the overlay is a select wish list overlay, we need to setup a few more things
        selectWishListOverlay.loadContent(overlay);
      }*/

      if (overlayId === this.selectWishlistOverlayId) {
        // if the overlay is a select wish list overlay, we need to setup a few more things
        document.dispatchEvent(new CustomEvent('selectWishListOverlayInitializeLoad', { detail: overlay }));
        // selectWishListOverlay.loadContent(overlay);
      }

      if (shouldFocusInput) {
        this.focusInputField(overlay);
      }
    }
  }

  /**
   * Focus input field when the overlay is opened and select the current text.
   */
  focusInputField(overlay: HTMLElement): void {
    const inputField = overlay.querySelector('input');
    const currentInputLength = inputField?.value?.length || 0;
    const breakpoint = currentBreakpoint();

    // Don't focus the input field on safari mobile because the keyboard
    // moves above the input field, which is not visible anymore then.
    if (inputField && !isMobileSafari() && !breakpoint.isMobileAny) {
      inputField.focus();
      inputField.setSelectionRange(0, currentInputLength);
    }
  }

  /**
   * Initialize the given button item element.
   * Sets the listener for the click event.
   * @param button The button element that should be initialized
   */
  initialize(button: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, button);
    button.addEventListener(
      'click',
      (event: MouseEvent) => {
        this.openOverlay(event);
      },
      false
    );
  }
}

const overlayTriggerButtonModule = new OverlayTriggerButtonModule();
export { overlayTriggerButtonModule as overlayTriggerButton };
