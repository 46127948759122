import { ComponentObservable, componentObserver } from '../../component-observer';

/**
 * Initializes the learning path playlist elements.
 */
class LearningPathPlaylistModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.learning-path-playlist';

  /**
   * Initialize all learning path playlist on the current page.
   * @param observe Boolean if the LearningPathPlaylistModule should listen for changes in the DOM and
   *    initialize dynamically added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allComponents.length; i++) {
        this.initialize(allComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given filter element.
   * Sets the listener for resize.
   * @param root The learning path playlist root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root); 
    this.sortPlaylist(root);  
  }

  /**
 * Sort playlist elements by title
 * @param root The learning path playlist root element
 */
  sortPlaylist(root: HTMLElement): void {
    let tiles = Array.prototype.slice.call(root.querySelectorAll('.tile__grid--playlist .tile', 0));
    const container = root.querySelector('.tile__grid--playlist');

    tiles = tiles.sort((tile1, tile2) => {
      const title1 = tile1.querySelector('.tile__headline').innerHTML;
      const title2 = tile2.querySelector('.tile__headline').innerHTML;
      if (title1 < title2) return -1;
      if (title1 > title2) return 1;
      return 0;
    });

    container.innerHTML = '';
    for (let i = 0; i < tiles.length; i++) {
      container.appendChild(tiles[i]);
    }
  }
}

const learningPathPlaylistModule = new LearningPathPlaylistModule();
export { learningPathPlaylistModule as learningPathPlaylist };
