import { ComponentObservable, componentObserver } from '../../component-observer';
import anime from 'animejs/lib/anime.es.js';
import { Icon } from '../../general/icons/Icon';
import { createHtmlElementFromString } from '../../utils/html.utils';

/**
 * Initializes show more component to add a click listener to the button and toggle the content.
 */
class ShowMoreModule implements ComponentObservable {
  /**
   * Selector of all dropdown buttons that must be initialized in javascript.
   */
  componentSelector = '.show-more';

  /**
   * Creates the mega button to toggle the content
   * @param root The show more root element
   */
  createButton(root: HTMLElement): void {
    // Add button if it is not available yet
    if (!root.querySelector('.divider')) {
      const isClosed = root.classList.contains('show-more--closed');
      const buttonMarkup = `<div class="divider">
      <div class="divider__line"></div>
      <div class="divider__content">
          <div class="button-animation-wrapper">
              <button class="button button--mega" aria-label="${
          isClosed ? root.dataset.labelShowMore : root.dataset.labelShowLess
      }">
                  <span class="button__text">${
                    isClosed ? root.dataset.labelShowMore : root.dataset.labelShowLess
                  }</span>
                  <span class="button__icon-right">${isClosed ? Icon.add : Icon.dash}</span>
              </button>
          </div>
      </div>`;
      root.appendChild(createHtmlElementFromString(buttonMarkup));
    }
  }

  /**
   * Initialize all show-more components on the current page.
   * @param observe Boolean if the ShowMoreModule should listen for changes in the DOM and initialize dynamically added
   *   show more components
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const elements = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < elements.length; i++) {
        this.initialize(elements[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Toggle the content of the show more button.
   * @param mouseClickEvent The click event that kicked off the opening/closing of the shore more button
   * @param root The show more root element
   */
  toggleShowMore(mouseClickEvent: MouseEvent, root: HTMLElement): void {
    // mouseClickEvent.stopPropagation();
    const button = root.querySelector<HTMLElement>('.button--mega .button__text');
    const content = root.querySelector<HTMLElement>('.show-more__content');
    const isClosed = root.classList.contains('show-more--closed');

    // Animate the height of the show more content
    const targetHeight = !isClosed ? '0' : content.scrollHeight;
    if (!isClosed) {
      content.style.height = content.clientHeight + 'px';
      // Restore the original overflow specification
      content.style.overflow = null;
    }

    anime({
      targets: content,
      height: targetHeight,
      duration: 300,
      easing: 'easeInOutQuad',
      complete: () => {
        setTimeout(() => {
          if (!isClosed) {
            content.style.height = '0';
            // Restore the original overflow specification
            content.style.overflow = null;
          } else {
            content.style.height = 'auto';
            // Allow the tooltips to be visible even outside of the accordion element
            content.style.overflow = 'visible';
          }
        }, 400);
      }
    });

    // Toggle show more visibility
    if (root.classList.contains('show-more--closed')) {
      root.classList.remove('show-more--closed');
      button.innerText = root.dataset.labelShowLess;
      button.parentElement.querySelector('svg').innerHTML = Icon.dash;
    } else {
      // Close content
      root.classList.add('show-more--closed');
      button.innerText = root.dataset.labelShowMore;
      button.parentElement.querySelector('svg').innerHTML = Icon.add;
    }
  }

  /**
   * Listen for changes in DOM and initialize element when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the show more element.
   * Sets the listeners for toggle and key events.
   * @param root The show more root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);
    this.createButton(root);
    const button = root.querySelector<HTMLElement>('.button--mega');
    // Set click listener on mega button
    button.addEventListener('click', (event) => this.toggleShowMore(event, root), false);
    const isClosed = root.classList.contains('show-more--closed');
    const content = root.querySelector<HTMLElement>('.show-more__content');

    if (!isClosed) {
      // Allow the tooltips to be visible even outside of the accordion element
      content.style.overflow = 'visible';
    }
  }
}

const showMoreModule = new ShowMoreModule();
export { showMoreModule as showMore };
