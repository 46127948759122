import { ComponentObservable, componentObserver } from '../../component-observer';
import { currentBreakpoint, equalBreakpoints } from '../../utils/resize.utils';
import { SliderModule } from '../../utils/slider.module';
import { formatText } from '../../utils/html.utils';
import { Icon } from '../../general/icons/Icon';
import { getUrlForImage } from '../../utils/image.utils';
import { ventilators } from '../../ventilators.module';
import { Ventilator } from '../../api/ventilators-api';

/**
 * Initializes the compare table and adds the REST API to get the data.
 * Extends the slider module to add swipe on mobile and tablet.
 */
class CompareTableModule extends SliderModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.compare-table';

  /**
   * Current breakpoint
   */
  breakpoint = null;

  /**
   * All devices
   */
  devices: Ventilator[] = null;

  /**
   * The devices that are currently shown
   */
  visibleDevices: Ventilator[] = null;

  /**
   * Current count of visible columns depending on screen size
   */
  columnCount = null;

  /**
   * The maximal visible devices
   */
  maxVisibleCounts = 3;

  /**
   * Initialize all compare tables on the current page.
   * @param observe Boolean if the CompareTableModule should listen for changes in the DOM and initialize dynamically
   *   added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allTableComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allTableComponents.length; i++) {
        this.initialize(allTableComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Removes styling and data attributes from the given table
   * @param table The table to reset
   */
  resetColumns(table: HTMLElement): void {
    const tr = table.querySelectorAll('tr');
    // Remove data-attribute and style from the given columns
    const resetColumn = (td: NodeListOf<HTMLElement>, dataAttribute: string): void => {
      for (let i = 1; i < td.length; i++) {
        td[i].removeAttribute(dataAttribute);
        td[i].removeAttribute('style');
      }
    };
    // Reset table cells and headers
    for (let i = 0; i < tr.length; i++) {
      const td = tr[i].querySelectorAll('td') as NodeListOf<HTMLElement>;
      resetColumn(td, 'data-slide-column');
    }
  }

  /**
   * Set the column visibility with opacity animation for table cells and table headers
   * @param root The compare table root element
   * @param visibleColumnId The column id that should be visible
   */
  setColumnVisibility(root: HTMLElement, visibleColumnId: string): void {
    const allTables = root.querySelectorAll('table');
    allTables.forEach((table) => {
      // Show three columns on mobile landscape and tablet (two on mobile and four on desktop)
      const showThreeColumns = this.breakpoint.isMobileLandscape || this.breakpoint.isTablet;
      // Change table on mobile and tablet
      const tr = table.querySelectorAll('tr');
      // Set column visibility and animation
      // Iterate over all rows and set the visibility for cells and headers
      for (let i = 0; i < tr.length; i++) {
        const td = tr[i].querySelectorAll('td') as NodeListOf<HTMLElement>;
        const length = showThreeColumns ? td.length - 1 : td.length;
        for (let i = 0; i < length; i++) {
          const attributeId = (i + 1).toString();
          // Add slide-able option to the last two columns on tablet, three for mobile
          const tIndex = showThreeColumns ? i + 1 : i;
          td[tIndex].setAttribute('data-slide-column', attributeId);
          // Hide not visible columns
          if (attributeId !== visibleColumnId) {
            td[tIndex].removeAttribute('style'); // Removes 'opacity: 1'
            td[tIndex].style.display = 'none';
          } else {
            // Otherwise set the column visible
            td[tIndex].removeAttribute('style'); // Removes 'display: none'
            td[tIndex].style.opacity = '0';
            // Fade in by removing the 'opacity: 0'
            setTimeout(() => {
              td[tIndex].removeAttribute('style');
            }, 200);
          }
        }
      }
    });
  }

  /**
   * Creates an accordion component with the given title and a table element inside
   * @param title The title for the accordion
   */
  createAccordion(title: string): { accordion: HTMLElement; table: HTMLElement } {
    // Create accordion
    const accordion = document.createElement('div');
    accordion.classList.add('accordion');
    const accordionHeader = document.createElement('button');
    accordionHeader.classList.add('accordion__header');
    accordionHeader.innerText = title;
    accordion.appendChild(accordionHeader);
    const content = document.createElement('div');
    content.classList.add('accordion__content');
    accordion.appendChild(content);
    // Create table
    const table = document.createElement('table');
    table.classList.add('table');
    content.appendChild(table);
    return { accordion, table };
  }

  /**
   * Creates a table row with the given title (tr > th)
   * @param id The id of the table header (used to references the translation key)
   * @param title The content of the table header element
   */
  createTableRow(id: string, title: string): HTMLElement {
    const tr = document.createElement('tr');
    const th = document.createElement('th');
    th.innerText = title;
    if (id.length > 0) th.setAttribute('id', id);
    tr.appendChild(th);
    return tr;
  }

  /**
   * Function to return the devices to compare from the API
   */
  async fetchDevices(id: string): Promise<void> {
    const devices = await ventilators.getCachedVentilators(false, id);

    // Set devices
    this.devices = [...devices];

    // Set visible devices
    if (this.devices.length > this.maxVisibleCounts) this.visibleDevices = [...devices.slice(0, this.maxVisibleCounts)];
    else {
      this.visibleDevices = [...devices];
      this.maxVisibleCounts = devices.length;
    }
  }

  /**
   * Returns the table content from the API whether it should be shown as text or icon
   * @param content The content to show as text or icon
   */
  getIconOrText(content: string): string {
    // Map icon name with api name or render content
    const iconName = ['available', 'option', 'unavailable'];
    const apiName = ['standard', 'option', 'notAvailable'];
    const index = apiName.indexOf(content);
    if (index !== -1) {
      return `<span class='icon icon--${iconName[index]}'></span>`;
    }
    return content.replaceAll('\n', '<br>') || '';
  }

  /**
   * Creates a list component with the legend for the icons used in the tables
   * @param legend The translation key for each value
   */
  createLegend(legend: { available: string; option: string; unavailable: string }): string {
    return `<ul class='list'>
      <li class='list__item'><span class='icon icon--available'>${legend.available}</span></li>
      <li class='list__item'><span class='icon icon--option'>${legend.option}</span></li>
      <li class='list__item'><span class='icon icon--unavailable'>${legend.unavailable}</span></li>
    </ul>`;
  }

  /**
   * Checkbox to toggle the identical information on the tables
   * @param label The label for the checkbox
   */
  createCheckbox(label: string): string {
    return `<input type='checkbox' class='checkbox' id='check_hide_identical_info' name='check_name_hide_identical_info' value='1' />
    <label class='label' for='check_hide_identical_info'>${label}</label>`;
  }

  /**
   * Creates a dropdown for each selectable device
   * @param items The dropdown items
   * @param selectedItem The default selected item
   * @param label The hidden label for the dropdown
   */
  createDropdown(items: Array<{ name: string }>, selectedItem: string, label: string, deviceId: string): string {
    let dropdownItems = '';
    items.forEach((item) => {
      dropdownItems += `<li class='dropdown__item' tabindex='0'>${item.name}</li>`;
    });
    return `<label class='label label--hidden' for='dropdown-products-${deviceId}'>${label}</label> 
    <div class='form-control form-control--dropdown'>
      <button class='button button--dropdown' id='dropdown-products-${deviceId}'>${selectedItem}</button>
      <ul class='dropdown' data-reference-id='dropdown-products-${deviceId}'>
        ${dropdownItems}
      </ul>
    </div>`;
  }

  /**
   * Creates the markup for the comparable devices including dropdown and image
   * @param label The hidden label for the dropdown
   */
  createDevices(label: string): string {
    let markup = '';
    if (this.devices !== null) {
      const selectableDevices = [...this.devices].splice(1); // First device is not selectable
      for (let i = 0; i < this.maxVisibleCounts; i++) {
        const device = this.visibleDevices[i];
        markup += `<td><div class='compare-table__device'>
    <img src='${getUrlForImage(device.image?.renditions?.small?.link)}' alt='${device.name}'>
    ${
      i === 0
        ? `<span>${device.name}</span>`
        : this.createDropdown(selectableDevices, device.name, label, device['@id'])
    }
      </div></td>`;
      }
    } else {
      for (let i = 0; i < this.columnCount; i++) {
        markup += `<td><div class='compare-table__device'>
        <div class="compare-table-skeleton-image"></div>
        <div class="compare-table-skeleton-label"></div>
      </div></td>`;
      }
    }

    return markup;
  }

  /**
   * Renders the table rows with the given labels, devices and the option to hide identical information
   * @param root The compare table root element
   * @param json The translation key for the table header
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderTableRows(root: HTMLElement, json: any): void {
    // Check if there is already a container for the accordions, or create one otherwise
    let accordionContainer = root.querySelector('.accordion-container');
    if (!accordionContainer) {
      // Create new accordion container
      accordionContainer = document.createElement('div');
      accordionContainer.classList.add('accordion-container');
      root.appendChild(accordionContainer);
    } else {
      // Remove all accordions inside the accordion container before creating new ones
      let accordion = accordionContainer.firstElementChild;
      while (accordion) {
        accordionContainer.removeChild(accordion);
        accordion = accordionContainer.firstElementChild;
      }
    }

    // Create accordions
    for (let i = 0; i < json.compare.length; i++) {
      const obj = json.compare[i];
      const headline = obj.headline;
      // Create accordion with empty table
      const { accordion, table } = this.createAccordion(headline);
      accordionContainer.appendChild(accordion);
      for (const key in obj.columns) {
        const value = obj.columns[key];
        // Create table row for each column
        const tr = this.createTableRow(key, value);
        table.appendChild(tr);
        let info = 0;
        if (this.visibleDevices?.length > 0) {
          for (let i = 0; i < this.maxVisibleCounts; i++) {
            const device = this.visibleDevices[i];
            // Check if info is identical to the first device
            if (this.visibleDevices[0][key] === device[key]) {
              info++; // Count identical information to check if all are the same
            }
            const td = document.createElement('td');
            td.innerHTML = this.getIconOrText(device[key]);
            tr.appendChild(td);
            tr.setAttribute('data-identical', (info === this.visibleDevices.length).toString());
          }
        } else {
          // Render skeleton if no data are set yet (loading state)
          for (let i = 0; i < this.columnCount; i++) {
            const td = document.createElement('td');
            td.innerHTML = '<span class="compare-table-skeleton-row"></span>';
            tr.appendChild(td);
          }
        }
      }
      // Render links for each column if a link name is given and the products have a link
      if (this.visibleDevices?.length > 0 && (obj.linkName || obj.link)) {
        const tr = this.createTableRow('', '');
        let hasLinks = false;
        // Check if each column has a link
        if (obj.linkName) {
          for (let i = 0; i < this.maxVisibleCounts; i++) {
            const device = this.visibleDevices[i];
            if (device.techSpecsLink) {
              hasLinks = true;
              const td = document.createElement('td');
              td.innerHTML = `<a class="link--icon" href="${device.techSpecsLink}">${Icon.rightChevron}${obj.linkName}</a>`;
              tr.appendChild(td);
            }
          }
        } else if (obj.link) {
          // Check if there is a link for the whole accordion
          hasLinks = true;
          tr.firstElementChild.setAttribute('colspan', `${this.visibleDevices.length}`);
          tr.firstElementChild.innerHTML = `<a class="link--icon" href="${obj.link.url}">${Icon.rightChevron}${obj.link.name}</a>`;
        }

        // Append row only if any link is available
        if (hasLinks) {
          table.appendChild(tr);
        }
      }
      // Only check if devices are visible
      if (this.visibleDevices && this.visibleDevices.length > 0) {
        // Check if accordion has only aria hidden attributes, so do not show the accordion
        const rows = table.querySelectorAll('tr[data-identical="true"]');
        if (rows.length === Object.keys(obj.columns).length) {
          accordion.setAttribute('data-identical', 'true');
        }
      }
      
      formatText(table);
    }
  }

  /**
   * Function to hide or show the identical information in the tables
   * @param root The compare table root element
   * @param hideIdenticalInfo Whether identical information should be shown or hidden
   */
  toggleIdenticalInfo(root: HTMLElement, hideIdenticalInfo: boolean): void {
    const elements = root.querySelectorAll('[data-identical="true"]');
    elements.forEach((element) => {
      element.setAttribute('aria-hidden', hideIdenticalInfo.toString());
    });
  }

  /**
   * Function to update the tables when a new dropdown item is selected
   * @param event The item select event
   * @param root The compare table root element
   * @param json The json file with translations
   * @param checkboxState The current state of the checkbox that toggles the identical information
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onItemSelect(event: CustomEvent, root: HTMLElement, json: any, checkboxState: boolean): void {
    // Get the selected item and the belonging device
    const selectedDropdownItem = event.detail.target;
    const selectedDevice = this.devices.filter((element) => element.name === selectedDropdownItem.innerText)[0];
    // Access the root element device element from [event.detail.target]
    // [dropdown__item] -> dropdown -> form-control -> compare-table__device
    const rootDevice = selectedDropdownItem.parentElement.parentElement.parentElement;
    // Change image and alt tag
    const image = rootDevice.querySelector('img');
    image.src = getUrlForImage(selectedDevice.image?.renditions?.small?.link);
    image.alt = selectedDevice.name;
    // Create an array with selected devices and re-render the table rows depending on the checkbox state
    this.visibleDevices = [this.devices[0]]; // First item is always fix
    // Devices that can vary
    const variableDevices = root.querySelectorAll('.button--dropdown');
    variableDevices.forEach((variableDevice) => {
      // Get the current selected item of the dropdown
      const device = this.devices.filter((element: { name: string }) => element.name === variableDevice.innerHTML)[0];
      this.visibleDevices.push(device);
    });
    // Re-render all rows depending on the selected items
    this.renderTableRows(root, json);
    this.toggleIdenticalInfo(root, checkboxState);
    // Set slider options on mobile and tablet
    if (!this.breakpoint.isDesktop && !this.breakpoint.isLarge) {
      this.setColumnVisibility(root, rootDevice.parentElement.getAttribute('data-slide-column'));
    }
  }

  /**
   * Removes the old generated table if available
   * @param root The compare table root element
   */
  removeOldTable(root: HTMLElement): void {
    let oldChild = root.firstElementChild;
    while (oldChild) {
      root.removeChild(oldChild);
      oldChild = root.firstElementChild;
    }
  }

  /**
   * Renders the table when data are loaded
   * @param root The compare table root element
   * @param json The labels as json
   */
  renderTableWithData(
    root: HTMLElement,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    json: any
  ): void {
    // Remove all children before creating new ones
    this.removeOldTable(root);

    // Create a new table for the devices (header with images and dropdowns)
    const newTable = document.createElement('table');
    root.appendChild(newTable);

    // Create row with device and legend
    const row = document.createElement('tr');
    const rowTh = `<th>${this.createLegend(json.legend) + this.createCheckbox(json.hide)}</th>`;
    const rowCells = this.createDevices(json.label);
    row.innerHTML = rowTh + rowCells;
    newTable.appendChild(row);

    // Get the checkbox to toggle identical information
    const checkbox = root.querySelector('#check_hide_identical_info') as HTMLInputElement;
    checkbox.addEventListener('change', () => {
      this.toggleIdenticalInfo(root, checkbox.checked);
    });

    // Get all dropdowns and add listener on item select
    const dropdowns = root.querySelectorAll('.dropdown');
    // Change the content of the table depending on the selected dropdown option
    dropdowns.forEach((dropdown) => {
      dropdown.addEventListener('onitemselect', (event: CustomEvent) =>
        this.onItemSelect(event, root, json, checkbox.checked)
      );
    });

    // Render all table rows initially
    this.renderTableRows(root, json);

    // Create the indicators and position the legend depending on the screen size
    this.updateColumnVisibility(root);
  }

  /**
   * Renders the table in the loading state
   * @param root The compare table root element
   * @param json The labels as json
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderTableWithLoadingState(root: HTMLElement, json: any): void {
    // Remove all children before creating new ones
    this.removeOldTable(root);

    // Create a new table for the devices (header with images and dropdowns)
    const newTable = document.createElement('table');
    root.appendChild(newTable);

    // Create row with device and legend
    const row = document.createElement('tr');
    const rowTh = `<th>${this.createLegend(json.legend) + this.createCheckbox(json.hide)}</th>`;
    const rowCells = this.createDevices(json.label);
    row.innerHTML = rowTh + rowCells;
    newTable.appendChild(row);

    // Render all table rows initially
    this.renderTableRows(root, json);

    // Create the indicators and position the legend depending on the screen size
    this.updateColumnVisibility(root);
  }

  /**
   * Function to fetch the devices and create the comparable table with all options and depending on screen size
   * @param root The compare table root element
   */
  createTable(root: HTMLElement): void {
    // Get data json for translations
    const dataJson = root.getAttribute('data-json');
    const json = JSON.parse(dataJson);

    // Render loading state if no devices are set
    if (this.devices === null) {
      const mainDeviceId = root.dataset.mainDevice;
      // Get devices from REST API
      this.fetchDevices(mainDeviceId).then(() => {
        this.renderTableWithData(root, json);
      });

      this.renderTableWithLoadingState(root, json);
    } else {
      this.renderTableWithData(root, json);
    }
  }

  /**
   * Initialize the given table element.
   * Sets the listeners for starting the animation on mouseover and mouseout.
   * @param table The table root element that should be initialized
   */
  initialize(table: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, table);

    this.updateBreakpoint();
    this.createTable(table);

    // timeoutId for debounce mechanism
    let timeoutId = null;
    // Set window resize listener
    const resizeListener = (): void => {
      // Prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // Change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        const newBreakPoint = currentBreakpoint();
        if (!equalBreakpoints(newBreakPoint, this.breakpoint)) {
          this.breakpoint = newBreakPoint;
          this.updateBreakpoint();
          this.createTable(table);
        }
      }, 500);
    };
    // Set resize listener
    window.addEventListener('resize', resizeListener);
  }

  /**
   * Sets the current breakpoint and if the slider is enabled
   */
  updateBreakpoint(): void {
    this.breakpoint = currentBreakpoint();
    this.sliderEnabled =
      this.breakpoint.isMobilePortrait || this.breakpoint.isMobileLandscape || this.breakpoint.isTablet;
    this.columnCount = this.breakpoint.isMobilePortrait
      ? 1
      : this.breakpoint.isMobileLandscape || this.breakpoint.isTablet
      ? 2
      : 3;
  }

  /**
   * Insert the indicators to the root element
   * @param root The compare table root element
   * @param indicators The indicators to insert
   */
  insertIndicators(root: HTMLElement, indicators: HTMLElement): void {
    const accordionContainer = root.querySelector('.accordion-container');
    root.insertBefore(indicators, accordionContainer);
  }

  /**
   * Move legend and checkbox on mobile views
   * @param root The compare table root element
   */
  updateMobileChanges(root: HTMLElement): void {
    // Move legend and checkbox
    const table = root.querySelector('table');
    const th = table.querySelector('table th');
    // Move list to the bottom
    const list = th.querySelector('.list');
    th.removeChild(list);
    root.appendChild(list);
    // Move checkbox
    const checkbox = th.querySelector('.checkbox');
    const checkboxLabel = th.querySelector('.checkbox + label');
    th.removeChild(checkbox);
    th.removeChild(checkboxLabel);
    if (this.breakpoint.isMobilePortrait) {
      th.parentElement.removeChild(th);
    }
    root.insertBefore(checkboxLabel, table);
    root.insertBefore(checkbox, checkboxLabel);
  }

  /**
   * Updates the indicator count and sets the first table column visible
   * @param root The compare table root element
   */
  updateSlideOptions(root: HTMLElement): void {
    // Set the indicator count
    this.indicatorCount = this.breakpoint.isMobilePortrait ? 3 : 2;

    // Set first column visible
    this.setColumnVisibility(root, '1');
  }
}

const compareTableModule = new CompareTableModule();
export { compareTableModule as compareTable };
