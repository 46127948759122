/**
 * The InViewportObserver creates an intersection observer that constantly checks all elements that enter the visible viewport.
 * Whenever an element appears, that has the [data-animate-when-visible] attribute set, it will receive a 'is-visible' class.
 * Based on this class, animations can be triggered that need to be started when elements enter the viewport (get visible to the user).
 */
class InViewportObserver {
  intersectionObserver: IntersectionObserver;

  inViewport = (entries): void => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('is-visible');
      }
    });
  };

  constructor() {
    // start animation when 10 percent of the element's height is visible.
    const obsOptions = { threshold: [0.1, 0] };
    this.intersectionObserver = new IntersectionObserver(this.inViewport, obsOptions);
  }

  observeElement(element: HTMLElement): void {
    this.intersectionObserver.observe(element);
  }
}

// As soon as the document is loaded, create a new InViewportObserver
const viewportObserver = new InViewportObserver();
export { viewportObserver as inViewportObserver };
