import { ComponentObservable, componentObserver } from '../../../component-observer';
import { Icon } from '../../../general/icons/Icon';
import { currentBreakpoint } from '../../../utils/resize.utils';
import {
  HamiltonResourceDocument,
  HamiltonResourceProduct,
  ResourceApiType,
  ResourceType
} from '../../../api/search-api';
import {
  createPatientGroupIconsFromString,
  createUseIconsFromId,
  getResourceTileIconForType,
  getResourceTilePlaceholderIconForType
} from '../../../utils/html.utils';
import { getUrlForImage } from '../../../utils/image.utils';
import { v4 as generateUUID } from 'uuid';
import { convertFileSize } from '../../../utils/helper.utils';
import { packagingUnitOrDefault } from '../../../api/products-api';

/**
 * Initializes the resource tile and sets listeners
 */
class ResourceTileModule implements ComponentObservable {
  /**
   * Selector of all buttons that must be initialized in javascript.
   */
  componentSelector = '.tile--resource';

  /**
   * Current breakpoint
   */
  breakpoint = null;

  /**
   * Class that identifies the buttons on resource tiles that link to detail pages.
   */
  resourceTileDetailButtonClass = 'resources-detail-button';

  /**
   * Pin and share button that are only displayed in the resource center tiles.
   */
  getPinAndShareButton(translations: { [key: string]: string }, withoutPinButton = false, link = ''): string {
    const pinButton = `
    <a class="button button--icon-only button--small tile__pin-button">
      <span class="button__icon-single">${Icon.pin}</span>
    </a>
    `;

    return `
    ${withoutPinButton ? '' : pinButton}

    <div class="social-share-wrapper">
      <div class="social-share-button">
        <button class="button button--icon-only button--small"><span class="button__icon-single">${
          Icon.share
        }</span></button>
        <div class="social-share social-share--hidden">
          <div class="social-share__text">${translations.shareOn}</div>
          <div class="social-share__icons">
            <a class="social-share__mail social-share__icon">${Icon.mail}</a>
            <button class="social-share__copy social-share__icon" data-label-copied="${translations.copiedInfo}" data-detail-link="${link}">${
      Icon.copy
    }</button>
          </div>
        </div>
      </div>
    </div>`;
  }

  /**
   * Initialize all resource tiles on the current page.
   * @param observe Boolean if the ECatalogTileModule should listen for changes in the DOM and initialize dynamically added
   *   buttons
   */
  initializeAll(observe: boolean): void {
    const allElements = document.querySelectorAll(this.componentSelector);
    for (let i = 0; i < allElements.length; i++) {
      this.initialize(allElements[i] as HTMLElement);
    }

    if (observe) {
      this.startListening();
    }
  }

  /**
   * Listen for changes in DOM and initialize resource tiles when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Shows and hides the content results for online manuals or hides the button if there is only one result.
   * @param root The resource tile root element
   * @param manualContent The manual content element
   */
  initializeManualContent(root: HTMLElement, manualContent: HTMLElement): void {
    // Get all results
    const results = root.querySelectorAll('.manual-content__result:not(:first-of-type)');
    results?.forEach((result: HTMLElement) => {
      result.dataset.scrollHeight = result.scrollHeight + 'px';
    });
    // Set current and total results
    const currentResult = root.querySelector<HTMLElement>('.current-results');
    currentResult.innerText = '1';
    const totalResult = root.querySelector<HTMLElement>('.total-results');
    totalResult.innerText = (results.length + 1).toString(); // total results are all hidden + 1 visible
    // Set title click listener
    const showAll = root.querySelector<HTMLElement>('.manual-content button');
    if (results.length > 0) {
      showAll.addEventListener('click', () => {
        // Show hidden results
        const isExpanded = showAll.getAttribute('aria-expanded') === 'true';
        showAll.setAttribute('aria-expanded', (!isExpanded).toString());
        results?.forEach((result: HTMLElement) => {
          result.style.height = isExpanded ? '0' : result.dataset.scrollHeight;
          result.style.marginTop = isExpanded ? '0' : '12px';
        });
        // Update button text
        showAll.innerHTML = !isExpanded
          ? manualContent.dataset.labelShowLess + Icon.showLess
          : manualContent.dataset.labelShowAll + Icon.showAll;
        // Update result count
        currentResult.innerText = isExpanded ? '1' : (results.length + 1).toString();
      });
    } else {
      showAll.remove();
    }
  }

  /**
   * Moves the container of the manual content depending on the current breakpoint.
   * @param root The resource tile root element
   */
  moveManualContent(root: HTMLElement): void {
    const isSearchTile = root.classList.contains('tile--search');
    if (isSearchTile) {
      const manualContent = root.querySelector<HTMLElement>('.manual-content');
      const tileInfo = root.querySelector<HTMLElement>('.tile__info');
      const tileContent = root.querySelector<HTMLElement>('.tile__content');

      // Adjust position and styling for mobile
      if (this.breakpoint.isMobilePortrait || this.breakpoint.isMobileLandscape) {
        root.insertBefore(manualContent, tileInfo);
        root.style.paddingBottom = '0';
        tileInfo.style.borderBottom = '0';
      } else {
        // Reset position and styling for other breakpoints
        tileContent.appendChild(manualContent);
        root.removeAttribute('style');
        tileInfo.removeAttribute('style');
      }
    }
  }

  /**
   * Renders the tile for a product
   * @param data The product data
   * @param translations Set of translations for the resources tile.
   * @param resourceTile If the tile should be a resource center tile with a pin and share button.
   */
  renderTileProduct(
    data: HamiltonResourceProduct,
    translations: { [key: string]: string },
    resourceTile = false
  ): string {
    const id = generateUUID();
    const link = '';
    let info = '';

    if (data.partNumber) {
      info += `<span id="${id}_pn">${translations.pn}</span><span aria-labelledby="${id}_pn">${data.partNumber}</span>`;
    }
    // Date / Last changed
    if (data.dateOfChange || data.createdAt || data.lastModified) {
      const tag =
        data.newOrUpdated === 'new'
          ? `<span class="tag tag--small tag--green">${translations.new}</span>`
          : data.newOrUpdated === 'updated'
          ? `<span class="tag tag--small">${translations.updated}</span>`
          : '';
      info += `<span id="${id}_date">${translations.lastUpdated}</span><span aria-labelledby="${id}_date">${
        (data.dateOfChange || data.lastModified || data.createdAt).split(', 12')[0]
      }${tag}</span>`;
    }
    if (data.length) {
      info += `<span id="${id}_length">${translations.length}</span><span aria-labelledby="${id}_length">${data.length}</span>`;
    }
    if (data.forVentilator) {
      info += `<span id="${id}_compatible">${translations.forVentilator}</span><span aria-labelledby="${id}_compatible">${data.forVentilator}</span>`;
    }
    if (data.material) {
      info += `<span id="${id}_material">${translations.material}</span><span aria-labelledby="${id}_material">${data.material}</span>`;
    }
    if (data.price) {
      info += `<span id="${id}_price">${translations.price}</span><span aria-labelledby="${id}_price">${data.price}</span>`;
    }
    if (data.gtin) {
      info += `<span id="${id}_gtin">${translations.gtin}</span><span aria-labelledby="${id}_gtin">${data.gtin}</span>`;
    }
    if (data.patientGroups) {
      info += `<span id="${id}_groups">${translations.patientGroups}</span><span aria-labelledby="${id}_groups">${
        createPatientGroupIconsFromString(data.patientGroups, translations).outerHTML
      }</span>`;
    }
    if (data.typeOfUse) {
      info += `<span id="${id}_use">${translations.typeOfUse}</span><span aria-labelledby="${id}_use">${
        createUseIconsFromId(data.typeOfUse, translations).outerHTML
      }</span>`;
    }
    info += `<span id="${id}_packaging">${
      translations.packagingUnit
    }</span><span aria-labelledby="${id}_packaging"><span class="packing-unit">${packagingUnitOrDefault(
      data
    )}</span></span>`;
    return `<div class="tile ${resourceTile ? 'tile--resource' : 'tile--search'}" data-resource-id="${data.nodeid}">
  <div class="tile__image${data.imageSmall ? '' : ' tile__image--icon-only'}">
     ${data.imageSmall ? `<img src="${getUrlForImage(data.imageSmall)}" alt="${data.productName}">` : Icon.productLarge}
  </div>
    <div class="tile__content">
      <div class="tile__subtitle-tag">
        ${Icon.product}
        <h2 class="tile__subtitle">${data.tag}</h2>  
      </div>
      <a class="tile__headline">${data.productName || ''}</a>
      <p class="text-small">${data.description || ''}</p>
      
      <!-- same button section as below. This one is displayed on desktop devices -->
      <div class="button-section button-section--no-margin button-section--row-spacing">
      ${resourceTile ? this.getPinAndShareButton(translations) : ''}
        <a ${link ? `href="${link}"` : 'type="button"'} class="button button--small${
      link ? '' : ` ${this.resourceTileDetailButtonClass}`
    }">
          <span class="button__text">${translations.viewButton}</span>
          <span class="button__icon-right">${Icon.rightArrow}</span>
        </a>
      </div>
    </div>
    <div class="tile__info">  
      <div class="tile-table">
        ${info}
      </div>
    </div>
    <!-- same button section as above. This one is displayed on mobile devices -->
    <div class="button-section button-section--no-margin button-section--row-spacing">
      ${resourceTile ? this.getPinAndShareButton(translations) : ''}
        <a ${link ? `href="${link}"` : 'type="button"'} class="button button--small${
      link ? '' : ` ${this.resourceTileDetailButtonClass}`
    }">
          <span class="button__text">${translations.viewButton}</span>
          <span class="button__icon-right">${Icon.rightArrow}</span>
        </a>
      </div>
  </div>`;
  }

  /**
   * Renders the tile for article, news, document, publication, ops-manual, knowledge-base
   * @param data The news data
   * @param translations Set of translations for the resources tile.
   * @param resourceTile If the tile should be a resource center tile with a pin and share button.
   */
  renderTileDocument(
    data: HamiltonResourceDocument,
    translations: { [key: string]: string },
    resourceTile = false
  ): string {
    const isNewsTile = data.type === ResourceApiType.News;
    const isWebsiteTile = data.type === ResourceApiType.Page;
    const isQuote = data.type === ResourceApiType.Quote;
    const id = generateUUID();
    let link =
      data.type === ResourceApiType.Page || data.type === ResourceApiType.OpsManual
        ? data.url
        : data.type === ResourceApiType.Video || data.type === ResourceApiType.Audio
        ? data.videoURL
        : data.type === ResourceApiType.ELearning
        ? data.link
        : '';
    const img =
      data.imageSmall && data.type !== ResourceApiType.Page
        ? `<img src="${getUrlForImage(data.imageSmall)}" alt="${data.title}"/>`
        : getResourceTilePlaceholderIconForType(data.tagValue);

    // Buttons
    let buttons = '';
    if (
      data.type === ResourceApiType.News ||
      data.type === ResourceApiType.Page ||
      data.type === ResourceApiType.Video ||
      data.type === ResourceApiType.Audio ||
      data.type === ResourceApiType.KnowledgeBase ||
      data.type === ResourceApiType.Quote ||
      data.type === ResourceApiType.ELearning
    ) {
      if (data.type === ResourceApiType.ELearning) {
        if (!link.startsWith('http')) {
          link = '//' + link;
        }
      }
      buttons = ` <a ${link ? `href="${link}"` : 'type="button"'} class="button button--small${
        link ? '' : ` ${this.resourceTileDetailButtonClass}`
      }">
        <span class="button__text">${translations.viewButton}</span>
        <span class="button__icon-right">${Icon.rightArrow}</span>
      </a>`;
    } else if (data.type === ResourceApiType.Document || data.type === ResourceApiType.Publication) {
      if (typeof data.hasPublicPdf === 'string') {
        data.hasPublicPdf = data.hasPublicPdf === 'true';
      }
      if (data.hasPublicPdf && data.downloadLink && data.downloadLink.length > 10 && !data.downloadLink.endsWith('/')) {
        buttons = `<a href="${data.downloadLink}" class="button button--small">
          <span class="button__text">${translations.downloadButton}</span>
          <span class="button__icon-right">${Icon.download}</span>
        </a>`;
      }
      if (data.type === 'document') {
        buttons = `<a href="${data.downloadLink}" class="button button--small">
          <span class="button__text">${translations.downloadButton}</span>
          <span class="button__icon-right">${Icon.download}</span>
        </a>`;
      }
      buttons += `<a ${link ? `href="${link}"` : 'type="button"'} class="button button--small${
        link ? '' : ` ${this.resourceTileDetailButtonClass}`
      }">
          <span class="button__text">${translations.detailButton}</span>
          <span class="button__icon-right">${Icon.detail}</span>
        </a>`;
    }
    // Content that is shown on the right side
    let tableContent = '';
    // Public information
    if (data.accessType === 'public') {
      tableContent += `<span id="${id}_source">${translations.source}</span><span aria-labelledby="${id}_source">${Icon.global} ${translations.public}</span>`;
    }
    // PN
    const firstPnPart = data.hmIdentifier || data.referenceNumber;
    if (firstPnPart && data.revisionNumber) {
      const pnAndRevision = `${firstPnPart}.${data.revisionNumber}`;
      tableContent += `<span id="${id}_pn">${translations.pn}</span><span aria-labelledby="${id}_pn">${pnAndRevision}</span>`;
    } else if (firstPnPart) {
      tableContent += `<span id="${id}_pn">${translations.pn}</span><span aria-labelledby="${id}_pn">${firstPnPart}</span>`;
    }

    // Date / Last changed
    if (data.dateOfChange || data.createdAt || data.lastModified) {
      const tag =
        data.newOrUpdated === 'new'
          ? `<span class="tag tag--small tag--green">${translations.new}</span>`
          : data.newOrUpdated === 'updated'
          ? `<span class="tag tag--small">${translations.updated}</span>`
          : '';
      tableContent += `<span id="${id}_date">${
        data.tagValue === ResourceType.News ? translations.date : translations.lastUpdated
      }</span><span aria-labelledby="${id}_date">${
        (data.tagValue === ResourceType.News ? (data.lastModified || data.createdAt).split(', 12')[0] : (data.newUpdateChangeDate || data.lastModified || data.dateOfChange || data.createdAt).split(', 12')[0])
      }${tag}</span>`;
    }
    // Language
    if (data.languages && !isQuote) {
      // add white spaces to comma separated values.
      tableContent += `<span id="${id}_language">${
        translations.languages
      }</span><span aria-labelledby="${id}_language">${data.languages.split(',').filter((value, index, array) => array.indexOf(value) === index).join(', ')}</span>`;
    }
    // File type
    if (data.mimeType) {
      tableContent += `<span id="${id}_file-type">${translations.fileType}</span><span aria-labelledby="${id}_file-type">${data.mimeType}</span>`;
    }
    // File size
    if (data.fileSize && Number(data.fileSize) > 0) {
      tableContent += `<span id="${id}_file-size">${translations.fileSize}</span>
        <span aria-labelledby="${id}_file-size">${convertFileSize(data.fileSize)}</span>`;
    }
    // Author
    if (data.author) {
      const label = isQuote ? translations.quoteName : translations.author;
      const author = data.author.replace(/, /gi, ', <br />'); // Writes multiple authors in multiple lines
      tableContent += `<span id="${id}_author">${label}</span><span aria-labelledby="${id}_author">${author}</span>`;
    }

    // Person Function (Job title of a quote)
    if (data.personFunction) {
      tableContent += `<span id="${id}_person-function">${translations.quotePersonFunction}</span><span aria-labelledby="${id}_person-function">${data.personFunction}</span>`;
    }

    // Location (location / work place of a quote)
    if (data.personLocation) {
      tableContent += `<span id="${id}_location">${translations.personLocation}</span><span aria-labelledby="${id}_location">${data.personLocation}</span>`;
    }

    const resources = [ResourceType.Document, ResourceType.Presentation, ResourceType.OnlineManual];
    const typeIcon = isQuote ? Icon.resourcesTileQuote : getResourceTileIconForType(data.tagValue);

    return `<div class="tile ${resourceTile ? 'tile--resource' : 'tile--search'} is-visible${
      isWebsiteTile ? ' no-print' : ''
    }" data-resource-id="${data.nodeid}">
    <div class="tile__image ${
      data.imageSmall && data.tagValue !== ResourceType.Page
        ? resources.indexOf(data.tagValue) !== -1
          ? ' tile__image--resource'
          : ''
        : ' tile__image--icon-only'
    }">
       ${img}
    </div>
    <div class="tile__content">
      <div class="tile__subtitle-tag">
        ${typeIcon}
        <h2 class="tile__subtitle">${data.tag}</h2>
      </div>
      <a class="tile__headline">${data.title || data.lead || ''}</a>
      <p class="text-small">${data.text || ''}</p>
      
      <!-- same button section as below. This one is displayed on desktop devices -->
      <div class="button-section button-section--no-margin button-section--row-spacing">
        ${resourceTile ? this.getPinAndShareButton(translations, isNewsTile, link) : ''}
        ${buttons}
      </div>
    </div>
    <div class="tile__info">
      <div class="tile-table">
        ${tableContent}
      </div>
    </div>
    <!-- same button section as above. This one is displayed on mobile devices -->
    <div class="button-section button-section--no-margin button-section--row-spacing">
        ${resourceTile ? this.getPinAndShareButton(translations, isNewsTile, link) : ''}
        ${buttons}
    </div>
  </div>`;
  }

  /**
   * Initialize the given resource tile.
   * @param root The tile root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);
    const manualContent = root.querySelector<HTMLElement>('.manual-content');

    // Only handle tiles with manual content (online manuals)
    if (manualContent) {
      this.initializeManualContent(root, manualContent);

      // Setup current breakpoint view
      this.breakpoint = currentBreakpoint();
      this.moveManualContent(root);

      // timeoutId for debounce mechanism
      let timeoutId = null;
      // Set window resize listener
      const resizeListener = (): void => {
        // Prevent execution of previous setTimeout
        clearTimeout(timeoutId);
        // Change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => {
          const newBreakpoint = currentBreakpoint();
          if (newBreakpoint !== this.breakpoint) {
            this.breakpoint = newBreakpoint;
            this.moveManualContent(root);
          }
        }, 500);
      };

      // Set resize listener
      window.addEventListener('resize', resizeListener);
    }
  }
}

const resourceTileModule = new ResourceTileModule();
export { resourceTileModule as resourceTile };
