import { ComponentObservable, componentObserver } from '../../component-observer';
import { currentBreakpoint } from '../../utils/resize.utils';
import { createHtmlElementFromString, formatText } from '../../utils/html.utils';
import { Icon } from '../../general/icons/Icon';
import anime from 'animejs/lib/anime.es.js';
import { getAllEvents } from '../../api/event-table-api';
import { getUrlForFileDownload } from '../../utils/helper.utils';

/**
 * Object that holds the data for a single event.
 */
export interface HamiltonEvent {
  name: string;
  type: string;
  date: string;
  startDate: string;
  country: string;
  eventCountry: string;
  city: string;
  attendance: string;
  status: string;
  link: HamiltonEventLink;
  linkLabel: string;
}

/**
 * Object that holds the data for a link to an event.
 */
export interface HamiltonEventLink {
  type: 'external' | 'internal' | 'download' | 'phone' | 'none' | 'anchor';
  target: 'true' | 'false'; // if true -> open link in new window
  external_link?: string;
  internal_link?: string;
  anchor?: string;
  download_link?: HamiltonEventDownloadLink;
  number?: string;
}

/**
 * Object that holds the data for download link of an event.
 */
export interface HamiltonEventDownloadLink {
  '@link': string;
}

/**
 * Initializes and renders the event table.
 */
class EventTableModule implements ComponentObservable {
  /**
   * Selector of all event tables that must be initialized in javascript.
   */
  componentSelector = '.event-table';

  /**
   * Class for table rows that are initially hidden and only
   * shown on click of the 'show more' button.
   */
  hiddenRowClass = 'event-table__row--hidden';

  /**
   * Count of the skeleton elements to render
   */
  skeletonRows = 2;

  /**
   * Number of events to show (before the users can expand them with the show all button).
   */
  maxEventNumber = 5;

  /**
   * Store the event data. Needed when the table must be recreated for mobile/desktop breakpoints.
   * This is the complete, unfiltered list of all events.
   */
  eventData: HamiltonEvent[] = [];

  /**
   * These are the filtered events that are displayed to the user.
   */
  filteredEvents: HamiltonEvent[] = [];

  /**
   * Current filter selection by the user.
   */
  currentFilter = { location: null, type: null, showAll: false };

  /**
   * If the event type dropdown should show the available options or hide them. If this is set to true,
   * the user will not be able to change the event type.
   */
  hideEventTypeOptions = false;

  /**
   * Markup for a dropdown option for the filter dropdowns. Used to dynamically create the dropdown options.
   */
  filterDropdownItemMarkup = '<li class="dropdown__item" tabindex="0"></li>';

  /**
   * Markup for the desktop table header.
   */
  desktopHeadlineMarkup = `
  <div class="event-table__row event-table__row--headline">
    <h5 class="event-table__row-heading"></h5>
    <h5 class="event-table__row-heading"></h5>
    <h5 class="event-table__row-heading"></h5>
    <h5 class="event-table__row-heading"></h5>
    <h5 class="event-table__row-heading"></h5>
  </div>`;

  /**
   * Markup for a row in the desktop table.
   */
  desktopTableRowMarkup = `
  <div class="event-table-animation-wrapper">
    <a class="event-table__row">
    <div class="event-table__event-type-container">
      <span class="event-table__title"></span>
      <span class="event-table__type"></span>
    </div>
    <span class="event-table__date"></span>
    <span class="event-table__location"></span>
    <span class="event-table__country"></span>
    <span class="event-table__status"></span>
    <div class="event-table__booking-wrapper">
      <button class="button">
        <span class="button__text"></span>
      </button>
    </div>
  </a>
  </div>`;

  /**
   * Markup for an event accordion on mobile.
   */
  mobileAccordionMarkup = `
<div class="event-table-animation-wrapper">
 <div class="accordion accordion--with-subtitle accordion--closed" data-group="events-table-accordions">
  <button class="accordion__header">
    <h4 class="accordion__title"></h4>
    <span class="accordion__subtitle text-small"></span>
  </button>
  <div class="accordion__content accordion__content--spacing">
     <div class="event-table__mobile-table">
        <span class="event-table__mobile-row-title"></span>
        <span class="event-table__mobile-row-value"></span>
        <span class="event-table__mobile-row-title"></span>
        <span class="event-table__mobile-row-value"></span>
        <span class="event-table__mobile-row-title"></span>
        <span class="event-table__mobile-row-value"></span>
        <span class="event-table__mobile-row-title"></span>
        <span class="event-table__mobile-row-value"></span>
        <span class="event-table__mobile-row-title"></span>
        <span class="event-table__mobile-row-value"></span>
      </div>
      <div class="event-table__mobile-button-wrapper">
        <a href="#" class="button">
          <span class="button__text"></span>
        </a>
      </div>
    </div>
  </div>
</div>
  `;

  /**
   * Create the events table. Fetches the events data and renders the table.
   * @param eventsTableElement: The root element of the events table.
   */
  createEventsTable(eventsTableElement: HTMLElement): void {
    // Render loading state while waiting for data
    this.renderEventsTableWithLoadingState(eventsTableElement);

    // check if any country should be included/excluded
    const includedCountries = eventsTableElement.dataset.countriesShow;
    const excludedCountries = eventsTableElement.dataset.countriesHide;
    const includedCategories = eventsTableElement.dataset.eventCategories;

    const preselectedEventType = eventsTableElement.dataset.preselectedEventType;
    this.hideEventTypeOptions = eventsTableElement.dataset.hideEventTypeOptions === 'true';
    if (preselectedEventType) {
      this.currentFilter.type = preselectedEventType;
    }

    // now fetch the data
    this.fetchEventsData(includedCountries, excludedCountries, includedCategories).then((events) => {
      // store the events for later use
      this.eventData = events;
      this.filterAndSortEvents(eventsTableElement);
      // Render table
      this.renderEventsTableWithData(this.filteredEvents, eventsTableElement);

      formatText(eventsTableElement);
    });
  }

  /**
   * Retrieve the events data.
   * @param includedCountries: A list of country codes for which events should be fetched. E.g. this is a whitelist of countries.
   * @param excludedCountries: Blacklists countries. E.g. this is a list of country codes and the fetch should not return events in those countries.
   */
  fetchEventsData(
    includedCountries: string,
    excludedCountries: string,
    includedCategories: string
  ): Promise<HamiltonEvent[]> {
    return getAllEvents(includedCountries, excludedCountries, includedCategories);
  }

  /**
   * Render the events table. Depending on the current breakpoint, this method renders the mobile or desktop
   * version of the table.
   * @param events: List of events to render.
   * @param eventsTableElement: The events table root element.
   */
  renderEventsTableWithData(events: HamiltonEvent[], eventsTableElement: HTMLElement): void {
    // This is the container for all the dynamic table data
    const tableContentElement = eventsTableElement.querySelector('.event-table__content') as HTMLElement;
    const breakpoint = currentBreakpoint();

    // clear the content before adding new elements
    tableContentElement.innerHTML = '';

    // Render events or empty state
    if (events && events.length > 0) {
      tableContentElement.classList.remove('event-table__content--empty');
      if (breakpoint.isDesktop || breakpoint.isLarge) {
        // render the table data for desktop
        this.renderDesktopTable(events, eventsTableElement, tableContentElement);
      } else {
        this.renderMobileTable(events, eventsTableElement, tableContentElement);
      }
    } else {
      this.renderEmptyState(eventsTableElement, tableContentElement);
    }

    // create the filter options
    this.renderFilterOptions(this.eventData, eventsTableElement);
  }

  /**
   * Render the events table loading state. Depending on the current breakpoint, this method renders the mobile or desktop
   * version of the table.
   * @param eventsTableElement: The events table root element.
   */
  renderEventsTableWithLoadingState(eventsTableElement: HTMLElement): void {
    // This is the container for all the dynamic table data
    const tableContentElement = eventsTableElement.querySelector('.event-table__content') as HTMLElement;
    const breakpoint = currentBreakpoint();

    // clear the content before adding new elements
    tableContentElement.innerHTML = '';

    // Render loading state for desktop or mobile
    tableContentElement.classList.remove('event-table__content--empty');
    if (breakpoint.isDesktop || breakpoint.isLarge) {
      // render the table data for desktop
      this.renderDesktopTable(null, eventsTableElement, tableContentElement);
    } else {
      this.renderMobileTable(null, eventsTableElement, tableContentElement);
    }
  }

  /**
   * Update the 'show more' button. This sets the label and icon depending on
   * the current expanded state.
   * @param filteredEvents: List of events that are currently displayed to the user.
   * @param eventsTableElement: The events table root element.
   */
  updateShowAllButtonState(filteredEvents: HamiltonEvent[], eventsTableElement: HTMLElement): void {
    const showMoreButton = eventsTableElement.querySelector<HTMLElement>('.divider');

    if (filteredEvents.length > this.maxEventNumber) {
      showMoreButton.removeAttribute('style');

      if (this.currentFilter.showAll) {
        // show less label and minus icon
        showMoreButton.querySelector<HTMLElement>('.button__text').innerText =
          eventsTableElement.dataset.showLessButtonLabel;
        showMoreButton.querySelector('svg').innerHTML = Icon.dash;
      } else {
        // show more label and plus icon
        showMoreButton.querySelector<HTMLElement>('.button__text').innerText =
          eventsTableElement.dataset.showAllButtonLabel;
        showMoreButton.querySelector('svg').innerHTML = Icon.add;
      }
    } else {
      // if there are less than this.maxEventNumber events, we don't need a show more button
      showMoreButton.style.display = 'none';
    }
  }

  /**
   * Render the filter options. This method iterates over all events and checks which options should be included
   * in the filter. It then adds these options to the filter dropdowns.
   * @param events: List of events for which the filter options get created.
   * @param eventsTableElement: The events table root element.
   */
  renderFilterOptions(events: HamiltonEvent[], eventsTableElement: HTMLElement): void {
    // These are the filter dropdown lists, that need to be filled with the possible options
    const eventTypesDropdownButton = eventsTableElement.querySelector<HTMLElement>('#event-type-dropdown');
    const eventTypesLabel = <HTMLElement>eventTypesDropdownButton.previousElementSibling;
    const eventTypesDropdownList = <HTMLElement>eventTypesDropdownButton.nextElementSibling;
    const locationsDropdownList = eventsTableElement.querySelector<HTMLElement>('#event-country-dropdown + ul');

    // first clear the dropdown options
    eventTypesDropdownList.innerHTML = '';
    locationsDropdownList.innerHTML = '';

    // preselect an event type if specified
    if (this.currentFilter.type) {
      eventTypesLabel.innerText = this.currentFilter.type;
    }

    // lets collect which event types and locations exist in the events
    const eventTypes = new Set<string>();
    const locations = new Set<string>();

    for (const event of events) {
      eventTypes.add(event.type);
      locations.add(event.eventCountry || '');
    }

    for (const eventType of eventTypes) {
      // Render options for the event types
      if (!this.hideEventTypeOptions || eventType === this.currentFilter.type) {
        // only show the event type options if the user should see them
        const dropdownOption = createHtmlElementFromString(this.filterDropdownItemMarkup);
        dropdownOption.innerText = eventType;
        eventTypesDropdownList.appendChild(dropdownOption);
      }
    }

    // Render options for the locations
    for (const location of locations) {
      if (location && location !== '') {
        const dropdownOption = createHtmlElementFromString(this.filterDropdownItemMarkup);
        dropdownOption.innerText = location;
        locationsDropdownList.appendChild(dropdownOption);
      }
    }

    // add the 'all' options for locations and types
    const allLocationsDropdownOption = createHtmlElementFromString(this.filterDropdownItemMarkup);
    allLocationsDropdownOption.innerText = eventsTableElement.dataset.filterAllCountries;
    allLocationsDropdownOption.setAttribute('data-all-locations', 'true');
    locationsDropdownList.appendChild(allLocationsDropdownOption);

    const allTypesDropdownOption = createHtmlElementFromString(this.filterDropdownItemMarkup);
    allTypesDropdownOption.innerText = eventsTableElement.dataset.filterAllTypes;
    allTypesDropdownOption.setAttribute('data-all-types', 'true');

    if (!this.hideEventTypeOptions) {
      // only show the all event type options if the user should be able to select options
      eventTypesDropdownList.appendChild(allTypesDropdownOption);
    }
  }

  /**
   * Renders the mobile table. This is only a list of accordions.
   */
  renderMobileTable(events: HamiltonEvent[], eventsTableElement: HTMLElement, tableContentElement: HTMLElement): void {
    // create the accordions
    if (events) {
      this.renderAccordions(events, tableContentElement, eventsTableElement);
    } else {
      let content = '';
      for (let i = 0; i < this.skeletonRows; i++) {
        content += `<span class="event-table--skeleton${i !== 0 ? '-delay' : ''}"></span>`;
      }
      tableContentElement.innerHTML = content;
    }
  }

  /**
   * Renders the desktop table. This is an html table with a header and multiple event rows.
   */
  renderDesktopTable(events: HamiltonEvent[], eventsTableElement: HTMLElement, tableContentElement: HTMLElement): void {
    // create the header row
    this.renderTableHeaders(eventsTableElement, tableContentElement);

    // create the rows from the events data
    this.renderTableRows(events, tableContentElement, eventsTableElement);
  }

  /**
   * Renders the empty state if no event is available for the selected location.
   */
  renderEmptyState(eventsTableElement: HTMLElement, tableContentElement: HTMLElement): void {
    // Create empty view
    tableContentElement.classList.add('event-table__content--empty');
    tableContentElement.innerHTML = `
    <h4>${eventsTableElement.dataset.emptyHeadlineHighlight} <span class="text-color-light">${eventsTableElement.dataset.emptyHeadline}</span></h4>
    <p>${eventsTableElement.dataset.emptyText}</p>
    <div class="button-section button-section--small-margin">
      <button class="button">
        <span class="button-text">${eventsTableElement.dataset.showAllEventsButtonLabel}</span>
      </button>
    </div>
    `;

    // Get the dropdown buttons
    const eventTypesButton = eventsTableElement.querySelector<HTMLElement>('#event-type-dropdown');
    const locationsButton = eventsTableElement.querySelector<HTMLElement>('#event-location-dropdown');

    // Get the 'show all events' button and add click listener
    const button = tableContentElement.querySelector('button');
    button.addEventListener('click', () => {
      // Set filter to all events on button click
      this.currentFilter = { location: null, type: null, showAll: false };

      // Reset the dropdown label and set the selected value
      eventTypesButton.innerText = eventsTableElement.dataset.filterAllTypes;
      eventTypesButton.previousElementSibling.innerHTML = '';

      // Reset the dropdown label and set the selected value
      locationsButton.innerText = eventsTableElement.dataset.filterAllLocations;
      locationsButton.previousElementSibling.innerHTML = '';

      this.filterAndSortEvents(eventsTableElement);
    });
  }

  /**
   * Creates a string from the event country and city.
   */
  locationStringForEvent(event: HamiltonEvent): string {
    return event.city || '';
  }

  /**
   * Render the table headers for the desktop table. Reads the translations from the events table root element and puts them
   * into the table headers.
   * @param eventsTableElement: The root element of the events table.
   * @param tableContentElement: The container element of the table. This is where new rows must be inserted.
   */
  renderTableHeaders(eventsTableElement: HTMLElement, tableContentElement: HTMLElement): void {
    const rowElement = createHtmlElementFromString(this.desktopHeadlineMarkup);

    // Read the translations from the data-attributes and set the table headers
    rowElement.querySelectorAll<HTMLElement>(
      '.event-table__row-heading'
    )[0].innerText = `${eventsTableElement.dataset.eventLabel} / ${eventsTableElement.dataset.eventTypeLabel}`;
    rowElement.querySelectorAll<HTMLElement>('.event-table__row-heading')[1].innerText =
      eventsTableElement.dataset.eventDateLabel;
    rowElement.querySelectorAll<HTMLElement>('.event-table__row-heading')[2].innerText =
      eventsTableElement.dataset.eventLocationLabel;
    rowElement.querySelectorAll<HTMLElement>('.event-table__row-heading')[3].innerText =
      eventsTableElement.dataset.eventCountryLabel;
    rowElement.querySelectorAll<HTMLElement>('.event-table__row-heading')[4].innerText =
      eventsTableElement.dataset.eventStatusLabel;

    // finally append the new header row to the table (DOM)
    tableContentElement.appendChild(rowElement);
  }

  /**
   * Renders the rows for the desktop table.
   * @param events: The events to render in the table.
   * @param tableContentElement: The container element of the table. This is where new rows must be inserted.
   * @param eventsTableElement: The root element of the events table.
   */
  renderTableRows(
    events: HamiltonEvent[] | null,
    tableContentElement: HTMLElement,
    eventsTableElement: HTMLElement
  ): void {
    const eventsLength = events?.length || this.skeletonRows;

    for (let i = 0; i < eventsLength; i++) {
      // Add skeleton on loading (events is null)
      const skeleton = `<span class="event-table--skeleton${i !== 0 ? '-delay' : ''}"></span>`;
      const event = events ? events[i] : null;
      const rowElement = createHtmlElementFromString(this.desktopTableRowMarkup);

      if (i >= this.maxEventNumber) {
        rowElement.classList.add(this.hiddenRowClass);
      }

      // Populate the row with the event data
      const eventNameElem = rowElement.querySelector<HTMLElement>('.event-table__title');
      const eventTypeElem = rowElement.querySelector<HTMLElement>('.event-table__type');
      const eventDateElem = rowElement.querySelector<HTMLElement>('.event-table__date');
      const eventLocationElem = rowElement.querySelector<HTMLElement>('.event-table__location');
      const eventCountryElem = rowElement.querySelector<HTMLElement>('.event-table__country');
      const eventStatusElem = rowElement.querySelector<HTMLElement>('.event-table__status');

      if (event) {
        eventNameElem.innerText = event.name;
        eventTypeElem.innerText = event.type;
        eventDateElem.innerText = event.date;
        eventLocationElem.innerText = this.locationStringForEvent(event);
        eventCountryElem.innerText = event.eventCountry || '';
        eventStatusElem.innerText = event.status;
      } else {
        eventNameElem.innerHTML = skeleton;
        eventTypeElem.innerHTML = '';
        eventDateElem.innerHTML = skeleton;
        eventLocationElem.innerHTML = skeleton;
        eventCountryElem.innerHTML = skeleton;
        eventStatusElem.innerHTML = skeleton;
      }

      // Finally the label for the booking button
      const bookingButton = rowElement.querySelector('.event-table__booking-wrapper') as HTMLElement;
      if (event?.link && event.link.type !== 'none') {
        let eventLink = '';
        switch (event.link.type) {
          case 'external':
            eventLink = event.link.external_link;
            break;
          case 'internal':
            eventLink = event.link.internal_link;
            break;
          case 'phone':
            eventLink = `tel:${event.link.number}`;
            break;
          case 'download':
            eventLink = getUrlForFileDownload(event.link.download_link['@link']);
            break;
          case 'anchor':
            eventLink = event.link.anchor;
        }

        const eventRowAnchor = rowElement.querySelector('.event-table__row');
        bookingButton.querySelector<HTMLElement>('.button__text').innerText =
          event.linkLabel || eventsTableElement.dataset.bookingButtonLabel;
        eventRowAnchor.setAttribute('href', eventLink);

        if (event.link.target === 'true' || event.link.target === true) {
          eventRowAnchor.setAttribute('target', '_blank');
        }

        if (event.link.type === 'download') {
          eventRowAnchor.setAttribute('download', '');
        }
      } else {
        bookingButton.style.display = 'none';
      }

      // append each row to the table (DOM)
      tableContentElement.appendChild(rowElement);
    }
  }

  /**
   * Render the accordions for the mobile events table.
   * @param events: The events to render in the table.
   * @param tableContentElement: The container element of the table. This is where new rows must be inserted.
   * @param eventsTableElement: The root element of the events table.
   */
  renderAccordions(events: HamiltonEvent[], tableContentElement: HTMLElement, eventsTableElement: HTMLElement): void {
    const eventsLength = events?.length || 0;

    for (let i = 0; i < eventsLength; i++) {
      const event = events[i];
      const accordion = createHtmlElementFromString(this.mobileAccordionMarkup);

      if (i >= this.maxEventNumber) {
        accordion.classList.add(this.hiddenRowClass);
      }

      // Read the translations from the data-attributes and populate the headings column
      accordion.querySelectorAll<HTMLElement>('.accordion__title')[0].innerText = event.name;
      accordion.querySelectorAll<HTMLElement>('.accordion__subtitle')[0].innerText = `${event.date}, ${event.city}`;
      accordion.querySelectorAll<HTMLElement>('.event-table__mobile-row-title')[0].innerText =
        eventsTableElement.dataset.eventDateLabel;
      accordion.querySelectorAll<HTMLElement>('.event-table__mobile-row-title')[2].innerText =
        eventsTableElement.dataset.eventLocationLabel;
      accordion.querySelectorAll<HTMLElement>('.event-table__mobile-row-title')[2].innerText =
        eventsTableElement.dataset.eventTypeLabel;
      accordion.querySelectorAll<HTMLElement>('.event-table__mobile-row-title')[3].innerText =
        eventsTableElement.dataset.eventCountryLabel;
      accordion.querySelectorAll<HTMLElement>('.event-table__mobile-row-title')[4].innerText =
        eventsTableElement.dataset.eventStatusLabel;

      // Populate the value column with the event data
      accordion.querySelectorAll<HTMLElement>('.event-table__mobile-row-value')[0].innerText = event.date;
      accordion.querySelectorAll<HTMLElement>('.event-table__mobile-row-value')[1].innerText =
        this.locationStringForEvent(event);
      accordion.querySelectorAll<HTMLElement>('.event-table__mobile-row-value')[2].innerText = event.type;
      accordion.querySelectorAll<HTMLElement>('.event-table__mobile-row-value')[3].innerText = event.eventCountry || '';
      accordion.querySelectorAll<HTMLElement>('.event-table__mobile-row-value')[4].innerText = event.status;

      // Finally the label for the booking button
      const bookingButton = accordion.querySelector('.event-table__mobile-button-wrapper') as HTMLElement;
      if (event?.link && event.link.type !== 'none') {
        let eventLink = '';
        switch (event.link.type) {
          case 'external':
            eventLink = event.link.external_link;
            break;
          case 'internal':
            eventLink = event.link.internal_link;
            break;
          case 'phone':
            eventLink = `tel:${event.link.number}`;
            break;
          case 'download':
            eventLink = getUrlForFileDownload(event.link.download_link['@link']);
            break;
          case 'anchor':
            eventLink = event.link.anchor;
        }

        const anchorElement = bookingButton.querySelector<HTMLElement>('a');
        bookingButton.querySelector<HTMLElement>('.button__text').innerText =
          event.linkLabel || eventsTableElement.dataset.bookingButtonLabel;
        anchorElement.setAttribute('href', eventLink);

        if (event.link.target === 'true') {
          anchorElement.setAttribute('target', '_blank');
        }

        if (event.link.type === 'download') {
          anchorElement.setAttribute('download', '');
        }
      } else {
        bookingButton.style.display = 'none';
      }

      // append each row to the table (DOM)
      tableContentElement.appendChild(accordion);
    }
  }

  /**
   * Check if the table must be rendered again. This is the case when the breakpoint changes from desktop to tablet/mobile. In this case
   * the table needs to be recreated once.
   */
  needToRerender(table: HTMLElement): boolean {
    const breakpoint = currentBreakpoint();

    if (breakpoint.isDesktop || breakpoint.isLarge) {
      // if on desktop or larger breakpoints and the desktop table is null, we need to render it
      return table.querySelector('.event-table__row') === null;
    }
    // if on mobile/tablet breakpoints and the desktop table exists, we need to render the mobile version instead
    return table.querySelector('.event-table__row') !== null;
  }

  /**
   * Show or hide the rows (events) that are initially hidden.
   */
  showAllEvents(table: HTMLElement, show: boolean): void {
    // These are the rows that are initially hidden and should be faded in/out on click on the "show more/less" button
    const rowsToShow = table.querySelectorAll<HTMLElement>(`.${this.hiddenRowClass}`);

    // animate the height of each row
    for (const row of rowsToShow) {
      const targetHeight = show ? row.scrollHeight : '0';

      if (!show) {
        // If collapsing, we need to first set a fixed height (instead of height auto)
        row.style.height = `${row.clientHeight}px`;
      }

      anime({
        targets: row,
        height: targetHeight,
        duration: 300,
        easing: 'easeInOutQuad',
        complete: () => {
          setTimeout(() => {
            // After fading in, we need to reset the fixed height to 'auto' in case
            // the height changes depending on the content of the row (on resize for example)
            row.style.height = show ? 'auto' : '0';
          }, 400);
        }
      });
    }
  }

  /**
   * Filter and sort the events depending on the users selection (filter by location/type). The initial full set
   * of events is stored in this.eventData and the filtered events are stored in this.filteredEvents.
   * This function also filters the events by start date (chronologically).
   */
  filterAndSortEvents(table: HTMLElement): void {
    // filter by the current filter criteria (location & type)
    this.filteredEvents = this.eventData.filter((event) => {
      let locationMatches = true;
      const typeMatches = this.currentFilter.type ? event.type === this.currentFilter.type : true;

      if (this.currentFilter.location) {
        locationMatches = event.eventCountry === this.currentFilter.location;
      }

      return typeMatches && locationMatches;
    });

    // sort events by date
    this.filteredEvents = this.filteredEvents.sort((a: HamiltonEvent, b: HamiltonEvent) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });

    // rerender the events table
    this.renderEventsTableWithData(this.filteredEvents, table);

    // update the show all button
    this.updateShowAllButtonState(this.filteredEvents, table);
  }

  /**
   * Initialize all event tables on the current page.
   * @param observe Boolean if the EventTableModule should listen for changes in the DOM and initialize dynamically added event tables
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allTableComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allTableComponents.length; i++) {
        this.initialize(allTableComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize event tables when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given event table.
   * @param table The table root element that should be initialized
   */
  initialize(table: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, table);

    // render the events table
    this.createEventsTable(table);

    // update the table when switching between mobile/desktop
    this.listenForWindowResize(table);

    // listen for changes in the filters by the user
    this.setupFilterButtons(table);

    // Setup the 'show more' button
    this.setupShowMoreButton(table);
  }

  /**
   * Setup a click listener for the 'show all' button that fades in/out the remaining events.
   */
  setupShowMoreButton(table: HTMLElement): void {
    const showAllButton = table.querySelector<HTMLElement>('.divider');
    showAllButton.addEventListener(
      'click',
      () => {
        // User clicked on 'show more', now toggle the expanded state
        // fade in/out the remaining events and update the label of the button
        this.currentFilter.showAll = !this.currentFilter.showAll;

        // start the animation
        this.showAllEvents(table, this.currentFilter.showAll);

        // update the show all button (from 'show all' <-> 'show less')
        this.updateShowAllButtonState(this.filteredEvents, table);
      },
      false
    );
  }

  /**
   * Listen for changes in the filters (type & location). If the user selects a new filter,
   * the events get filtered and the table rendered again.
   */
  setupFilterButtons(table: HTMLElement): void {
    // These are the filter dropdowns
    const eventTypesDropdownList = table.querySelector<HTMLElement>('#event-type-dropdown + ul');
    const locationsDropdownList = table.querySelector<HTMLElement>('#event-country-dropdown + ul');

    // Listen for changes in the Event Type dropdown
    eventTypesDropdownList.addEventListener('onitemselect', (event: CustomEvent) => {
      // check if the option is the 'all types' option. If so, set the filter to null, otherwise use the selected type
      this.currentFilter.type = event.detail.target.getAttribute('data-all-types')
        ? null
        : event.detail.target.innerText;
      this.currentFilter.showAll = false;
      this.filterAndSortEvents(table);
    });

    // Changes in the Location dropdown
    locationsDropdownList.addEventListener('onitemselect', (event: CustomEvent) => {
      // check if the option is the 'all locations' option. If so, set the filter to null, otherwise use the selected location
      this.currentFilter.location = event.detail.target.getAttribute('data-all-locations')
        ? null
        : event.detail.target.innerText;
      this.currentFilter.showAll = false;
      this.filterAndSortEvents(table);
    });
  }

  /**
   * Setup resize listeners that update the table html on resize (if switched from mobile <-> desktop). This
   * is necessary because the mobile events table has a different markup than the desktop variant.
   */
  listenForWindowResize(table: HTMLElement): void {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    // Set window resize listener
    const resizeListener = (): void => {
      // Prevent execution of previous setTimeout
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        if (this.needToRerender(table)) {
          this.currentFilter.showAll = false;
          this.updateShowAllButtonState(this.filteredEvents, table);
          this.renderEventsTableWithData(this.filteredEvents, table);
        }
      }, 150);
    };

    // Set resize listener
    window.addEventListener('resize', resizeListener);
  }
}

const eventTableModule = new EventTableModule();
export { eventTableModule as eventTable };
