import { baseUrl, ResourceDetailPageType } from './general-api';
import { HamiltonResourceBaseType, HamiltonResourceResults, ResourceType } from './search-api';
import {
  ageFilterCategoryId,
  ageFilterNewId,
  ageFilterNewIdForApi,
  ageFilterUpdatedId,
  ageFilterUpdatedIdForApi,
  typeFilterDocumentId,
  typeFilterDocumentIdForApi,
  typeFilterElearningId,
  typeFilterElearningIdForApi,
  typeFilterFieldName,
  typeFilterKbId,
  typeFilterKbIdForApi,
  typeFilterPublicationId,
  typeFilterPublicationIdForApi,
  typeFilterQuoteId,
  typeFilterQuoteIdForApi,
  typeFilterScientificPublicationIdForApi,
  typeFilterScientificPublicationId,
  typeFilterAudioId,
  typeFilterAudioIdForApi,
  typeFilterVideoId,
  typeFilterVideoIdForApi
} from './products-api';
import { getLocaleFromUrl } from './../utils/url.utils';

/**
 * Base URL for the resources API.
 */
export const baseResourcesPath = baseUrl + '/.rest/delivery/v1/resources/list';

/**
 * Returns all resources that match the given filters.
 * @param filters: List of taxonomy IDs (filter tags) that should be used to filter the resource results.
 * @param searchPhrase: Free text search from the user.
 * @param category
 * @param tab
 * @param paths Used to only show specific resources from the resources center. Specified using a data-attribute.
 * @param ventilatorId Used to display only resources for the ventilator with the given ID.
 * @param languages List of two digit language codes, that filter the resources by their language.
 * @param limit Number of resources to load.
 * @param offset How many items should be skipped.
 * @param onlyNews If only news should be returned.
 */
export const getAllResources = (
  filters: string[],
  searchPhrase: string = null,
  category: string | string[],
  tab: string | string[],
  paths: string,
  ventilatorId: string,
  languages: string[],
  limit: number,
  offset = 0,
  onlyNews = false
): Promise<HamiltonResourceResults> => {
  const emptyResult = {
    results: [],
    taxonomyCounts: {},
    tagCounts: {},
    totalResults: 0
  };
  // Remove the age filter IDs from the filters, because they are not actually taxonomies
  const taxonomies = [
    ...filters.filter((item) => {
      return (
        item !== ageFilterNewId &&
        item !== ageFilterUpdatedId &&
        item !== typeFilterDocumentId &&
        item !== typeFilterElearningId &&
        item !== typeFilterKbId &&
        item !== typeFilterPublicationId &&
        item !== typeFilterQuoteId &&
        item !== typeFilterVideoId &&
        item !== typeFilterScientificPublicationId &&
        item !== typeFilterAudioId
      );
    })
  ];
  const includesAgeFilterNew = filters.includes(ageFilterNewId);
  const includesAgeFilterUpdated = filters.includes(ageFilterUpdatedId);
  const includesTypeFilterDocument = filters.includes(typeFilterDocumentId);
  const includesTypeFilterElearning = filters.includes(typeFilterElearningId);
  const includesTypeFilterKb = filters.includes(typeFilterKbId);
  const includesTypeFilterPublication = filters.includes(typeFilterPublicationId);
  const includesTypeFilterVideo = filters.includes(typeFilterVideoId);
  const includesTypeFilterQuote = filters.includes(typeFilterQuoteId);
  const includesTypeFilterScientificPublication = filters.includes(typeFilterScientificPublicationId);
  const includesTypeFilterAudio = filters.includes(typeFilterAudioId);

  if (category) {
    if (Array.isArray(category)) {
      for (const categoryId of category) {
        taxonomies.push(categoryId);
      }
    } else {
      taxonomies.push(category);
    }
  }
  if (tab) {
    // tab is also a taxonomy
    if (Array.isArray(tab)) {
      for (const tabId of tab) {
        taxonomies.push(tabId);
      }
    } else {
      taxonomies.push(tab);
    }
  }
  if (ventilatorId) {
    // ventilator is also a taxonomy
    taxonomies.push(ventilatorId);
  }

  let url = baseResourcesPath;
  const params: Record<string, string> = {};
  if (taxonomies?.length > 0) {
    params.taxonomies = taxonomies.join(',');
  }

  params.limit = limit.toString();
  params.offset = offset.toString();
  params.lang = getLocaleFromUrl(new URL(window.location.href)).replace('_', '-')

  if (onlyNews) {
    // will only return news (which are by default not returned)
    params.types = ResourceType.News;
  }

  if (searchPhrase) {
    params.q = searchPhrase;
  }

  if (paths) {
    params.paths = paths;
  }

  if (languages && languages.length > 0) {
    // multiple languages need to be comma separated
    params.languages = languages.join(',');
  }

  // Age filter (newOrUpdated)
  if (includesAgeFilterNew) {
    params[ageFilterCategoryId] = ageFilterNewIdForApi;
  }

  if (includesAgeFilterUpdated) {
    params[ageFilterCategoryId] = ageFilterUpdatedIdForApi;
  }

  if (includesTypeFilterDocument) {
    params[typeFilterFieldName] = typeFilterDocumentIdForApi;
  }

  if (includesTypeFilterPublication) {
    params[typeFilterFieldName] = typeFilterPublicationIdForApi;
  }

  if (includesTypeFilterElearning) {
    params[typeFilterFieldName] = typeFilterElearningIdForApi;
  }

  if (includesTypeFilterKb) {
    params[typeFilterFieldName] = typeFilterKbIdForApi;
  }

  if (includesTypeFilterQuote) {
    params[typeFilterFieldName] = typeFilterQuoteIdForApi;
  }

  if (includesTypeFilterVideo) {
    params[typeFilterFieldName] = typeFilterVideoIdForApi;
  }

  if (includesTypeFilterScientificPublication) {
    params[typeFilterFieldName] = typeFilterScientificPublicationIdForApi;
  }

  if (includesTypeFilterAudio) {
    params[typeFilterFieldName] = typeFilterAudioIdForApi;
  }

  if (Object.keys(params).length > 0) {
    const parameterString = new URLSearchParams(params).toString();
    url = `${url}?${parameterString}`;
  }

  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        return data;
      }

      return emptyResult;
    })
    .catch(() => {
      return emptyResult;
    });
};

/**
 * The detail page for the resource center is provided by the API. Depending on the
 * type of the resource, a different API endpoint must be used to retrieve the page.
 * Currently there are two different endpoints/detail pages: articles and publications.
 * This function maps the type of resource to the endpoint, that must be called.
 */
export const getResourceDetailPageTypeFromResourceType = (
  resourceType: HamiltonResourceBaseType
): ResourceDetailPageType => {
  let detailPageType: ResourceDetailPageType;

  switch (resourceType) {
    case 'knowledge-base':
    case 'news':
    case 'quote':
      detailPageType = ResourceDetailPageType.Article;
      break;

    case 'publication':
    case 'document':
      detailPageType = ResourceDetailPageType.Resource;
      break;
  }
  return detailPageType;
};
