import { ComponentObservable, componentObserver } from '../../../component-observer';
import { currentBreakpoint } from '../../../utils/resize.utils';
import { createHtmlElementFromString } from '../../../utils/html.utils';
import { breakpointListener } from '../../../utils/breakpoint-listener.module';

/**
 * Initializes the section selector.
 */
class SectionSelectorModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.section-selector-wrapper';

  /**
   * Initialize all section selectors on the current page.
   * @param observe Boolean if the SectionSelectorModule should listen for changes in the DOM and initialize dynamically
   *   added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allComponents.length; i++) {
        this.initialize(allComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Creates an accordion component with the given title and a table element inside
   * @param title The title for the accordion
   */
  createAccordion(title: string): { accordion: HTMLElement; content: HTMLElement } {
    // Create accordion
    const accordion = document.createElement('div');
    accordion.classList.add('accordion', 'accordion--closed');
    const accordionHeader = document.createElement('button');
    accordionHeader.classList.add('accordion__header');
    accordionHeader.innerText = title;
    accordion.appendChild(accordionHeader);
    const content = document.createElement('div');
    content.classList.add('accordion__content', 'accordion__content--spacing');
    accordion.appendChild(content);
    return { accordion, content };
  }

  /**
   * Creates the section selector for desktop view
   * @param root The section selector wrapper root element
   */
  createSectionSelector(root: HTMLElement): void {
    const ul = document.createElement('ul');
    ul.classList.add('section-selector');
    root.insertBefore(ul, root.firstElementChild);

    // Add section elements
    const labels = root.querySelectorAll<HTMLElement>('[data-section-label]');
    labels.forEach((label) => {
      const id = label.dataset.sectionLabel;
      const selectorItem = createHtmlElementFromString(`<li class="selector-item"><a role="button">${id}</a></li>`);
      selectorItem.addEventListener('click', () => this.setSectionVisible(root, id));
      ul.appendChild(selectorItem);
    });

    // Set first element visible and hide the others
    this.setSectionVisible(root, null);
  }

  /**
   * Sets the first section item visible on desktop view
   * @param root The section selector wrapper root element
   * @param label The label that should be visible
   */
  setSectionVisible(root: HTMLElement, label: string): void {
    const selectorItems = root.querySelectorAll<HTMLElement>('.selector-item');
    const referenceItems = root.querySelectorAll<HTMLElement>('[data-section-label]');

    const id = label || selectorItems.item(0).innerText;

    // Iterate over all items and set the content/item visible or active
    for (let i = 0; i < selectorItems.length; i++) {
      const item = selectorItems[i];
      const refItem = referenceItems[i];
      if (item.innerText === id) {
        item.classList.add('selector-item--active');
      } else {
        item.classList.remove('selector-item--active');
      }
      if (refItem.dataset.sectionLabel === id) {
        // animation to fade-in the content
        refItem.style.display = 'block';
        setTimeout(() => {
          refItem.style.opacity = '1';
        });
      } else {
        referenceItems[i].removeAttribute('style');
      }
    }
  }

  /**
   * Renders the desktop view of the section selector
   * @param root The section selector root element
   */
  renderDesktopView(root: HTMLElement): void {
    // Clear view before creating new elements
    this.clearView(root);

    this.createSectionSelector(root);
  }

  /**
   * Renders the mobile view of the section selector
   * @param root The section selector wrapper root element
   */
  renderMobileView(root: HTMLElement): void {
    // Clear view before creating new elements
    this.clearView(root);

    // Create accordions and move form content to the accordion
    const labels = root.querySelectorAll<HTMLElement>('[data-section-label]');
    labels.forEach((label) => {
      const id = label.dataset.sectionLabel;
      const accordion = this.createAccordion(id);
      accordion.content.appendChild(label);
      root.appendChild(accordion.accordion);
    });
  }

  /**
   * Updates the view depending on screen size
   * @param root The section selector wrapper root element
   */
  updateView(root: HTMLElement): void {
    if (currentBreakpoint().isMobileAny) {
      this.renderMobileView(root);
    } else {
      this.renderDesktopView(root);
    }
  }

  /**
   * Removes all children of the root element
   * @param root The root element to remove the children from
   */
  clearView(root: HTMLElement): void {
    // Remove section selector on desktop view
    root.querySelector('.section-selector')?.remove();
    // Move accordion content back on mobile view
    const referenceItems = root.querySelectorAll<HTMLElement>('[data-section-label]');
    referenceItems.forEach((item) => {
      root.appendChild(item);
    });
    // Remove accordions afterwards
    const accordions = root.querySelectorAll('.accordion');
    accordions.forEach((accordion) => accordion.remove());
  }

  /**
   * Initialize the given section selector element.
   * Sets the listener for resize.
   * @param root The section selector root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);
    this.updateView(root);

    // Listen for breakpoint changes
    breakpointListener.listenForBreakpointChangeBetweenMobileAndDesktop(() => {
      this.updateView(root);
    });
  }
}

const sectionSelectorModule = new SectionSelectorModule();
export { sectionSelectorModule as sectionSelector };
