import { ComponentObservable, componentObserver } from '../../../component-observer';

/**
 * Initializes the selectable tile and set listener
 */
class SelectableTileModule implements ComponentObservable {
  /**
   * Selector of all buttons that must be initialized in javascript.
   */
  componentSelector = '.tile--selectable';

  /**
   * Initialize all selectable tiles on the current page.
   * @param observe Boolean if the ECatalogTileModule should listen for changes in the DOM and initialize dynamically added
   *   buttons
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allElements = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allElements.length; i++) {
        this.initialize(allElements[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize selectable tiles when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given selectable tile.
   * @param root The tile root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);
    const checkbox = root.querySelector<HTMLInputElement>('input[type="checkbox"]');
    const button = root.querySelector<HTMLElement>('.button');
    const labelSelect = root.dataset.labelSelect || button.innerText;
    const labelSelected = root.dataset.labelSelected || button.innerText;

    if (checkbox.checked) {
      root.classList.toggle('tile--selected', checkbox.checked);
      button.classList.toggle('button--primary', checkbox.checked);
      button.innerText = labelSelected;
    } else {
      button.innerText = labelSelect; // Make sure the same label is set on the button as provided as data-label
    }

    // Set checkbox click listener
    checkbox?.addEventListener('change', () => {
      root.classList.toggle('tile--selected', checkbox.checked);
      button.classList.toggle('button--primary', checkbox.checked);
      button.innerText = checkbox.checked ? labelSelected : labelSelect;
    });
  }
}

const selectableTileModule = new SelectableTileModule();
export { selectableTileModule as selectableTile };
