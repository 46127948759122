import { HamiltonEvent } from '../building-blocks/event-table/event-table.module';
import { baseUrl } from './general-api';

/**
 * Returns a list of all events.
 * @param includedCountries: A list of country codes for which events should be fetched. E.g. this is a whitelist of countries.
 * @param excludedCountries: Blacklists countries. E.g. this is a list of country codes and the fetch should not return events
 * in those countries. These lists are provided as data attributes on the event table and there will never be both specified.
 */
export const getAllEvents = (
  includedCountries: string,
  excludedCountries: string,
  includedCategories: string
): Promise<HamiltonEvent[]> => {
  let url = baseUrl + '/.rest/delivery/v1/events';

  const params: Record<string, any> = {};

  params.limit = '10000';

  if (includedCountries) {
    params.country = includedCountries;
  }

  if (includedCategories) {
    params.type = includedCategories;
  }

  if (window.location.hostname.indexOf('medin') > -1) {
    params['@ancestor'] = '/medin-public|/medin-partner-net';
  } else {
    params['@ancestor'] = '/public|/partner-net';
  }

  // only events that have not passed, by next day at 10:00 user local time
  // we use the UTC
  const yesterday = new Date();

  yesterday.setHours(yesterday.getHours() - 10);
  const dateParam = yesterday.toISOString().slice(0, 10);

  params['endDate[gte]'] = dateParam;

  if (Object.keys(params).length > 0) {
    let parameterString = new URLSearchParams(params).toString();
    if (excludedCountries) {
      parameterString += '&country%5Bne%5D=' + excludedCountries.split("|").join('&country%5Bne%5D=');
    }
    url = `${url}?${parameterString}`;
  }

  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      return data.results;
    })
    .catch(() => {
      return [];
    });
};
