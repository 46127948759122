import { ComponentObservable, componentObserver } from '../../component-observer';
import { getTechnologies } from '../../api/technologies-api';
import { getReferenceInfo } from '../../api/references-api';
import { createHtmlElementFromString, removeAllHtmlTagsAndLineBreaks, formatText } from '../../utils/html.utils';
import { ventilators } from '../../ventilators.module';
import { updateFootnoteTexts } from '../../utils/footnotes.module';

/**
 * Initializes the technologies overview elements.
 */
class TechnologiesOverviewModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.section--technologies-overview';

  /**
   * Id for the default all dropdown
   */
  defaultAllId = 'technologies-default-all';

  /**
   * Markup for a technologies overview tile
   */
  tileMarkup = `<a href="javascript:void(0);" class="tile tile--technology-overview" data-animate-when-visible="fade-in-up">
    <div class="tile__image"></div>
    <div class="tile__content">
        <h4 class="tile__headline"><span class="text-color-grey"></span></h4>
        <p class="tile__rich-text rte-style"></p>
        <div class="button-section">
            <button class="button"></button>
        </div>
    </div>
  </a>`;

  /**
   * Initialize all technologies overviews on the current page.
   * @param observe Boolean if the TechnologyOverviewModule should listen for changes in the DOM and
   *    initialize dynamically added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allComponents.length; i++) {
        this.initialize(allComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given filter element.
   * Sets the listener for resize.
   * @param root The technologies overview root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);
    this.createDropdown(root).then(() => {
      this.initializeEmptyStateButton(root);
    });
    this.renderTechnologies(root);
  }

  /**
   * Renders the technologies for all or one device and sets empty state if none is available.
   * @param root The technologies overview root element
   * @param device The id of the device
   */
  renderTechnologies(root: HTMLElement, device?: string): void {
    let container = root.querySelector('.tile__grid');
    // Remove old container with all content first, then create a new one
    if (container) {
      container.remove();
    }
    container = document.createElement('div');
    container.classList.add('tile__grid');
    root.firstElementChild.appendChild(container);

    // Render loading state
    const skeletonTile = '<a class="tile tile--technology-overview tile--skeleton"></a>';
    container.innerHTML = skeletonTile.repeat(3);

    getTechnologies(device).then((data) => {
      // Remove loading state
      const skeletons = container.querySelectorAll('.tile--skeleton');
      const referenceIds = [];
      skeletons.forEach((skeleton) => skeleton.remove());
      // Check if empty state is shown
      const emptyState = root.querySelector('.technologies-empty');
      emptyState.setAttribute('aria-hidden', (data.length > 0).toString());

      // Render technologies if available
      data.forEach((technology) => {
        const item = createHtmlElementFromString(this.tileMarkup);
        // Disable link if none given
        if (technology.detailPage) {
          (item as HTMLAnchorElement).href = technology.detailPage;
        } else {
          (item as HTMLAnchorElement).style.pointerEvents = 'none'; // disable link if none available
        }
        const subTitle = item.querySelector<HTMLElement>('.tile__headline span');
        const title = item.querySelector<HTMLElement>('.tile__headline');
        const titleTextNode = document.createTextNode(technology.title + ' ');
        title.insertBefore(titleTextNode, subTitle);
        subTitle.innerText = technology.subtitle || '';

        item.querySelector<HTMLElement>('p').innerHTML = removeAllHtmlTagsAndLineBreaks(technology.text);
        if (technology.text.indexOf('footnote-reference') > -1) {
          // Has footnotes and references
          this.extractAndAddFootnotesAndReferences(technology.text, referenceIds);
        }
        const button = item.querySelector<HTMLElement>('.button');
        if (technology.detailPageLinkText && technology.detailPage) {
          button.innerText = technology.detailPageLinkText;
        } else {
          button.remove(); // Remove button if no detail page link is available
        }

        item.querySelector<HTMLElement>(
          '.tile__image'
        ).innerHTML = `<span class="icon-hm-${technology.iconClass}"></span>`;
        container.appendChild(item);
        updateFootnoteTexts();
      });

      formatText(root);
    });
  }

  /**
   * Setup the button for the empty state to select all devices again.
   * @param root The technologies overview root element
   */
  initializeEmptyStateButton(root: HTMLElement): void {
    const button = root.querySelector('.technologies-empty button');
    button.addEventListener('click', () => {
      // Set text of the dropdown to 'all devices'
      const dropdown = root.querySelector<HTMLElement>('#technologies-dropdown-products');
      dropdown.innerText = dropdown.parentElement.querySelector<HTMLElement>(`#${this.defaultAllId}`).innerText;
      // Load all devices
      this.renderTechnologies(root);
    });
  }

  /**
   * Creates the dropdown element with the ventilator values
   * @param root The technologies overview root element
   */
  async createDropdown(root: HTMLElement): Promise<void> {
    if (!root.querySelector('#technologies-dropdown-products .dropdown__item')) {
      // Set all devices selected if none is specified or id does not match
      const dropdown = root.querySelector<HTMLElement>('#technologies-dropdown-products');
      const selectedDeviceLabel = dropdown.innerText;

      const ventilatorResults = await ventilators.getCachedVentilators();
      // Get the devices from ventilator endpoint
      const devices = [];
      ventilatorResults.forEach((ventilator) => {
        devices.push({
          name: ventilator.name,
          id: ventilator['@id']
        });
      });

      let items = `<li class="dropdown__item" tabindex="0" id="${this.defaultAllId}">${selectedDeviceLabel}</li>`;
      devices.forEach((device) => {
        items += `<li class="dropdown__item" tabindex="0" id="${device.id}">${device.name}</li>`;
      });

      const dropdownItems = `<ul class="dropdown dropdown--images" data-reference-id="technologies-dropdown-products">
          ${items}
        </ul>`;
      dropdown.parentElement.appendChild(createHtmlElementFromString(dropdownItems));

      // Update technologies when a device is selected
      dropdown.parentElement.lastElementChild.addEventListener('onitemselect', (event: CustomEvent) => {
        const id = event.detail.target.id === this.defaultAllId ? undefined : event.detail.target.id;
        this.renderTechnologies(root, id);
      });
    }
  }

  async extractAndAddFootnotesAndReferences(text: string, referenceIds: string[]): Promise<void> {
    const responses = await getReferenceInfo(text);
    responses.forEach((response) => {
      const existingReference = document.querySelector('#reference-item-' + response.id);
      if (referenceIds.indexOf(response.id) === -1 && !existingReference) {
        referenceIds.push(response.id);
        if (response.type === 'reference') {
          const placeholder = document.createElement('div');
          placeholder.insertAdjacentHTML('afterbegin', response.overlay);

          if (document.querySelector('.footnote-reference-overlays-container') === null) return;
          document.querySelector('.footnote-reference-overlays-container').append(placeholder.firstElementChild);
          const lists = document.querySelectorAll('.references-container');
          const orderedLists = [].concat(...lists);
          orderedLists.sort((l1, l2) => {
            if (l1.querySelectorAll('li').length > l2.querySelectorAll('li').length) return 1;
            if (l1.querySelectorAll('li').length < l2.querySelectorAll('li').length) return -1;
            return 0;
          });
          let added = false;
          for (let i = 0; i < orderedLists.length; i++) {
            const listElements = orderedLists[i].querySelectorAll('li');
            if (added) {
              added = false;
              continue;
            }
            if (listElements.length === 0) {
              const placeholder = document.createElement('div');
              placeholder.insertAdjacentHTML('afterbegin', response.listItem);
              orderedLists[i].appendChild(placeholder.firstElementChild);
              added = true;
              continue;
            }
            added = false;
            for (let j = 0; j < listElements.length && !added; j++) {
              if (added) continue;
              const listElement = listElements[j];
              if (listElement.querySelector('.ref-id')) {
                const refId = listElement.querySelector('.ref-id').textContent;
                try {
                  const currentRef = parseFloat(refId.replace(/[^\d.]/g, ''));
                  const ref = parseFloat(response.refId.replace(/[^\d.]/g, ''));
                  if (currentRef > ref) {
                    const placeholder = document.createElement('div');
                    placeholder.insertAdjacentHTML('afterbegin', response.listItem);
                    listElement.parentElement.insertBefore(placeholder.firstElementChild, listElement);
                    added = true;
                  }
                } catch (ex) {
                  added = true;
                }
              }
            }
            if (!added) {
              const placeholder = document.createElement('div');
              placeholder.insertAdjacentHTML('afterbegin', response.listItem);
              orderedLists[i].appendChild(placeholder.firstElementChild);
              added = true;
              continue;
            }
          }
        } else {
          const lists = document.querySelectorAll('.footnotes-container');
          const orderedLists = [].concat(...lists);
          orderedLists.sort((l1, l2) => {
            if (l1.querySelectorAll('li').length > l2.querySelectorAll('li').length) return -1;
            if (l1.querySelectorAll('li').length < l2.querySelectorAll('li').length) return 1;
            return 0;
          });
          let added = false;
          for (let i = 0; i < orderedLists.length; i++) {
            const listElements = orderedLists[i].querySelectorAll('li');
            if (added) {
              added = false;
              continue;
            }
            if (listElements.length === 0) {
              const placeholder = document.createElement('div');
              placeholder.insertAdjacentHTML('afterbegin', response.listItem);
              orderedLists[i].appendChild(placeholder.firstElementChild);
              added = true;
              continue;
            }
            added = false;
            for (let j = 0; j < listElements.length; j++) {
              if (added) continue;
              const listElement = listElements[j];
              if (listElement.querySelector('.ref-id')) {
                const refId = listElement.querySelector('.ref-id').textContent;
                try {
                  let currentRef = parseFloat(refId.replace(/[^\d.]/g, ''));
                  let ref = parseFloat(response.refId.replace(/[^\d.]/g, ''));
                  if (isNaN(currentRef) || isNaN(ref)) {
                    currentRef = refId;
                    ref = response.refId;
                  }
                  if (ref < currentRef) {
                    const placeholder = document.createElement('div');
                    placeholder.insertAdjacentHTML('afterbegin', response.listItem);
                    listElement.parentElement.insertBefore(placeholder.firstElementChild, listElement);
                    added = true;
                    continue;
                  } else if (j === listElements.length - 1) {
                    const placeholder = document.createElement('div');
                    placeholder.insertAdjacentHTML('afterbegin', response.listItem);
                    listElement.parentElement.insertBefore(placeholder.firstElementChild, listElement.nextSibling);
                  }
                } catch (ex) {
                  continue;
                }
              }
            }
          }
        }
      }
    });
  }
}

const technologiesOverviewModule = new TechnologiesOverviewModule();
export { technologiesOverviewModule as technologiesOverview };
