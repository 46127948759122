import { ComponentObservable, componentObserver } from '../../component-observer';
import { currentBreakpoint } from '../../utils/resize.utils';

export interface SelectorItem {
  label: string;
  link?: string;
  icon?: string;
  locale?: string;
  entries?: SelectorItem[];
}

/**
 * Initializes the country selector.
 */
class CountrySelectorModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.country-selector';

  /**
   * Current breakpoint
   */
  breakpoint = null;

  /**
   * Data labels for all continents to render
   */
  dataLabels = ['global', 'europe', 'north-america', 'latin-america', 'asia-oceania', 'middle-east-africa'];

  /**
   * Country configuration
   */

  config = null;

  /**
   * Initialize all country selectors on the current page.
   * @param observe Boolean if the CountrySelectorModule should listen for changes in the DOM and initialize dynamically
   *   added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allComponents.length; i++) {
        this.initialize(allComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Formats the data-label to json keys that are used in the configuration
   * @param dataLabel The label to transform
   */
  dataLabelToKey(dataLabel: string): string {
    return dataLabel.replace('data-label-', '').replace(/-/g, '_');
  }

  /**
   * Returns the selector items from the configuration that belong to the given label
   * @param label The label to get the items from
   */
  getItemFromConfig(label: string): SelectorItem[] {
    for (const item of this.config) {
      for (const key in item) {
        if (key === label) {
          return item[key];
        }
      }
    }
  }

  /**
   * Handles the click on an anchor element. If it is a direct link, it sets the url,
   * otherwise it creates a further sub menu.
   * @param root The country selector root element
   * @param ul The list element
   * @param li The selector item html element
   * @param selectorItem The item information to render
   */
  onAnchorButtonClick(root: HTMLElement, ul: HTMLElement, li: HTMLElement, selectorItem: SelectorItem): void {
    // Remove old active item and set the clicked item active only on desktop
    if (this.breakpoint.isDesktop) {
      const active = ul.querySelector('.selector-item--active');
      active && active.classList.remove('selector-item--active');
      li.classList.add('selector-item--active');
    }
    // Get the items to render
    const result = selectorItem.entries || this.getItemFromConfig(this.dataLabelToKey(selectorItem.label));
    // Create a new list
    const list = document.createElement('ul');
    list.classList.add('selector');
    // Always remove second level
    const secondLevel = root.querySelector('.selector--second-level');
    secondLevel && secondLevel.parentElement.removeChild(secondLevel);
    // Check if there are already selector lists
    const selector = root.querySelectorAll('.selector');
    // Add modifier for second level selector
    if (selector.length === 1 && ul.classList.contains('selector')) {
      list.classList.add('selector--second-level');
    }
    // Remove all children if a continent is selected (no selector yet)
    if (!ul.classList.contains('selector') && selector.length > 0) {
      selector.forEach((ul) => ul.parentElement.removeChild(ul));
    }
    // Add list and create next level list item
    ul.parentElement.appendChild(list);
    // Set opacity to fade in the list (animation)
    setTimeout(() => {
      list.style.opacity = '1';
    });
    if (!this.breakpoint.isDesktop && !this.breakpoint.isTablet) {
      this.createBackNavigationItem(list, root);
      // Move items from right to the view (animation)
      setTimeout(() => {
        list.style.right = '0';
      });
    }
    result.forEach((listElement) => {
      this.createSelectorItem(root, list, listElement, !!listElement.entries);
    });
  }

  /**
   * Creates a selector item and appends it to the given list.
   * @param root The country selector root element
   * @param ul The list to render the items to
   * @param selectorItem The item information to render
   * @param button Whether the item is a button or anchor element
   */
  createSelectorItem(root: HTMLElement, ul: HTMLElement, selectorItem: SelectorItem, button?: boolean): void {
    if (selectorItem.label) {
      // Gets the selected item to set set the item active
      const selectedItem = root.getAttribute('data-selected-item');
      // Check if the sub entries (result) contain the selected item
      const result = selectorItem.entries || this.getItemFromConfig(this.dataLabelToKey(selectorItem.label));
      const hasSelectedItem =
        result?.filter((item) => {
          return (
            item.locale === selectedItem ||
            item.entries?.filter((subEntry) => subEntry.locale === selectedItem).length > 0
          );
        })?.length > 0;
      // Get the label for the item
      const label = root.getAttribute(`${selectorItem.label}`) || selectorItem.label;
      const li = document.createElement('li');
      li.classList.add('selector-item');
      // Highlight selected item
      if (
        hasSelectedItem ||
        (selectorItem.locale === selectedItem && (this.breakpoint.isDesktop || this.breakpoint.isTablet))
      ) {
        li.classList.add('selector-item--active');
        // Render sub menu if available
        if (result) {
          this.onAnchorButtonClick(root, ul, li, selectorItem);
        }
      }
      li.setAttribute('id', selectorItem.label);
      const anchor = document.createElement('a');
      if (button) {
        anchor.setAttribute('role', 'button');
        anchor.addEventListener('click', () => this.onAnchorButtonClick(root, ul, li, selectorItem));
      } else {
        // No more levels -> set link
        const baseUrl = root.getAttribute('data-base-url');
        anchor.setAttribute('href', baseUrl + selectorItem.link);
      }
      // Append children and set label
      li.appendChild(anchor);
      if (selectorItem.locale === 'zh_CN') {
        anchor.innerHTML = `${selectorItem.icon ? selectorItem.icon + ' ' : ''}<span style="font-family: '宋体';">${label}</span>`;
      } else {
        anchor.innerHTML = `${selectorItem.icon ? selectorItem.icon + ' ' : ''}${label}`;
      }

      ul.appendChild(li);
    }
  }

  /**
   * Creates a back navigation item that is used on mobile only
   * @param ul The list to create the item to
   * @param root The country selector root element
   */
  createBackNavigationItem(ul: HTMLElement, root: HTMLElement): void {
    const backLabel = root.getAttribute('data-label-back');
    const li = document.createElement('li');
    li.classList.add('selector-item', 'selector-item--back');
    li.setAttribute('id', 'back');
    const anchor = document.createElement('a');
    anchor.innerHTML = backLabel;
    anchor.setAttribute('role', 'button');
    anchor.addEventListener('click', () => {
      // Move items from right to the view (animation)
      ul.style.right = '-120%';
      setTimeout(() => {
        ul.parentElement.removeChild(ul);
      }, 600);
    });
    li.appendChild(anchor);
    ul.appendChild(li);
  }

  /**
   * Creates an accordion component with the given title and a table element inside
   * @param title The title for the accordion
   * @param isOpen Whether the accordion is opened or not
   */
  createAccordion(title: string, isOpen: boolean): { accordion: HTMLElement; content: HTMLElement } {
    // Create accordion
    const accordion = document.createElement('div');
    !isOpen && accordion.classList.add('accordion--closed');
    accordion.classList.add('accordion', 'accordion--no-spacing');
    const accordionHeader = document.createElement('button');
    accordionHeader.classList.add('accordion__header');
    accordionHeader.innerText = title;
    accordion.appendChild(accordionHeader);
    const content = document.createElement('div');
    content.classList.add('accordion__content');
    accordion.appendChild(content);
    return { accordion, content };
  }

  /**
   * Renders the desktop view of the country selector
   * @param root The country selector root element
   */
  renderDesktopView(root: HTMLElement): void {
    // Clear view before creating new elements
    this.clearView(root);

    const ul = document.createElement('ul');
    root.appendChild(ul);

    // Create continent selection
    this.dataLabels.forEach((label) => {
      const item = {
        label: `data-label-${label}`,
        entries: this.getItemFromConfig(this.dataLabelToKey(`data-label-${label}`))
      };

      if(item.entries)
        this.createSelectorItem(root, ul, item, true);
    });
  }

  /**
   * Renders the mobile view of the country selector
   * @param root The country selector root element
   */
  renderMobileView(root: HTMLElement): void {
    // Clear view before creating new elements
    this.clearView(root);
    // Gets the selected item to set the accordion opened if available
    const selectedItem = root.getAttribute('data-selected-item');
    // Create continent selection
    this.dataLabels.forEach((label) => {
      const itemLabel = root.getAttribute(`data-label-${label}`);
      // Get the items that belong to the data label
      const result = this.getItemFromConfig(this.dataLabelToKey(`data-label-${label}`));

      if(result){
        // Check if the selected item is in this accordion
        const hasSelectedItem = result.filter((item) => item.locale === selectedItem);
        // Create accordion and append list
        const { accordion, content } = this.createAccordion(itemLabel, hasSelectedItem.length > 0);
        const ul = document.createElement('ul');
        root.appendChild(ul);
        content.appendChild(ul);
        root.appendChild(accordion);
        // Render list items
        result.forEach((listElement) => {
          this.createSelectorItem(root, ul, listElement, !!listElement.entries);
        });
      }     
    });
  }

  /**
   * Removes all children of the root element
   * @param root The root element to remove the children from
   */
  clearView(root: HTMLElement): void {
    let child = root.firstElementChild;
    while (child) {
      root.removeChild(child);
      child = root.firstElementChild;
    }
  }

  /**
   * Initialize the given country selector element.
   * Sets the listener for resize.
   * @param root The country selector root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);

    this.breakpoint = currentBreakpoint();
    // Set custom config
    const customConfig = root.getAttribute('data-json');
    if (customConfig) {
      this.config = JSON.parse(customConfig);
    } else {
      // eslint-disable-next-line no-console
      console.error(
        'No configuration for the country selector provided. Please use the data-json attribute to pass a config.'
      );
    }

    // Render view depending on screen size
    if (this.breakpoint.isTablet || this.breakpoint.isDesktop) {
      this.renderDesktopView(root);
    } else {
      this.renderMobileView(root);
    }

    // timeoutId for debounce mechanism
    let timeoutId = null;
    // Set window resize listener
    const resizeListener = (): void => {
      // Prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // Change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        this.breakpoint = currentBreakpoint();
        // Render view depending on screen size
        if (this.breakpoint.isTablet || this.breakpoint.isDesktop) {
          this.renderDesktopView(root);
        } else {
          this.renderMobileView(root);
        }
      }, 500);
    };
    // Set resize listener
    window.addEventListener('resize', resizeListener);
  }
}

const countrySelectorModule = new CountrySelectorModule();
export { countrySelectorModule as countrySelector };
