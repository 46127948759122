export const updateFootnoteTexts = (): Promise<void> => {
  return new Promise((resolve) => {
    const footnoteItems = document.querySelectorAll('.footnote-item');
    for (const footnote of footnoteItems) {
      for (const element of footnote.childNodes) {
        // TEXT_NODE
        if (element.nodeType === 3) {
          const id = footnote.id.replace('reference-item-', '');
          const onPageFootnote = document.querySelector<HTMLElement>('[data-overlay-id="ref-overlay-' + id + '"]');
          if (onPageFootnote) {
            const footnoteText = onPageFootnote.querySelector<HTMLElement>('.footnote-text');
            if (footnoteText) {
              footnoteText.textContent = element.textContent;
            }
          }
        }
      }
    }
    resolve();
  });
};
