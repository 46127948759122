import sanitizeHtml from 'sanitize-html';
import { Icon } from '../general/icons/Icon';
import { ResourceType } from '../api/search-api';

/**
 * Removes all HTML tags and line breaks from the given string so that it can be displayed in the wish list or catalog tiles.
 */
export const removeAllHtmlTagsAndLineBreaks = (htmlString: string): string => {
  const sanitizedString = sanitizeHtml(htmlString, {
    allowedTags: ['span', 'footnote-reference', 'ul', 'li', 'p'],
    allowedAttributes: {
      'footnote-reference': ['data-*', 'style'],
      span: ['style', 'class', 'data-*'],
      a: ['data-*']
    }
  });
  const stringWithoutLineBreaks = sanitizedString.replace(/(\r\n|\n|\r)/gm, '');
  return stringWithoutLineBreaks;
};

/**
 * Turns a string into an HTML element.
 * @param templateString: The HTML string.
 */
export const createHtmlElementFromString = (templateString: string): HTMLElement => {
  const htmlTemplate = document.createElement('div');
  htmlTemplate.innerHTML = templateString.trim();
  return htmlTemplate.firstChild as HTMLElement;
};

/**
 * Turns a string into an HTML div element and returns the div element.
 * It removes empty tags if it is not a <br>.
 * @param templateString The HTML string.
 */
export const createDivElementFromString = (templateString: string): HTMLElement => {
  const div = document.createElement('div');
  div.innerHTML = templateString.trim();
  div.querySelectorAll(':empty:not(br)').forEach((element) => element.remove());
  return div;
};

/**
 * Taxonomies (IDs) that represent the different patient groups like neonatal or adult. These
 * are visualized to the user with three humans (icons) representing each patient group.
 */
enum PatientGroupTaxonomy {
  NEONATAL = '6bdef229-7c88-412a-801d-24eb353e19a0',
  PEDIATRIC = 'ba10ac26-db76-41a3-8dc6-82e6991c2cf3',
  ADULT = 'bffe1b24-c42a-4bd6-b6ab-f9562b5b7319'
}

/**
 * IDs that represent the different patient groups used in the patientGroups string
 * provided by the wish list and easy reference cards API.
 */
enum PatientGroupID {
  NEONATAL = 'neo',
  PEDIATRIC = 'ped',
  ADULT = 'adult'
}

/**
 * HTML used to display the patient groups.
 */
const patientGroupHtml = `<div class="patient-group">
      <div class="patient patient--s"></div>
      <div class="patient patient--m"></div>
      <div class="patient patient--l"></div>
  </div>`;

/**
 * Create the patient group icons (three humans that represent the patient groups) from the given string.
 * @param patientGroupsIconsString: The string returned from the API that contains the patient group IDs.
 * This method checks if they include the patient groups and if so, highlight the corresponding patient group.
 * @param translations: The translations for translating the patient group labels.
 */
export const createPatientGroupIconsFromString = (
  patientGroupsIconsString: string,
  translations: { [key: string]: string }
): HTMLElement => {
  return createPatientGroupIcons(
    patientGroupsIconsString.includes(PatientGroupID.NEONATAL),
    patientGroupsIconsString.includes(PatientGroupID.PEDIATRIC),
    patientGroupsIconsString.includes(PatientGroupID.ADULT),
    translations
  );
};

/**
 * Create the patient group icons (three humans that represent the patient groups) from the given taxonomies.
 * @param taxonomies: The list of taxonomies returned from the API. This method checks if they include the patient groups and if so, highlight the corresponding patient group.
 * @param translations: The translations for translating the patient group labels.
 */
export const createPatientGroupIconsFromTaxonomies = (
  taxonomies: string[],
  translations: { [key: string]: string }
): HTMLElement => {
  return createPatientGroupIcons(
    taxonomies.includes(PatientGroupTaxonomy.NEONATAL),
    taxonomies.includes(PatientGroupTaxonomy.PEDIATRIC),
    taxonomies.includes(PatientGroupTaxonomy.ADULT),
    translations
  );
};

/**
 * Create the patient group icons. The arguments can be used to highlight or disable the respective patient group.
 */
export const createPatientGroupIcons = (
  neonatal: boolean,
  pediatric: boolean,
  adult: boolean,
  translations: { [key: string]: string }
): HTMLElement => {
  const patientGroupHTML = createHtmlElementFromString(patientGroupHtml);
  const activeTranslation = translations.active;
  const inactiveTranslation = translations.inactive;

  const neonatalIcon = patientGroupHTML.querySelector<HTMLElement>('.patient--s');
  neonatalIcon.classList.toggle('patient--active', neonatal);
  neonatalIcon.title = translations.neonatal;
  neonatalIcon.ariaLabel = `${translations.neonatal}: ${neonatal ? activeTranslation : inactiveTranslation}`;

  const pediatricIcon = patientGroupHTML.querySelector<HTMLElement>('.patient--m');
  pediatricIcon.classList.toggle('patient--active', pediatric);
  pediatricIcon.title = translations.pediatric;
  pediatricIcon.ariaLabel = `${translations.pediatric}: ${pediatric ? activeTranslation : inactiveTranslation}`;

  const adultIcon = patientGroupHTML.querySelector<HTMLElement>('.patient--l');
  adultIcon.classList.toggle('patient--active', adult);
  adultIcon.title = translations.adults;
  adultIcon.ariaLabel = `${translations.adults}: ${adult ? activeTranslation : inactiveTranslation}`;

  return patientGroupHTML;
};

/**
 * Taxonomies (IDs) that represent the different usage types.
 */
enum UsageTaxonomy {
  SINGLE_USE = '91a32506-09b7-48b7-8bfe-0dd98c16294e',
  REUSABLE = '91c96dac-b30d-4653-b612-251ce1fcbb3f',
  AUTOCLAVABLE = '28a56164-bcea-432b-9e3d-b842213ca823'
}

/**
 * IDs for the different types of use as defined by the API.
 */
enum UsageId {
  SINGLE_USE = 'su',
  REUSABLE = 'ru',
  AUTOCLAVABLE = 'ac'
}

/**
 * Create the usage icon (icon that displays how reusable the product is) from the given taxonomies.
 * @param taxonomies: The list of taxonomies returned from the API. This method checks if they include a usage
 * taxonomy and if so, display the corresponding usage icon (like reusable, single use, etc.).
 * @param translations The translations of the properties
 */
export const createUseIconsFromTaxonomies = (
  taxonomies: string[],
  translations: { [key: string]: string }
): HTMLElement => {
  const useIconHtml = '<div class="reusable"></div>';
  const useIconElement = createHtmlElementFromString(useIconHtml);

  let usageClass = '';
  let title = '';

  if (taxonomies.includes(UsageTaxonomy.SINGLE_USE)) {
    usageClass = 'reusable--single-use';
    title = translations.singleUse;
  }

  if (taxonomies.includes(UsageTaxonomy.REUSABLE)) {
    usageClass = 'reusable--reusable';
    title = translations.reusable;
  }

  if (taxonomies.includes(UsageTaxonomy.AUTOCLAVABLE)) {
    usageClass = 'reusable--autoclavable';
    title = translations.autoclavable;
  }

  if (usageClass) {
    useIconElement.classList.add(usageClass);
    useIconElement.title = title;
  } else {
    useIconElement.className = 'no-info';
  }

  return useIconElement;
};

/**
 * Create the usage icon (icon that displays how reusable the product is) from the given ID.
 * @param usageId: The usage ID provided by the API.
 * @param translations The translations of the properties
 */
export const createUseIconsFromId = (usageId: string, translations: { [key: string]: string }): HTMLElement => {
  const useIconHtml = '<div class="reusable"></div>';
  const useIconElement = createHtmlElementFromString(useIconHtml);

  let usageClass = '';
  let title = '';

  switch (usageId) {
    case UsageId.SINGLE_USE:
      usageClass = 'reusable--single-use';
      title = translations.singleUse;
      break;
    case UsageId.REUSABLE:
      usageClass = 'reusable--reusable';
      title = translations.reusable;
      break;
    case UsageId.AUTOCLAVABLE:
      usageClass = 'reusable--ac';
      title = translations.autoclavable;
      break;
  }

  if (usageClass) {
    useIconElement.classList.add(usageClass);
    useIconElement.title = title;
  } else {
    useIconElement.className = 'no-info';
  }

  return useIconElement;
};

/**
 * IDs for the different e-catalog tile icons as defined by the API.
 */
enum ECatalogIconId {
  SOFTWARE = 'software',
  DEVICE = 'device',
  ACCESSORIES = 'accessories',
  CONSUMABLES = 'consumable',
  MARKETING = 'marketing',
  SERVICE_PARTS = 'service-parts'
}

/**
 * Get the fallback svg icon that is displayed if a product or resource has no image. Returns either the e-catalog icon or resources center icon.
 * @param icons: Icon list of the product or resource. For E-Catalog this is returned by the API. In resources center this is the "tagValue" of the resource.
 * @param large: If the large (more detailed) version of the icon should be returned.
 */
export const getFallbackIconForCategory = (icons: string[], large = false): HTMLElement => {
  let iconMarkup = '';

  if (icons?.length > 0) {
    // if there are multiple icons, display the first one
    const categoryId = icons[0];

    switch (categoryId) {
      case ECatalogIconId.SOFTWARE:
        iconMarkup = large ? Icon.eCatalogSoftwareLarge : Icon.eCatalogSoftware;
        break;
      case ECatalogIconId.DEVICE:
        iconMarkup = large ? Icon.eCatalogDeviceLarge : Icon.eCatalogDevice;
        break;
      case ECatalogIconId.ACCESSORIES:
        iconMarkup = large ? Icon.eCatalogAccessoriesLarge : Icon.eCatalogAccessories;
        break;
      case ECatalogIconId.CONSUMABLES:
        iconMarkup = large ? Icon.eCatalogConsumableLarge : Icon.eCatalogConsumable;
        break;
      case ECatalogIconId.MARKETING:
        iconMarkup = large ? Icon.eCatalogMarketingLarge : Icon.eCatalogMarketing;
        break;
      case ECatalogIconId.SERVICE_PARTS:
        iconMarkup = large ? Icon.eCatalogServicePartLarge : Icon.eCatalogServicePart;
        break;
      default:
        // resources center icon instead of e-catalog icon
        iconMarkup = large
          ? getResourceTilePlaceholderIconForType(<ResourceType>categoryId)
          : getResourceTilePlaceholderIconSmallForType(<ResourceType>categoryId);
    }

    if (iconMarkup) {
      return createHtmlElementFromString(iconMarkup);
    }
  }

  // fallback icon is the product icon from global search
  return createHtmlElementFromString(large ? Icon.productLarge : Icon.product);
};

/**
 * Returns the icon (inside a circle) for the given type for the resource tile.
 * @param type The resource type
 */
export const getResourceTileIconForType = (type: ResourceType): string => {
  switch (type) {
    case ResourceType.Article:
      return Icon.article;
    case ResourceType.Document:
      return Icon.document;
    case ResourceType.ELearning:
      return Icon.eLearningType;
    case ResourceType.Learning:
      return Icon.learning;
    case ResourceType.LearningNugget:
      return Icon.learningNugget;
    case ResourceType.LearningPath:
      return Icon.learningPath;
    case ResourceType.EModule:
      return Icon.eLearningType;
    case ResourceType.News:
      return Icon.news;
    case ResourceType.OnlineManual:
      return Icon.manual;
    case ResourceType.Page:
      return Icon.page;
    case ResourceType.Presentation:
      return Icon.presentation;
    case ResourceType.Product:
      return Icon.product;
    case ResourceType.Video:
      return Icon.videoPlay;
    case ResourceType.Audio:
      return Icon.videoPlay;
    case ResourceType.ScientificPublication:
      return Icon.document;
    // TODO: Switch videoPlay icon with audio icon we will receive
  }
};

/**
 * Returns the placeholder icon for each type of the resource tile.
 * @param type The resource type
 */
export const getResourceTilePlaceholderIconForType = (type: ResourceType): string => {
  switch (type) {
    case ResourceType.Article:
      return Icon.articleLarge;
    case ResourceType.Document:
      return Icon.documentLarge;
    case ResourceType.ELearning:
      return Icon.eLearningTypeLarge;
    case ResourceType.Learning:
      return Icon.learningLarge;
    case ResourceType.LearningNugget:
      return Icon.learningNuggetLarge;
    case ResourceType.LearningPath:
      return Icon.learningPathLarge;
    case ResourceType.EModule:
      return Icon.eLearningTypeLarge;
    case ResourceType.News:
      return Icon.newsLarge;
    case ResourceType.OnlineManual:
      return Icon.manualLarge;
    case ResourceType.Page:
      return Icon.pageLarge;
    case ResourceType.Presentation:
      return Icon.presentationLarge;
    case ResourceType.Product:
      return Icon.productLarge;
    case ResourceType.Video:
      return Icon.videoPlayLarge;
    case ResourceType.ScientificPublication:
      return Icon.documentLarge;
    // TODO: Add icon for audio when we have one
  }
};

/**
 * Returns the placeholder icon for each type of the resource tile.
 * @param type The resource type
 */
export const getResourceTilePlaceholderIconSmallForType = (type: ResourceType): string => {
  switch (type) {
    case ResourceType.Article:
      return Icon.articleSmall;
    case ResourceType.Document:
      return Icon.documentSmall;
    case ResourceType.ELearning:
      return Icon.eLearningTypeSmall;
    case ResourceType.Learning:
      return Icon.learningSmall;
    case ResourceType.LearningNugget:
      return Icon.learningNuggetSmall;
    case ResourceType.LearningPath:
      return Icon.learningPathSmall;
    case ResourceType.EModule:
      return Icon.eLearningTypeSmall;
    case ResourceType.News:
      return Icon.newsSmall;
    case ResourceType.OnlineManual:
      return Icon.manualSmall;
    case ResourceType.Page:
      return Icon.pageSmall;
    case ResourceType.Presentation:
      return Icon.presentationSmall;
    case ResourceType.Product:
      return Icon.productSmall;
    case ResourceType.Video:
      return Icon.videoPlaySmall;
  }
};

/**
 * Replaces all hyphens/dashes with a non-breaking hyphen
 * @param el The html element which will be recursively formatted
 */
export const formatText = (el: HTMLElement): Promise<void> => {
  return new Promise((resolve) => {
    const findAndReplaceText = function (el: HTMLElement, from: any, to: string) {
      if (!el || !from || !to) {
        return;
      }

      let node;
      const nodes = [];
      const walker = document.createTreeWalker(el, NodeFilter.SHOW_TEXT);

      // Find nodes
      while ((node = walker.nextNode())) {
        if (from instanceof RegExp) {
          from.test(node.wholeText) && nodes.push(node);
        } else {
          node.wholeText.indexOf(from) !== -1 && nodes.push(node);
        }
      }

      // Change DOM
      while (nodes.length > 0) {
        if (nodes[0].parentElement && nodes[0].textContent.trim() !== '沪ICP备18000884号-2') {
          if (nodes[0].parentElement.style.display !== 'none') {
            const el = nodes[0].parentElement;
            nodes[0].textContent = nodes[0].textContent.replaceAll(from, to);
            el.innerHTML = el.innerHTML.replaceAll(to.replace('&', '&amp;'), to);
          }
        }

        nodes.splice(0, 1);
      }
    };

    findAndReplaceText(el, /[-–—―]/g, '&#8209;');
    resolve();
  });

};
