import { ComponentObservable, componentObserver } from '../../component-observer';
import { createHtmlElementFromString } from '../../utils/html.utils';
import { breakpointListener } from '../../utils/breakpoint-listener.module';
import { currentBreakpoint } from '../../utils/resize.utils';

/**
 * Initializes the video elements.
 */
class VideoModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = 'video';

  /**
   * Initialize all videos on the current page.
   * @param observe Boolean if the VideoModule should listen for changes in the DOM and
   *    initialize dynamically added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allComponents.length; i++) {
        this.initialize(allComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Creates a source element for the video tag
   * @param url The url element to create the source tag from
   */
  createSourceElement(url: string): HTMLElement | null {
    const lastIndex = url.lastIndexOf('.');
    if (lastIndex !== -1) {
      const fileType = url.substr(lastIndex + 1);
      const source = `<source src="${url}" type="video/${fileType}">`;
      return createHtmlElementFromString(source);
    }
    return null;
  }

  /**
   * Loads the videos depending on the current screen size.
   * @param root The video root element
   * @param url The video url for desktop
   * @param urlMobile The video url for mobile
   */
  loadVideos(root: HTMLVideoElement, url: string[], urlMobile: string[]): void {
    const isMobile = currentBreakpoint().isMobileAny;
    root.innerHTML = ''; // Remove old sources
    if (isMobile) {
      urlMobile.forEach((element) => {
        const sourceElement = this.createSourceElement(element);
        if (sourceElement) {
          root.appendChild(sourceElement);
        }
      });
    } else {
      url.forEach((element) => {
        const sourceElement = this.createSourceElement(element);
        if (sourceElement) {
          root.appendChild(sourceElement);
        }
      });
    }
    root.load();
  }

  /**
   * Initialize the given video element.
   * Sets the listener for resize.
   * @param root The video root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);
    const path = root.dataset.path;
    const pathMobile = root.dataset.pathMobile;
    const url = path ? path.split('|') : [];
    const urlMobile = pathMobile ? pathMobile.split('|') : url;

    // Only create source elements if the path are given
    if (path) {
      this.loadVideos(root as HTMLVideoElement, url, urlMobile);
    }

    // Only add listener if there are different video contents for mobile and desktop
    if (path && pathMobile) {
      breakpointListener.listenForBreakpointChangeBetweenMobileAndDesktop(() => {
        this.loadVideos(root as HTMLVideoElement, url, urlMobile);
      });
    }
  }
}

const videoModule = new VideoModule();
export { videoModule as video };
