import { ComponentObservable, componentObserver } from '../../component-observer';
import { Icon } from '../../general/icons/Icon';
import { createHtmlElementFromString } from '../../utils/html.utils';

/**
 * Initializes to top buttons.
 */
class ToTopButtonModule implements ComponentObservable {
  /**
   * Selector of all buttons that must be initialized in javascript.
   */
  componentSelector = '[data-to-top]';

  /**
   * Threshold when the button should appear
   */
  SCROLL_Y_THRESHOLD = 500;

  /**
   * Initialize all to top buttons on the current page.
   * @param observe Boolean if the toTopButtonModule should listen for changes in the DOM and initialize dynamically added
   *   buttons
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allElements = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allElements.length; i++) {
        this.initialize(allElements[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize to top buttons when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given to top button element
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);

    // add to top button
    const button = createHtmlElementFromString(`
  <button class="to-top-button button button--primary button--icon-only" aria-label="${root.dataset.toTop}">
    <span class="button__icon-single">${Icon.topChevron}</span>
  </button>`);

    // Check if `data-to-top-container` is set, use root in this case, use window otherwise
    const scrollInContainer = root.dataset.toTopContainer !== undefined;
    const main = scrollInContainer ? root : window;

    // Add button to last element of the body
    document.body.appendChild(button);

    // Scroll listener to show or hide the button
    const scrollListener = (): void => {
      // Check if the element with the component selector still exists, remove the button and listeners otherwise
      if (!document.querySelector(this.componentSelector)) {
        button.remove();
        main.removeEventListener('scroll', scrollListener);
      }
      if (scrollInContainer ? root.scrollTop : window.scrollY > this.SCROLL_Y_THRESHOLD) {
        button.classList.add('to-top-button--visible');
      } else {
        button.classList.remove('to-top-button--visible');
      }
    };

    // Show/Hide button on scroll
    main.addEventListener('scroll', scrollListener);

    // Scroll to top on click
    button.addEventListener('click', () => main.scroll({ top: 0, behavior: 'smooth' }));
  }
}

const toTopButtonModule = new ToTopButtonModule();
export { toTopButtonModule as toTopButton };
