import { ComponentObservable, componentObserver } from '../../component-observer';

/**
 * Initializes the click function on the dropdown items
 */
class DropdownItemModule implements ComponentObservable {
  /**
   * Selector of all dropdown items that must be initialized in javascript.
   */
  componentSelector = '.dropdown__item';

  /**
   * Initialize all dropdown items on the current page.
   * Sets listener for all dropdown items.
   * @param observe Boolean if the DropdownItemModule should listen for changes in the DOM and initialize dynamically added
   *   dropdown items
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const dropdownItems = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < dropdownItems.length; i++) {
        this.initialize(dropdownItems[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize dropdown items when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Select the dropdown item on click or key up (enter) or focus the next/previous item on arrow down/up.
   * @param event The keyboard or mouse event
   */
  onEvent(event: KeyboardEvent | MouseEvent): void {
    const item = event.currentTarget as HTMLElement;

    if ((event instanceof KeyboardEvent && event.key === 'Enter') || event instanceof MouseEvent) {
      const dropdown = item.parentElement;
      const dropdownId = dropdown.getAttribute('data-reference-id');

      // Hide label if available
      const label = document.querySelector(`[for=${dropdownId}]`);
      if (label && !label.classList.contains('form-control__label-outside')) {
        label.classList.add('label--hidden');
      }

      // Set selected item as button value
      const button = document.getElementById(dropdownId);
      if (!button.hasAttribute('data-keep-text') && !button.classList.contains('button--dropdown__editable')) {
        button.innerHTML = item.innerHTML;
      } else if(button.classList.contains('button--dropdown__editable')) {
        const input = <HTMLInputElement> button;
        input.value = item.innerHTML;
      }

      // Close dropdown
      dropdown.classList.remove('dropdown--open');

      const customEvent = new CustomEvent('onitemselect', { detail: event });
      dropdown.dispatchEvent(customEvent);
    } else if (event instanceof KeyboardEvent && event.key === 'ArrowUp') {
      (item?.previousElementSibling as HTMLElement)?.focus();
    } else if (event instanceof KeyboardEvent && event.key === 'ArrowDown') {
      (item?.nextElementSibling as HTMLElement)?.focus();
    }
  }

  /**
   * Initialize the given dropdown item element.
   * Sets the listeners for click and keyboard events.
   * @param dropdown The dropdown item root element that should be initialized
   */
  initialize(dropdown: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, dropdown);
    dropdown.addEventListener('click', this.onEvent, false);
    dropdown.addEventListener('keyup', this.onEvent, false);
  }
}

const dropdownItemModule = new DropdownItemModule();
export { dropdownItemModule as dropdownItem };
