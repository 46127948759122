/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = function (string, newString) {
    // If a regex pattern
    if (Object.prototype.toString.call(string).toLowerCase() === '[object regexp]') {
      return this.replace(string, newString);
    }

    // If a string
    return this.replace(new RegExp(string, 'g'), newString);
  };
}
