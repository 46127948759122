import { ComponentObservable, componentObserver } from '../../component-observer';

/**
 * Enables and disables the clear button for the search field and removes the text if clear button is pressed.
 */
class SearchFieldModule implements ComponentObservable {
  /**
   * Selector of all search fields that must be initialized in javascript.
   */
  componentSelector = '.search-field';

  /**
   * Initialize all search fields on the current page.
   * @param observe Boolean if the searchFieldModule should listen for changes in the DOM and initialize dynamically
   *   added search fields
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allInputs = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allInputs.length; i++) {
        this.initialize(allInputs[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize search fields when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given search field element.
   * Sets the listeners for starting the animation on mouseover and mouseout.
   * @param searchField The search field root element that should be initialized
   */
  initialize(searchField: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, searchField);
    const hasSearchButton = searchField.classList.contains('search-field--search-button');
    const input = searchField.querySelector('input') as HTMLInputElement;
    const button = searchField.querySelector('button') as HTMLElement;

    // Hide button if input is empty
    if (hasSearchButton && input.value.length === 0) {
      button.setAttribute('aria-hidden', 'true');
    }

    // Change the search icon or x icon depending on the input focus and value length
    const changeIconListener = (event): void => {
      const hasSearchButton = searchField.classList.contains('search-field--search-button');
      const value = (event.currentTarget as HTMLInputElement).value;
      if (value.length > 0) {
        if (hasSearchButton) {
          button.removeAttribute('aria-hidden');
        }
        // Set the x icon
        button.removeAttribute('disabled');
        button.addEventListener('blur', changeIconListener, { once: true }); // Can be removed after fired once
      } else if (!hasSearchButton) {
        // Set the search icon
        button.setAttribute('disabled', 'disabled');
      } else if (hasSearchButton) {
        button.setAttribute('aria-hidden', 'true');
      }
    };

    // Change icon on input or focus
    input.addEventListener('input', changeIconListener, false);
    input.addEventListener('focus', changeIconListener, false);

    // Removes the input value, changes the icon and removes listener
    button.addEventListener('click', () => {
      input.value = '';
      if (!hasSearchButton) {
        button.setAttribute('disabled', 'disabled');
      }
    });
  }
}

const searchFieldModule = new SearchFieldModule();
export { searchFieldModule as searchField };
