import { ComponentObservable, componentObserver } from '../../component-observer';
import { smoothScrollToElement } from '../../utils/helper.utils';

/**
 * Initializes the anchor
 */
class AnchorModule implements ComponentObservable {
  /**
   * Selector of all anchor elements that must be initialized in javascript.
   */
  componentSelector = '.anchor';

  /**
   * Current breakpoint
   */
  breakpoint = null;

  /**
   * Initialize all anchor elements on the current page.
   * @param observe Boolean if the AnchorModule should listen for changes in the DOM and initialize dynamically
   *   added anchor elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allElements = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allElements.length; i++) {
        this.initialize(allElements[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize anchor elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given anchor.
   * @param anchor The anchor element that should be initialized
   */
  initialize(anchor: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, anchor);
    const itemId = anchor.getAttribute('href');
    const target = document.querySelector(`[id='${itemId.replace('#', '')}']`) as HTMLElement;

    anchor.addEventListener('click', (event: MouseEvent) => {
      event.preventDefault();

      smoothScrollToElement(target);
    });
  }
}

const anchorModule = new AnchorModule();
export { anchorModule as anchor };
