import { baseUrl, requestHeadersBasicAuth, requestHeadersJsonBasicAuth } from './general-api';
import { userData } from '../user-data.module';

/**
 * Base URL to retrieve all wish lists.
 */
export const baseUserDataPath = baseUrl + '/.rest/delivery/v1/catalog/userdata';

/**
 * Object returned from the userdata endpoint.
 * Also contains the wish list and shopping cart information, which can be added to this interface
 * as soon as they are necessary or used somewhere.
 */
export interface HamiltonUserData {
  customerNumbers: string;
}

/**
 * Returns the customer IDs for the current user. These IDs are displayed in a customer dropdown
 * to the user and when a customerId was selected, product prices update to show the price for the chosen customer.
 */
export const getCustomerIdsForCurrentUser = (): Promise<string[]> => {
  if (document.querySelector('html').classList.contains('user-logged-out')) {
    return Promise.resolve([]);
  }
  let url = baseUserDataPath;
  const params: Record<string, string> = {};

  if (Object.keys(params).length > 0) {
    const parameterString = new URLSearchParams(params).toString();
    url = `${url}?${parameterString}`;
  }

  return fetch(url, {
    method: 'GET',
    headers: requestHeadersJsonBasicAuth
  })
    .then((response) => response.json())
    .then((data) => {
      if (data && data.customerNumbers) {
        return data.customerNumbers.split(',');
      }

      return [];
    })
    .catch(() => {
      return [];
    });
};

/**
 * Returns the user data.
 */
export const getUserData = (): Promise<HamiltonUserData> => {
  if (document.querySelector('html').classList.contains('user-logged-out')) {
    return Promise.resolve({customerNumbers: ''});
  }
  let url = baseUserDataPath;
  const params: Record<string, string> = {};

  if (userData.lastSelectedCustomerId) {
    params.customerNo = userData.lastSelectedCustomerId;
  }

  if (Object.keys(params).length > 0) {
    const parameterString = new URLSearchParams(params).toString();
    url = `${url}?${parameterString}`;
  }

  return fetch(url, {
    headers: requestHeadersBasicAuth
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(() => {
      return [];
    });
};
