import {ComponentObservable, componentObserver} from "../../../component-observer";

class SessionTimeoutOverlayModule implements ComponentObservable {
    componentSelector = '#session-timeout-overlay';

    lastPingTime = 0;

    pingInterval = 30000;

    initializeAll(observe: boolean): Promise<void> {
        return new Promise((resolve) => {
            const overlays = document.querySelectorAll(this.componentSelector);
            for (let i = 0; i < overlays.length; i++) {
                this.initialize(overlays[i] as HTMLFormElement);
            }

            if (observe) {
                this.startListening();
            }
            resolve();
        });
    }

    startListening(): void {
        componentObserver.subscribeListener(this);
    }

    initialize(sessionOverlay: HTMLFormElement): void {
        componentObserver.markElementAsInitialized(this.componentSelector, sessionOverlay);
        document.addEventListener('mousemove', (event) => {
            const currentTime = Date.now();
            if (currentTime - this.lastPingTime >= this.pingInterval) {
                this.sendPing(sessionOverlay);
                this.lastPingTime = currentTime;
            }
        });
        sessionOverlay.querySelector('.login-button').addEventListener('click', () => {
           window.location = window.location;
        });
    }

    sendPing(overlay) {
        if (document.querySelector('html').classList.contains('user-logged-in')) {
            fetch('/.rest/user/v1/ping',)
                .then(async response => {
                    if ((await response.text()) === 'true') {
                        overlay.classList.remove('overlay--visible');
                    } else {
                        overlay.classList.add('overlay--visible');
                    }
                })
                .catch(error => console.error('Error sending ping:', error));
        }
    }
}

const sessionTimeoutOverlayModule = new SessionTimeoutOverlayModule();
export { sessionTimeoutOverlayModule as sessionTimeoutOverlay };
