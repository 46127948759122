import 'toastify-js/src/toastify.css';

/** Import our styles after external styles because we override them */
import './assets/scss/styles.scss';
import { Icon } from './general/icons/Icon';
import { formatText, createHtmlElementFromString } from './utils/html.utils';
import { updateFootnoteTexts } from './utils/footnotes.module';
import { initializeCookieSettings } from './utils/cookie-settings.utils';
import smoothScroll from 'smoothscroll-polyfill';
// necessary to support async/await with babel
import 'regenerator-runtime/runtime';
import { detectIncognito } from "detectincognitojs";

import './polyfills';

import { megaButton } from './components/button/button.module';
import { dropdown } from './components/dropdown/dropdown.module';
import { dropdownItem } from './components/dropdown/dropdown-item.module';
import { accordion } from './components/accordion/accordion.module';
import { staticCompareTable } from './building-blocks/static-compare-table/static-compare-table.module';
import { socialShare } from './components/social-share/social-share.module';
import { input } from './components/form-controls/input/input.module';
import { cdInputInitializeAll } from './components/form-controls/input/input.cd';
import { searchField } from './components/search-field/search-field.module';
import { newsTable } from './building-blocks/news-table/news-table.module';
import { overlayTriggerButton } from './building-blocks/overlays/overlay-trigger-button.module';
import { overlay } from './building-blocks/overlays/overlay.module';
import { promoElement } from './building-blocks/promo-element/promo-element.module';
import { eventTable } from './building-blocks/event-table/event-table.module';
import { textAndImageComponent } from './building-blocks/text-and-image-component/text-and-image-component.module';
import { animation } from './utils/animation.module';
import { compareTable } from './building-blocks/compare-table/compare-table.module';
import { heroSection } from './building-blocks/hero-section/hero-section.module';
import { mainNavigation } from './building-blocks/main-navigation/main-navigation.module';
import { subNavigation } from './building-blocks/main-navigation/sub-navigation.module';
import { countrySelector } from './building-blocks/country-selector/country-selector.module';
import { interactiveTags } from './components/interactive-tags/interactive-tags.module';
import { toastNotification } from './building-blocks/toast-notification/toast-notification.module';
import { stickyComponent } from './sticky-component';
import { technologiesOverview } from './building-blocks/technologies-overview/technologies-overview.module';
import { ventilationModes } from './building-blocks/ventilation-modes/ventilation-modes.module';
import { showMore } from './components/show-more/show-more.module';
import { productSelector } from './building-blocks/product-selector/product-selector.module';
import { globalSearch } from './building-blocks/global-search/global-search.module';
import { selectableTile } from './building-blocks/tiles/selectable-tile/selectable-tile.module';
import { footnote } from './building-blocks/article/article-footnote/footnote.module';
import { video } from './components/video/video.module';
import { toTopButton } from './components/to-top-button/to-top-button.module';
import { sectionSelector } from './building-blocks/overlays/profile-overlay/section-selector.module';
import { botdetect } from './components/form-controls/botdetect/botdetect.module';
import { productInfoBox } from './building-blocks/product-info-box/product-info-box.module';
import { productAnimation } from './building-blocks/product-animation/product-animation.module';
import { helpStickyButton } from './components/help-sticky-button/help-sticky-button.module';
import { anchor } from './components/anchor/anchor.module';
import { contact } from './components/contact/contact.module';
import { contactForm } from './components/contact/contact-form.module';
import { cdForm } from './components/cd-form/cd-form.module';
import { registrationModule } from './components/profile/registration.module';
import { loginModule } from './components/profile/login.module';
import { passwordResetModule } from './components/profile/password-reset.module';
import { profileModule } from './components/profile/profile.module';
import { registerMatomoEvent } from './utils/matomo.utils';
import { learningPathPlaylist } from './building-blocks/learning-path-playlist/learning-path-playlist.module';
import { mediaOverlay } from './building-blocks/overlays/media-overlay/media-overlay.module';
import { survey } from './components/survey/survey.module';
import { sessionTimeoutOverlay } from './building-blocks/overlays/session-timeout-overlay/session-timeout-overlay.module';


export { megaButton } from './components/button/button.module';
export { dropdown } from './components/dropdown/dropdown.module';
export { dropdownItem } from './components/dropdown/dropdown-item.module';
export { accordion } from './components/accordion/accordion.module';
export { staticCompareTable } from './building-blocks/static-compare-table/static-compare-table.module';
export { socialShare } from './components/social-share/social-share.module';
export { input } from './components/form-controls/input/input.module';
export { searchField } from './components/search-field/search-field.module';
export { newsTable } from './building-blocks/news-table/news-table.module';
export { inViewportObserver } from './in-viewport-observer';
export { eventTable } from './building-blocks/event-table/event-table.module';
export { textAndImageComponent } from './building-blocks/text-and-image-component/text-and-image-component.module';
export { animation } from './utils/animation.module';
export { toastNotification } from './building-blocks/toast-notification/toast-notification.module';
export { tabOverflow } from './building-blocks/tab-overflow/tab-overflow.module';
export { botdetect } from './components/form-controls/botdetect/botdetect.module';
export { contact } from './components/contact/contact.module';
export { contactForm } from './components/contact/contact-form.module';
export { cdForm } from './components/cd-form/cd-form.module';
export { form } from './components/form-controls/form/form.module';
export { survey } from './components/survey/survey.module';
export { sessionTimeoutOverlay } from './building-blocks/overlays/session-timeout-overlay/session-timeout-overlay.module';

/**
 * Initializes all components on the current page and optionally listens for changes in the DOM to initialize
 * dynamically created objects later on.
 * @param observe Boolean if all components should listen for changes in the DOM and initialize dynamically added
 *   components
 */
const initializeAllComponents = (observe): void => {
  accordion.initializeAll(observe);
  animation.initializeAll(observe);
  compareTable.initializeAll(observe);
  countrySelector.initializeAll(observe);
  dropdown.initializeAll(observe);
  dropdownItem.initializeAll(observe);
  eventTable.initializeAll(observe);
  footnote.initializeAll(observe);
  globalSearch.initializeAll(observe);
  heroSection.initializeAll(observe);
  input.initializeAll(observe);
  form.initializeAll(observe);
  survey.initializeAll(observe);
  sessionTimeoutOverlay.initializeAll(observe);
  interactiveTags.initializeAll(observe);
  mainNavigation.initializeAll(observe);
  megaButton.initializeAll(observe);
  newsTable.initializeAll(observe);
  overlay.initializeAll(observe);
  overlayTriggerButton.initializeAll(observe);
  productSelector.initializeAll(observe);
  promoElement.initializeAll(observe);
  searchField.initializeAll(observe);
  sectionSelector.initializeAll(observe);
  selectableTile.initializeAll(observe);
  showMore.initializeAll(observe);
  socialShare.initializeAll(observe);
  staticCompareTable.initializeAll(observe);
  subNavigation.initializeAll(observe);
  textAndImageComponent.initializeAll(observe);
  toastNotification.initializeAll(observe);
  toTopButton.initializeAll(observe);
  stickyComponent.initializeAll(observe);
  technologiesOverview.initializeAll(observe);
  ventilationModes.initializeAll(observe);
  video.initializeAll(observe);
  botdetect.initializeAll(observe);
  productInfoBox.initializeAll(observe);
  productAnimation.initializeAll(observe);
  helpStickyButton.initializeAll(observe);
  anchor.initializeAll(observe);
  contact.initializeAll(observe);
  contactForm.initializeAll(observe);
  cdForm.initializeAll(observe);
  registrationModule.initializeAll(observe);
  loginModule.initializeAll(observe);
  passwordResetModule.initializeAll(observe);
  profileModule.initializeAll(observe);
  learningPathPlaylist.initializeAll(observe);
  mediaOverlay.initializeAll(observe);

  // Click dimensions
  const observer = new MutationObserver(cdInputInitializeAll);
  const forms = document.querySelectorAll('.cd-form');

  forms.forEach((form) => observer.observe(form, { attributes: true, childList: true, subtree: true }));
};

const initializeServiceWorker = (): void => {
  // register service worker (automatically updates if a new version is available)
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('service-worker.js');
  }
};

// register the service worker for product animations
initializeServiceWorker();

/**
 * Waits for document content loaded event and initializes all javascript controls.
 */
document.addEventListener('DOMContentLoaded', () => {
  if (!document.body.getAttribute('data-initialized')) {
    // Polyfill smooth scroll for safari
    smoothScroll.polyfill();
    // Initialize all components
    initializeAllComponents(true);
    formatText(document.body);
    document.body.setAttribute('data-initialized', 'true');
    updateFootnoteTexts();
    // Initialize the footnotes at the end so that their positioning is correct
    footnote.initializeAll(true);
    // Added initialize again because formatText removes the event listener from the overlay trigger button
    overlayTriggerButton.initializeAll(true);

    initializeCookieSettings();
    if (window.location.href.indexOf('Contact-form') > -1) {
      detectIncognito().then((result) => {
        const element = '<a class="important-message " href="#" target="_self">If you try to fill out the form in the incognito mode of your browser, please switch to the normal mode of your browser so that you can submit the form.</a>';
        if (result.isPrivate) {
          document.querySelector('.main-navigation-wrapper').append(createHtmlElementFromString(element));
        }
      });
    }

    window.printPageTitle = () => {
      registerMatomoEvent('Print Button', 'Print button click', window.location.href);
      window.print();
    }

    document.addEventListener('mousemove', initializeUC);
    document.addEventListener('touchmove', initializeUC);
  }
});

const initializeUC = () => {
  let ucScript = document.getElementById('usercentrics-cmp');
  let ucBlockScript = document.getElementById('uc-block-script');

  if (ucScript) {
    ucScript.setAttribute('src', ucScript.getAttribute('data-src'));
  }
  if (ucBlockScript) {
    ucBlockScript.setAttribute('src', ucBlockScript.getAttribute('data-src'));
  }
}
