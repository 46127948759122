import { baseUrl } from './general-api';

/**
 * Base URL for the ventilators API.
 */
export const baseVentilatorsPath = baseUrl + '/.rest/delivery/v1/ventilators';

/**
 * ID of the taxonomy category that is returned from the /taxonomies endpoint.
 * This taxonomy category returns the available ventilators for the ventilator
 * dropdown of the e-catalog.
 */
export const ventilatorTaxonomyId = '420c9986-12ef-425a-972f-b6e202a0569e';

/**
 * New taxonomy ID for medin ventilators
 */
export const ventilatorTaxonomyIdMedin = '2c3daf5c-40ef-43b2-98f4-c48f4d7ae388';

/**
 * The ventilators that are available for the current selected country.
 */
export interface Ventilator {
  /**
   * (Translated) name of the ventilator (product).
   */
  name: string;
  /**
   * (Translated) title of the ventilator (product).
   */
  title: string;
  /**
   * (Translated) subtitle of the ventilator (product).
   */
  subtitle: string;
  /**
   * Ventilator ID.
   */
  '@id': string;
  /**
   * Images of the ventilators for small and large image.
   */
  image: {
    renditions: {
      small: {
        link: string;
      };
      large: {
        link: string;
      };
    };
  };
  /**
   * The link to the product page of the ventilator (product selector)
   */
  catalogArticleLink: string;

  /**
   * The link to the overview page of the ventilator (e-catalog)
   */
  catalogProductOverviewPage: string;

  /**
   * The relative link to technical specification download.
   */
  techSpecsLink: string;

  /**
   * The link to the overview page of the ventilator (resources center)
   */
  resourceArticleLink: string;

  /**
   * Description text of the ventilator (technology)
   */
  text: string;

  /**
   * Icon class for the ventilator
   */
  iconClass: string;

  /**
   * Link to the detail page
   */
  detailPage: string;

  /**
   * Link text that is shown on the button
   */
  detailPageLinkText: string;

  /**
   * Advanced features in percent
   */
  advancedFeaturesPercent?: string;

  /**
   * Mobility in percent
   */
  mobilityPercent?: string;

  /**
   * The id of the related ventilator taxonomy
   */
  ventilatorTaxonomy: string;

  sortOrder: string;
}

/**
 * Returns a list of all ventilators.
 */
export const getAllVentilators = (id?: string, taxonomy?: string, includeHidden?: boolean): Promise<Ventilator[]> => {
  let url = baseVentilatorsPath;
  const params: Record<string, string> = {};
  if (id) {
    params.id = id;
  }
  if (taxonomy) {
    params.taxonomy = taxonomy;
  }
  if (includeHidden !== undefined) {
    params.hidden = includeHidden.toString();
  }
  if (Object.keys(params).length > 0) {
    const parameterString = new URLSearchParams(params).toString();
    url = `${url}?${parameterString}`;
  }

  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      return data || [];
    })
    .catch(() => {
      return [];
    });
};
