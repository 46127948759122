import anime from 'animejs/lib/anime.es.js';
import { ComponentObservable, componentObserver } from '../../component-observer';
import { currentBreakpoint } from '../../utils/resize.utils';

/**
 * Initializes the animation of the mega buttons that will be triggered on mouseover and mouseout.
 */
class MegaButtonModule implements ComponentObservable {
  /**
   * The width of the button after mouseover
   */
  shrinkWidth = '72px';
  /**
   * The duration of the animation
   */
  animationDuration = 100;
  /**
   * Selector of all buttons that must be initialized in javascript.
   */
  componentSelector = '.button-animation-wrapper';

  /**
   * If the current devices primary input can hover.
   */
  isTouch = false;

  /**
   * Initialize all mega buttons on the current page.
   * Sets the animation listener for all mega buttons.
   * @param observe Boolean if the buttonModule should listen for changes in the DOM and initialize dynamically added
   *   buttons
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allMegaButtons = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allMegaButtons.length; i++) {
        this.initialize(allMegaButtons[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize mega buttons when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given mega button element.
   * Sets the listeners for starting the animation on mouseover and mouseout.
   * @param button The button root element that should be initialized
   */
  initialize(button: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, button);
    // Get current button width
    let buttonWidth = getComputedStyle(button).width;
    // Get current breakpoint from window size
    const breakpoint = currentBreakpoint();
    // Set the initial width of the mega button wrapper if breakpoint is desktop or large
    if (breakpoint.isDesktop || breakpoint.isLarge) {
      button.style.width = buttonWidth;
    }

    // check if the devices primary input can hover, if not -> we are on a touch device and disable hover
    this.isTouch = !window.matchMedia('(hover: hover)').matches;

    // Mouseover listener
    const mouseOverListener = (): void => {
      if (!this.isTouch) {
        return this.animateWidth(this.shrinkWidth, button);
      }
    };
    // Mouseout listener
    const mouseOutListener = (): void => {
      if (!this.isTouch) {
        return this.animateWidth(buttonWidth, button);
      }
    };

    // Click listener to re-calculate the button width in case the text changes
    const clickListener = (): void => {
      setTimeout(() => {
        button.removeAttribute('style');
        button.firstElementChild && (button.firstElementChild as HTMLElement).removeAttribute('style');
        buttonWidth = getComputedStyle(button).width;
        button.style.width = buttonWidth;
        button.dataset.clickWidth = buttonWidth;
      }, 600);
    };

    // Adds mouse listeners to the button if screen size is desktop or large
    const addListeners = (): void => {
      const breakpoint = currentBreakpoint();
      if (breakpoint.isDesktop || breakpoint.isLarge) {
        button.addEventListener('mouseover', mouseOverListener, false);
        button.addEventListener('mouseout', mouseOutListener, false);
        button.addEventListener('click', clickListener, false);
      }
    };

    // Removes mouse listener if screen size is not desktop or large
    // Animation is only displayed on mobile sizes
    const removeListeners = (): void => {
      const breakpoint = currentBreakpoint();
      if (!breakpoint.isDesktop && !breakpoint.isLarge) {
        button.removeEventListener('mouseover', mouseOverListener, false);
        button.removeEventListener('mouseout', mouseOutListener, false);
        button.removeEventListener('click', clickListener, false);
      }
    };

    // timeoutId for debounce mechanism
    let timeoutId = null;
    // Set window resize listener
    const resizeListener = (): void => {
      // Prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // Change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        // check current window width
        const breakpoint = currentBreakpoint();
        if (breakpoint.isDesktop || breakpoint.isLarge) {
          setTimeout(() => {
            buttonWidth = getComputedStyle(button).width;
            button.style.width = buttonWidth;
          }, 100);
        } else {
          button.removeAttribute('style');
          button.firstElementChild && (button.firstElementChild as HTMLElement).removeAttribute('style');
        }
        removeListeners();
        addListeners();
      }, 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // Add initial listeners
    if (breakpoint.isDesktop || breakpoint.isLarge) {
      addListeners();
    }
  }

  /**
   * Animates the width of the mega button element
   * @param width
   * @param buttonElement
   */
  animateWidth(width: string, buttonElement: HTMLElement): void {
    const animationTargetElement = buttonElement.querySelector('.button--mega');
    anime({
      targets: animationTargetElement,
      width,
      duration: this.animationDuration
    });
  }
}

const megaButtonModule = new MegaButtonModule();
export { megaButtonModule as megaButton };
