import { ComponentObservable, componentObserver } from '../../component-observer';

/**
 * Initializes dropdown buttons to toggle its state.
 */
class DropdownModule implements ComponentObservable {
  /**
   * Selector of all dropdown buttons that must be initialized in javascript.
   */
  componentSelector = '.button--dropdown';

  /**
   * Boolean that stores if a click listener has been set to the document. That click listener closes open dropdowns
   * when clicked outside.
   */
  eventListenersRegistered = false;

  /**
   * Initialize all dropdowns on the current page.
   * Sets listener for all dropdown buttons.
   * @param observe Boolean if the DropdownModule should listen for changes in the DOM and initialize dynamically added
   *   buttons
   */
  initializeAll(observe: boolean): void {
    const dropdownButtons = document.querySelectorAll(this.componentSelector);
    for (let i = 0; i < dropdownButtons.length; i++) {
      this.initialize(dropdownButtons[i] as HTMLElement);
    }

    if (observe) {
      this.startListening();
    }
  }

  // Function to close all dropdowns
  closeOpenDropdowns(): void {
    const dropdowns = document.querySelectorAll('.dropdown.dropdown--open');
    dropdowns.forEach((dropdown) => {
      // Close dropdown
      dropdown.classList.remove('dropdown--open');
    });
  }

  /**
   * Toggle a dropdown item list. This function will show/hide a dropdown with the given selector relative to the
   * anchor element with the given selector.
   * @param mouseClickEvent The click event that kicked off the opening/closing of the dropdown
   */
  toggleDropdown(mouseClickEvent: MouseEvent): void {
    mouseClickEvent.stopPropagation();
    const button = mouseClickEvent.currentTarget as HTMLElement;
    const formControl = button.parentElement;
    const id = button.getAttribute('id') || button.dataset.id;

    if (!id) {
      /* eslint-disable no-console */
      console.error('All buttons with button--dropdown class require an `id` attribute.');
      return;
    }

    // Get the dropdown that belongs to the dropdown button
    const dropdown = document.querySelector(`[data-reference-id=${id}]`);

    // Toggle dropdown visibility
    if (dropdown !== null) {
      if (dropdown.classList.contains('dropdown--open')) {
        dropdown.classList.remove('dropdown--open');
      } else {
        // Remove invalid modifier from form control while dropdown is opened
        if (formControl && formControl.classList.contains('form-control--invalid')) {
          formControl.classList.remove('form-control--invalid');
        }
        // close all other dropdowns first
        this.closeOpenDropdowns();
        // now open this dropdown
        dropdown.classList.add('dropdown--open');
        // open dropdown list always from the first item position (not scrolled)
        if (button.hasAttribute('data-keep-text')) {
          dropdown.scrollTo({ top: 0 });
        }
      }
    }
  }

  /**
   * Register event listeners that are responsible for closing dropdown menus when the window is resized, the user scrolls or clicks anywhere outside the dropdown.
   */
  registerEventListenersForDropdowns(): void {
    // Close dropdown on scroll
    document.addEventListener('scroll', () => {
      this.closeOpenDropdowns();
    });

    // Close on resize
    window.addEventListener('resize', () => {
      this.closeOpenDropdowns();
    });

    // Close on escape
    window.addEventListener('keyup', (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        this.closeOpenDropdowns();
      }
    });

    // Close dropdown when clicked outside the dropdown button
    document.addEventListener('click', () => {
      if (document.querySelectorAll('.dropdown.dropdown--open').length > 0) {
        this.closeOpenDropdowns();
      }
    });
  }

  /**
   * Adds a click listener, scroll and resize listener to the whole page that closes open dropdowns when clicked
   * outside, scrolled or resized.
   */
  addEventListeners(): void {
    if (!this.eventListenersRegistered) {
      this.registerEventListenersForDropdowns();
      this.eventListenersRegistered = true;
    }
  }

  /**
   * Focuses the first element child of the dropdown flyout if arrow down is pressed
   * @param event The keyboard event
   */
  onKeyEvent(event: KeyboardEvent): void {
    const button = event.currentTarget as HTMLElement;

    // Get the dropdown that belongs to the dropdown button
    const dropdown = document.querySelector(`[data-reference-id=${button.getAttribute('id')}]`);

    if (event.key === 'ArrowDown') {
      (dropdown.firstElementChild as HTMLElement).focus();
    } else if (button.classList.contains('button--dropdown__editable') && event.key.match(/(\w|\s)/g)) {
        // Filter the dropdown options
        const rootElement = dropdown.parentElement;

        const label = rootElement.querySelector<HTMLInputElement>('label');
        label.classList.add('label--hidden');

        const dropdownOptions = rootElement.querySelectorAll<HTMLElement>('.dropdown__item');
        const filter = (<HTMLInputElement> button).value.toUpperCase();

        for (let i = 0; i < dropdownOptions.length; i++) {
          let txtValue = dropdownOptions[i].textContent || dropdownOptions[i].innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            dropdownOptions[i].style.display = "";
          } else {
            dropdownOptions[i].style.display = "none";
          }
        }
      }
  }

  /**
   * Listen for changes in DOM and initialize dropdown buttons when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the dropdown button element.
   * Sets the listeners for toggle and key events.
   * @param dropdown The dropdown root element that should be initialized
   */
  initialize(dropdown: HTMLElement): void {
    const rootElement = dropdown.parentElement;
    componentObserver.markElementAsInitialized(this.componentSelector, dropdown);
    dropdown.addEventListener('click', this.toggleDropdown.bind(this), false);
    rootElement.querySelector('.dropdown-arrow')?.addEventListener('click', this.toggleDropdown.bind(this), false);// Arrow 
    dropdown.addEventListener('keyup', this.onKeyEvent.bind(this), false);
    this.addEventListeners();
    this.listenForSelections(rootElement);
  }

  /**
   * If the dropdown is used as a form control, update the value of the hidden input element
   * according to the user's selection.
   */
  listenForSelections(rootElement: HTMLElement): void {
    // update the selected option of the form control if there is a hidden input element
    const hiddenInput = rootElement.querySelector('input[type=hidden]');
    if (hiddenInput) {
      const dropdownOptions = rootElement.querySelectorAll<HTMLElement>('.dropdown__item');
      for (const option of dropdownOptions) {
        option.addEventListener('click', (event) => {
          const target = <HTMLElement>event.currentTarget;
          hiddenInput.value = target.getAttribute('data-id') || '';
        });
      }
    }
  }
}

const dropdownModule = new DropdownModule();
export { dropdownModule as dropdown };
