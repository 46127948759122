import { ComponentObservable, componentObserver } from '../../component-observer';
import { Icon } from '../../general/icons/Icon';
import {
  getAllVentilationModes,
  getAllVentilationModesCompanies,
  VentilationMode
} from '../../api/ventilation-modes-api';
import { getUrlForImage } from '../../utils/image.utils';
import { createHtmlElementFromString, formatText } from '../../utils/html.utils';

/**
 * Initializes the ventilation modes.
 */
class VentilationModesModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.ventilation-modes';

  /**
   * Id of the ventilation modes overlay
   */
  overlayId = 'ventilation-modes-overlay';

  /**
   * Markup for the skeleton to show while loading
   */
  skeletonMarkup(label: string): string {
    const rowMarkup = `<tr>
        <td class="ventilation-modes__left-info"><div class="ventilation-modes-skeleton"></div></td>
        <td class="ventilation-modes__right-info"><div class="ventilation-modes-skeleton"></div></td>
    </tr>`;

    return `<table class="ventilation-modes-skeleton">
    <tr>
        <th class="ventilation-modes__header">
            <h5 class="ventilation-modes__headline ventilation-modes__headline--skeleton"></h5>
        </th>
        <th class="ventilation-modes__compare-controls">
            <label class="form-control__label-outside" for="ventilation-compare-dropdown">${label}</label>
            <div class="ventilation-modes-skeleton"></div>
        </th>
    </tr>
    ${rowMarkup.repeat(3)}
  </table>`;
  }

  /**
   * Markup for a ventilation mode row
   */
  rowMarkup(
    title: string,
    description: string,
    imgUrl: string,
    imgAlt: string,
    overlayImg: string,
    mode: unknown
  ): string {
    // Pre-render all available options and hide them initially
    let companies = '';
    for (let i = 0; i < mode['@nodes'].length; i++) {
      const node = mode['@nodes'][i];
      companies += `<span aria-hidden="true" data-company-reference-id="${node}">${mode[node].ventilationMode}</span>`;
    }
    return `<tr>
      <td class="ventilation-modes__left-info">
      <div class="ventilation-modes__left-info-wrapper">
        <div class="ventilation-modes__title-and-image">
          <span class="ventilation-modes__title">${title || ''}</span>
          <button class="ventilation-modes__image" 
            data-overlay-id="${this.overlayId}" 
            data-overlay-image="${overlayImg || ''}"
            data-overlay-title="${title || ''}"
            data-overlay-description="${description || ''}">
            ${imgUrl ? `<img src="${imgUrl}" alt="${imgAlt}"/>` : ''}
            ${overlayImg ? Icon.info : ''}
          </button>
        </div>
        <span class="ventilation-modes__description">${description || ''}</span>
      </div>
      </td>
      <td class="ventilation-modes__right-info">
        ${companies}
      </td>
    </tr>`;
  }

  /**
   * Returns the markup for the row headline and sets the given headline.
   */
  rowHeaderMarkup(headline: string, hasDropdown: boolean): string {
    return `<tr>
    <th class="ventilation-modes__header">
      <h5 class="ventilation-modes__headline">${headline}</h5>
    </th>
    ${hasDropdown ? '<th class="ventilation-modes__compare-controls"></th>' : '<th></th>'}
  </tr>`;
  }

  /**
   * Renders the overlay that is shown on click.
   * @param root The ventilation modes root element
   */
  renderOverlay(root: HTMLElement): void {
    const overlay = `<div id="${this.overlayId}" class="overlay-wrapper">
      <div class="overlay overlay--ventilation-modes">
          <button class="button button--overlay button--icon-only">
              <span class="button__icon-single">${Icon.close}</span>
          </button>
          <h4 class="overlay__title"></h4>
          <p class="overlay__text"></p>
          <img src="" alt="" />
      </div>
    </div>`;
    root.appendChild(createHtmlElementFromString(overlay));
  }

  /**
   * Initialize all ventilation modes on the current page.
   * @param observe Boolean if the VentilationModesModule should listen for changes in the DOM and
   *    initialize dynamically added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allComponents.length; i++) {
        this.initialize(allComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given ventilation modes element.
   * @param root The ventilation modes root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);
    this.renderTable(root);
    this.renderOverlay(root);
  }

  /**
   * Removes the current table from the ventilation modes.
   * @param root The ventilation modes root element
   */
  removeTable(root: HTMLElement): void {
    const table = root.querySelector('table');
    if (table) {
      table.remove();
    }
  }

  /**
   * Removes the current table if available and renders the new ventilation mode table.
   * @param root The ventilation modes root element
   */
  renderTable(root: HTMLElement): void {
    // Get the label for the dropdown
    const labelCompare = root.dataset.labelCompareWith;
    // Remove old table if exists
    this.removeTable(root);
    // Add skeleton table for loading state
    const skeleton = createHtmlElementFromString(this.skeletonMarkup(labelCompare));
    root.appendChild(skeleton);

    // Render rows
    let rows = '';
    this.createVentilationModesMap().then((map) => {
      // Remove loading table
      this.removeTable(root);
      // Create new table
      const table = document.createElement('table');
      root.appendChild(table);

      let isFirst = true;
      // Render headlines
      for (const element of map.entries()) {
        const headline = element[0];
        const entries = element[1];
        rows += this.rowHeaderMarkup(headline, isFirst);
        // Render rows for each ventilation mode
        isFirst = false;
        for (const mode of entries) {
          const imgUrl = mode.image?.renditions?.small?.link ? getUrlForImage(mode.image.renditions.small.link) : '';
          const imgOverlayUrl = mode.imageLarge?.renditions?.large?.link
            ? getUrlForImage(mode.imageLarge.renditions.large.link)
            : '';
          const modeName = mode.name || mode['@name']; // the 'name' property is sometimes set when special characters are in the name. Otherwise, fallback to the @name property
          rows += this.rowMarkup(modeName, mode.description, imgUrl, modeName, imgOverlayUrl, mode);
        }
      }
      table.innerHTML = rows;
      this.createDropdown(root, labelCompare);

      formatText(root);
    });
  }

  /**
   * Creates the dropdown to select the ventilation mode companies.
   * @param root The ventilation modes root element
   * @param labelCompare The label for the compare with dropdown
   */
  createDropdown(root: HTMLElement, labelCompare: string): void {
    let items = '';
    // Get all companies and add dropdown
    getAllVentilationModesCompanies().then((result) => {
      // Set the initial selected value to the first item of the result
      const initialSelected = result[0];
      // Create dropdown items
      result.forEach((company) => {
        items += `<li class="dropdown__item" tabindex="0" data-company-id="${company['@name']}">${company.companyName}</li>`;
      });
      // Create dropdown markup
      const dropdown = `
      <label class="form-control__label-outside" for="ventilation-companies-compare-dropdown">${labelCompare}:</label>
        <div class="form-control form-control--dropdown">
            <button class="button button--dropdown" id="ventilation-companies-compare-dropdown">${initialSelected.companyName}</button>
            <ul class="dropdown" data-reference-id="ventilation-companies-compare-dropdown">
                ${items}
            </ul>
        </div>`;
      // Insert dropdown
      const dropdownWrapper = root.querySelector<HTMLElement>('.ventilation-modes__compare-controls');
      dropdownWrapper.innerHTML = dropdown;
      // Add listener when an item is selected
      const dropdownElement = dropdownWrapper.querySelector('.dropdown');
      dropdownElement.addEventListener('onitemselect', (event: CustomEvent) => {
        const company = event.detail.target.getAttribute('data-company-id');
        this.updateCompanyVisibility(root, company);
      });
      // Set initially the visible company properties
      this.updateCompanyVisibility(root, initialSelected['@name']);
    });
  }

  /**
   * Creates a map with the ventilation modes to group the headlines.
   */
  async createVentilationModesMap(): Promise<Map<string, VentilationMode[]>> {
    const map = new Map<string, VentilationMode[]>();
    const data = await getAllVentilationModes();
    data?.forEach((mode) => {
      const group = map.get(mode.group);
      if (group) {
        group.push(mode);
      } else {
        map.set(mode.group, [mode]);
      }
    });
    return map;
  }

  /**
   * Updates the visibility of the current selected company.
   * @param root The ventilation modes root element
   * @param company The id of the selected company
   */
  updateCompanyVisibility(root: HTMLElement, company: string): void {
    // Hide all currently visible values
    const visibleOptions = root.querySelectorAll('.ventilation-modes__right-info span[aria-hidden="false"]');
    visibleOptions.forEach((option) => {
      option.setAttribute('aria-hidden', 'true');
    });
    // Show visible values from the selected company
    const spans = root.querySelectorAll(`[data-company-reference-id='${company}']`);
    spans.forEach((option) => {
      option.setAttribute('aria-hidden', 'false');
    });
  }
}

const ventilationModesModule = new VentilationModesModule();
export { ventilationModesModule as ventilationModes };
