import { ComponentObservable, componentObserver } from './component-observer';

/**
 * Initializes accordions to toggle their states.
 */
class StickyComponentModule implements ComponentObservable {
  /**
   * Selector of all dropdown buttons that must be initialized in javascript.
   */
  componentSelector = '[data-stick-to]';

  /**
   * Initialize all accordions on the current page.
   * Sets listener for all accordions.
   * @param observe Boolean if the AccordionModule should listen for changes in the DOM and initialize dynamically added
   *   accordions
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const accordions = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < accordions.length; i++) {
        this.initialize(accordions[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize accordions when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the accordion element.
   * Sets the listeners for toggle and key events.
   * @param component The accordion root element that should be initialized
   */
  initialize(component: HTMLElement): void {
    const targetElemSelector = component.dataset.stickTo;
    const targetElement = document.querySelector(targetElemSelector);

    const updatePosition = (): void => {
      const targetElemPosition = targetElement.getBoundingClientRect();
      window.requestAnimationFrame(() => {
        component.style.top = `${targetElemPosition.bottom}px`;
      });
    };

    updatePosition();
    window.addEventListener('scroll', updatePosition, false);
  }
}

const stickyComponentModule = new StickyComponentModule();
export { stickyComponentModule as stickyComponent };
