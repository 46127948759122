import { ComponentObservable, componentObserver } from '../../component-observer';

/**
 * Initializes the interactive tags.
 */
class InteractiveTagsModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.interactive-tags--multiselect';

  /**
   * Initialize all interactive tags with multiselect on the current page.
   * @param observe Boolean if the InteractiveTagsModule should listen for changes in the DOM and initialize dynamically
   *   added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allComponents.length; i++) {
        this.initialize(allComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given interactive tags element.
   * Sets the listener for resize.
   * @param root The interactive tags root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);
    const checkboxes = root.querySelectorAll<HTMLInputElement>('input[type="checkbox"]:not([data-check-all])');
    const checkboxAll = root.querySelector<HTMLInputElement>('[data-check-all]');
    // Add listeners and logic for checkboxAll only if available and there is more than one checkbox
    if (checkboxAll && checkboxes.length > 1) {
      // Deselect checkboxAll if another checkbox is selected
      const selectAllIfNoneOrAllIsSelected = (): void => {
        checkboxAll.checked = false;
        // If none checkbox is selected, select checkboxAll
        const selectedCheckBoxes = root.querySelectorAll<HTMLInputElement>('input:checked');
        if (selectedCheckBoxes.length < 1 || selectedCheckBoxes.length === checkboxes.length) {
          checkboxAll.checked = true;
        }
      };

      // Deselect all checkboxes
      const deselectAll = (): void => {
        checkboxes.forEach((checkbox) => (checkbox.checked = false));
      };

      // All other checkboxes (not checkboxAll)
      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', selectAllIfNoneOrAllIsSelected, false);
      });
      // Prevent from disable checkboxAll if no other checkbox is selected
      checkboxAll.addEventListener('click', (event) => {
        if (!checkboxAll.checked) event.preventDefault();
      });
      // Deselect all checkboxes if checkboxAll is selected
      checkboxAll.addEventListener('change', deselectAll, false);
    }
  }
}

const interactiveTagsModule = new InteractiveTagsModule();
export { interactiveTagsModule as interactiveTags };
