import { ComponentObservable, componentObserver } from '../../component-observer';
import { smoothScrollToElement } from '../../utils/helper.utils';

/**
 * Initializes the sub navigation
 */
class SubNavigationModule implements ComponentObservable {
  /**
   * Selector of all sub navigation elements that must be initialized in javascript.
   */
  componentSelector = '.sub-navigation';

  /**
   * Current breakpoint
   */
  breakpoint = null;

  /**
   * Intersection observer to get the current headline in the view port
   */
  intersectionObserver: IntersectionObserver;

  /**
   * Array with the headings
   */
  headings = [];

  /**
   * Initialize all sub navigation elements on the current page.
   * @param observe Boolean if the SubNavigationModule should listen for changes in the DOM and initialize dynamically
   *   added sub navigation elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {const allElements = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allElements.length; i++) {
        // Set an internal id to each sub navigation element for mapping the intervals
        allElements[i].setAttribute('data-id', i.toString());
        this.initialize(allElements[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize sub navigation elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Get the smallest distance to top of all intersecting elements and return the index of the headline
   */
  checkSmallestTopDistance(): number {
    const headings = document.querySelectorAll('[data-intersecting]');
    let index = -1;
    if (headings && headings.length > 0) {
      index = 0;
      let smallestTopDistance = Math.abs(headings[0].getBoundingClientRect().top);
      for (let i = 1; i < headings.length; i++) {
        const currentTopDistance = Math.abs(headings[i].getBoundingClientRect().top);
        if (currentTopDistance < smallestTopDistance) {
          smallestTopDistance = currentTopDistance;
          index = i;
        }
      }
      index = this.headings.indexOf(headings[index]);
    }
    return index;
  }

  /**
   * Checks which entries are currently visible and highlights the current active element
   * @param entries The observable headlines
   * @param subNavigation The sub navigation element
   */
  inViewport(entries: IntersectionObserverEntry[], subNavigation: HTMLElement): void {
    // Set data attribute if element is visible or remove it if not
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.setAttribute('data-intersecting', 'true');
      } else {
        entry.target.removeAttribute('data-intersecting');
      }
    });
    // Get the index of the headline that is active
    const index = this.checkSmallestTopDistance();
    if (index !== -1 && !subNavigation.getAttribute('data-interaction')) {
      // Change the styling of the active element and removes it from the old one
      const id = this.headings[index].getAttribute('id');
      const anchor = subNavigation.querySelector(`a[href='#${id}']`);
      const activeItem = subNavigation.querySelector('.sub-menu__item--active');
      activeItem && activeItem.classList.remove('sub-menu__item--active');
      const newActiveItem = anchor.parentElement;
      newActiveItem.classList.add('sub-menu__item--active');
      this.smoothHorizontalScrollToElement(newActiveItem, 16);
    }
  }

  /**
   * Scrolls horizontally to left edge of element
   * Caution: parentElement must be position: relative for this to work
   * @param element The element to scroll to
   * @param additionalOffsetLeft Left offset
   */
  smoothHorizontalScrollToElement(element: HTMLElement, additionalOffsetLeft?: number): void {
    const additionalOffsetToLeft = additionalOffsetLeft || 0;
    const x = element.offsetLeft - additionalOffsetToLeft;
    setTimeout(() => {
      element.parentElement.scroll({
        left: x,
        behavior: 'smooth'
      });
    }, 50);
  }

  /**
   * Initialize the given sub navigation.
   * @param subNavigation The sub navigation element that should be initialized
   */
  initialize(subNavigation: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, subNavigation);
    const obsOptions = { threshold: 0, rootMargin: '0px' };
    this.intersectionObserver = new IntersectionObserver(
      (entries) => this.inViewport(entries, subNavigation),
      obsOptions
    );
    // Get the references to the headlines to scroll to
    const items = subNavigation.querySelectorAll('.sub-menu__item a');
    items.forEach((item) => {
      const itemId = item.getAttribute('href');
      if (itemId !== null && itemId !== undefined) {
        // Get the headline that belongs to the anchor
        const headline = document.querySelector(`[id='${itemId.replace('#', '')}']`) as HTMLElement;
        if (headline) {
          // Mark as anchor headline
          headline.setAttribute('data-anchor', 'true');
          // Scroll smooth to the headline on sub menu item click
          item.addEventListener('click', (event: MouseEvent) => {
            event.preventDefault();
            // Workaround to keep the main navigation from appearing on scroll to top
            subNavigation.setAttribute('data-interaction', 'true');
            document.addEventListener('scroll', handleScroll, false);
            smoothScrollToElement(headline);
            // Remove active item and set new active item
            const activeItem = subNavigation.querySelector('.sub-menu__item--active');
            activeItem && activeItem.classList.remove('sub-menu__item--active');
            item.parentElement.classList.add('sub-menu__item--active');
          });
          // Add headline to global array and add intersection observer
          this.headings.push(headline);
          this.intersectionObserver.observe(headline);
        }
      }
    });

    // Handle scroll when clicking on a sub navigation item
    let isScrolling;
    const handleScroll = (): void => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);
      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(() => {
        // Remove attribute that keeps the main navigation from appearing after scrollIntoView is finished
        subNavigation.removeAttribute('data-interaction');
        document.removeEventListener('scroll', handleScroll);
      }, 100);
    };
  }
}

const subNavigationModule = new SubNavigationModule();
export { subNavigationModule as subNavigation };
