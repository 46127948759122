import { baseUrl } from './general-api';

/**
 * Base URL for the global search API.
 */
export const baseSearchPath = baseUrl + '/.rest/delivery/v1/search';
export const baseSuggestPath = baseSearchPath + '/suggest';

/**
 * "tagValue" of a resource.
 */
export enum ResourceType {
  Page = 'page',
  News = 'news',
  Product = 'catalog-articles',
  Document = 'document',
  Presentation = 'presentation',
  OnlineManual = 'onlineManual',
  Article = 'article',
  ELearning = 'elearning',
  Learning = 'learning',
  LearningNugget = 'learningnugget',
  LearningPath = 'learningpath',
  EModule = 'emodule',
  Video = 'video',
  Quote = 'quote',
  ScientificPublication = 'scientificPublication',
  Audio = 'audio'
}

export enum ResourceApiType {
  Page = 'website',
  News = 'news',
  Product = 'catalog-articles',
  Document = 'document',
  Publication = 'publication',
  OpsManual = 'ops-manual',
  KnowledgeBase = 'knowledge-base',
  ELearning = 'elearning',
  Video = 'video',
  Audio = 'audio',
  Quote = 'quote'
}

/**
 * The result returned from the search endpoint
 */
export interface HamiltonResourceResults {
  results: HamiltonResourceResult[];
  totalResults: number;
  taxonomyCounts: Record<string, number>;
  tagCounts: Record<string, number>;
}

/**
 * "type" of a resource
 */
export type HamiltonResourceBaseType =
  | 'website' // only appears in search results
  | 'news' // only appears in search results and news archive
  | 'catalog-articles'
  | 'document'
  | 'publication'
  | 'ops-manual'
  | 'knowledge-base'
  | 'elearning'
  | 'video'
  | 'audio'
  | 'quote';

/**
 * Properties that are present in all search types
 */
export interface HamiltonResourceBase {
  type: HamiltonResourceBaseType;
  nodeid: string;
  tag: string; // translated key
  imageSmall: string;
  title: string;
}

/**
 * Properties for catalog-article
 */
export interface HamiltonResourceProduct extends HamiltonResourceBase {
  partNumber: string;
  length: string;
  forVentilator: string;
  material: string;
  price: string;
  gtin: string;
  patientGroups: string;
  typeOfUse: string;
  packagingUnit: string;
  productName: string;
  description: string;
  url: string;
  dateOfChange: string;
  createdAt: string;
  newOrUpdated: string;
  lastModified: string;
}

/**
 * Properties for article, news, document, publication, ops-manual, knowledge-base
 */
export interface HamiltonResourceDocument extends HamiltonResourceBase {
  newOrUpdated: string; // new | updated | none if nothing is set?
  text: string;
  title: string;
  url: string; // only for pages and onlineManual
  link: string;
  accessType: string; // public | partnernet
  tagValue: ResourceType;
  languages: string;
  hmIdentifier: string; // PN (first part) not for knowledge-base
  referenceNumber: string; // PN (first part) for publications
  revisionNumber: string; // PN (second part)
  mimeType: string; // not for knowledge-base
  fileSize: string; // in bytes, not for knowledge-base
  dateOfChange: string; // last updated if not present -> use createdAt
  createdAt: string; // last updated
  lastModified: string; // last updated
  newUpdateChangeDate: string;
  downloadLink: string;
  author: string;
  videoURL: string; // for video type only
  lead: string; // for articles
  personFunction: string; // Job title of the person in a quote tile.
  personLocation: string; // Location/work place of the person in a quote tile.
  hasPublicPdf: boolean; // Used for publications to mark a private PDF.
}

/**
 * Combined type for the search result
 */
export type HamiltonResourceResult = HamiltonResourceProduct | HamiltonResourceDocument;

/**
 * Returns the suggestions for the global search autocomplete.
 * @param search The search value
 */
export const getSuggestions = (search?: string): Promise<string[]> => {
  let url = baseSuggestPath;
  if (search) {
    const params: Record<string, string> = {};
    params.q = search;

    const parameterString = new URLSearchParams(params).toString();
    url = `${url}?${parameterString}`;
  }

  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        return data;
      }

      return [];
    })
    .catch(() => {
      return [];
    });
};

/**
 * Returns the list of results of the global search.
 * @param search The search value
 * @param type The search type
 * @param limit The result limit
 * @param offset The result offset
 */
export const getSearchResults = (
  search?: string,
  type?: string,
  limit?: number,
  offset?: number
): Promise<HamiltonResourceResults> => {
  let url = baseSearchPath;
  const params: Record<string, string> = {};
  if (search) {
    params.q = search;
  }
  if (type) {
    params.type = type;
  }
  if (limit) {
    params.limit = limit.toString();
  }
  if (offset) {
    params.offset = offset.toString();
  }

  if (Object.keys(params).length > 0) {
    const parameterString = new URLSearchParams(params).toString();
    url = `${url}?${parameterString}`;
  }

  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        return data;
      }

      return [];
    })
    .catch(() => {
      return [];
    });
};
