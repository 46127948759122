import { ComponentObservable, componentObserver } from '../../component-observer';

/**
 * Initializes the profile password change form.
 */
class ProfileModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.password-change-form';

  /**
   * Initialize all profile password change forms on the current page.
   * @param observe Boolean if the profileModule should listen for changes in the DOM and initialize dynamically
   *   added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allComponents.length; i++) {
        this.initialize(allComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given profile password change form element.
   * Sets the listener for resize.
   * @param root The profile password change form root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);
    root.addEventListener('submit', (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.disableSubmit(root);
      this.hideErrors(root);
      if (this.validateForm(e)) {
        e.currentTarget.submit();
      }
      this.enableSubmit(root);
      return false;
    });
  }

  disableSubmit(root: HTMLElement): void {
    root.querySelector<HTMLElement>('.button').setAttribute('disabled', 'disabled');
  }

  enableSubmit(root: HTMLElement): void {
    root.querySelector<HTMLElement>('.button').removeAttribute('disabled');
  }

  validateForm(e): boolean {
    e.preventDefault();
    e.stopPropagation();
    const formElement = document.querySelector<HTMLElement>(this.componentSelector);
    let flag = true;

    const passwordHelperTextContainer = formElement
      .querySelector<HTMLInputElement>('#new-pw')
      .parentElement.querySelector<HTMLElement>('.helper-text');
    const passwordConfirmHelperTextContainer = formElement
      .querySelector<HTMLInputElement>('#new-pw-confirm')
      .parentElement.querySelector<HTMLElement>('.helper-text');
    const passwordInput = formElement.querySelector<HTMLInputElement>('#new-pw');
    const passwordConfirm = formElement.querySelector<HTMLInputElement>('#new-pw-confirm');
    const passwordMatch = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[+*ç%&/=?!,.-;:_$]).+$');

    if (passwordInput.value !== passwordConfirm.value) {
      flag = false;
      passwordConfirmHelperTextContainer.querySelector<HTMLElement>('.match-error').style.display = 'block';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#new-pw-confirm').parentElement);
    } else {
      passwordConfirmHelperTextContainer.querySelector<HTMLElement>('.match-error').style.display = 'none';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#new-pw-confirm').parentElement);
    }
    if (!passwordInput.value || passwordInput.value === '') {
      flag = false;
      passwordHelperTextContainer.querySelector<HTMLElement>('.required-error').style.display = 'block';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#new-pw').parentElement);
    } else {
      passwordHelperTextContainer.querySelector<HTMLElement>('.required-error').style.display = 'none';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#new-pw').parentElement);
    }
    if (!passwordConfirm.value || passwordInput.value === '') {
      flag = false;
      passwordConfirmHelperTextContainer.querySelector<HTMLElement>('.required-error').style.display = 'block';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#new-pw-confirm').parentElement);
    } else {
      passwordConfirmHelperTextContainer.querySelector<HTMLElement>('.required-error').style.display = 'none';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#new-pw-confirm').parentElement);
    }
    if (passwordInput.value.length < 8) {
      flag = false;
      passwordHelperTextContainer.querySelector<HTMLElement>('.password-length-error').style.display = 'block';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#new-pw').parentElement);
    } else {
      passwordHelperTextContainer.querySelector<HTMLElement>('.password-length-error').style.display = 'none';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#new-pw').parentElement);
    }
    if (!passwordMatch.test(passwordInput.value)) {
      flag = false;
      passwordHelperTextContainer.querySelector<HTMLElement>('.password-uclc-error').style.display = 'block';
      passwordHelperTextContainer.querySelector<HTMLElement>('.password-numsym-error').style.display = 'block';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#new-pw').parentElement);
    } else {
      passwordHelperTextContainer.querySelector<HTMLElement>('.password-uclc-error').style.display = 'none';
      passwordHelperTextContainer.querySelector<HTMLElement>('.password-numsym-error').style.display = 'none';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#new-pw').parentElement);
    }
    return flag;
  }

  checkInvalidInput(element: HTMLElement): void {
    let flag = true;
    element
      .querySelector('.helper-text')
      .querySelectorAll('div')
      .forEach((message) => {
        if (message.clientHeight > 0) {
          element.classList.add('form-control--invalid');
          flag = false;
        }
      });
    if (flag) {
      element.classList.remove('form-control--invalid');
    }
  }

  hideErrors(root: HTMLElement): void {
    root.querySelectorAll('.helper-text').forEach((errorContainer: HTMLElement) => {
      errorContainer.querySelectorAll('div').forEach((errorMessage: HTMLElement) => {
        errorMessage.style.display = 'none';
      });
    });
  }
}

const profileModule = new ProfileModule();
export { profileModule };
