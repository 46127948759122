/**
 * Interface for the ComponentObserver
 */
interface IComponentObserver {
  /**
   * A list of listeners that want to be notified when an Element with the specified selector appears in the DOM.
   */
  changeListeners: ComponentObservable[];
  /**
   * The native MutationObserver object
   */
  observer: MutationObserver;
  /**
   * Register an ComponentObservable to be notified when an element with the given selector is added to the DOM.
   * @param listener An object that implements the ComponentObservable interface and that should be registered as a listener
   */
  subscribeListener: (listener: ComponentObservable) => void;
  /**
   * Check if the given object is already registered as a listener. Returns true if already registered, false if not.
   * @param listener An object that implements the ComponentObservable interface
   */
  checkIfAlreadyRegistered: (listener: ComponentObservable) => boolean;
  /**
   * Unregister a listener that was previously registered. This stops the invocation of the listeners
   * 'initialize(element)' method whenever a new element of the specified selector appears in the DOM.
   * @param listener The object that implements the ComponentObservable and was registered as a listener
   */
  unsubscribeListener: (listener: ComponentObservable) => void;
  /**
   * Starts observing the DOM for changes.
   */
  startObserving: () => void;
  /**
   * Stop observing any changes in the DOM.
   */
  stopObserving: () => void;
}

/**
 * Interface for the ComponentObservable
 */
export interface ComponentObservable {
  /**
   * The selector can be any selector that can be also passed to 'Element.querySelectorAll(..)'.
   * E.g. '.some-class-name' or '#someId'
   */
  componentSelector: string;
  /**
   * Initializes all elements on the current page that are selected by componentSelector.
   * @param observe Boolean if the component should listen for changes in the DOM and initialize dynamically added components of that type
   */
  initializeAll: (observe: boolean) => void;
  /**
   * Listen for changes in DOM and initialize components when new ones appear by subscribing to the ComponentObserver.
   */
  startListening: () => void;
  /**
   * Initialize the given HTMLElement.
   * @param element The element that should be initialized
   */
  initialize: (element: HTMLElement) => void;
}

/**
 * The ComponentObserver is a wrapper module around the native MutationObserver which listens for changes in the
 * DOM (only childList changes down the whole tree below the body). Control/Building Block modules can register as
 * listeners for those change events by specifying a selector (if that selector appears in a new node then they will
 * be notified) and an object that has an 'initialize(element)' method. If a new element with that selector appears in
 * the DOM, that initialize method of the listener gets called for each new element.
 * @namespace ComponentObserver
 */
class ComponentObserver implements IComponentObserver {
  // The ComponentObservable listeners
  changeListeners: ComponentObservable[] = [];
  // The mutation observer
  observer: null | MutationObserver;

  // Register the given ComponentObservable listener
  subscribeListener(listener: ComponentObservable): void {
    // only register the listener if the initialize function is implemented
    if (!this.checkIfAlreadyRegistered(listener)) {
      // and only register if the object is not already registered.
      this.changeListeners.push(listener);
    }
  }

  // Returns true if the given ComponentObservable listener is already registered or false otherwise.
  checkIfAlreadyRegistered(listener: ComponentObservable): boolean {
    for (let i = 0; i < this.changeListeners.length; i++) {
      if (this.changeListeners[i] === listener) {
        return true;
      }
    }
    return false;
  }

  // Unregister a listener that was previously registered. This stops the invocation of the listeners
  //  'initialize(element)' method whenever a new element of the specified selector appears in the DOM.
  unsubscribeListener(listener: ComponentObservable): void {
    for (let i = 0; i < this.changeListeners.length; i++) {
      // loop through all listeners and remove the given listener
      const changeListener = this.changeListeners[i];
      if (changeListener === listener) {
        this.changeListeners = this.changeListeners.splice(i, 1);
      }
    }
  }

  //  As long as no listeners are registered, the componentObserver does nothing.
  //  As soon as the first listener is registered and a matching element appears, the listener will be notified.
  // Make sure to stop the observer with the stopObserving method as soon as you don't need it anymore.
  startObserving(): void {
    if (this.observer) {
      // Observer has already been started
      return;
    }

    // listens for changed childLists within all nodes below body element
    const config = { childList: true, subtree: true };

    this.observer = new MutationObserver((mutationRecordArray) => {
      // loop through all mutations
      for (let i = 0; i < mutationRecordArray.length; i++) {
        const change = mutationRecordArray[i];

        if (change.type === 'childList') {
          // loop through all listeners and check if their selector appeared
          for (let j = 0; j < this.changeListeners.length; j++) {
            const changeListener = this.changeListeners[j];

            // loop through all new nodes and check if they are HTML elements. If so, check if the selector
            // of the current listener appears somewhere below that element.
            for (let k = 0; k < change.addedNodes.length; k++) {
              const parentNode = change.addedNodes[k];
              // Use the component name in the data-initialized attribute name so that a single element can
              // get initialized multiple times. E.g. when a component has an animation on top of the regular component selector.
              const componentName = this.componentNameForAttributeName(changeListener.componentSelector);

              if (parentNode instanceof HTMLElement) {
                if (parentNode.matches(changeListener.componentSelector)) {
                  // call the initialize method for the element
                  if (!parentNode.getAttribute(`data-initialized-${componentName}`)) {
                    parentNode.setAttribute(`data-initialized-${componentName}`, 'true');
                    changeListener.initialize(parentNode);
                  }
                }

                const elementsForListener = parentNode.querySelectorAll(changeListener.componentSelector);
                for (let l = 0; l < elementsForListener.length; l++) {
                  // call the initialize method for each new element with the registered selector
                  if (!elementsForListener[l].getAttribute(`data-initialized-${componentName}`)) {
                    elementsForListener[l].setAttribute(`data-initialized-${componentName}`, 'true');
                    changeListener.initialize(elementsForListener[l] as HTMLElement);
                  }
                }
              }
            }
          }
        }
      }
    });
    // start observing
    document.addEventListener('DOMContentLoaded', (): void => {
      const target = document.querySelector('body');
      this.observer.observe(target, config);
    });
  }

  // This method disconnects the native MutationObserver and releases it. Registered listeners are not deleted.
  // Either unregister them or start the observation again with the previously registered listeners.
  stopObserving(): void {
    this.observer.disconnect();
    this.observer = null;
  }

  /**
   * Removes all characters from the component selector that are not valid inside an attribute name.
   */
  componentNameForAttributeName(componentSelector: string): string {
    return componentSelector.replace(/[[\].#]|(data-)/g, '');
  }

  /**
   * Marks the given element as initialized.
   */
  markElementAsInitialized(componentSelector: string, element: HTMLElement): void {
    if (element) {
      element.setAttribute(`data-initialized-${this.componentNameForAttributeName(componentSelector)}`, 'true');
    }
  }
}

// As soon as the document is loaded, create a new ComponentObserver and start listening
const observer = new ComponentObserver();
observer.startObserving();

export { observer as componentObserver };
