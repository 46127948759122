import { ComponentObservable, componentObserver } from '../../component-observer';
import { toastNotification } from '../../building-blocks/toast-notification/toast-notification.module'
import { verifyCaptcha } from '../../api/botdetect-api';

/**
 * Initializes the registration form.
 */
class RegistrationModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.user-registration-form';

  /**
   * Initialize all registration forms on the current page.
   * @param observe Boolean if the RegistrationModule should listen for changes in the DOM and initialize dynamically
   *   added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allComponents.length; i++) {
        this.initialize(allComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given registration form element.
   * Sets the listener for resize.
   * @param root The registration form root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);
    root.querySelector('form').addEventListener('submit', (e) => {
      if (this.validateForm(e)) {
        const form = e.currentTarget;
        if (root.querySelectorAll('#botdetect-captcha').length == 0) {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(root.dataset.recaptchaSiteKey, { action: 'submit' }).then((token) => {
              document.querySelector<HTMLInputElement>('input[name="g-recaptcha-response"]').value = token;
              form.submit();
            });
          });
        } else {
          const captcha = root.querySelector('.botdetect');
          const captchaId = captcha.querySelector<HTMLInputElement>('#BDC_VCID_BotDetectCaptcha').value;
          const captchaValue = captcha.querySelector<HTMLInputElement>('#botDetectInput').value;
          const verifyUrl = captcha.dataset.botdetectVerifyUrl;

          if (captchaValue.length === 0) {
            captcha.querySelector('.form-control').classList.add('form-control--invalid');
            return false;
          }

          

          if (verifyCaptcha(verifyUrl, captchaValue, captchaId)) {
            captcha.querySelector('.form-control').classList.remove('form-control--invalid');
            form.submit();
          } else {
            captcha.querySelector('.form-control').classList.add('form-control--invalid');
          }
        }
      } else {
        toastNotification.createErrorToastMessage(root.dataset.formErrorLabel);
      }
      return false;
    });

    root.querySelector<HTMLInputElement>('#confirm-password-register').addEventListener('blur', () => {
      this.matchFields('#password-register', '#confirm-password-register');
    });

    root.querySelector<HTMLInputElement>('#email-confirm-register').addEventListener('blur', () => {
      this.matchFields('#email-register', '#email-confirm-register');
    });
  }

  matchFields(firstFieldSelector: string, secondFieldSelector: string): void {
    const firstInput = document.querySelector<HTMLInputElement>(firstFieldSelector);
    const secondInput = document.querySelector<HTMLInputElement>(secondFieldSelector);
    const helperTextContainer = secondInput.parentElement.querySelector<HTMLElement>('.helper-text');

    if (firstInput.value !== secondInput.value) {
      helperTextContainer.querySelector<HTMLElement>('.match-error').style.display = 'block';
      this.checkInvalidInput(secondInput.parentElement);
    } else {
      helperTextContainer.querySelector<HTMLElement>('.match-error').style.display = 'none';
      this.checkInvalidInput(secondInput.parentElement);
    }
  }

  validateForm(e): boolean {
    e.preventDefault();
    e.stopPropagation();
    const formElement = document.querySelector<HTMLElement>(this.componentSelector).querySelector<HTMLElement>('form');
    const isPartnernet = window.location.href.indexOf('Partner-net') > -1;
    const requiredFields = [
      'email-register',
      'email-confirm-register',
      'password-register',
      'confirm-password-register',
      'name-input-register',
      'lastname-input-register',
      'hospital-register',
      'form-dropdown-salutation-register',
      'form-dropdown-country-register'
    ];
    if (isPartnernet) {
      //requiredFields.push('form-dropdown-function-group-register');
      requiredFields.push('form-dropdown-position-register');
      requiredFields.push('city-register');
      requiredFields.push('zipCode-register');
    }
    let flag = true;

    for (let i = 0; i < requiredFields.length; i++) {
      const inputField = formElement.querySelector<HTMLInputElement>('#' + requiredFields[i]);
      const helperTextContainer = inputField.parentElement.querySelector<HTMLElement>('.helper-text');
      if (!inputField.value || inputField.value === '') {
        if (flag) {
          window.scrollTo(0, Math.round(inputField.getBoundingClientRect().top + window.pageYOffset - 100));
        }
        flag = false;
        helperTextContainer.querySelector<HTMLElement>('.required-error').style.display = 'block';
        this.checkInvalidInput(inputField.parentElement);
      } else {
        inputField.parentElement.classList.remove('form-control--invalid');
        helperTextContainer.querySelector<HTMLElement>('.required-error').style.display = 'none';
        this.checkInvalidInput(inputField.parentElement);
      }
    }

    const emailHelperTextContainer = formElement
      .querySelector<HTMLInputElement>('#email-register')
      .parentElement.querySelector<HTMLElement>('.helper-text');
    if (!this.validateEmail(formElement.querySelector<HTMLInputElement>('#email-register').value)) {
      if (flag) {
        window.scrollTo(0, Math.round(emailHelperTextContainer.parentElement.getBoundingClientRect().top + window.pageYOffset - 100));
      }
      flag = false;
      emailHelperTextContainer.querySelector<HTMLElement>('.invalid-email-error').style.display = 'block';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#email-register').parentElement);
    } else {
      emailHelperTextContainer.querySelector<HTMLElement>('.invalid-email-error').style.display = 'none';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#email-register').parentElement);
    }

    if (!formElement.querySelector<HTMLInputElement>('#privacyPolicy').checked) {
      flag = false;
      formElement
        .querySelector<HTMLInputElement>('#privacyPolicy')
        .parentElement.classList.add('form-control--invalid');
    } else {
      formElement
        .querySelector<HTMLInputElement>('#privacyPolicy')
        .parentElement.classList.remove('form-control--invalid');
    }

    if (!isPartnernet) {
      if (formElement.querySelector<HTMLInputElement>('#AARC_policy_registration')) {
        if (!formElement.querySelector<HTMLInputElement>('#AARC_policy_registration').checked) {
          flag = false;
          formElement
            .querySelector<HTMLInputElement>('#AARC_policy_registration')
            .parentElement.classList.add('form-control--invalid');
        } else {
          formElement
            .querySelector<HTMLInputElement>('#AARC_policy_registration')
            .parentElement.classList.remove('form-control--invalid');
        }
      }
    }

    const passwordHelperTextContainer = formElement
      .querySelector<HTMLInputElement>('#password-register')
      .parentElement.querySelector<HTMLElement>('.helper-text');
    const passwordInput = formElement.querySelector<HTMLInputElement>('#password-register');
    const passwordMatch = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[+*ç%&/=?!,.-;:_$]).+$');

    if (passwordInput.value.length < 8) {
      if (flag) {
        window.scrollTo(0, Math.round(passwordHelperTextContainer.parentElement.getBoundingClientRect().top + window.pageYOffset - 100));
      }
      flag = false;
      passwordHelperTextContainer.querySelector<HTMLElement>('.password-length-error').style.display = 'block';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#password-register').parentElement);
    } else {
      passwordHelperTextContainer.querySelector<HTMLElement>('.password-length-error').style.display = 'none';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#password-register').parentElement);
    }
    if (!passwordMatch.test(passwordInput.value)) {
      if (flag) {
        window.scrollTo(0, Math.round(passwordHelperTextContainer.parentElement.getBoundingClientRect().top + window.pageYOffset - 100));
      }
      flag = false;
      passwordHelperTextContainer.querySelector<HTMLElement>('.password-uclc-error').style.display = 'block';
      passwordHelperTextContainer.querySelector<HTMLElement>('.password-numsym-error').style.display = 'block';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#password-register').parentElement);
    } else {
      passwordHelperTextContainer.querySelector<HTMLElement>('.password-uclc-error').style.display = 'none';
      passwordHelperTextContainer.querySelector<HTMLElement>('.password-numsym-error').style.display = 'none';
      this.checkInvalidInput(formElement.querySelector<HTMLInputElement>('#password-register').parentElement);
    }
    return flag;
  }

  checkInvalidInput(element: HTMLElement): void {
    let flag = true;
    element
      .querySelector('.helper-text')
      .querySelectorAll('div')
      .forEach((message) => {
        if (message.clientHeight > 0) {
          element.classList.add('form-control--invalid');
          flag = false;
        }
      });
    if (flag) {
      element.classList.remove('form-control--invalid');
    }
  }

  validateEmail(email: string): boolean {
    const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
    return re.test(String(email).toLowerCase());
  }
}

const registrationModule = new RegistrationModule();
export { registrationModule };
