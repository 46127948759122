import { baseUrl } from './general-api';
import { userData } from '../user-data.module';
import { HamiltonResourceProduct } from './search-api';

/**
 * Base URL for the products API.
 */
export const baseProductsPath = baseUrl + '/.rest/delivery/v1/catalog/product-list';

/**
 * Base URL for the product detail API.
 */
export const baseProductDetailPath = baseUrl + '/.rest/delivery/v1/catalog/product';

/**
 * IDs used for the 'age' filter of the e-catalog.
 * Important: Don't include the word 'filter' in the IDs,
 * as this is a suffix used to set IDs of HTML elements.
 */
export const ageFilterCategoryId = 'newOrUpdated';

// These are the IDs that we use within the application
// the prefix 'age-' is used to prevent collision with UUIDs
export const ageFilterNewId = 'age-new';
export const ageFilterUpdatedId = 'age-updated';

export const typeFilterFieldName = 'tagValue';

// These are the IDs that the API expects
export const ageFilterNewIdForApi = 'new';
export const ageFilterUpdatedIdForApi = 'updated';

export const typeFilterKbId = 'type-article';
export const typeFilterVideoId = 'type-video';
export const typeFilterDocumentId = 'type-document';
export const typeFilterPublicationId = 'type-presentation';
export const typeFilterElearningId = 'type-elearning';
export const typeFilterQuoteId = 'type-customerVoices';
export const typeFilterScientificPublicationId = 'type-scientificPublication';
export const typeFilterAudioId = 'type-audio';

export const typeFilterKbIdForApi = 'article';
export const typeFilterVideoIdForApi = 'video';
export const typeFilterDocumentIdForApi = 'document';
export const typeFilterPublicationIdForApi = 'presentation';
export const typeFilterElearningIdForApi = 'elearning';
export const typeFilterQuoteIdForApi = 'customerVoices';
export const typeFilterScientificPublicationIdForApi = 'scientificPublication';
export const typeFilterAudioIdForApi = 'audio';

/**
 * Response object that is returned from the product-list endpoint.
 * This object holds the list of products that match the requested filter
 * and additional information about the results.
 */
export interface HamiltonProductResults {
  /**
   * (partial) list of products that match the requested filters.
   */
  results: HamiltonProductResult[];
  /**
   * List of all taxonomies of the products that match the filter
   * and how many product belong to which taxonomy.
   * The key is the taxonomy ID and the value is the count (how many
   * products belong to this taxonomy)
   */
  taxonomyCounts: Record<string, number>;
  tagCounts: Record<string, number>;
  /**
   * Total number of results/products for the requested filter.
   */
  totalResults: number;

  /**
   * Full URL to download the current e-catalog as a PDF file.
   */
  listPdfLink: string;
}

export interface HamiltonImage {
  renditions: {
    small: {
      link: string;
    };
    large: {
      link: string;
    };
  };
}

/**
 * Object that holds the data for a single product (returned from the 'products' endpoint).
 */
export interface HamiltonProduct {
  '@id': string;
  partNumber: string;
  name: string;
  description: string;
  packagingUnit: string;
  image: HamiltonImage;
  length?: string;
  compatibility?: string;
  price?: HamiltonProductPrice[];
  taxonomy: {
    'Patient-groups': string[];
    Use: string[];
  };
  // currently those only exist for wish list and easy reference card products
  patientGroups: string;
  typeOfUse: string;
  icons: string[];
  catalog_taxonomies?: string[];
  isWebshopUser?: boolean;
}

export interface HamiltonProductPrice {
  quantity: number;
  price: number;
  currency: string;
}

/**
 * Reduced object that holds the data for a single product.
 */
export interface HamiltonProductReduced {
  nodeid: string;
  partNumber: string;
  productName: string;
  imageSmall: string;
  icons: string[];
}

/**
 * Object that holds the data for a single product (returned by the 'product-list' endpoint).
 */
export interface HamiltonProductResult extends HamiltonProductReduced {
  id: string;
  description: string;
  packagingUnit: string;
  imageLarge: string;
  length?: string;
  compatibility?: string;
  price?: HamiltonProductPrice[];
  /**
   * String with all taxonomies for this product
   * separated by white spaces. Not necessary anymore
   * because the below properties can be used to retrieve
   * the patientGroups and typeOfUse for the icons.
   */
  catalog_taxonomies: string[];
  patientGroups: string;
  typeOfUse: string;
  icons: string[];
  isWebshopUser: boolean;
  newOrUpdated: string;
}

/**
 * Documents for the download table on the product detail view.
 */
export interface HamiltonDocument {
  metadata: {
    mimeType: string;
    fileName: string;
    fileSize: string;
  };
  renditions: {
    languages: string;
    revisionNumber: string;
    link: string;
    hmIdentifier: string;
    title: string;
  };
}

/**
 * Device configuration that is shown on the product detail view.
 */
export interface HamiltonDeviceConfiguration {
  gtin: string;
  name: string;
  partNumber: string;
}

/**
 * Device configuration that is shown on the product detail view.
 */
export interface HamiltonProductDetailRelatedArticle {
  '@id': string;
  name: string;
  partNumber: string;
}

/**
 * Information about a single product for the product detail view.
 * Inherits from the HamiltonProduct and adds properties for the
 * download table and device configuration.
 */
export interface HamiltonProductDetail extends HamiltonProduct {
  forVentilator: string;
  material: string;
  gtin: string;
  weight: string;
  packagingWeight: string;
  packagingHeight: string;
  packagingLength: string;
  packagingWidth: string;
  includedArticles: HamiltonProductDetailRelatedArticle[];
  requiredArticles: HamiltonProductDetailRelatedArticle[];
  width: string;
  length: string;
  height: string;
  shelfLife: string;
  accessType: string; // public | partnernet
  relatedDocuments: HamiltonDocument[];
  deviceConfiguration: HamiltonDeviceConfiguration[];
  gallery: HamiltonImage[];
  icons: string[];
  note: string;
  newOrUpdated: string;
  isWebshopUser: boolean;
  catalog_taxonomies: string;
}

/**
 * Converts the given HamiltonProduct to a HamiltonProductReduced.
 * @param product The product to convert
 */
export const convertToReducedProduct = (product: HamiltonProduct): HamiltonProductReduced => {
  return {
    nodeid: product['@id'],
    partNumber: product.partNumber,
    productName: product.name,
    imageSmall: product?.image?.renditions?.small?.link,
    icons: product.icons
  };
};

/**
 * Get the packaging unit of the given product as a string. Uses a default if the packaging unit is zero or not set.
 * @param product The product for which the packaging unit should be returned.
 */
export const packagingUnitOrDefault = (
  product: HamiltonProduct | HamiltonProductResult | HamiltonResourceProduct
): string => {
  const packagingUnitDefaultValue = 1;
  return `${product.packagingUnit || packagingUnitDefaultValue}`;
};

/**
 * Returns all products that match the given filters.
 * @param filters: List of taxonomy IDs (filter tags) that should be used to filter the product results.
 * @param searchPhrase: Free text search from the user.
 * @param category
 * @param tab
 * @param paths Used to only show specific products from the e-catalog. Specified using a data-attribute.
 * @param customerId Used to display prices for a specific customer with the given ID.
 * @param ventilatorId Used to display only products for the ventilator with the given ID.
 * @param limit Number of products to load.
 * @param offset How many items should be skipped.
 */
export const getAllProducts = (
  filters: string[],
  searchPhrase: string = null,
  category: string | string[],
  tab: string | string[],
  paths: string,
  customerId: string,
  ventilatorId: string,
  limit: number,
  offset = 0
): Promise<HamiltonProductResults> => {
  const emptyResult = {
    results: [],
    taxonomyCounts: {},
    totalResults: 0
  };
  // Remove the age filter IDs from the filters, because they are not actually taxonomies
  const taxonomies = [...filters.filter((item) => item !== ageFilterNewId && item !== ageFilterUpdatedId)];
  const includesAgeFilterNew = filters.includes(ageFilterNewId);
  const includesAgeFilterUpdated = filters.includes(ageFilterUpdatedId);

  if (category) {
    // category is also a taxonomy
    if (Array.isArray(category)) {
      for (const categoryId of category) {
        taxonomies.push(categoryId);
      }
    } else {
      taxonomies.push(category);
    }
  }
  if (tab) {
    // tab is also a taxonomy
    if (Array.isArray(tab)) {
      for (const tabId of tab) {
        taxonomies.push(tabId);
      }
    } else {
      taxonomies.push(tab);
    }
  }
  if (ventilatorId) {
    // ventilator is also a taxonomy
    taxonomies.push(ventilatorId);
  }

  let url = baseProductsPath;
  const params: Record<string, string> = {};
  if (taxonomies?.length > 0) {
    params.taxonomies = taxonomies.join(',');
  }

  if (customerId) {
    params.customerNo = customerId;
  }

  params.limit = limit.toString();
  params.offset = offset.toString();

  if (searchPhrase) {
    params.q = searchPhrase;
  }

  if (paths) {
    params.paths = paths;
  }

  // Age filter (newOrUpdated)
  if (includesAgeFilterNew) {
    params[ageFilterCategoryId] = ageFilterNewIdForApi;
  }

  if (includesAgeFilterUpdated) {
    params[ageFilterCategoryId] = ageFilterUpdatedIdForApi;
  }

  const ecatalogParams = new URLSearchParams(window.location.search.slice(1));
  if (ecatalogParams.has('pn')) {
    params.pn = ecatalogParams.get('pn');
  }

  // finally, append the params to the query string
  if (Object.keys(params).length > 0) {
    const parameterString = new URLSearchParams(params).toString();
    url = `${url}?${parameterString}`;
  }

  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        return data;
      }

      return emptyResult;
    })
    .catch(() => {
      return emptyResult;
    });
};

export const getProductDetail = (id: string): Promise<HamiltonProductDetail> => {
  let url = baseProductDetailPath;
  const params: Record<string, string> = {};

  params.id = id;

  if (userData.lastSelectedCustomerId) {
    params.customerNo = userData.lastSelectedCustomerId;
  }

  if (Object.keys(params).length > 0) {
    const parameterString = new URLSearchParams(params).toString();
    url = `${url}?${parameterString}`;
  }

  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (!data.error) {
        return data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};
