/**
 * Module that is responsible for fetching and caching the ventilators.
 * The ventilators-api is only responsible for fetching the data from the api, this module in contrast
 * adds some caching functionality, so that ventilators must not be fetched each time they are used.
 */
import { getAllVentilators, Ventilator } from './api/ventilators-api';

class VentilatorsModule {
  /**
   * List of ventilators. This property is null
   * until it was fetched once from the API.
   */
  ventilators: Ventilator[] = null;

  /**
   * List of all products, including ventilators. This property is null
   * until it was fetched once from the API.
   */
  allProducts: Ventilator[] = null;

  /**
   * Id of the ventilator that should be returned first (optional)
   */
  ventilatorId = null;

  /**
   * Get the ventilators. If they have not been cached yet, this function makes a request to the ventilators API.
   * After that it will return the cached ventilators.
   * @param forceUpdate Boolean If the ventilators should be loaded from the API even if there exists a cached version.
   * @param id Optional id of the ventilator that should be returned as first value (e.g. for compare table)
   * @param includeHidden Boolean If the result should include products that are usually hidden by configuration from the ventilators list.
   */
  async getCachedVentilators(forceUpdate = false, id?: string, includeHidden = false): Promise<Ventilator[]> {
    const alreadyCached = (includeHidden && this.allProducts) || (!includeHidden && this.ventilators);

    if (alreadyCached && !forceUpdate && id === this.ventilatorId) {
      // If the ventilators have been fetched already, return the cached ones
      return Promise.resolve(includeHidden ? this.allProducts : this.ventilators);
    } else if (id) {
      this.ventilatorId = id;
    }

    return new Promise((resolve) => {
      // Otherwise call the ventilators API
      getAllVentilators(id, null, includeHidden).then((ventilators) => {
        if (includeHidden) this.allProducts = ventilators;
        else this.ventilators = ventilators;

        resolve(ventilators);
      });
    });
  }
}

const ventilatorsModule = new VentilatorsModule();
export { ventilatorsModule as ventilators };
