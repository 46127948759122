import { ComponentObservable, componentObserver } from '../../../component-observer';
import { getCaptcha, verifyCaptcha } from '../../../api/botdetect-api';
import { baseUrl } from '../../../api/general-api';

class BotdetectModule implements ComponentObservable {
  componentSelector = '.botdetect';
  styleName = '';

  /**
   * Initialize all botdetect captchas on the current page.
   * Calls the endpoint for the captcha and other elements
   * @param observe Boolean if the botdetect module should listen for changes in the DOM and initialize dynamically added
   *   captchas
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allCaptchas = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allCaptchas.length; i++) {
        this.initialize(allCaptchas[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize botdetect captchas when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  initialize(captcha: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, captcha);
    const endpoint = captcha.dataset.botdetectEndpoint;
    const form = captcha.closest('form');
    this.styleName = captcha.dataset.botdetectCaptchaStylename;
    this.getHtml(endpoint, this.styleName);
  }

  getHtml(endpoint: string, styleName: string): void {
    getCaptcha(endpoint, styleName).then((html) => {
      document.getElementById('botdetect-captcha').innerHTML = this.changeRelativeToAbsoluteUrls(html);
      this.loadScriptIncludes(endpoint, styleName);
    });
  }

  changeRelativeToAbsoluteUrls(originCaptchaHtml?: string, endpoint?: string): string {
    originCaptchaHtml = originCaptchaHtml.replace(/<script.*<\/script>/g, '');
    const relativeUrls = originCaptchaHtml.match(/(src|href)="([^"]+)"/g);

    let relativeUrl,
      relativeUrlPrefixPattern,
      absoluteUrl,
      changedCaptchaHtml = originCaptchaHtml;

    for (let i = 0; i < relativeUrls.length; i++) {
      relativeUrl = relativeUrls[i].slice(0, -1).replace(/src="|href="/, '');
      relativeUrlPrefixPattern = new RegExp('.*' + endpoint);
      absoluteUrl = relativeUrl.replace(relativeUrlPrefixPattern, baseUrl + endpoint);
      changedCaptchaHtml = changedCaptchaHtml.replace(relativeUrl, absoluteUrl);
    }

    return changedCaptchaHtml;
  }

  loadScriptIncludes(endpoint: string, styleName: string): void {
    const captchaId = (document.getElementById('BDC_VCID_' + styleName) as HTMLInputElement).value;
    const scriptIncludeUrl = endpoint + '?get=script-include&c=' + styleName + '&t=' + captchaId + '&cs=2';
    const elem = document.createElement('script');
    elem.src = scriptIncludeUrl;
    document.body.appendChild(elem);
  }

  getInstance(): unknown {
    const instance = null;
    if (typeof window.botdetect !== 'undefined') {
      return window.botdetect.getInstanceByStyleName(this.styleName);
    }
    return instance;
  }

  getCaptchaId(): string {
    return this.getInstance().captchaId;
  }

  getCaptchaCode(): string {
    return this.getInstance().userInput.value;
  }

  getUserEnteredCaptchaCode(): string {
    return this.getCaptchaCode();
  }

  reloadImage(): void {
    this.getInstance().reloadImage();
  }
}

const botdetectModule = new BotdetectModule();
export { botdetectModule as botdetect };
