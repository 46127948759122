/**
 * API to retrieve the image for captcha verification
 */

/**
 * Returns the HTML for the captcha image
 */
export const getCaptcha = (endpoint?: string, styleName?: string): Promise<string> => {
  let url = endpoint;

  const params: Record<string, string> = {};
  params.get = 'html';
  params.c = styleName;
  url = `${url}?${new URLSearchParams(params).toString()}`;

  return fetch(url.toString())
    .then((response) => response.text())
    .then((data) => {
      return data;
    })
    .catch(() => {
      return '';
    });
};

export const verifyCaptcha = async (url?: string, code?: string, captchaId?: string): boolean => {
  const captchaData = {
    userEnteredCaptchaCode: code,
    captchaId
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Cotent-Type': 'application/json; charset=utf-8'
    },
    body: JSON.stringify(captchaData)
  });
  const response = await res.json();

  if (!response) return false;
  if (!response.responseJSON) return false;
  if (!response.responseJSON.success) return false;
  if (response.responseJSON.success === false) return false;

  return true;
};
