/**
 * Parameter that can be set and read from the url
 */
export enum Param {
  TAB = 'tab',
  CATEGORY = 'category',
  SEARCH_PHRASE = 'phrase',
  GLOBAL_SEARCH_PHRASE = 'global-search-phrase',
  GLOBAL_SEARCH_TYPE = 'global-search-type',
  FILTER = 'filter',
  CUSTOMER = 'customer',
  VENTILATOR = 'ventilator',
  PRODUCT = 'product',
  CUSTOM_URL = 'custom',
  RESOURCE_DETAIL_TYPE = 'resource-detail-type',
  RESOURCE_DETAIL_ID = 'resource-detail-id',
  RESOURCE_LANGUAGE = 'resource-lang',
  NEWS_DETAIL_ID = 'news-id',
  INCLUDE_RESOURCES_EN = 'include-resources-en',
  QR_VIEW = 'qr-view',
  PN = 'pn'
}

/**
 * Represents a single GET parameter in the URL.
 */
export interface UrlParamInfo {
  /* Parameter name */
  param: Param;
  /* Parameter value */
  value: string;
}

/**
 * Writes and removes the given URL parameters to the current URL.
 * @param parameters Either a single or list of GET parameters that should be appended to the current URL.
 * @param removeParams A list of parameters to remove from the current URL.
 * @param noHistory: If the changes of the URL should be pushed to the browser history or not. If true the user
 * will be able to navigate back/forth to this page. If false, the current URL will be replaced in the browser history
 * by the new URL.
 * @param noEvent: If the custom url change event should be fired. This event is used in the e-catalog and resources center to
 * listen for changes in the URL and reload/rerender the page.
 */
export const writeToUrl = (
  parameters: UrlParamInfo | UrlParamInfo[],
  removeParams: Param[] = [],
  noHistory = false,
  noEvent = false
): void => {
  let paramList: UrlParamInfo[] = [];

  if (parameters) {
    if (!Array.isArray(parameters)) {
      // Create an array with the new parameters (in case there was only a single parameter passed)
      paramList = [parameters];
    } else if (parameters) {
      paramList = parameters;
    }
  }

  const url = window.location;
  // Remove parameter and hashtags from the current url to get the base url with path
  const baseUrl = url.href.split(/[?#]/)[0];
  // Set the new url parameter
  const params = new URLSearchParams(url.search);

  for (const paramInfo of paramList) {
    // add each new GET parameter
    params.set(paramInfo.param.toString(), paramInfo.value);
  }

  // remove the given params
  if (removeParams) {
    for (const param of removeParams) {
      params.delete(param);
    }
  }

  // Create new url from origin url and (old and new) parameter
  const newUrl = baseUrl + '?' + params.toString();

  if (!noHistory) {
    // Pushing to the history does not reload the current page, but makes it available in the browsers history
    window.history.pushState({}, '', newUrl);
  } else {
    window.history.replaceState({}, '', newUrl);
  }
  if (!noEvent) {
    // Send custom event when url has changed
    window.dispatchEvent(new Event('urlchange'));
  }
};

/**
 * Returns the value of the given parameter from the url if exists, or null otherwise.
 * @param param The parameter to get the value from the url
 */
export const readUrlParameter = (param: Param): string | null => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};

export const getLocaleFromUrl = (url) => {
  const validLocales = ['en', 'de', 'fr', 'it', 'pt', 'zh', 'en_US', 'en_AE', 'en_SA', 'en_ZA', 'en_NL', 'en_FI', 'en_SE', 'en_IE', 'en_NO',
                'en_DK', 'en_GB', 'en_IN', 'en_PK', 'en_AU', 'en_IL', 'de_CH', 'fr_CH', 'fr_FR', 'it_CH', 'it_IT', 'de_DE', 'de_AT', 'en_CA',
                'fr_CA', 'zh_CN', 'ru', 'ru_RU', 'tr', 'ja', 'ja_JP', 'es', 'es_CL', 'es_MX', 'es_ES', 'en_PT'];
  const urlParts = url.pathname.split('/');
  for (let part of urlParts) {
    if (validLocales.indexOf(part) > -1) {
      return part;
    }
  }
  return '';
}