import { ComponentObservable, componentObserver } from '../../component-observer';
import { Icon } from '../../general/icons/Icon';
import { createHtmlElementFromString, getFallbackIconForCategory } from '../../utils/html.utils';
import { HamiltonProductReduced } from '../../api/products-api';
import { getUrlForImage } from '../../utils/image.utils';
import Toastify from 'toastify-js';
import { currentBreakpoint } from '../../utils/resize.utils';

/**
 * Initializes the toast notification and removes it automatically after a specified timeout.
 */
class ToastNotificationModule implements ComponentObservable {
  /**
   * Selector of all toasts that must be initialized in javascript.
   */
  componentSelector = '.toast-notification';

  /**
   * How long the notification should be visible until it gets removed.
   */
  removeNotificationAfterDuration = 5000;

  /**
   * Position of the toast on desktop from the top edge. This distance aligns
   * the toast in the center of the header.
   */
  toastPositionTopDesktop = 104;

  /**
   * Position of the toast on tablet from the top edge. This distance aligns
   * the toast in the center of the header.
   */
  toastPositionTopTablet = 75;

  /**
   * Position of the toast on desktop and tablet from the right edge. This distance aligns
   * the toast with the product tiles right edges.
   */
  toastPositionRight = 32;

  /**
   * Markup for the 'added to shopping cart' toast.
   */
  mobileNotificationMarkup = `
  <div class="toast-notification">
      <img src="" alt=""/>
      <div class="toast-notification__content">
        <span class="toast-notification__product-id"></span>
        <p class="toast-notification__product-label text-color-dark"></p>
        <p class="toast-notification__info">${Icon.checkCircle}<span></span></p>
        <div class="button-section button-section--right">
            <button class="button button--primary button--small-icon-spacing">
                <span class="button__text"></span>
                <span class="button__icon-right">
                    ${Icon.rightArrow}
                </span>
            </button>
        </div>
        <span role="button" class="toast-notification__close-button">${Icon.closeSmall}</span>
      </div>
    </div>
  `;

  /**
   * Markup for the default success toast on mobile.
   */
  mobileSuccessNotificationMarkup = `
  <div class="toast-notification">
    <div class="toast-notification__content">
      <p class="toast-notification__text">${Icon.checkCircle}<span></span></p>
      <span role="button" class="toast-notification__close-button">${Icon.closeSmall}</span>
    </div>
  </div>
  `;

  /**
   * Markup for the default error toast on mobile.
   */
  mobileErrorNotificationMarkup = `
  <div class="toast-notification toast-notification--error">
    <div class="toast-notification__content">
      <p class="toast-notification__text">${Icon.error}<span></span></p>
      <span role="button" class="toast-notification__close-button">${Icon.closeSmall}</span>
    </div>
  </div>
  `;

  /**
   * Markup for the 'added to shopping cart' toast.
   */
  toastDesktopNotificationMarkup = `
      <div class="toast-notification toast-notification--toastify">
      <img src="" width="80" height="80" alt=""/>
      <div class="toast-notification__content">
        <span class="toast-notification__product-id"></span>
        <p class="toast-notification__product-label text-color-dark"></p>
        <p class="toast-notification__info">${Icon.checkCircle}<span></span></p>
      </div>
    </div>
  `;

  /**
   * Markup for the default success toast.
   */
  toastDesktopSuccessMarkup = `
      <div class="toast-notification toast-notification--toastify">
      <div class="toast-notification__content">
        <p class="toast-notification__text">${Icon.checkCircle}<span></span></p>
      </div>
    </div>
  `;

  /**
   * Markup for the default error toast.
   */
  toastDesktopErrorMarkup = `
      <div class="toast-notification toast-notification--toastify toast-notification--error">
      <div class="toast-notification__content">
        <p class="toast-notification__text">${Icon.error}<span></span></p>
      </div>
    </div>
  `;

  /**
   * Create a confirmation toast, that the given product was successfully added to the cart or wishlist.
   */
  createMobileToast(
    product: HamiltonProductReduced,
    addedToLabel: string,
    goToLabel: string,
    buttonClick: () => void
  ): void {
    const toast = createHtmlElementFromString(this.mobileNotificationMarkup);
    const toastNotificationContent = toast.querySelector('.toast-notification__content');

    toast.querySelector<HTMLElement>('.toast-notification__product-id').innerText = product.partNumber;
    toast.querySelector<HTMLElement>('.toast-notification__product-label').innerText = product.productName;
    toast.querySelector<HTMLElement>('.toast-notification__info span').innerText = addedToLabel;
    toast.querySelector<HTMLElement>('.button__text').innerText = goToLabel;
    toast.querySelector<HTMLImageElement>('img').alt = product.productName;
    toast.querySelector<HTMLElement>('.button--primary').addEventListener('click', buttonClick);

    const imageUrl = getUrlForImage(product.imageSmall);
    const itemImage = toast.querySelector('img');
    if (imageUrl) {
      // if there is an image for the product, show it
      itemImage.src = imageUrl;
    } else {
      // otherwise show the small version of the category icon for this product
      toast.removeChild(itemImage);
      const categoryIconLarge = getFallbackIconForCategory(product.icons, false);
      toast.insertBefore(categoryIconLarge, toastNotificationContent);
    }

    document.body.append(toast);
  }

  /**
   * Create a default success message depending on the current screen size.
   * @param label The label to show
   */
  createSuccessToastMessage(label: string): void {
    this.createMessageDependingOnScreenSize(
      label,
      this.mobileSuccessNotificationMarkup,
      this.toastDesktopSuccessMarkup
    );
  }

  /**
   * Create a default error message depending on the current screen size.
   * @param label The label to show
   */
  createErrorToastMessage(label: string): void {
    this.createMessageDependingOnScreenSize(
      label,
      this.mobileErrorNotificationMarkup,
      this.toastDesktopErrorMarkup,
      'toastify--error'
    );
  }

  /**
   * Create a default message depending on the current screen size with the given markup for mobile and desktop.
   * @param label The label to show
   * @param mobileMarkup The markup for the mobile view
   * @param desktopMarkup The markup for the desktop view
   * @param customClass Optional class for toastify
   */
  createMessageDependingOnScreenSize(
    label: string,
    mobileMarkup: string,
    desktopMarkup: string,
    customClass?: string
  ): void {
    const breakpoint = currentBreakpoint();
    const toastTopPosition = breakpoint.isTablet ? this.toastPositionTopTablet : this.toastPositionTopDesktop;
    if (breakpoint.isTablet || breakpoint.isDesktop) {
      const toast = createHtmlElementFromString(desktopMarkup);
      toast.querySelector<HTMLElement>('.toast-notification__text span').innerText = label;
      Toastify({
        node: toast,
        duration: this.removeNotificationAfterDuration,
        close: true,
        className: customClass,
        offset: {
          x: this.toastPositionRight - 15, // horizontal axis, minus 15px which is the default right position of the toast
          y: toastTopPosition - 15 // vertical axis, minus 15px which is the default top position of the toast
        }
      }).showToast();
    } else {
      const toast = createHtmlElementFromString(mobileMarkup);
      toast.querySelector<HTMLElement>('.toast-notification__text span').innerText = label;
      document.body.append(toast);
    }
  }

  /**
   * Creates a toast message to show on desktop.
   * @param product The product to show
   * @param addedToCartLabel The label text
   */
  createDesktopToast(product: HamiltonProductReduced, addedToCartLabel: string): void {
    const toast = createHtmlElementFromString(this.toastDesktopNotificationMarkup);
    const toastNotificationContent = toast.querySelector('.toast-notification__content');
    const breakpoint = currentBreakpoint();
    const toastTopPosition = breakpoint.isTablet ? this.toastPositionTopTablet : this.toastPositionTopDesktop;

    toast.querySelector<HTMLElement>('.toast-notification__product-id').innerText = product.partNumber;
    toast.querySelector<HTMLElement>('.toast-notification__product-label').innerText = product.productName;
    toast.querySelector<HTMLElement>('.toast-notification__info span').innerText = addedToCartLabel;
    toast.querySelector<HTMLImageElement>('img').alt = product.productName;

    const imageUrl = getUrlForImage(product.imageSmall);
    const itemImage = toast.querySelector('img');
    if (imageUrl) {
      // if there is an image for the product, show it
      itemImage.src = imageUrl;
    } else {
      // otherwise show the small version of the category icon for this product
      toast.removeChild(itemImage);
      const categoryIconLarge = getFallbackIconForCategory(product.icons, false);
      toast.insertBefore(categoryIconLarge, toastNotificationContent);
    }

    Toastify({
      node: toast,
      duration: this.removeNotificationAfterDuration,
      close: true,
      offset: {
        x: this.toastPositionRight - 15, // horizontal axis, minus 15px which is the default right position of the toast
        y: toastTopPosition - 15 // vertical axis, minus 15px which is the default top position of the toast
      }
    }).showToast();
  }

  /**
   * Initialize all toast notification on the current page.
   * @param observe Boolean if the toastNotificationModule should listen for changes in the DOM and initialize dynamically
   *   added toast notifications
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allCookieComponents = document.querySelectorAll(
          `${this.componentSelector}:not(.toast-notification--toastify)`
      );
      for (let i = 0; i < allCookieComponents.length; i++) {
        this.initialize(allCookieComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize toast notifications when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given toast notification element.
   * @param toastNotification The toast notification root element that should be initialized
   */
  initialize(toastNotification: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, toastNotification);
    const closeButton = toastNotification.querySelector('.toast-notification__close-button');

    const closeToast = (): void => {
      if (toastNotification) {
        toastNotification.classList.add('toast-notification--hidden');
        setTimeout(() => {
          toastNotification.remove();
        }, 400);
      }
    };

    if (!toastNotification.classList.contains('toast-notification--always-visible')) {
      // automatically remove the notification after a period of time
      setTimeout(() => {
        closeToast();
      }, this.removeNotificationAfterDuration);
    }

    // Close toast notification on click
    closeButton?.addEventListener('click', () => closeToast(), false);
  }
}

const toastNotificationModule = new ToastNotificationModule();
export { toastNotificationModule as toastNotification };
