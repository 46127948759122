/**
 * Returns the current size of the window (including the scroll bar so it equals css breakpoints)
 */
export const currentWindowWidth = (): number =>
  document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;

/**
 * Breakpoints that represents the current device size
 */
export type Breakpoint = {
  isMobilePortrait: boolean;
  isMobileLandscape: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLarge: boolean;

  /* If the current breakpoint is any of the mobile breakpoints */
  isMobileAny: boolean;

  /* If on the current breakpoint the product animation should be shown */
  isProductAnimation: boolean;
};

/**
 * Returns the current breakpoint depending on the current size of the window
 */
export const currentBreakpoint = (): Breakpoint => {
  const width = currentWindowWidth();
  return {
    isMobilePortrait: width <= 479,
    isMobileLandscape: width <= 767 && width > 480,
    isTablet: width <= 991 && width > 767,
    isDesktop: width > 991,
    isLarge: width > 1440,
    isMobileAny: width <= 767,
    isProductAnimation: width > 833
  };
};

export const equalBreakpoints = (b1: Breakpoint, b2: Breakpoint): boolean => {
  return (
    b1.isMobilePortrait === b2.isMobilePortrait &&
    b1.isMobileLandscape === b2.isMobileLandscape &&
    b1.isTablet === b2.isTablet &&
    b1.isDesktop === b2.isDesktop &&
    b1.isLarge === b2.isLarge
  );
};
