import { ComponentObservable, componentObserver } from '../../../component-observer';

/**
 * Initializes the animation of the inputs that will be triggered on mouseover and mouseout.
 */
class InputModule implements ComponentObservable {
  /**
   * Selector of all inputs that must be initialized in javascript.
   */
  componentSelector = '.input';

  /**
   * Initialize all inputs on the current page.
   * Sets the animation listener for all inputs.
   * @param observe Boolean if the inputModule should listen for changes in the DOM and initialize dynamically added
   *   inputs
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allInputs = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allInputs.length; i++) {
        this.initialize(allInputs[i] as HTMLInputElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize inputs when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given input element.
   * Sets the listeners for starting the animation on mouseover and mouseout.
   * @param input The input root element that should be initialized
   */
  initialize(input: HTMLInputElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, input);
    const label = document.querySelector(`[for="${input.getAttribute('id')}"]`);
    // Focus listener
    const focusListener = (): void => {
      label.classList.add('label--sup');
      label.classList.add('label--active');
    };
    // Blur listener
    const blurListener = (event: FocusEvent): void => {
      const inputElement = event.currentTarget as HTMLInputElement;
      label.classList.remove('label--active');
      if (inputElement.value.length === 0) {
        label.classList.remove('label--sup');
      }
    };


    if (input.value.length > 0) {
      label.classList.add('label--sup');
    }

    // Adds listeners to the input
    if (!input.disabled) {
      input.addEventListener('focus', focusListener, false);
      input.addEventListener('blur', blurListener, false);
    } else {
      label.classList.remove('label--active');
      label.classList.add('label--disabled');
    }
  }

  /**
   * Reset the state of the given input field. Removes any text from it,
   * blurs it and displays the full size label again.
   */
  resetInput(input: HTMLInputElement): void {
    input.value = '';
    input.blur();
    const label = input.parentElement?.querySelector('label');
    label?.classList.remove('label--active');
    label?.classList.remove('label--sup');
  }
}

const inputModule = new InputModule();
export { inputModule as input };
