// contactForm
import { ComponentObservable, componentObserver } from '../../component-observer';

/**
 * Initializes and renders the contact component.
 */
class ContactForm implements ComponentObservable {
  /**
   * Selector of all contact form components that must be initialized in javascript.
   */
  componentSelector = '#contactForm';

  /**
   * Listen for changes in DOM and initialize contact form components when new ones appears
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given contact form component.
   * @param contact The contact form root element that should be initialized
   */
  initialize(contact: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, contact);
    contact.querySelector<HTMLElement>('button[type=submit]').addEventListener('click', (e) => {
        setTimeout(() => {
            contact.querySelector<HTMLElement>('button[type=submit]').style.pointerEvents = 'none';
        }, 10);
    })
  }

  /**
   * Initialize all contact form components on the current page.
   * @param observe Boolean if the contact form components should listen for changes in the DOM and initialize dynamically added contact form components
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise<void>((resolve) => {
      const allContactComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allContactComponents.length; i++) {
        this.initialize(allContactComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }
}

const contactFormModule = new ContactForm();
export { contactFormModule as contactForm };
