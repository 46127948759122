import { ComponentObservable, componentObserver } from '../../component-observer';
import {
  getSearchResults,
  getSuggestions,
  HamiltonResourceDocument,
  HamiltonResourceProduct,
  HamiltonResourceResult,
  HamiltonResourceResults
} from '../../api/search-api';
import { createHtmlElementFromString, getResourceTilePlaceholderIconForType, formatText } from '../../utils/html.utils';
import { resourceTile } from '../tiles/resource-tile/resource-tile.module';
import { getResourceDetailPageTypeFromResourceType } from '../../api/resources-api';
import { Param, readUrlParameter, writeToUrl } from '../../utils/url.utils';
import { getCatalogDetailPageUrl } from '../../api/general-api';

/**
 * Initializes the header. Make sure that there is only one header per page.
 */
export class GlobalSearch implements ComponentObservable {
  /**
   * Selector of all main navigation elements that must be initialized in javascript.
   */
  componentSelector = '.global-search-wrapper';

  /**
   * The translations for the page.
   */
  protected translations: { [key: string]: string } = null;

  /**
   * All search results that are currently loaded.
   */
  searchResults: HamiltonResourceResults = null;

  /**
   * Variable to prevent multiple calls to load more products. Is set to false
   * as long as new products are loaded from the API.
   */
  isLoadingProducts = false;

  /**
   * Keeps track of how many results are loaded/shown at the moment.
   */
  currentNumberOfResults = 10;

  /**
   * Number of results to load when the user reaches the end of the list.
   */
  resultNumberStepSize = 10;

  /**
   * When the user reaches the bottom of the page minus this distance in px, new results
   * get loaded automatically.
   */
  loadingDistanceToBottom = 500;

  /**
   * Number of results that should be loaded in the beginning on different breakpoints. Is also the
   * step size which determines how many results will be loaded on scroll when the user reaches the end
   * of the page.
   */
  numberOfResultsToDisplay = {
    large: 20,
    desktop: 9,
    tablet: 6,
    phone: 4
  };

  /**
   * The type of the current selected tab.
   */
  currentSelectedType = null;

  /**
   * Total results depending on the selected tab.
   */
  currentTotalResults = null;

  /**
   * Base URL for the eCatalog. When the user clicks on the view button of a product,
   * this url is used to build the path to the detail page of the eCatalog.
   */
  eCatalogBaseUrl;

  /**
   * Base URL for the resources center. When the user clicks on the view button of a resource,
   * this url is used to build the path to the detail page of the resources center.
   */
  resourcesCenterBaseUrl;

  /**
   * URL to retrieve article detail pages.
   */
  articlesUrl: string;

  /**
   * URL to retrieve knowledge base detail pages.
   */
  kbUrl: string;

  /**
   * Initialize all global search elements on the current page.
   * @param observe Boolean if the GlobalSearchModule should listen for changes in the DOM and initialize dynamically
   *   added global search elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allElements = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allElements.length; i++) {
        // Set an internal id to each main navigation element for mapping the intervals
        allElements[i].setAttribute('data-id', i.toString());
        this.initialize(allElements[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize global search elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given global search.
   * @param root The global search element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);

    // Load translations once
    if (!this.translations) {
      this.translations = JSON.parse(root.dataset.translations);
    }

    if (!this.eCatalogBaseUrl) {
      this.eCatalogBaseUrl = root.dataset.eCatalogBaseUrl;
    }

    if (!this.resourcesCenterBaseUrl) {
      this.resourcesCenterBaseUrl = root.dataset.resourcesCenterBaseUrl;
    }

    if (!this.articlesUrl) {
      this.articlesUrl = root.dataset.detailUrlArticles;
    }

    if (!this.kbUrl) {
      this.kbUrl = root.dataset.detailUrlKb;
    }

    this.initializeSearch(root);
    this.readSearchFromUrl(root);

    this.setupInfiniteScrollListener(root);
  }

  /**
   * Check if there are any global search parameters in the URL. If so, put the last search
   * text into the search field and preselect the last selected category.
   */
  readSearchFromUrl(root: HTMLElement): void {
    const searchPhraseFromUrl = readUrlParameter(Param.GLOBAL_SEARCH_PHRASE);
    const searchTypeFromUrl = readUrlParameter(Param.GLOBAL_SEARCH_TYPE);

    if (searchPhraseFromUrl && !searchTypeFromUrl) {
      const input = root.querySelector<HTMLInputElement>('.search-field__input');
      input.value = searchPhraseFromUrl;
      this.onSearch(root);
    } else if (searchPhraseFromUrl) {
      const input = root.querySelector<HTMLInputElement>('.search-field__input');
      input.value = searchPhraseFromUrl;
      this.currentSelectedType = searchTypeFromUrl;
      this.onSearchWithType(root, searchTypeFromUrl);
    }
  }

  /**
   * Creates the possible search tags
   * @param root The global search root element
   * @param taxonomyCounts The counts for the taxonomies
   * @param totalResults The total results for the "all" tag
   * @param preselectedCategoryId Category that should be preselected in the tabs.
   */
  createTabs(
    root: HTMLElement,
    taxonomyCounts: Record<string, number>,
    totalResults: number,
    preselectedCategoryId: string = null
  ): void {
    const tabs = root.querySelector('ul[role="tablist"]');
    const tabData = JSON.parse(root.dataset.categories);
    let items = '';
    tabData.forEach((tab, index) => {
      const keys = (Object.keys(taxonomyCounts) as string[]).filter((key) => tab.types.indexOf(key) !== -1);
      // const key = keys.reduce((pv, cv) => Number(pv) + Number(cv), 0);
      let counter = 0;
      keys.forEach((key) => (counter += Number(taxonomyCounts[key])));
      if ((counter && counter > 0) || index === 0) {
        // set types as data attribute to pass to the search on tab click
        const isActive = preselectedCategoryId === tab.types.join() || (!preselectedCategoryId && index === 0);
        items += `<li role="presentation" class="tab${isActive ? ' tab--active' : ''}">
        <a role="tab" id="${tab.id}" data-types="${tab.types.join()}" tabindex="0">
          ${tab.name}${`<span class="tag tag--counter">${index !== 0 ? counter : totalResults}</span>`}
        </a></li>`;
      }
    });
    // Set new tabs and override old tabs
    tabs.innerHTML = items;

    // Set click listener for tabs
    const tabElements = root.querySelectorAll<HTMLElement>('.tab');
    tabElements.forEach((element) =>
      element.addEventListener('click', () => {
        // Set selected tab styling
        const selectedTab = root.querySelector('.tab--active');
        selectedTab.classList.remove('tab--active');
        element.classList.add('tab--active');
        // Reset scroll information
        this.resetScrollInformation(root);
        // Reset results
        const results = root.querySelector<HTMLElement>('.results');
        results.innerHTML = '';
        this.searchResults.results = [];
        // Get type array from anchor element
        const link = element.firstElementChild as HTMLAnchorElement;
        this.currentSelectedType = link.dataset.types; // set the current type
        this.currentTotalResults = Number((link.firstElementChild as HTMLElement).innerText); // get total results from counter
        // Search with type
        this.onSearchWithType(root);
        // Change background
        root.classList.add('global-search-wrapper--white');
      })
    );
  }

  /**
   * Resets the scroll information for the infinite loading on a new tab.
   * @param root The global search wrapper root element
   */
  resetScrollInformation(root: HTMLElement): void {
    // Scroll to top again
    root.scroll({ top: 0, behavior: 'smooth' });
    this.currentNumberOfResults = 10;
    this.isLoadingProducts = false;
    this.showLoadingIndicator(root, false);
    this.currentTotalResults = null;
    if (this.searchResults) {
      this.searchResults.results = [];
    }
  }

  /**
   * Creates a dropdown with the suggested values and removes the old one if exists.
   * @param root The global search root element
   * @param input The search input element
   * @param suggestions The suggestions to create the dropdown from
   */
  createDropdownFromSuggestions(root: HTMLElement, input: HTMLInputElement, suggestions: string[]): void {
    // Remove existing dropdown if available
    const oldDropdown = root.querySelector('.dropdown');
    oldDropdown?.remove();
    // Create items for new dropdown
    let items = '';
    suggestions.forEach((suggestion) => {
      items += `<li tabindex="0" class="dropdown__item"><span>${suggestion.replace(
        input.value.trim(),
        `<strong>${input.value.trim()}</strong>`
      )}</span></li>`;
    });
    // Create new dropdown with items and event listener
    const dropdown = createHtmlElementFromString(
      `<ul class="dropdown dropdown--open" data-reference-id="search-flyout">${items}</ul>`
    );
    // Set z-index of tab list to -1 so the dropdown is above the results
    const tabList = root.querySelector<HTMLElement>('.tablist-wrapper');
    tabList.style.zIndex = '-1';

    // Search on item select
    dropdown.addEventListener('onitemselect', (event: CustomEvent) => {
      input.value = event.detail.target.innerText;
      this.onSearch(root);
    });
    input.parentElement.parentElement.appendChild(dropdown);
  }

  /**
   * Initialize the search field and listen for changes.
   * @param root The global search element
   */
  initializeSearch(root: HTMLElement): void {
    const removeButton = root.querySelector('.search-field__button');
    const input = root.querySelector<HTMLInputElement>('.search-field__input');
    const infoMessage = root.querySelector('.global-search-info');
    // Listen to input events
    input.addEventListener('input', () => {
      infoMessage.setAttribute('aria-hidden', 'true');
      if (input.value.length > 1) {
        getSuggestions(input.value).then((suggestions) => {
          if (suggestions.length > 0) {
            this.createDropdownFromSuggestions(root, input, suggestions);
          }
        });
      } else if (input.value.length >= 0) {
        // Show and hide remove button depending on input length
        input.value.length > 0
          ? removeButton.removeAttribute('disabled')
          : removeButton.setAttribute('disabled', 'disabled');
        // Hide dropdown
        const dropdown = root.querySelector('.dropdown--open');
        dropdown?.remove();
        // Make sure that the dropdown overlays the tab list and remove style on dropdown close
        const tabList = root.querySelector<HTMLElement>('.tablist-wrapper');
        tabList.removeAttribute('style');
      }
    });
    // Search on blur
    input.addEventListener('blur', () => {
      // Set timeout to avoid blur on dropdown listener
      setTimeout(() => {
        const dropdown = root.querySelector('.dropdown--open');
        dropdown?.remove();
        // Make sure that the dropdown overlays the tab list and remove style on dropdown close
        const tabList = root.querySelector<HTMLElement>('.tablist-wrapper');
        tabList.removeAttribute('style');
        if (input.value.length > 0) {
          this.onSearch(root);
        }
      }, 500);
    });
    // Search on enter
    input.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        // Remove results
        const results = root.querySelector('.results');
        results.innerHTML = '';
        // Search
        input.blur();
      } else {
        return false;
      }
    });

    const clearSearch = (): void => {
      input.value = '';
      removeButton.setAttribute('disabled', 'disabled');
      infoMessage.setAttribute('aria-hidden', 'true');
      const tabs = root.querySelector('ul[role="tablist"]');
      tabs.setAttribute('aria-hidden', 'true');
      // Remove results
      const results = root.querySelector('.results');
      results.innerHTML = '';
      // Remove style
      root.classList.remove('global-search-wrapper--white');
      results.parentElement.classList.add('results-wrapper--empty');
      this.resetScrollInformation(root);
      this.currentSelectedType = null;
      this.currentNumberOfResults = 10;
    };

    // Add event listener to the remove button
    removeButton.addEventListener('click', () => clearSearch());
    // Delete search if global search is closed
    window.addEventListener('globalsearchclosed', () => clearSearch());
  }

  /**
   * Triggers the actual search
   * @param root The global search root element
   */
  onSearch(root: HTMLElement): void {
    // Remove results
    const results = root.querySelector('.results');
    const input = root.querySelector<HTMLInputElement>('.search-field__input');
    const tabs = root.querySelector<HTMLElement>('ul[role="tablist"]');
    const info = root.querySelector('.global-search-info');
    results.innerHTML = '';
    getSearchResults(input.value).then((data) => {
      writeToUrl({ param: Param.GLOBAL_SEARCH_PHRASE, value: input.value }, [Param.GLOBAL_SEARCH_TYPE], false, true);
      this.searchResults = data;
      if (data?.results.length > 0) {
        this.createTabs(root, data.taxonomyCounts, data.totalResults);
        tabs.removeAttribute('aria-hidden');
        info.innerHTML = `${data.totalResults} <span class="text-color-light">${this.translations.searchResults} "${input.value}"</span>`;
        info.removeAttribute('aria-hidden');
        this.renderResourceTiles(data.results, root);

        formatText(root);
      } else {
        // Show empty state
        info.innerHTML = `${this.translations.emptyResults} <span class="text-color-light">${this.translations.resultsRecheck}</span>`;
        info.removeAttribute('aria-hidden');
        tabs.setAttribute('aria-hidden', 'true');
        root.classList.remove('global-search-wrapper--white');
      }
    });
  }

  /**
   * Triggers the actual search with type
   * @param root The global search root element
   * @param preselectedCategoryId If a tab should be preselected, then this is the ID of the tab
   */
  onSearchWithType(root: HTMLElement, preselectedCategoryId: string = null): void {
    const input = root.querySelector<HTMLInputElement>('.search-field__input');
    getSearchResults(input.value, this.currentSelectedType).then((data) => {
      if (this.currentSelectedType) {
        writeToUrl(
          [
            { param: Param.GLOBAL_SEARCH_PHRASE, value: input.value },
            { param: Param.GLOBAL_SEARCH_TYPE, value: this.currentSelectedType }
          ],
          null,
          false,
          true
        );
      } else {
        writeToUrl(
          [{ param: Param.GLOBAL_SEARCH_PHRASE, value: input.value }],
          [Param.GLOBAL_SEARCH_TYPE],
          false,
          true
        );
      }
      this.showLoadingIndicator(root, false);
      if (data.results.length > 0) {
        if (this.searchResults) {
          this.searchResults.results = [...this.searchResults.results, ...data.results];
        } else {
          // the result data (resources) comes from the call with the selected category
          this.searchResults = data;

          // we need to make an additional request to the API without the category filter, to retrieve the taxonomy counts
          // for the other categories as well.
          getSearchResults(input.value).then((data) => {
            this.searchResults.taxonomyCounts = data.taxonomyCounts;

            // when coming from a URL, we need to create the tabs
            const tabs = root.querySelector<HTMLElement>('ul[role="tablist"]');
            const info = root.querySelector('.global-search-info');
            this.createTabs(root, data.taxonomyCounts, data.totalResults, preselectedCategoryId);
            tabs.removeAttribute('aria-hidden');
            info.innerHTML = `${data.totalResults} <span class="text-color-light">${this.translations.searchResults} "${input.value}"</span>`;
            info.removeAttribute('aria-hidden');
          });
        }

        this.renderResourceTiles(data.results, root);
      }
    });
  }

  /**
   * Renders the tiles for the resources (everything except products)
   * @param data The result data
   * @param root The global search root element
   */
  async renderResourceTiles(data: HamiltonResourceResult[], root: HTMLElement): Promise<void> {
    let content = '';
    const results = root.querySelector('.results');
    results.parentElement.classList.remove('results-wrapper--empty');
    for (const result of data) {
      if (result.type === 'catalog-articles') {
        content = resourceTile.renderTileProduct(result as HamiltonResourceProduct, this.translations);
      } else {
        content = resourceTile.renderTileDocument(result as HamiltonResourceDocument, this.translations);
      }
      const tileElement = createHtmlElementFromString(content);
      if (result.type !== 'catalog-articles') {
        const imgElem = tileElement.querySelector<HTMLElement>('img');
        if (imgElem) {
          imgElem.addEventListener('error', (e) => {
            const fallbackElement = createHtmlElementFromString(
              getResourceTilePlaceholderIconForType((result as HamiltonResourceDocument).tagValue)
            );
            if (
              (result as HamiltonResourceDocument).mimeType &&
              (result as HamiltonResourceDocument).mimeType.toLowerCase().indexOf('ppt') > -1
            ) {
              (e.currentTarget as HTMLImageElement).src =
                '/.resources/hamilton-theme/hamilton-medical-theme/webresources/css/images/ppt.jpg';
            } else {
              (e.currentTarget as HTMLElement).replaceWith(fallbackElement);
            }
          });
        }
      }
      if (this.resourcesCenterBaseUrl && this.eCatalogBaseUrl) {
        this.setupClickListenerForDetailPage(tileElement, result, result.type === 'catalog-articles');
      }
      results.appendChild(tileElement);
    }
  }

  /**
   * Listen for clicks on the resource tiles and redirect to the detail page in the respective center (either
   * e-catalog or resources center).
   */
  setupClickListenerForDetailPage(tile: HTMLElement, result: HamiltonResourceResult, isProduct: boolean): void {
    const buttonMobile = tile.querySelector(`.${resourceTile.resourceTileDetailButtonClass}`);
    const buttonDesktop = tile.querySelectorAll(`.${resourceTile.resourceTileDetailButtonClass}`)[1];
    const title = tile.querySelector('.tile__headline');

    if (isProduct) {
      const detailButtonClickListenerProduct = (): void => {
        const paramSeparator = this.resourcesCenterBaseUrl.includes('?') ? '&' : '?';
        const url = `${this.eCatalogBaseUrl}${paramSeparator}product=${result.nodeid}`;
        window.location.href = url;
      };

      buttonMobile.addEventListener('click', detailButtonClickListenerProduct);
      buttonDesktop.addEventListener('click', detailButtonClickListenerProduct);
      title?.addEventListener('click', detailButtonClickListenerProduct);
    } else {
      const detailPageType = getResourceDetailPageTypeFromResourceType(result.type);

      const detailButtonClickListenerResourceEmbeddedPage = (): void => {
        const paramSeparator = this.resourcesCenterBaseUrl.includes('?') ? '&' : '?';
        const url = `${this.resourcesCenterBaseUrl}${paramSeparator}resource-detail-type=${result.type}&resource-detail-id=${result.nodeid}`;
        window.location.href = url;
      };

      const detailButtonClickListenerStandalonePage = (): void => {
        const detailPageUrl = getCatalogDetailPageUrl(
          this.articlesUrl.replace('.html', ''),
          result.nodeid,
          result.type
        );
        window.location.href = detailPageUrl;
      };

      const detailButtonClickListenerStandalonePageKb = (): void => {
        const detailPageUrl = getCatalogDetailPageUrl(
            this.kbUrl.replace('.html', ''),
            result.nodeid,
            result.type
        );
        window.location.href = detailPageUrl;
      };

      if (detailPageType) {
        if (result.type === 'news') {
          buttonMobile.addEventListener('click', detailButtonClickListenerStandalonePage);
          buttonDesktop.addEventListener('click', detailButtonClickListenerStandalonePage);
          title?.addEventListener('click', detailButtonClickListenerStandalonePage);
        } else if (result.type === 'knowledge-base') {
          buttonMobile.addEventListener('click', detailButtonClickListenerStandalonePageKb);
          buttonDesktop.addEventListener('click', detailButtonClickListenerStandalonePageKb);
          title?.addEventListener('click', detailButtonClickListenerStandalonePageKb);
        } else {
          buttonMobile.addEventListener('click', detailButtonClickListenerResourceEmbeddedPage);
          buttonDesktop.addEventListener('click', detailButtonClickListenerResourceEmbeddedPage);
          title?.addEventListener('click', detailButtonClickListenerResourceEmbeddedPage);
        }
      }
    }
  }

  /**
   * When the user scrolls to the bottom of the page, automatically load more items
   */
  setupInfiniteScrollListener(root: HTMLElement): void {
    let lastScrollTop = root.scrollTop;

    let isThrottling = false;
    const scrollListener = (): void => {
      const currentDocumentHeight = root.clientHeight + lastScrollTop;
      const totalDocumentHeight = root.scrollHeight;

      lastScrollTop = root.scrollTop;
      if (!isThrottling) {
        setTimeout(() => {
          if (currentDocumentHeight >= totalDocumentHeight - this.loadingDistanceToBottom) {
            this.loadMoreResults(root);
          }
          isThrottling = false;
        }, 16);
      }
      isThrottling = true;
    };

    root.addEventListener('scroll', scrollListener, false);
  }

  /**
   * show or hide the loading indicator.
   */
  showLoadingIndicator(root: HTMLElement, show: boolean): void {
    const loadingIndicator = root.querySelector<HTMLElement>('.indicators--loading');
    if (loadingIndicator && !show) {
      loadingIndicator.remove();
    } else if (!loadingIndicator && show) {
      root.appendChild(
        createHtmlElementFromString(`<div class="indicators indicators--loading">
      <span class="indicator indicator--active"></span>
      <span class="indicator indicator--active"></span>
      <span class="indicator indicator--active"></span>
    </div>`)
      );
    }
  }

  /**
   * Retrieve the results.
   */
  fetchResultData(search: string, limit: number): Promise<HamiltonResourceResults> {
    return new Promise((resolve) => {
      const offset = limit - this.resultNumberStepSize;
      getSearchResults(search, this.currentSelectedType, this.resultNumberStepSize, offset).then((results) => {
        resolve(results);
      });
    });
  }

  /**
   * Call this function to load more products because the user scrolled to the end of the products list.
   */
  loadMoreResults(root: HTMLElement): void {
    // now fetch the products
    if (!this.isLoadingProducts) {
      const totalResults = this.currentTotalResults || this.searchResults.totalResults;
      const moreProductsAvailable = this.currentNumberOfResults < totalResults;
      if (moreProductsAvailable) {
        this.showLoadingIndicator(root, true);
        this.isLoadingProducts = true;
        const newNumberOfResults = this.currentNumberOfResults + this.resultNumberStepSize;
        const input = root.querySelector<HTMLInputElement>('.search-field__input');
        this.fetchResultData(input.value, newNumberOfResults).then((data) => {
          if (data.results.length > 0) {
            this.searchResults.results = [...this.searchResults.results, ...data.results];
            this.renderResourceTiles(data.results, root);
            this.currentNumberOfResults += this.resultNumberStepSize;
            this.isLoadingProducts = false;
            this.showLoadingIndicator(root, false);
          }
        });
      } else {
        // hide loading indicator
        this.showLoadingIndicator(root, false);
      }
    }
  }
}

const globalSearchModule = new GlobalSearch();
export { globalSearchModule as globalSearch };
