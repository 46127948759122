import { ComponentObservable, componentObserver } from '../../../component-observer';

/**
 * Initializes the animation of the inputs that will be triggered on mouseover and mouseout.
 */
class FormModule implements ComponentObservable {
    componentSelector = '.crm-form';

    initializeAll(observe: boolean): Promise<void> {
        return new Promise((resolve) => {
            const allInputs = document.querySelectorAll(this.componentSelector);
            for (let i = 0; i < allInputs.length; i++) {
                this.initialize(allInputs[i] as HTMLFormElement);
            }

            if (observe) {
                this.startListening();
            }
            resolve();
        });
    }

    startListening(): void {
        componentObserver.subscribeListener(this);
    }

    initialize(form: HTMLFormElement): void {
        componentObserver.markElementAsInitialized(this.componentSelector, form);
        if (!window['formMappings']) {
            window['formMappings'] = {};
        }
        window['formMappings'][form.id] = [];
        const formInputs = [
            ...form.querySelectorAll('input'),
            ...form.querySelectorAll('select'),
            ...form.querySelectorAll('textarea'),
        ];
        for (let i = 0; i < formInputs.length; i++) {
            const input = formInputs[i];
            if (input.dataset.crmFieldName) {
                const mapping = {
                    FormFieldName: input.name,
                    DataverseFieldName: input.dataset.crmFieldName
                }
                if (input.dataset.crmFieldValue) {
                    mapping['DataverseFieldValue'] = input.dataset.crmFieldValue
                }
                console.log(mapping);
                window['formMappings'][form.id].push(mapping)
            }
        }

        const submitButton = form.querySelector('button[type="submit"]');
        submitButton.addEventListener('click', (e) => {
            const serializedForm = window.d365mktformcapture.serializeForm(form, window['formMappings'][form.id]);
            console.log(JSON.stringify(serializedForm));
            const payload = serializedForm.SerializedForm.build();
            const captureConfig = {
                FormId: form.dataset.crmId,
                FormApiUrl: form.dataset.crmFormUrl
            }
            window.d365mktformcapture.submitForm(captureConfig, payload).then(() => {
                form.submit();
            });
        });
    }
}

const formModule = new FormModule();
export { formModule as form };
