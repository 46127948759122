import { baseUrl } from '../api/general-api';

/**
 * Returns the full URL to an image with the given path.
 * @param imagePath: Path to the image as provided by the API.
 */
export const getUrlForImage = (imagePath: string): string => {
  // Image paths sometimes contain non-breaking spaces which can not
  // be removed by the backend but break the image URL, therefore
  // we need to replace all those non-breaking spaces with regular ones.
  const nonBreakingSpace = '%C2%A0';
  const regularSpace = '%20';

  // Check if imagePath already contains the base url, in this case do nothing and return the path
  if (imagePath && imagePath.indexOf(baseUrl) === -1 && !imagePath.includes('https://')) {
    return baseUrl + imagePath.replaceAll(nonBreakingSpace, regularSpace);
  } else if (imagePath) {
    return imagePath;
  }
  return '';
};
