import { ComponentObservable, componentObserver } from '../../component-observer';
import { currentBreakpoint } from '../../utils/resize.utils';
import { createHtmlElementFromString } from '../../utils/html.utils';

/**
 * Initializes and renders the tab overflow.
 */
class TabOverflowModule implements ComponentObservable {
  /**
   * Selector of all tab overflows that must be initialized in javascript.
   */
  componentSelector = '.tab-overflow';

  /**
   * Initialize all tab overflows on the current page.
   * @param observe Boolean if the EventTableModule should listen for changes in the DOM and initialize dynamically added tab overflows
   */
  initializeAll(observe: boolean): void {
    const allTableComponents = document.querySelectorAll(this.componentSelector);
    for (let i = 0; i < allTableComponents.length; i++) {
      this.initialize(allTableComponents[i] as HTMLElement);
    }

    if (observe) {
      this.startListening();
    }
  }

  /**
   * Listen for changes in DOM and initialize tab overflows when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Converts the given tab to a dropdown item.
   * @param tab The tab to convert
   */
  convertTabToDropdownItem(tab: HTMLElement): HTMLElement {
    const tabContent = tab.querySelector('a');
    const dropdown = `<li class="dropdown__item" tabindex="0">${tabContent.outerHTML}</li>`;
    return createHtmlElementFromString(dropdown);
  }

  /**
   * Converts the given dropdown item to a tab.
   * @param dropdownItem The item to convert
   */
  convertDropdownItemToTab(dropdownItem: HTMLElement): HTMLElement {
    const itemContent = dropdownItem.querySelector('a');
    const tab = `<li role="presentation" class="selector-item">${itemContent.outerHTML}</li>`;
    return createHtmlElementFromString(tab);
  }

  /**
   * Moves all dropdown elements back to the tabs.
   * @param root The tab overflow root element
   * @param dropdownList The dropdown list
   */
  moveAllDropdownElementsToTabs(root: HTMLElement, dropdownList: HTMLElement): void {
    const item = dropdownList.querySelectorAll('li');
    const tabContainer = root.querySelector<HTMLElement>('ul[role="tablist"]');
    item.forEach((item) => {
      tabContainer.appendChild(this.convertDropdownItemToTab(item));
      item.remove();
    });
    root.dispatchEvent(new CustomEvent('ontabschanged', {}));
  }

  /**
   * Checks the length of each tab and if all tabs fit to the available space.
   * Moves the tabs in a dropdown if there is too little space.
   * @param root The tab overflow root element
   */
  initializeTabs(root: HTMLElement): void {
    // Always move the dropdown elements back to the tabs
    const dropdownList = root.querySelector<HTMLElement>('.dropdown');
    this.moveAllDropdownElementsToTabs(root, dropdownList);

    if (!currentBreakpoint().isMobileAny) {
      const menuButton = root.querySelector<HTMLButtonElement>('.button--dropdown');
      const totalWidth = root.scrollWidth; // Available space
      let totalTabWidth = 0;
      let longestValueIndex = -1;
      let tabsToHideIndex = -1;

      const tabs = root.querySelectorAll<HTMLElement>('.selector-item');
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i];
        const tabWidth = tab.scrollWidth; // width of each tab
        if (longestValueIndex === -1) {
          totalTabWidth += tabWidth; // calculate total width of tabs
        }
        // check once if tab fits in the available width
        if (totalTabWidth > totalWidth && longestValueIndex === -1) {
          // tab does not fit anymore
          longestValueIndex = i - 1; // set longest value to the previous element
          tabsToHideIndex = i - 1; // beginning of the tabs that are too long
        }

        // check the length of the following tabs
        if (longestValueIndex !== -1 && tabWidth > tabs[longestValueIndex].scrollWidth) {
          longestValueIndex = i;
        }
      }

      // Add event listener only if there is no listener set yet and there is not enough space
      if (tabsToHideIndex > 0) {
        // Show "more" button
        dropdownList.parentElement.removeAttribute('style');
        // Check if there is enough space for the longest value
        const enoughSpace = totalTabWidth - tabs[longestValueIndex].scrollWidth + menuButton.scrollWidth <= totalWidth;
        if (tabsToHideIndex > 0) {
          for (let i = enoughSpace ? tabsToHideIndex : tabsToHideIndex - 1; i < tabs.length; i++) {
            // all tabs fit in the available space
            dropdownList.appendChild(this.convertTabToDropdownItem(tabs[i]));
            tabs[i].remove();
          }
        }
      } else {
        // Hide "more" button if enough space is available
        dropdownList.parentElement.style.display = 'none';
      }
    }
  }

  /**
   * Sets up a click listener to the dropdown element to move the dropdown items back to the tabs on click.
   * @param root The tab overflow root element
   * @param dropdownList The dropdown list element
   * @param event The item select event
   */
  dropdownListener(root: HTMLElement, dropdownList: HTMLElement, event: CustomEvent): void {
    // Moves the item from the dropdown to the tabs on select
    const dropdownItem = event.detail.target;
    // Get the last current tab, that will be moved to the dropdown list, remove it from the tabs
    const lastTab = root.querySelector<HTMLElement>('.selector-item:last-child');
    // Append the clicked dropdown item to the tabs and remove it afterwards from the dropdown
    const dropdownItemElement = this.convertDropdownItemToTab(dropdownItem);
    lastTab.parentElement.appendChild(dropdownItemElement);
    dropdownItem.remove();
    dropdownList.appendChild(this.convertTabToDropdownItem(lastTab));
    lastTab.remove();
    // Throw custom event when a tab is selected from the dropdown
    root.dispatchEvent(new CustomEvent('ontabselected', { detail: dropdownItemElement }));
    root.dispatchEvent(new CustomEvent('ontabschanged', {}));
  }

  /**
   * Initialize the given tab overflow.
   * @param root The tab overflow root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    if (root) {
      componentObserver.markElementAsInitialized(this.componentSelector, root);
      this.initializeTabs(root);
      this.listenForWindowResize(root);

      const dropdownList = root.querySelector<HTMLElement>('.dropdown');

      dropdownList.addEventListener('onitemselect', (event: CustomEvent) => {
        this.dropdownListener(root, dropdownList, event);
      });
    }
  }

  /**
   * Setup resize listeners that update the tab overflow on resize.
   */
  listenForWindowResize(root: HTMLElement): void {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    // Set window resize listener
    const resizeListener = (): void => {
      // Prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        this.initializeTabs(root);
      }, 500);
    };

    // Set resize listener
    window.addEventListener('resize', resizeListener);
  }
}

const tabOverflowModule = new TabOverflowModule();
export { tabOverflowModule as tabOverflow };
