import { ComponentObservable, componentObserver } from '../../component-observer';
import {selectWishListOverlay} from "./e-catalog-overlays/select-wish-list-overlay.module";
//import { selectWishListOverlay } from './e-catalog-overlays/select-wish-list-overlay.module';

/**
 * Initializes all overlays. This sets click listeners on the background dimmer and the close button.
 */
class OverlayModule implements ComponentObservable {
  /**
   * Attribute that all buttons have, that should open an overlay.
   */
  componentSelector = '.overlay-wrapper';

  /**
   * Class name for buttons that should close the overlay.
   */
  closeButtonClassName = 'button--close-overlay';

  /**
   * Move here from select-wish-list-overlay.module.ts for separation
   */
  selectWishlistOverlayId = 'select-wish-list-overlay';

  /**
   * Initialize all buttons that trigger overlays on the current page.
   * @param observe Boolean if the OverlayTriggerButtonModule should listen for changes in the DOM and initialize dynamically added
   *   buttons
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const overlays = document.querySelectorAll(this.componentSelector);

      const singleInstances = [];
      for (let i = 0; i < overlays.length; i++) {
        // Remove any duplicate
        if (singleInstances.filter((overlay) => overlay.id === overlays[i].id).length === 0) {
          singleInstances.push(overlays[i]);
          document.body.appendChild(overlays[i]); // Move every overlay to the body so that it's always visible if triggered
          this.initialize(overlays[i] as HTMLElement);
        } else {
          overlays[i].remove();
        }
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize overlays when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Close the overlay on click of the close button or the background dimmer.
   * @param event The keyboard or mouse event
   */
  closeOverlay(event: MouseEvent): void {
    let overlayWrapper = event.currentTarget as HTMLElement;
    const clickWasOnCloseButton =
      overlayWrapper.classList.contains('button--overlay') ||
      overlayWrapper.classList.contains(this.closeButtonClassName);
    const clickWasOutsideOverlay =
      overlayWrapper.classList.contains('overlay-wrapper') && event.currentTarget === event.target;

    // Check if menu is open so we don't remove no-scroll
    if (document.querySelector('.menu-button')) {
      if (document.querySelector('.menu-button').ariaExpanded !== 'true') {
        document.querySelector('html').classList.remove('no-scroll');
      }
    } else {
      document.querySelector('html').classList.remove('no-scroll');
    }

    while (!overlayWrapper.classList.contains('overlay-wrapper')) {
      // if the close button was clicked, search for the overlay root element
      overlayWrapper = overlayWrapper.parentElement;
    }

    if (overlayWrapper && (clickWasOutsideOverlay || clickWasOnCloseButton)) {
      // Stop video on close (if available)
      if (overlayWrapper.classList.contains('overlay-wrapper--video') || overlayWrapper.classList.contains('overlay-wrapper--media')) {
        const video = overlayWrapper.querySelector('iframe');
        if (video) {
          video.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
        }
      }

      // Remove login parameter from url on close (if it's a login overlay)
      const isLogin = overlayWrapper.querySelector('.overlay--login');

      if (isLogin) {
        const url = new URL(window.location.href);
        url.searchParams.delete('login');
        window.history.replaceState({}, '', url.toString());
      }

      // Remove profile parameter from url on close (if it's a profile overlay)
      const isProfile = overlayWrapper.querySelector('.overlay--profile');

      if (isProfile) {
        const url = new URL(window.location.href);
        url.searchParams.delete('profile');
        window.history.replaceState({}, '', url.toString());
      }

      // Remove media parameter from url on close (if it's a media overlay)
      const isMedia = overlayWrapper.querySelector('.overlay--media');

      if (isMedia) {
        const url = new URL(window.location.href);
        url.searchParams.delete('media');
        window.history.replaceState({}, '', url.toString());
        document.querySelector('html').classList.remove('no-scroll');
      }

      // close the overlay only if the click was not inside the overlay and bubbled up to the wrapper
      overlayWrapper.classList.add('animation-fade-out');
      setTimeout(() => {
        overlayWrapper.classList.remove('overlay--visible');
      }, 50);

      setTimeout(() => {
        if (window.localStorage && window.localStorage.getItem('scrollPosition')) {
          window.scroll(0, parseInt(window.localStorage.getItem('scrollPosition')));
          window.localStorage.removeItem('scrollPosition');
        }
      }, 50);
    }
  }

  /**
   * Close the given overlay.
   */
  close(overlay: HTMLElement): void {
    overlay.classList.add('animation-fade-out');
    setTimeout(() => {
      overlay.classList.remove('overlay--visible');
    }, 50);

    setTimeout(() => {
      if (window.localStorage && window.localStorage.getItem('scrollPosition')) {
        window.scroll(0, parseInt(window.localStorage.getItem('scrollPosition')));
        window.localStorage.removeItem('scrollPosition');
      }
    }, 50);
  }

  /**
   * Initialize the given overlay element.
   * Sets the listeners for click events.
   * @param overlay The overlay wrapper element
   */
  initialize(overlay: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, overlay);
    overlay.addEventListener('click', this.closeOverlay.bind(this), false);
    overlay.querySelector('.button--overlay').addEventListener('click', this.closeOverlay.bind(this), false);

    const closeButtons = overlay.querySelectorAll(`.${this.closeButtonClassName}`);
    for (const button of closeButtons) {
      // all buttons that have a specific class name should close the overlay
      button.addEventListener('click', this.closeOverlay.bind(this), false);
    }

    if (overlay.id === this.selectWishlistOverlayId) {
      // if the overlay is a select wish list overlay, we need to setup a few more things
      document.dispatchEvent(new CustomEvent('selectWishListOverlayInitialize', { detail: overlay }));
      //selectWishListOverlay.initialize(overlay);
    }
  }
}

const overlayModule = new OverlayModule();
export { overlayModule as overlay };
