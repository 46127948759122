import { ComponentObservable, componentObserver } from '../../component-observer';
import anime from 'animejs/lib/anime.es';
import { smoothScrollToElement } from '../../utils/helper.utils'

/**
 * Initializes accordions to toggle their states.
 */
class AccordionModule implements ComponentObservable {
  /**
   * Selector of all dropdown buttons that must be initialized in javascript.
   */
  componentSelector = '.accordion';

  /**
   * Initialize all accordions on the current page.
   * Sets listener for all accordions.
   * @param observe Boolean if the AccordionModule should listen for changes in the DOM and initialize dynamically added
   *   accordions
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const accordions = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < accordions.length; i++) {
        this.initialize(accordions[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Toggle an accordion. This function will show/hide the content of the accordion.
   * @param mouseClickEvent The click event that kicked off the opening/closing of the accordion
   */
  toggleAccordion(mouseClickEvent: MouseEvent): void {
    mouseClickEvent.stopPropagation();
    const button = mouseClickEvent.currentTarget as HTMLElement;
    const accordion = button.parentElement;
    const content = accordion.querySelector('.accordion__content') as HTMLElement;

    // Check if the accordion belongs to a data-group; in this case all other opened accordions should be closed
    const group = accordion.getAttribute('data-group');

    // Animate the height of the accordion
    const animateHeight = (close: boolean, target: HTMLElement): void => {
      let targetHeight = 0;

      if (!close) {
        targetHeight = target.scrollHeight;
      } else {
        // set a fixed height for smooth animation
        target.style.height = target.clientHeight + 'px';
        // Restore the original overflow specification
        target.parentElement.style.overflow = null;
      }

      anime({
        targets: target,
        height: targetHeight,
        duration: 50,
        easing: 'easeInOutQuad',
        complete: () => {
          setTimeout(() => {
            if (!close) {
              target.style.height = 'auto';
              // Allow the tooltips to be visible even outside of the accordion element
              accordion.style.overflow = 'visible';   
              
              if (group)
                smoothScrollToElement(accordion, 100);
            }
          }, 300);
        }
      });
    };

    // Closes the given accordion and updates the attributes
    const closeAccordion = (accordion: HTMLElement, button: HTMLElement, content: HTMLElement): void => {
      accordion.classList.add('accordion--closed');
      button.setAttribute('aria-expanded', 'false');
      animateHeight(true, content);
    };

    // Check if the accordion belongs to a data-group; in this case all other opened accordions should be closed
    if (group) {
      const accordions = document.querySelectorAll(`[data-group="${group}"]:not(.accordion--closed)`);
      accordions.forEach((element: HTMLElement) => {
        if (element !== accordion) {
          const button = element.querySelector('.accordion__header') as HTMLElement;
          const content = element.querySelector('.accordion__content') as HTMLElement;
          closeAccordion(element, button, content);
        }
      });
    }

    // Toggle accordion visibility
    if (accordion.classList.contains('accordion--closed')) {
      accordion.classList.remove('accordion--closed');
      button.setAttribute('aria-expanded', 'true');
      animateHeight(false, content);
    } else {
      // Close accordion
      closeAccordion(accordion, button, content);
    }
  }

  /**
   * Open the accordion without animation.
   */
  setAccordionOpen(accordion: HTMLElement): void {
    accordion.classList.remove('accordion--closed');
    accordion.querySelector('.accordion__header').setAttribute('aria-expanded', 'true');
    // Allow the tooltips to be visible even outside of the accordion element
    accordion.style.overflow = 'visible';
  }

  /**
   * Listen for changes in DOM and initialize accordions when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the accordion element.
   * Sets the listeners for toggle and key events.
   * @param accordion The accordion root element that should be initialized
   */
  initialize(accordion: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, accordion);
    const button = accordion.querySelector('.accordion__header') as HTMLElement;
    const content = accordion.querySelector('.accordion__content') as HTMLElement;
    // Set initial height of the accordion
    if (content.offsetHeight !== 0) {
      let height = content.offsetHeight;
      height += parseInt(window.getComputedStyle(content).getPropertyValue('margin-bottom'));
      content.setAttribute('data-height', height + 'px');
      // After saving the initial height, set height to 0 when accordion is closed
      if (accordion.classList.contains('accordion--closed')) {
        content.setAttribute('style', 'height: 0');
        button.setAttribute('aria-expanded', 'false');
      } else {
        button.setAttribute('aria-expanded', 'true');
        // Allow the tooltips to be visible even outside of the accordion element
        accordion.style.overflow = 'visible';
      }
      // Remove maximum height attribute to show accordion content when visible (otherwise it is now hidden by height = 0)
      accordion.style.maxHeight = 'none';
    }
    // Set click listener on accordion
    button.addEventListener('click', this.toggleAccordion, false);
  }
}

const accordionModule = new AccordionModule();
export { accordionModule as accordion };
