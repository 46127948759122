import { baseUrl } from './general-api';

/**
 * Base URL for the ventilators API.
 */
export const baseVentilationModesPath = baseUrl + '/.rest/delivery/v1/ventilation-modes?limit=10000';
export const baseVentilationModesCompaniesPath =
  baseUrl + '/.rest/delivery/v1/ventilation-modes-companies?orderBy=companyName&limit=10000';

/**
 * The ventilation modes that will be returned from the ventilation modes api.
 */
export interface VentilationMode {
  /**
   * (Translated) name of the ventilation mode (should not be used in the frontend
   * because apparently trademark icons are not shown in this).
   */
  '@name': string;
  /**
   * (Translated) name of the ventilation mode.
   */
  name: string;
  /**
   * The name of the group where the ventilation modes belong to
   */
  group: string;
  /**
   * Ventilation mode id.
   */
  '@id': string;

  /**
   * Description of the ventilation mode.
   */
  description?: string;
  /**
   * Images of the ventilators for small and large image.
   */
  image: {
    renditions: {
      small: {
        link: string;
      };
      large: {
        link: string;
      };
    };
  };
  /**
   * Image Large of the ventilators for small and large image.
   */
  imageLarge?: {
    renditions: {
      small: {
        link: string;
      };
      large: {
        link: string;
      };
    };
  };
}

/**
 * The ventilation mode company that will be returned from the ventilation modes companies api.
 */
export interface VentilationModeCompany {
  /**
   * Name of the company
   */
  companyName: string;
  /**
   * Name / id of the ventilation mode company
   */
  '@name': string;
}

/**
 * Returns a list of all ventilation modes.
 */
export const getAllVentilationModes = (): Promise<VentilationMode[]> => {
  const url = baseVentilationModesPath;
  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      return data.results || [];
    })
    .catch(() => {
      return [];
    });
};

/**
 * Returns a list of all ventilation modes.
 */
export const getAllVentilationModesCompanies = (): Promise<VentilationModeCompany[]> => {
  const url = baseVentilationModesCompaniesPath;
  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      return data.results || [];
    })
    .catch(() => {
      return [];
    });
};
