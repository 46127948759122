import { ComponentObservable, componentObserver } from '../../component-observer';

/**
 *
 */
class ProductInfoBoxModule implements ComponentObservable {
  /**
   * Selector of all product info boxes that must be initialized in javascript.
   */
  componentSelector = '.product-info-box';
  /**
   * Initialize all product info boxes on the current page.
   * @param observe Boolean if the productInfoBoxModule should listen for changes in the DOM and initialize dynamically
   *   added product info boxes
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allProductInfoBoxComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allProductInfoBoxComponents.length; i++) {
        this.initialize(allProductInfoBoxComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize product info boxes when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given product info box element.
   * @param productInfoBox The product info box root element that should be initialized
   */
  initialize(productInfoBox: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, productInfoBox);
  }
}

const productInfoBoxModule = new ProductInfoBoxModule();
export { productInfoBoxModule as productInfoBox };
