import { ComponentObservable, componentObserver } from '../../component-observer';

/**
 * Initializes the text and image component with slider function
 */
class TextAndImageComponentModule implements ComponentObservable {
  /**
   * Selector of all text and image components with slider that must be initialized in javascript.
   */
  componentSelector = '.text-and-image-component--gallery';

  /**
   * Duration in milliseconds after which the gallery automatically switches to the next image.
   */
  imageGalleryInterval = 5000;

  /**
   * Initialize all text and image components with slider on the current page.
   * @param observe Boolean if the TextAndImageComponentModule should listen for changes in the DOM and
   *  initialize dynamically added text and image components
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allElements = document.querySelectorAll<HTMLElement>(this.componentSelector);
      for (let i = 0; i < allElements.length; i++) {
        if (!allElements[i].getAttribute('data-initialized')) {
          this.initialize(allElements[i] as HTMLElement);
        }
        componentObserver.markElementAsInitialized(this.componentSelector, allElements[i]);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize text and image components when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Hides the images that are not shown and sets the elements with the given index active
   * @param index The index of the current visible/active element
   * @param thumbnails The thumbnail images
   * @param images The image elements
   */
  setElementsActive(index: number, thumbnails: NodeListOf<Element>, images: NodeListOf<Element>): void {
    for (let i = 0; i < images.length; i++) {
      i === index ? thumbnails[i].classList.add('active') : thumbnails[i].classList.remove('active');
      i === index ? images[i].setAttribute('aria-hidden', 'false') : images[i].setAttribute('aria-hidden', 'true');
    }
  }

  /**
   * Initialize the given text and image component with slider element.
   * @param component The text and image component with slider that should be initialized
   */
  initialize(component: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, component);
    // Get all images and thumbnails
    const images = component.querySelectorAll('.text-and-image-component__image-wrapper > img');
    const thumbnails = component.querySelectorAll('.text-and-image-component__thumbnails > img');

    if (images.length === 0 || thumbnails.length === 0) {
      return;
    }

    if (images.length !== thumbnails.length) {
      /* eslint-disable no-console */
      console.error('There must be a thumbnail for each image element and vice versa');
      return;
    }

    // Create array from thumbnails to get the index of a thumbnail image
    const thumbnailArray = Array.from(thumbnails);

    // Indicator click listener
    const thumbnailClickListener = (event: MouseEvent): void => {
      const thumbnail = event.currentTarget as HTMLElement;
      this.setElementsActive(thumbnailArray.indexOf(thumbnail), thumbnails, images);

      // stop automatically switching images
      clearInterval(sliderIntervalId);
    };

    // Initialize all images and thumbnails (first element is visible/active, all others are hidden/not active)
    for (let i = 0; i < images.length; i++) {
      images[i].setAttribute('aria-hidden', String(i !== 0));
      i === 0 ? thumbnails[i].classList.add('active') : thumbnails[i].classList.remove('active');
      thumbnails[i].addEventListener('click', thumbnailClickListener);
    }

    // Show one image after another
    // ID of the interval that automatically switches images
    // used to cancel the interval when the user clicks an image
    const sliderIntervalId = setInterval(() => {
      const activeElement = component.querySelector('img.active');
      const next = activeElement.nextElementSibling || thumbnails[0];
      this.setElementsActive(thumbnailArray.indexOf(next), thumbnails, images);
    }, this.imageGalleryInterval);
  }
}

const textAndImageComponentModule = new TextAndImageComponentModule();
export { textAndImageComponentModule as textAndImageComponent };
