import { ComponentObservable, componentObserver } from '../../component-observer';

/**
 * Initializes the hero section with slider function
 */
class HeroSectionModule implements ComponentObservable {
  /**
   * Selector of all hero sections with slider that must be initialized in javascript.
   */
  componentSelector = '.hero-section--slider';

  /**
   * Interval to change the slides automatically in ms
   */
  interval = 8000;

  /**
   * Map to store the intervals for each hero section
   */
  intervalMap = new Map();

  /**
   * Initialize all hero sections with slider on the current page.
   * @param observe Boolean if the HeroSectionModule should listen for changes in the DOM and initialize dynamically
   *   added hero sections
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allElements = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allElements.length; i++) {
        // Set an internal id to each hero section element for mapping the intervals
        allElements[i].setAttribute('data-id', i.toString());
        this.initialize(allElements[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize hero sections when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Creates the footer and indicators depending on the given number. It also adds a listener to the indicators.
   * @param images The images to slide
   * @param contents The contents to slide
   */
  createIndicators(images: NodeListOf<Element>, contents: NodeListOf<Element>): HTMLElement {
    // Build footer
    const footer = document.createElement('div');
    footer.classList.add('hero-section__footer');
    const navigator = document.createElement('div');
    navigator.classList.add('hero-section__navigator');
    // Add indicators
    for (let i = 0; i < images.length; i++) {
      const span = document.createElement('span');
      span.innerText = '0' + (i + 1);
      span.setAttribute('data-indicator', i.toString());
      span.addEventListener('click', (event) => this.onIndicatorClick(event, images, contents), false);
      navigator.appendChild(span);
    }
    footer.appendChild(navigator);
    // Add progress bar
    const progressBar = document.createElement('div');
    progressBar.classList.add('hero-section__progress');
    footer.appendChild(progressBar);
    return footer;
  }

  /**
   * Gets the index of the clicked indicator and calls the method to change the slide.
   * @param event The click event
   * @param images The images to update
   * @param contents The contents to update
   */
  onIndicatorClick(event: MouseEvent, images: NodeListOf<Element>, contents: NodeListOf<Element>): void {
    const indicator = event.target as HTMLElement;
    const heroSection = images.item(0).parentElement;
    // Update slide and progress
    this.changeSlide(Number(indicator.getAttribute('data-indicator')), images, contents);
    this.updateProgress(heroSection);
    // Stop current interval and create a new one
    const interval = this.intervalMap.get(heroSection.getAttribute('data-id'));
    clearInterval(interval);
    this.intervalMap.delete(interval);
    this.createInterval(heroSection, images, contents);
  }

  /**
   * Function to set the slide with the given index visible.
   * It also updates the selected indicator and sets the images and content to show / hide.
   * @param index The index of the slide that should be visible
   * @param images The images to update
   * @param contents The contents to update
   */
  changeSlide(index: number, images: NodeListOf<Element>, contents: NodeListOf<Element>): void {
    // Deselect the old indicator if exist
    const deselect = images.item(0).parentElement.querySelector('.selected');
    deselect && deselect.classList.remove('selected');
    // Select the new active indicator
    const selected = images.item(0).parentElement.querySelector(`[data-indicator='${index}']`);
    selected.classList.add('selected');
    // Hide and show the image and contents
    for (let i = 0; i < images.length; i++) {
      images[i].setAttribute('aria-hidden', i === index ? 'false' : 'true');
      contents[i].setAttribute('aria-hidden', i === index ? 'false' : 'true');
      // Remove attribute to animate the opacity from 0 to 1
      if (i === index) {
        setTimeout(() => {
          images[i].removeAttribute('aria-hidden');
          contents[i].removeAttribute('aria-hidden');
        }, 50);
      }
    }
  }

  /**
   * Restarts the progress in the progress bar
   * @param heroSection The hero section that contains the progress bar
   */
  updateProgress(heroSection: HTMLElement): void {
    const progressBar = heroSection.querySelector('.hero-section__progress') as HTMLElement;
    progressBar.style.animationName = 'none';
    setTimeout(() => {
      progressBar.removeAttribute('style');
    }, 50);
  }

  /**
   * Creates an interval to change the slides automatically
   * @param heroSection The hero section element
   * @param images The images to change
   * @param contents The contents to change
   */
  createInterval(heroSection: HTMLElement, images: NodeListOf<Element>, contents: NodeListOf<Element>): void {
    const interval = setInterval(() => {
      const selected = heroSection.querySelector('.selected').getAttribute('data-indicator');
      const currentIndex = Number(selected);
      const nextIndex = currentIndex < images.length - 1 ? currentIndex + 1 : 0;
      this.changeSlide(nextIndex, images, contents);
      this.updateProgress(heroSection);
    }, this.interval);
    this.intervalMap.set(heroSection.getAttribute('data-id'), interval);
  }

  /**
   * Initialize the given hero section with slider element.
   * @param heroSection The hero section with slider that should be initialized
   */
  initialize(heroSection: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, heroSection);
    const images = heroSection.querySelectorAll('.hero-section__image');
    const contents = heroSection.querySelectorAll('.hero-section__content');
    if (images.length !== contents.length) {
      /* eslint-disable no-console */
      console.error('There must be an image for each content element and vice versa');
      return;
    }
    // Create indicators for each image and progress bar
    const footer = this.createIndicators(images, contents);
    contents[0].parentElement.appendChild(footer);
    // Set first image/content element selected and hide the others
    this.changeSlide(0, images, contents);
    // Change slides automatically
    this.createInterval(heroSection, images, contents);
  }
}

const heroSectionModule = new HeroSectionModule();
export { heroSectionModule as heroSection };
