import { ComponentObservable, componentObserver } from '../../component-observer';
import { createHtmlElementFromString, formatText } from '../../utils/html.utils';
import { getAllVentilators } from '../../api/ventilators-api';
import { getUrlForImage } from '../../utils/image.utils';
import { baseUrl } from '../../api/general-api';
import { getAllTaxonomyCategories } from '../../api/taxonomies-api';

/**
 * Initializes the product selector elements.
 */
class ProductSelectorModule implements ComponentObservable {
  /**
   * Selector of all elements that must be initialized in javascript.
   */
  componentSelector = '.section--product-selector';

  /**
   * Id for the all id of the tags
   */
  defaultAllId = 'product-selector-all';

  /**
   * Markup for a product selector tile
   */
  tileMarkup = `<a href="#" class="tile tile--product-selector tile--isolated-image" data-animate-when-visible="fade-in-up">
    <div class="tile__image"><img src="" alt="" /></div>
    <div class="tile__content">
      <h4 class="tile__headline"></h4>
      <h5 class="tile__indicator-text"></h5>
      <div class="tile__indicator-bar-wrapper">
        <div class="tile__indicator-bar"></div>
      </div>
      <h5 class="tile__indicator-text"></h5>
      <div class="tile__indicator-bar-wrapper">
        <div class="tile__indicator-bar"></div>
      </div>
      <button class="button"></button>
    </div>
  </a>`;

  /**
   * Initialize all product selectors on the current page.
   * @param observe Boolean if the TechnologyOverviewModule should listen for changes in the DOM and
   *    initialize dynamically added elements
   */
  initializeAll(observe: boolean): Promise<void> {
    return new Promise((resolve) => {
      const allComponents = document.querySelectorAll(this.componentSelector);
      for (let i = 0; i < allComponents.length; i++) {
        this.initialize(allComponents[i] as HTMLElement);
      }

      if (observe) {
        this.startListening();
      }
      resolve();
    });
  }

  /**
   * Listen for changes in DOM and initialize elements when new ones appear
   */
  startListening(): void {
    componentObserver.subscribeListener(this);
  }

  /**
   * Initialize the given filter element.
   * Sets the listener for resize.
   * @param root The product selector root element that should be initialized
   */
  initialize(root: HTMLElement): void {
    componentObserver.markElementAsInitialized(this.componentSelector, root);
    this.createInteractiveTags(root);
    this.initializeEmptyStateButton(root);
    this.renderProducts(root);
  }

  /**
   * Renders the products and sets empty state if none is available.
   * @param root The product selector root element
   * @param filter The id of the selected tag (filter)
   */
  renderProducts(root: HTMLElement, filter?: string): void {
    let container = root.querySelector('.tile__grid');
    // Remove old container with all content first, then create a new one
    if (container) {
      container.remove();
    }
    container = document.createElement('div');
    container.classList.add('tile__grid', 'tile__grid--product-selector');
    root.lastElementChild.insertBefore(container, root.lastElementChild.firstElementChild);

    // Hide empty state before showing the loading state
    const emptyState = root.querySelector('.products-empty');
    emptyState.setAttribute('aria-hidden', 'true');

    // Render loading state
    const skeletonTile = '<a class="tile tile--product-selector tile--skeleton"></a>';
    container.innerHTML = skeletonTile.repeat(3);

    getAllVentilators(null, filter).then((data) => {
      // Check if empty state is shown
      emptyState.setAttribute('aria-hidden', (data.length > 0).toString());
      // Remove loading state
      const skeletons = container.querySelectorAll('.tile--skeleton');
      skeletons.forEach((skeleton) => skeleton.remove());

      // Render products if available
      data.forEach((product) => {
        const item = createHtmlElementFromString(this.tileMarkup);
        (item as HTMLAnchorElement).href = baseUrl + product.catalogArticleLink;
        item.querySelector<HTMLElement>('.tile__headline').innerText = product.name;
        item.querySelector<HTMLElement>('.button').innerText = root.dataset.labelViewProduct;
        const indicatorText = item.querySelectorAll<HTMLElement>('.tile__indicator-text');
        indicatorText[0].innerText = root.dataset.labelAdvancedFeatures;
        (
          indicatorText[0].nextElementSibling.firstElementChild as HTMLElement
        ).style.width = `${product.advancedFeaturesPercent}%`;
        indicatorText[1].innerText = root.dataset.labelMobility;
        (
          indicatorText[1].nextElementSibling.firstElementChild as HTMLElement
        ).style.width = `${product.mobilityPercent}%`;

        if (product.image) {
          const image = item.querySelector<HTMLImageElement>('.tile__image img');
          image.src = getUrlForImage(product.image.renditions?.small?.link);
          image.alt = product.name;
        }

        container.appendChild(item);
      });

      formatText(root);
    });
  }

  /**
   * Creates the interactive tags from the taxonomies and renders loading tags until the rest api responses.
   * @param root The product selector root element
   */
  createInteractiveTags(root: HTMLElement): void {
    const interactiveTags = root.querySelector('.interactive-tags');
    const tagName = 'product-selector-filter';
    // Create 'all' option
    const input = createHtmlElementFromString(
      `<input type="radio" id="${this.defaultAllId}" name="${tagName}" value="${root.dataset.labelAll}" checked>`
    );
    input.addEventListener('change', () => this.renderProducts(root));
    const label = `<label for="${this.defaultAllId}">${root.dataset.labelAll}</label>`;
    interactiveTags.appendChild(input);
    interactiveTags.appendChild(createHtmlElementFromString(label));

    // Create loading tags
    const loadingTag = '<span class="tag tag--loading"></span>';
    const loadingDelayTag = '<span class="tag tag--loading tag--loading-delay"></span>';
    interactiveTags.appendChild(createHtmlElementFromString(loadingTag));
    interactiveTags.appendChild(createHtmlElementFromString(loadingDelayTag));
    interactiveTags.appendChild(createHtmlElementFromString(loadingTag));

    // Create tags for all other taxonomies
    getAllTaxonomyCategories(undefined, 'f40d13ac-445a-453a-819e-92f68baf2099').then((data) => {
      // Remove loading state
      const loadingTags = root.querySelectorAll('.tag');
      loadingTags.forEach((tag) => tag.remove());

      if (data.length) {
        // Render real tags
        data[0].taxonomies.forEach((taxonomy) => {
          const input = createHtmlElementFromString(
            `<input type="radio" id="${taxonomy['@id']}" name="${tagName}" value="${taxonomy['@name']}">`
          );
          // Add click listener for tags
          input.addEventListener('change', (event) => {
            const id = (event.target as HTMLElement).id;
            this.renderProducts(root, id);
          });
          interactiveTags.appendChild(input);
          // Create label
          const label = `<label for="${taxonomy['@id']}">${taxonomy.label || taxonomy.name}</label>`;
          interactiveTags.appendChild(createHtmlElementFromString(label));
        });
      }
    });
  }

  /**
   * Setup the button for the empty state to select all devices again.
   * @param root The product selector root element
   */
  initializeEmptyStateButton(root: HTMLElement): void {
    const button = root.querySelector('.products-empty button');
    button?.addEventListener('click', () => {
      // Set all tag selected
      const tagAll = root.querySelector<HTMLInputElement>(`#${this.defaultAllId}`);
      tagAll.checked = true;
      // Load all devices
      this.renderProducts(root);
    });
  }
}

const productSelectorModule = new ProductSelectorModule();
export { productSelectorModule as productSelector };
